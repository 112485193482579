import React, { useState, useEffect } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import Filter from './subscriber-filter';
import { BulkUploadForm } from './bulk-upload-form';
//import { ISubscriberResponse } from '@lipihipi/client-sdk/dist/subscriber';
import _ from 'lodash';
import Styled from 'styled-components';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
//import { ICourseListResponse } from '@lipihipi/client-sdk/dist/course';
import { ISubsriberListProps } from './subscriber-list.types';

import {
  ActionButton,
  ListItemAction,
  Menu,
  PageHeader,
  PaginatedTable,
  IconButton,
} from '@lipihipi/ec-ui';
import { commonApiError } from './subscriber-report';

export const Avatar = Styled.div<{ url: string }>`
	background-image: url(${({ url }: any) => url});
`;

export const Title = Styled.span`
  color: #434544;
  font-size: 14px;
  font-weight: 500;
  text-align: left;

`;

export const Wrapper = Styled.div`
  display: flex;
  align-items: center;
`;

export const SubscriberList = ({
  isAdmin,
  getSubscriber,
  getCourses,
  title,
  breadCrumbs,
  upload,
  onCreate,
  onAddStudentClick,
  getGroup,
  getAssetUrl,
  updateStudentProfile,
  onEdit,
  onViewReport,
  currentModulePermission,
  getLoginToken,
}: ISubsriberListProps) => {
  const _window = window as any;

  const [modal, setModal] = React.useState<{
    visible: boolean;
    _id?: string;
  }>({
    visible: false,
  });
  const [params, setParams] = useState<{
    q?: string;
    course?: string;
    batchName?: string;
    page?: number;
    populate: boolean;
    groupId?: string;
  }>({
    populate: true,
    page: 1,
  });

  const [subscriber, setSubscriber] = useState<any>({
    totalItems: 0,
    users: [],
  });
  const [courses, setCourses] = useState<any>({
    totalItems: 0,
    courses: [],
  });
  const [group, setGroup] = useState<any>({
    totalItems: 0,
    groups: [],
  });

  const _getSubscriberList = () => {
    getSubscriber({ ...params })
      .then((response: any) => {
        setSubscriber(response.data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };
  const _getCourses = () => {
    getCourses({ populate: true })
      .then((response: any) => {
        setCourses(response.data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };
  const _getGroup = () => {
    getGroup({ populate: true })
      .then((response: any) => {
        setGroup(response.data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };

  useEffect(() => {
    _getCourses();
    _getGroup();
  }, []);

  useEffect(() => {
    _getSubscriberList();
  }, [params]);

  const getSelectedCourse = (student: any) => {
    const coursesId: any = [];
    const courseName: any = [];
    const selectedCourse = student?.selectedCourses;

    selectedCourse.forEach((item: any) => {
      coursesId.push(item?.course);
    });
    coursesId.forEach((value: any) => {
      courses.courses.forEach((item: any) => {
        if (item._id === value) {
          courseName.push(item?.name);
        }
      });
    });

    return courseName.join(', ');
  };

  const onClickLogin = (id: string) => {
    getLoginToken(id)
      .then(({ data }: any) => {
        _window.open(`${data.redirectUrl}?token=${data.token}`);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };

  const onClickUpdateUser = (student: any, isDeleted = false) => {
    const payload = {
      ...student,
    };

    if (isDeleted) {
      payload.isDeleted = true;
    } else {
      payload.status =
        student.status === 'APPROVED' ? 'UNAPPROVED' : 'APPROVED';
    }

    updateStudentProfile(student._id, payload)
      .then(({ status }: any) => {
        if (status === 200) {
          swal({
            title: 'Success',
            text: `User Updated`,
            icon: 'success',
            dangerMode: false,
          }).then(() => {
            _getSubscriberList();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };

  const tableHeader = [
    {
      dataRenderer: (student: any) => {
        return (
          <div className="avtar-with-text">
            <Avatar
              className="primary-avtar"
              url={getAssetUrl(student.displayPicture)}
            />
            <div className="wrap">
              <p className="avtar-name">{student.name}</p>
              <p className="avtar-email">{student.email}</p>
              <p className="avtar-mobile">{student.mobile}</p>
            </div>
          </div>
        );
      },
      title: 'Name',
      width: isAdmin
        ? 'calc(100% - (200px + 150px + 120px + 105px + 70px ))'
        : 'calc(100% - (200px + 150px + 120px + 105px))',
    },
    {
      dataRenderer: (student: any) => (
        <div className="subject">{student.eduId}</div>
      ),
      title: 'EnrollmentId',
      width: '100px',
    },
    {
      dataRenderer: (student: any) => (
        <div className="subject">
          {student?.selectedCourses?.length ? (
            <p>{getSelectedCourse(student)}</p>
          ) : (
            <p className="subject">Not Available</p>
          )}
        </div>
      ),
      title: 'Course',
      width: '100px',
    },
    {
      dataRenderer: (student: any) => (
        <div className="subject">
          {student?.group?.name ? <p>{student?.group?.name}</p> : '-'}
        </div>
      ),
      title: 'Group Name',
      width: '120px',
    },
    {
      dataRenderer: (student: any) => (
        <div className="subject">
          {moment(student.createdAt).format('DD MMM yyyy')}
        </div>
      ),
      title: 'Subsribed Date',
      width: '120px',
    },
    {
      dataRenderer: (student: any) => (
        <>
          <div
            className={
              'status ' +
              (student.status === 'APPROVED' ? 'approved' : 'pending')
            }
          >
            {student.status}
          </div>
        </>
      ),
      title: 'Status',
      width: '105px',
    },
    {
      dataRenderer: (student: any) => (
        <ListItemAction className="action-button">
          <ActionButton>
            <BsThreeDotsVertical />
          </ActionButton>
          <Menu>
            {currentModulePermission?.includes('update') && (
              <li
                onClick={() => {
                  onEdit(student._id);
                }}
              >
                Edit
              </li>
            )}
            <li
              onClick={() => {
                onClickUpdateUser(student);
              }}
            >
              {student.status === 'APPROVED' ? 'Un-approve' : 'Approve'}
            </li>
            <li
              onClick={() => {
                onViewReport(student?._id);
              }}
            >
              View Report
            </li>
            <li
              onClick={() => {
                onClickLogin(student?._id);
              }}
            >
              Login
            </li>
          </Menu>
        </ListItemAction>
      ),
      title: '',
      width: '70px',
    },
  ];
  if (!isAdmin) {
    tableHeader.pop();
  }

  const handleClose = () => {
    setModal({ visible: false });
  };

  const handleOpen = () => {
    setModal({ visible: true });
  };

  const handleSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  const getGroupList = () => {
    if (group?.groups?.length) {
      return group.groups.map((item: any) => {
        return {
          name: item.name,
        };
      });
    }

    return [];
  };

  return (
    <section className="main-structure">
      {currentModulePermission?.includes('read') ? (
        <>
          <PageHeader
            title={title || 'Manage Candidates'}
            breadCrumbs={
              breadCrumbs || [
                { title: 'Home', link: '/dashboard' },
                { title: 'Manage Candidates', link: '/' },
              ]
            }
            component={
              isAdmin ? (
                <>
                  {currentModulePermission?.includes('create') && (
                    <div className="d-flex align-items-center">
                      <IconButton
                        onClick={handleOpen}
                        className="primary-outine-button"
                      >
                        Bulk Upload
                      </IconButton>
                      <IconButton
                        onClick={onAddStudentClick}
                        className="primary-outine-button ml-3"
                      >
                        <AiOutlinePlusCircle />
                        Add a Candidate
                      </IconButton>
                    </div>
                  )}
                </>
              ) : null
            }
          />

          <Filter
            params={params}
            courses={courses}
            groups={group.groups}
            handleSearch={handleSearch}
          />
          <PaginatedTable
            columns={tableHeader}
            data={subscriber?.users ? subscriber?.users : subscriber?.students}
            totalItems={subscriber.totalItems}
            itemsPerPage={10}
            currentPage={params.page || 1}
            onPageChange={page => setParams({ ...params, page })}
          />
          <BulkUploadForm
            courses={courses}
            isOpen={modal.visible}
            onRequestClose={handleClose}
            upload={upload}
            onCreate={onCreate}
            group={getGroupList()}
            getGroup={_getGroup}
          />
        </>
      ) : (
        <div>
          <h4>Sorry!, you don't have permission to see this.</h4>
        </div>
      )}
    </section>
  );
};

export default SubscriberList;
