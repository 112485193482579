import React, { useState } from 'react';
import { PageHeader, Button, Loader } from '@lipihipi/ec-ui';
import { Form, TextArea } from '@lipihipi/form';
import { ISupport } from '@lipihipi/client-sdk/dist/support';
import { IUserComplaintProps } from './generate-complaint.types';
import { GrAttachment } from 'react-icons/gr';
import { MdCancel } from 'react-icons/md';

// @ts-ignore
import swal from 'sweetalert';
import { GenerateSupportSchema } from '../support.schema';

export const UserComplaint = ({
  onCreate,
  breadCrumbs,
  createAsset,
  getAssetUrl,
  afterGenerateTicket,
}: IUserComplaintProps) => {
  const initialValues = {
    text: '',
    displayPicture: '',
  };

  const [complaints, setComplaints] = useState<any>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileSelect = (e: any) => {
    setIsLoading(true);
    createAsset({ file: e.target.files[0] }).then(
      (res: any) => {
        setIsLoading(false);
        setComplaints((prevState: any) => ({
          ...prevState,
          displayPicture: res?.data?.key,
        }));
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const removeFile = () => {
    setIsLoading(false);
    setComplaints((prevState: any) => ({
      ...prevState,
      displayPicture: '',
    }));
  };

  const handleSubmit = (values: ISupport, { resetForm }: any) => {
    values.displayPicture = complaints.displayPicture;
    setIsLoading(true);
    onCreate(values).then(({ status }: any) => {
      setIsLoading(false);
      if (status === 200) {
        swal({
          title: 'Sucess',
          text: 'Ticket has been generated',
          icon: 'success',
        });
        afterGenerateTicket();
      } else {
        swal({
          title: 'Error',
          text: 'Server error!',
          icon: 'error',
          dangerMode: false,
        });
      }
    });
    resetForm({ values: initialValues });
    removeFile();
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="main-mid">
        <PageHeader title={''} breadCrumbs={breadCrumbs} />
        <Form
          initialValues={initialValues}
          className="card p-3"
          style={{ maxWidth: '800px' }}
          onSubmit={handleSubmit}
          validationSchema={GenerateSupportSchema}
        >
          {/* <h3 className="text-secondary">Tell us what you want?</h3> */}
          <div className="row">
            <div className="col-md-12">
              <TextArea name="text" id="text" placeholder="Issue Details..." />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="primary-upload-ui">
                  <input
                    accept="image/.png,image/.jpg,image/*.jpeg,image/*"
                    type="file"
                    onChange={handleFileSelect}
                    id="upload"
                  />
                  <label htmlFor="upload">
                    <GrAttachment />
                  </label>
                </div>
                <p
                  className="text-secondary"
                  style={{ paddingLeft: '10px', fontSize: '14px' }}
                >
                  Attach Image
                </p>
              </div>
              <div className="my-3">
                {complaints.displayPicture && (
                  <div className="modal-image-box d-flex">
                    {
                      <img
                        width="180px"
                        src={getAssetUrl(complaints.displayPicture)}
                        alt="image"
                        style={{ borderRadius: '10px' }}
                      />
                    }
                    <span className="cross mx-2" onClick={removeFile}>
                      <MdCancel color="#000" size="20px" />
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <Button
                shape={'primary'}
                className={isLoading ? 'ml-3 loading-state ' : 'ml-3 '}
                type="submit"
              >
                {isLoading ? <span className="loader"></span> : 'Continue'}
              </Button>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
};
