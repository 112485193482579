import { PageHeader, Tab, Tabs, Button, Loader } from '@lipihipi/ec-ui';
import { CheckBox, Form, Input, Select } from '@lipihipi/form';
import { commonApiError } from '../coupon/index';
import moment from 'moment';
import React, { useEffect } from 'react';
//import { MdSearch } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import swal from 'sweetalert';

export default function RewardEdit(props: any) {
  const {
    programList,
    testList,
    interviewList,
    getCourses,
    title,
    id,
    breadCrumbs,
    onSuccessAddEdit,
    updateCoupon,
    getCouponDetails,
  } = props;
  const [loading, setLoading] = React.useState(true);
  const [state, setState] = React.useState('program');
  const [programsList, setProgramList] = React.useState([]);
  const [testsList, setTestsList] = React.useState([]);
  const [interviewsList, setinterviewsList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [courses, setCourses] = React.useState([]);
  const [courseId, setCourseID] = React.useState('');
  const [courseIDDetails, setCourseIDDetails] = React.useState([]);
  const [selectedBatch, setSelectedBatch] = React.useState([]);
  const [noEndDate, setNoEndDate] = React.useState(false);
  const [deSelect, setDeSelect] = React.useState(false);
  const [deSeletedValue, setDeSelectedValue] = React.useState([]);
  const [payload, setPayload] = React.useState([]);
  const [initialData, setData] = React.useState<any>({
    name: '',
    emailAddress: '',
    phoneNumber: '',
    coupon: '',
    discount: 0,
    maxUsagePerUser: 0,
    maxTotalUsage: 0,
    StartDate: '',
    EndDate: '',
    course: [],
    associateModule: []
  });
  const [disable, setDisable] = React.useState(false);
  const type = [
    { label: 'Influencer', value: 'INFLUENCER' },
    { label: 'Global Promotional', value: 'GLOBAL_PROMOTIONAL' }
  ];

  var selectedBatchArray: Array<any> = [];
  var selectedCourseArray: Array<any> = [];

  useEffect(() => {
    if (courseIDDetails.length != 0) {
      setProgramList([]);
      programList({ courseIds: courseIDDetails, all: 1 })
        .then((data: any) => {
          setProgramList(data?.data?.programs);
          fillEditData(data?.data?.programs);
        })
        .catch((err: any) => {
          commonApiError(err);
        });
    }
    else {
      setProgramList([]);
    }
  }, [courseIDDetails, courseId]);


  useEffect(() => {
    if (courseIDDetails.length != 0) {
      if (state === 'testbundle') {
        testList({ courseIds: courseIDDetails, all: 1 })
          .then((data: any) => {
            var values = data.data.testBundles;
            const names = values.map((o: { _id: any; }) => o._id)
            const filteredTestData = values.filter(({ _id }: any, index: number) => !names.includes(_id, index + 1));
            setTestsList(filteredTestData);
            fillEditData(filteredTestData);
          })
          .catch((err: any) => {
            commonApiError(err);
          });
      }
      else if (state === 'interview') {
        interviewList({ courseIds: courseIDDetails, all: 1 })
          .then((data: any) => {
            var values = data.data.interviews;
            const names = values.map((o: { _id: any; }) => o._id)
            const filteredInterviewData = values.filter(({ _id }: any, index: number) => !names.includes(_id, index + 1));
            setinterviewsList(filteredInterviewData);
            fillEditData(filteredInterviewData);
          })
          .catch((err: any) => {
            commonApiError(err);
          });
      }
    }
    else {
      setinterviewsList([]);
      setTestsList([]);
    }
  }, [courseId, courseIDDetails, state]);

  useEffect(() => {
    if (id) {
      getCouponDetails(id, { populate: true })
        .then((res: any) => {
          setData(res.data);
          setCourseIDDetails(res.data.course);
          setStartDate(initialData.validityStartTime);

          setEndDate(initialData.validityEndTime);
          // if (res.data.validityEndTime === null) {
          //   setNoEndDate(true)
          // }
          if (res.data.couponType === 'GLOBAL_PROMOTIONAL') {
            setDisable(true);
          }
          setLoading(false);
        })
        .catch((err: any) => {
          commonApiError(err);
        });
    }
    getCourses({ all: true })
      .then(({ data }: any) => setCourses(data.courses))
      .catch((err: any) => {
        commonApiError(err);
      });
  }, []);

  const setEndDateValue = () => {
    noEndDate ? setNoEndDate(!noEndDate) : (setNoEndDate(!noEndDate), setEndDate(null))
  };

  const fillEditData = (values: any) => {
    let batches: any = [];
    let tests: any = [];
    let interviews: any = [];
    initialData.associateModule.map((item: any) => {
      switch ((item.module_type)) {
        case 'PROGRAM':
          batches.push(item.module_id);
          filterListValue(batches, values);
          break;
        case 'TESTBUNDLE':
          tests.push(item.module_id);
          filterListValue(tests, values);
          break;
        case 'INTERVIEW':
          interviews.push(item.module_id);
          filterListValue(interviews, values);
          break;
      }
    })
    setData({ ...initialData, batches: batches, tests: tests, interviews: interviews });
  }

  useEffect(() => {
    setDeSelect(false);
  }, [deSelect]);

  const handleTypeChange = (event: any) => {
    if (event.value === 'GLOBAL_PROMOTIONAL') {
      setDisable(true);
    }
    else {
      setDisable(false);
    }
  }

  const handleSave = (values: any) => {
    var selectedModule: any = [];
    selectedBatch.map((item: any) => {
      selectedModule.push({
        module_id: item.id,
        module_type: item.module.toUpperCase(),
      });
    });
    var currentUpdatedPayload = { ...values, associateModule: selectedModule };
    console.log(initialData);
    setPayload(currentUpdatedPayload);
    if (payload.length != 0) {
      let fn = updateCoupon(id, payload);
      fn.then(({ status }: any) => {
        if (status === 200) {
          swal({
            title: 'Success',
            text: 'Coupon Update Successfully',
            icon: 'success',
            dangerMode: false,
          }).then(function () {

            onSuccessAddEdit();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
        .catch((err: any) => {
          commonApiError(err);
        });
    }

  };

  const handleCourseSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectedCourseArray.push(event.target.id);
      var coursesID = [...courseIDDetails, event.target.id];
      setCourseIDDetails(coursesID);
      setCourseID(event.target.id);
    }
    else {
      selectedCourseArray = courseIDDetails.filter(l => l !== event.target.id);
      setCourseIDDetails(selectedCourseArray);
      setCourseID(event.target.id);
    }
  };

  const filterListValue = (idList: any, valuesList: any) => {
    let filtered
    idList.map((id: any) => {
      valuesList.map((item: any) => {
        if (id === item._id) {
          selectedBatchArray.push({
            id: item._id,
            name: item.name,
            module: state,
          });
        }
        var moduleData = selectedBatch.concat(selectedBatchArray);
        const names = moduleData.map(o => o.name);
        filtered = moduleData.filter(
          ({ name }, index) => !names.includes(name, index + 1)
        );

      })
    })
    setSelectedBatch(filtered);
  }

  const setSelectedBatchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      if (selectedBatchArray.includes(event.target.id)) {
      }
      selectedBatchArray.push({
        id: event.target.id,
        name: event.target.labels[0].innerText,
        module: state,
      });

    } else {
      selectedBatchArray = selectedBatchArray.filter(
        l => l.id !== event.target.id
      );
      var deselect = {
        id: event.target.id,
        name: event.target.labels[0].innerText,
        module: state,
      }
      setDeSelectedValue([...deSeletedValue, deselect])
    }
  };

  const onAttachClick = () => {
    var totalModule = selectedBatch.concat(selectedBatchArray);
    const names = totalModule.map(o => o.name);
    const filtered = totalModule.filter(
      ({ name }, index) => !names.includes(name, index + 1)
    );
    setSelectedBatch(filtered);
  };

  const onDeAttachClick = () => {
    deSeletedValue.map((item: any) => {
      selectedBatch.map((values, index) => {
        if (values.id === item.id) {
          selectedBatch.splice(index, 1);
        }
      });
    });
    setSelectedBatch(selectedBatch);
    setDeSelect(true);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="main-structure">
          <PageHeader
            title={title || 'Edit Coupon'}
            breadCrumbs={
              breadCrumbs || [
                { title: 'Home', link: '/dashboard' },
                { title: 'Manage Rewards and Coupons', link: '/dashboard' },
                { title: 'Edit' },
              ]
            }
          />

          <Form
            initialValues={initialData}
            onSubmit={handleSave}
            render={() => (
              <>
                <div className="row">
                  <div className="col-md-4">
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      label="Name"
                      placeholder="Name"
                      disabled={disable}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      id="emailAddress"
                      name="emailAddress"
                      type="email"
                      label="Email ID"
                      placeholder="Email Address"
                      disabled={disable}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      label="Phone Number"
                      placeholder="Phone Number"
                      disabled={disable}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      id="coupon"
                      name="coupon"
                      type="text"
                      label="Coupon Code"
                      placeholder="Coupon Code"
                    />
                  </div>
                  <div className='col-md-4'>
                    <Select
                      id="couponType"
                      name="couponType"
                      label="Coupon Type"
                      placeholder="Select Type"
                      options={type}
                      onChange={e => handleTypeChange(e)}
                    />
                  </div>
                </div>

                <div className="applicable-on-courses mt-5">
                  <p>Applicable on Courses</p>
                  <ul>
                    {courses.map((dataValue: any) => {
                      return (
                        <li>
                          <CheckBox
                            id={dataValue._id}
                            name="course"
                            label={dataValue.name}
                            isArray={true}
                            onChange={event => handleCourseSelect(event)}
                            value={dataValue._id}
                          />
                        </li>
                      );
                    })
                    }
                  </ul>
                </div>

                <div className="applicable-for mt-5">
                  <p>Applicable for</p>
                  <Tabs active={state} onClick={setState}>
                    <Tab id={'program'} title={'program'}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="applicable-box">
                            <div className="wrap">
                              <div className="wrap_body">
                                <ul>
                                  {programsList.map((dataValue: any) => {
                                    return (
                                      <li>
                                        <CheckBox
                                          id={dataValue._id}
                                          name="batches"
                                          isArray={true}
                                          label={dataValue.name}
                                          onChange={e => setSelectedBatchValue(e)}
                                          value={dataValue._id}
                                        />
                                      </li>
                                    );
                                  })
                                  }
                                </ul>
                              </div>
                              <div
                                className="wrap_foot attach"
                                onClick={onAttachClick}
                              >
                                Attach to coupon
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="applicable-box">
                            <div className="wrap">
                              <div className="wrap_body">
                                <ul>
                                  {selectedBatch.map((dataValue: any) => {
                                    if (dataValue.module === 'program') {
                                      return (
                                        <li>
                                          <CheckBox
                                            id={dataValue.id}
                                            name="batches"
                                            label={dataValue.name}
                                            isArray={true}
                                            onChange={(e) => {
                                              setSelectedBatchValue(e)
                                            }
                                            }
                                            value={dataValue.id}
                                          />
                                        </li>
                                      );
                                    }
                                  })
                                  }
                                </ul>
                              </div>
                              <div
                                className="wrap_foot detach"
                                onClick={onDeAttachClick}
                              >
                                Detach from Coupon
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab id={'testbundle'} title={'Test Bundle'}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="applicable-box">
                            <div className="wrap">
                              <div className="wrap_body">
                                <ul>
                                  {testsList.map((dataValue: any) => {
                                    return (
                                      <li>
                                        <CheckBox
                                          id={dataValue._id}
                                          name={"tests"}
                                          label={dataValue.name}
                                          onChange={e => setSelectedBatchValue(e)}
                                          isArray={true}
                                          value={dataValue._id}
                                        />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              <div
                                className="wrap_foot attach"
                                onClick={onAttachClick}
                              >
                                Attach to coupon
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="applicable-box">
                            <div className="wrap">
                              <div className="wrap_body">
                                <ul>
                                  {selectedBatch.map((dataValue: any) => {
                                    if (dataValue.module === 'testbundle') {
                                      return (
                                        <li>
                                          <CheckBox
                                            id={dataValue.id}
                                            name="tests"
                                            label={dataValue.name}
                                            isArray={true}
                                            onChange={e =>
                                              setSelectedBatchValue(e)
                                            }
                                            value={dataValue.id}
                                          />
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>
                              <div
                                className="wrap_foot detach"
                                onClick={onDeAttachClick}
                              >
                                Detach from Coupon
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab id={'interview'} title={'AI Mock Interviews'}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="applicable-box">
                            <div className="wrap">
                              <div className="wrap_body">
                                <ul>
                                  {interviewsList.map((dataValue: any) => {
                                    return (
                                      <li>
                                        <CheckBox
                                          id={dataValue._id}
                                          name="interviews"
                                          label={dataValue.name}
                                          onChange={e => setSelectedBatchValue(e)}
                                          isArray={true}
                                          value={dataValue._id}
                                        />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              <div
                                className="wrap_foot attach"
                                onClick={onAttachClick}
                              >
                                Attach to coupon
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="applicable-box">
                            <div className="wrap">
                              <div className="wrap_body">
                                <ul>
                                  {selectedBatch.map((dataValue: any) => {
                                    if (dataValue.module === 'interview') {
                                      return (
                                        <li>
                                          <CheckBox
                                            id={dataValue.id}
                                            name="interviews"
                                            label={dataValue.name}
                                            isArray={true}
                                            onChange={e => setSelectedBatchValue(e)}
                                            value={dataValue.id}
                                          />
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>
                              <div
                                className="wrap_foot detach"
                                onClick={onDeAttachClick}
                              >
                                Detach from Coupon
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>

                <div className="row mt-5">
                  <div className="col-md-4">
                    <Input
                      id="discount"
                      name="discount"
                      type="number"
                      label="Discount(%)"
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      id="maxUsagePerUser"
                      name="maxUsagePerUser"
                      type="number"
                      label="Per user usage Limit"
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      id="maxTotalUsage"
                      name="maxTotalUsage"
                      type="number"
                      label="Coupon Max usage limit (total)"
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="datepicker-wrap form-group">
                      <DatePicker
                        id="validityStartTime"
                        selected={moment(initialData.validityStartTime).toDate()}
                        name="validityStartTime"
                        className="primary-form-control"
                        onChange={(date: any) => {
                          setData({ ...initialData, validityStartTime: date })
                          setStartDate(date);
                        }}
                        selectsStart
                        placeholderText="Start Date"
                        startDate={startDate}
                        endDate={endDate}
                      />
                      <FaCalendarAlt />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="datepicker-wrap form-group">
                      {initialData.validityEndTime !== null ?
                        <DatePicker
                          id="validityEndTime"
                          name="validityEndTime"
                          selected={moment(initialData.validityEndTime).toDate()}
                          className="primary-form-control"
                          onChange={(date: any) => {
                            setData({ ...initialData, validityEndTime: date });
                            setEndDate(date);
                          }}
                          selectsStart
                          placeholderText="End Date"
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          disabled={noEndDate}
                        /> :
                        <DatePicker
                          id="validityEndTime"
                          name="validityEndTime"
                          className="primary-form-control"
                          onChange={(date: any) => {
                            setData({ ...initialData, validityEndTime: date });
                            setEndDate(date);
                          }}
                          selectsStart
                          placeholderText="End Date"
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          disabled={noEndDate}
                        />
                      }
                      <FaCalendarAlt />
                    </div>
                  </div>
                  <div className="col-md-4" style={{ paddingTop: 31 }}>
                    {initialData.validityEndTime !== null ?
                      <CheckBox
                        id="end"
                        name="end"
                        label="No End Date"
                        checked={false}
                        onChange={setEndDateValue}
                      />
                      :
                      <CheckBox
                        id="end"
                        name="end"
                        label="No End Date"
                        checked={true}
                        onChange={setEndDateValue}
                      />
                    }
                  </div>
                </div>

                <div className="mt-3">
                  <Button shape="primary" type="submit">
                    Finish
                  </Button>
                </div>
              </>
            )}
          />
        </section>
      )}
    </>
  );
}
