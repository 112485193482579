import React from 'react';
import { ResultsScoreBlockProps } from './results-score-block.types';
// import Styled from 'styled-components';

// const IconContainer = Styled.div`
//   height: 50px;
//   width: 50px;
//   border-radius: 4px;
//   box-shadow: 0px 1px 4px 1px #ddd;
//   border: 1px solid;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 16px;
// `;

// const Score = Styled.span`
//  font-size: 30px;
//  display: block;
//  line-height: 25px;
// `;

// const ScoreText = Styled.span`
//  font-size: 14px;
// `;

export const ResultScoreBlock = ({
  icon,
  score,
  scoreText,
  color,
  bgColor,
  borderColor,
  className,
  ...rest
}: ResultsScoreBlockProps) => {
  return (
    <React.Fragment>
      <div
        className="score-block d-flex align-items-start flex-column flex-lg-row"
        {...rest}
      >
        <div
          className="icon d-flex align-items-center justify-content-center"
          style={{ backgroundColor: bgColor, borderColor: borderColor }}
        >
          <img style={{ color: color }} alt={score || '0'} src={icon} />
        </div>
        <div className="final-score">
          <p style={{ color: color }}>{score || '0'}</p>
          <span style={{ color: color }}>{scoreText}</span>
        </div>
      </div>
    </React.Fragment>
  );
};
