import { Loader } from '@lipihipi/ec-ui';
import React from 'react';
import swal from 'sweetalert';

export const DsTestLandingPage = (props:any) => {
    const { testId, userTestEnrollment, listTestAttempt, onSuccessEnroll, onFailEnroll,id } = props;
      //  this is for single attemt tests only
      
    React.useEffect(() => {
        handleSubmit( testId);
    }, [])
    
  const handleSubmit = async (testId: any) => {
    let response;
    // if (testType && testType === "batch") {
      response = await userTestEnrollment({freeResourceId:id, testId: testId});
    // } else {
    //   response = await userTestEnrollment({ testId: testId ,bundleId :  id});
    // }
    const {enrollmentId} = response?.data;
        const { data } = await listTestAttempt({ test: enrollmentId });
        if (!!data.totalItems) {
          swal({
            title: 'Warning',
            text: 'This test is already attempted.',
            icon: 'warning',
          }).then(function() {
            onFailEnroll();
            // _window.location.reload();
          });
        } else {
            onSuccessEnroll(enrollmentId)
        }
    }
    return <>
    <Loader />
    </>
}
