import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IQuestionDocument extends IQuestion, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IQuestionListParams extends IListParams {
  subject?: ObjectId;
  topic?: ObjectId;
  filterBy?: FilterBy;
  addedBy?: ObjectId;
}

export interface IQuestionListResponse {
  questions: IQuestionDocument[];
  totalItems: number;
}
export enum FilterBy {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
  BLANK = 'BLANK',
}
export interface IQuestion {
  exams: ObjectId[] | PopulatedDocument[];
  course: ObjectId | PopulatedDocument;
  subject: ObjectId | PopulatedDocument;
  difficulty: string;
  topic: ObjectId | PopulatedDocument;
  questionType: string;
  commonData: string;
  fileUrl: string;
  description: string;
  tags: string[];
  tests: string[];
  questions: IQuestionItem[];
  questionId?: string;
  filterBy?: FilterBy;
}

export interface IQuestionItem {
  text: string;
  type: string;
  options: IOption[];
  hasExplanation: boolean;
  explanation: string;
  correctAnswerCount?: number;
}

export interface IOption {
  text: string;
  isAnswer: boolean;
}

export interface IBulkUpload {
  subject: string;
  topic: string;
  fileUrl: string;
}

export interface IBulkUploadResp {
  questionCreated: number;
  error: any;
}
const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IQuestionDocument>(`/questions/${id}`, { params });
};

const create = (question: IQuestion) => {
  return client.post<IQuestionDocument>(`/questions`, question);
};

const createAll = (question: IQuestion) => {
  return client.post<IQuestionDocument>(`/questions/batch`, question);
};

const bulkUpload = (payload: IBulkUpload) => {
  return client.post<IBulkUploadResp>(`/questions/upload`, payload);
};

const update = (question: IQuestionDocument) => {
  return client.put<IQuestionDocument>(`/questions/${question._id}`, question);
};

const remove = (id: string) => {
  return client.delete(`/questions/${id}`);
};

const list = (params: IQuestionListParams) => {
  return client.get<IQuestionListResponse>(`/questions/`, { params });
};

export const question = {
  get,
  create,
  update,
  remove,
  list,
  createAll,
  bulkUpload,
};
