import { Modal } from '@lipihipi/ec-ui';
import { Form, Input } from '@lipihipi/form';
import React from 'react';

const RedeemModal = ({ isOpen, imageBaseUrl, onRequestClose }: any) => {
  return (
    <Modal heightDefault={true} isOpen={isOpen} onRequestClose={onRequestClose}>
      {/* Phone Number */}
      <div className="redeem-modal">
        <img src={`${imageBaseUrl}paytm.svg`} height="25" alt="Paytm" />
        <p>
          Please provide your registered Paytm mobile number to link your paytm
          wallet for redemption of points
        </p>

        <Form>
          <Input
            id={'phone'}
            className="form-group m-0"
            name={'phone'}
            placeholder="(xxxxx-xxxxx)"
          />
        </Form>
      </div>

      {/* OTP */}
      {/* <div className="redeem-modal">
        <h3>Account Verification </h3>
        <img src={`${imageBaseUrl}paytm.svg`} height="25" alt="Paytm" />
        <p>
          We have sent the OTP to your registered mobile number to verify your account. Please enter OTP
        </p>

        <Form>
          <ul>
            <li>
              <Input
                id={'phone'}
                className="form-group m-0"
                name={'phone'}
              />
            </li>
            <li>
              <Input
                id={'phone'}
                className="form-group m-0"
                name={'phone'}
              />
            </li>
            <li>
              <Input
                id={'phone'}
                className="form-group m-0"
                name={'phone'}
              />
            </li>
            <li>
              <Input
                id={'phone'}
                className="form-group m-0"
                name={'phone'}
              />
            </li>
            <li>
              <Input
                id={'phone'}
                className="form-group m-0"
                name={'phone'}
              />
            </li>
            <li>
              <Input
                id={'phone'}
                className="form-group m-0"
                name={'phone'}
              />
            </li>
          </ul>

          <div className="d-flex justify-content-center">
            <Button shape={'primary'} className="px-5" type="submit">Submit</Button>
          </div>

          <div className="resend-otp">
            Didn’t recieve the OTP? <a href="javascript:void(0)">Resend OTP</a>
          </div>
        </Form>
      </div> */}

      {/* Success Modal */}
      {/* <div className="redeem-modal">
        <img src={`${imageBaseUrl}reward-tick.svg`} height="131" alt="Tick" />
        <h4>Successfully Redeemed your Points!</h4>
        <div className="d-flex justify-content-center">
          <Button shape={'primary'} className="px-5">Continue</Button>
        </div>
      </div> */}
    </Modal>
  );
};

export default RedeemModal;
