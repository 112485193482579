import React from 'react';
import { ContinueTestCardProps } from './continue-test-card.types';
import Styled from 'styled-components';
import { PrimaryButton } from './../primary-button/primary-button';

const ContinueTestCardContainer = Styled.div`
  background-color: #239F87;
  width: auto;
  padding: 10px 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0px 1px 4px 1px #ddd;
`;

const Title = Styled.span`
  font-size: 24px;
  font-weight: 600;
  color: white;
  display: block;
`;

const DurationText = Styled.span`
  font-size: 16px;
  font-weight: 400;
  color: white;
`;

export const ContinueTestCard = ({
  testDetails,
  onTestContinueClick,
  ...rest
}: ContinueTestCardProps) => {
  return (
    <React.Fragment>
      <ContinueTestCardContainer {...rest}>
        <div>
          <Title>{testDetails.name}</Title>
          <DurationText>{testDetails.totalDurationInMinute} Min</DurationText>
        </div>

        <PrimaryButton
          isWhite
          onClick={() =>
            onTestContinueClick(
              testDetails._id,
              testDetails?.shinkanProctoredTest,
              testDetails?.procotoredUrl
            )
          }
        >
          Continue
        </PrimaryButton>
      </ContinueTestCardContainer>
    </React.Fragment>
  );
};
