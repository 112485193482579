import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IWallDocument extends IWall, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}
export interface IOption {
  text: string;
  isAnswer: boolean;
}

export interface IWallListParams extends IListParams {
  subject?: ObjectId;
  course?: ObjectId;
  user?: ObjectId;
  populate?: boolean;
  postType?: string;
  startDate: Date;
  endDate: Date;
}

export interface IWall {
  text: string;
  displayPicture: string;
  isCommentAllowed: boolean;
  postType: string;
  options: IOption[] | PopulatedDocument[];
  details: string;
  subjects?: ObjectId | PopulatedDocument;
  isBlocked: Boolean;
}

export interface IWallDocument extends IWall, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}
export interface wallList extends IWallDocument {
  views: number;
  likes: number;
  comments: any[];
  commentsCount: number;
}
export interface IWallListResponse {
  walls: wallList[];
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IWallDocument>(`/walls/${id}`, { params });
};

const create = (wall: IWall) => {
  return client.post<IWallDocument>(`/walls`, wall);
};

const update = (wall: IWallDocument) => {
  return client.put<IWallDocument>(`/walls/${wall._id}`, wall);
};

const remove = (id: string) => {
  return client.delete(`/walls/${id}`);
};

const list = (params: IWallListParams) => {
  return client.get<IWallListResponse>(`/walls/`, { params });
};

export const wall = { get, create, update, remove, list };
