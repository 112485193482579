import React from 'react';
import { IoPlayCircleOutline } from 'react-icons/io5';

export const Explanation = () => {
  return (
    <main className="main-structure p-4">
      <div className="primary-page-header mb-3">
        <div className="wrap">
          <h3>Test name will be displayed here</h3>
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="section-info">
          <strong>Section:</strong>
          <span>Marks: +20</span>
        </div>

        <ul className="test-duration-info">
          <li>Easy</li>
          <li>Topic Name</li>
        </ul>
      </div>

      <div className="attempted-test-view">
        <div className="left">
          <div className="audio-view mb-3">
            <p>IELTS Final round questiond.mp3</p>
            <div className="wrap">
              <IoPlayCircleOutline />

              <div className="bar">
                <span style={{ width: '30%' }}>Fill</span>
              </div>
            </div>
          </div>

          <div className="common-data-view">
            <h3 className="mb-3">Description</h3>

            <p>
              Directions: Read the passage carefully and answer the question
              given below it.
            </p>

            <p>
              When the curtain went up several months ago on a production of
              Stephen Sondheim's Pacific Overtures at the new home of East West
              Players in downtown Los Angeles, it marked another new chapter in
              the annals of this troupe, and, in a broader sense, the history of
              multicultural theater in the United States. East West Players is
              the oldest, and one of the most influential, Asian-American
              theater companies in the nation, with a three-decade-plus track
              record of affording Pacific Rim actors a place to practice their
              craft, hone their skills and gain insights into the business of
              acting. The troupe's success is preceded by that of its alumni.
              Jan Breslauer wrote in the Los Angeles Times recently about this
              invaluable nurturing ground. Actors Pat Morita, John Lone and Sab
              Shimono -- all of whom are well known in the U.S. film and
              television industry -- are among those who have passed through the
              company's doors, along with playwrights David Henry Hwang (who has
              had four of his plays staged there) and Philip Kan Gotanda. East
              West Players is now in residence in the 220-seat David Henry Hwang
              Theater in a former church, known today as the Union Center for
              the Arts, which also houses an art exhibitor and an independent
              film organization. Lead donors included Henry and Dorothy Hwang,
              parents of the playwright for whom the theater was named. Hwang,
              author of such Broadway dramas as M. Butterfly and Golden Child,
              is the most successful Asian-American playwright on the
              contemporary scene. The company's first artistic director was
              Mako, a familiar face as a character actor in a skein of{' '}
            </p>
          </div>
        </div>
        <div className="right">
          <div className="question-result-view">
            <p>
              A tetrahedron was cut from the corner of the cuboid shown above,
              with three of its vertices at the midpoints of three edgers of the
              cuboid. If tetrahedrons of the same size are cut from the
              remaining seven corners of the cuboid, how many faces will the
              resulting solid have?
            </p>
          </div>

          <div className="custom-radio-group">
            <div className="radio-button checked">
              <label>
                <input type="radio" name="a" />
                <span>Option 1</span>
              </label>
            </div>

            <div className="radio-button">
              <label>
                <input type="radio" name="a" />
                <span>Option 2</span>
              </label>
            </div>

            <div className="radio-button">
              <label>
                <input type="radio" name="a" />
                <span>Option 3</span>
              </label>
            </div>

            <div className="radio-button">
              <label>
                <input type="radio" name="a" />
                <span>Option 4</span>
              </label>
            </div>
          </div>

          <div className="question-result-view my-3">
            <div className="correct-answer">
              <strong>Correct Answer:</strong> <span>Option 2</span>
            </div>

            {/* <div className="attempt-info correct">Correct Attempt</div> */}
            <div className="attempt-info wrong">Wrong Attempt</div>
          </div>

          <div className="explanation-box common-data-view">
            <h3>Explanation</h3>

            <p>
              A tetrahedron was cut from the corner of the cuboid shown above,
              with three of its vertices at the midpoints of three edgers of the
              cuboid. If tetrahedrons of the same size are cut from the
              remaining seven corners of the cuboid, how many faces will the
              resulting solid have? A tetrahedron was cut from the corner of the
              cuboid shown above, with three of its vertices at the midpoints of
              three edgers of{' '}
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};
