import * as Yup from 'yup';

const CreateDoubtSchema = Yup.object().shape({
  text: Yup.string().required('Text is Required'),
  educator: Yup.string().required('Educator is Required'),
  // subject: Yup.string().required('Subject is Required'),
  // topic: Yup.string().required('Topic is Required'),
  course: Yup.string().required('Course is Required'),
});

export default CreateDoubtSchema;
