import React from 'react';
import { useField } from 'formik';
import { ErrorMessage } from '../error/error';
import { Label } from '../label/label';
import Styled from 'styled-components';

const CustomRadio = Styled.input`
  &:after{
    width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -4px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
  };
  &:checked:after{
    width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -4px;
        left: -1px;
        position: relative;
        background-color: #239f87;
        content: '';
        display: inline-block;
        visibility: visible;
  }
`;

export const RadioButtons = ({
  className,
  choices,
  name,
  label,
  required,
}: any) => {
  const [field, meta] = useField({ name });
  return (
    <div className={className || 'form-group'}>
      {label && <Label id={``} label={label} required={required} />}
      {choices.map((choice: any, index: number) => (
        <div className="d-flex mt-2" key={index}>
          <CustomRadio
            {...field}
            id={`${name}-${index}`}
            name={name}
            type="radio"
            value={choice.value}
            checked={field.value === choice.value}
          />
          <Label
            id={`${name}-${index}`}
            label={choice.label}
            className="m-0 ml-2"
          />
        </div>
      ))}
      <ErrorMessage meta={meta} />
    </div>
  );
};
