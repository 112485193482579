import React, { FC, useCallback, useEffect, useState } from 'react';
import { LeftPanel, CategoryListing } from '../../common';
import { useHistory } from 'react-router-dom';
import EducrackAPI from '@lipihipi/client-sdk';

const CourseSelection: FC<any> = ({
  getCourses,
  getMyProfile,
  updateStudentProfile,
  imageBaseUrl,
}: any) => {
  const [courses, setCourses] = useState<Array<any>>([]);
  const [selected, setSelected] = useState<Array<string>>([]);
  const [profile, setProfile] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    getCourses()
      .then((response: any) => setCourses(response.data.courses))
      .catch(() => {});
    getMyProfile()
      .then((response: any) => setProfile(response.data))
      .catch(() => {});
  }, []);

  const getSelectedCourses = (items: any) => setSelected(items);

  const saveSelectedCoursee = useCallback(() => {
    const selectedCourses = selected.map(id => ({
      course: id,
      isActive: true,
    }));
    updateStudentProfile(profile?._id, { selectedCourses })
      .then(() => {
        console.log('[Success]: courses saved');
        history.push('/course/wall');
      })
      .catch((error: any) => console.log('[Error]: courses failed', error));
  }, [selected]);

  const { styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};

  return (
    <main className="user-structure">
      <LeftPanel
        imageBaseUrl={imageBaseUrl}
        title={styles?.onboardingText || ''}
      />
      <div className="user-structure--form">
        <div className="wrap">
          <h2 className="m-b-8">Welcome!</h2>
          <p>What are you preparing for?</p>

          <div className="courses">
            <p>
              Please select the Courses you will be able to switch between it
              anytime.
            </p>
            <CategoryListing
              data={courses}
              multipleSelection
              getSelectedItems={getSelectedCourses}
            />
          </div>

          <div className="button-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveSelectedCoursee}
              disabled={!selected.length}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CourseSelection;
