import React, { useEffect, useState } from 'react';
import { FiClock } from 'react-icons/fi';
import { PageHeader, Tab, Tabs } from '@lipihipi/ec-ui';
import moment from 'moment';
import { BsClock } from 'react-icons/bs';
import { FiCalendar } from 'react-icons/fi';
import { ImPlay } from 'react-icons/im';
import EducrackAPI from '@lipihipi/client-sdk';
import { saveAs } from 'file-saver';

import { IUserInterviewListResponse } from '@lipihipi/client-sdk/dist/user-interview';

const eduPro = (props: any) => {
  const [testList, setTestList] = useState<any>({
    totalItems: 0,
    UserTestBundleEnrollments: [],
  });

  const [subscribedInterviews, setSubscribedInterviews] = useState<
    IUserInterviewListResponse
  >({
    totalItems: 0,
    userInterview: [],
  });

  const [purchasedBatchList, setPurchasedBatchList] = useState<any>({
    totalItems: 0,
    batches: [],
  });

  const [orderList, setOrderList] = useState<any>({
    totalItems: 0,
    payments: [],
  });

  const [state, setState] = useState<any>('tests');

  useEffect(() => {
    setState('tests');
  }, []);

  async function fetchSubscribedList() {
    try {
      // get Subscribed Test
      var testBundlelist = await props.getTestBundlelist({ populate: true });
      setTestList(testBundlelist.data);

      // get Subscribed Interview
      var interviews = await props.getSubscribedInterviews({ populate: true });
      setSubscribedInterviews(interviews.data);

      // get Purchased Batch
      var purchasedBatch = await props.getPurchasedBatch({
        all: true,
        populate: true,
      });
      setPurchasedBatchList(purchasedBatch.data);

      var response = await props.getPaymentHistory({
        populate: true,
        all: true,
      });
      setOrderList(response.data);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const pdfFileDownload = (order: any) => {
    EducrackAPI.payment.getInvoice(order._id).then((res: any) => {
      let name;
      if (order.testBundleId?.name) {
        name = order.testBundleId?.name;
      } else if (order.batchId?.batchId?.name) {
        name = order.batchId?.batchId?.name;
      } else if (order.interviewId?.interview?.name) {
        name = order.interviewId?.interview?.name;
      }
      const blob = new Blob([res.data], { type: 'application/pdf' });
      saveAs(blob, `${name}.pdf`);
    });
  };

  useEffect(() => {
    fetchSubscribedList();
  }, []);

  // console.log({testList, subscribedInterviews});

  return (
    <div className="d-flex flex-wrap">
      <div className="main-mid">
        <PageHeader
          title={props.title || 'My Enrolled and subscribed item'}
          breadCrumbs={props.breadCrumbs || [{ title: 'My Course', link: '#' }]}
        />
        {(testList && testList.totalItems) ||
        (subscribedInterviews && subscribedInterviews.totalItems) ||
        (purchasedBatchList && purchasedBatchList.totalItems) ? (
          <div className="main-mid-inner" id="test-bundles">
            <Tabs active={state} onClick={setState}>
              <Tab id={'tests'} title={'My Tests'}>
                {testList && testList.totalItems > 0 && (
                  <>
                    {/* <div style={{ paddingTop: '30px' }}>
                  <h3>My Tests</h3>
                </div> */}
                    <div className="row">
                      {testList.UserTestBundles.map((test: any) => (
                        <>
                          <div
                            key={test?._id}
                            data-bundle-id={test?._id}
                            className="col-md-4 col-lg-3"
                            onClick={() => props.onTestBundleClick(test._id)}
                          >
                            <div className="priamry-test-card subscribed">
                              <div className="wrap">
                                <img
                                  src={props.getAssetUrl(test?.displayPicture)}
                                  alt="Test Bundle"
                                />

                                <p title={test.name}>{test.name}</p>

                                <ul>
                                  <li>{test.course?.name || 'NA'}</li>
                                  <li>{test.tests?.length} Tests</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                )}
              </Tab>
              <Tab id={'interview'} title={'My Interviews'}>
                {subscribedInterviews && subscribedInterviews.totalItems > 0 && (
                  <div id="interview">
                    {/* <div style={{ paddingTop: '30px' }}>
                  <h3>My Interviews</h3>
                </div> */}
                    <div className="row">
                      {subscribedInterviews.userInterview.map(
                        (Sinterview: any) => (
                          <div
                            className="col-md-4"
                            key={Sinterview?.interview?._id}
                            data-interview-id={Sinterview?._id}
                            onClick={() =>
                              props.onInterviewsClick(Sinterview._id)
                            }
                          >
                            <figure className="custom-thumbnail">
                              <span className="subscribed">
                                <img
                                  src={props.getAssetUrl(
                                    Sinterview.interview.displayPicture
                                  )}
                                  alt="Interviews"
                                />
                              </span>
                              <figcaption>
                                <h3>{Sinterview.interview.name}</h3>
                                <ul>
                                  <li>{Sinterview.interview?.course?.name}</li>
                                </ul>
                                <p>{Sinterview.interview.description}</p>
                                <p>
                                  <FiClock />
                                  Attempted - {Sinterview.attended.length}/
                                  {Sinterview.interview.noOfLinks}
                                </p>
                              </figcaption>
                            </figure>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </Tab>
              <Tab id={'programs'} title={'My Programs'}>
                {purchasedBatchList && purchasedBatchList.totalItems > 0 && (
                  <div id="batches">
                    {/* <div style={{ paddingTop: '30px' }}>
                  <h3>My Purchased Programs</h3>
                </div> */}

                    <div className="row">
                      {purchasedBatchList?.batches?.map(
                        (purchasedBatch: any) => {
                          return (
                            <div
                              className="col-md-4"
                              key={purchasedBatch?.batchId?._id}
                              data-batch-id={purchasedBatch?.batchId?._id}
                              onClick={() => {
                                debugger;
                                props.onBatchClick(
                                  purchasedBatch?.batchId?._id
                                );
                              }}
                            >
                              <figure className="custom-thumbnail">
                                <span className="subscribed">
                                  <img
                                    src={props.getAssetUrl(
                                      purchasedBatch?.batchId?.displayPicture
                                    )}
                                    alt="batch_icon"
                                  />
                                </span>
                                <figcaption>
                                  <h3
                                    title={
                                      purchasedBatch?.batchId?.name || 'NA'
                                    }
                                  >
                                    {purchasedBatch?.batchId?.name || 'NA'}
                                  </h3>
                                  <ul>
                                    <li>
                                      {' '}
                                      {purchasedBatch?.batchId?.course?.name ||
                                        'NA'}
                                    </li>
                                  </ul>
                                  <p>
                                    {purchasedBatch?.batchId?.batchType ===
                                    'recorded' ? (
                                      <ImPlay />
                                    ) : (
                                      <BsClock />
                                    )}
                                    {purchasedBatch?.batchId?.totalVideos}{' '}
                                    Videos
                                  </p>
                                  <p>
                                    <FiCalendar />
                                    {moment(purchasedBatch?.createdAt).format(
                                      'DD MMMM YYYY h:mm a'
                                    )}
                                  </p>
                                  <p>
                                    {purchasedBatch?.batchId?.program
                                      ?.programType === 'Recorded'
                                      ? 'Recorded Batch'
                                      : 'Live Batch'}
                                  </p>
                                </figcaption>
                              </figure>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </Tab>
              <Tab id={'history'} title={'My Purchase History'}>
                {orderList.totalItems > 0 && (
                  <div className="wrap" id="payment">
                    {/* <h3>My Purchase History</h3> */}
                    <div className="attempts-list d-none d-md-block">
                      <ul>
                        <li>
                          <p>Order Type</p>
                        </li>
                        <li>
                          <p>Name</p>
                        </li>
                        <li>
                          <p>Course</p>
                        </li>
                        <li>
                          <p>Amount </p>
                        </li>
                        <li>
                          <p>Payment Status</p>
                        </li>
                        <li>
                          <p>Time</p>
                        </li>
                        <li>
                          <p></p>
                        </li>
                      </ul>
                    </div>

                    {orderList.totalItems
                      ? orderList.payments.map((order: any) => (
                          <div
                            className="attempts-list"
                            key={order?._id}
                            id={order?._id}
                          >
                            <ul>
                              <li>
                                <span className="d-md-none">Order Type</span>
                                <p>{order.order_type}</p>
                              </li>
                              <li>
                                <span className="d-md-none">Name</span>
                                <p>
                                  {order.testBundleId?.name}{' '}
                                  {order.batchId?.batchId?.name}{' '}
                                  {order.interviewId?.interview?.name}
                                </p>
                              </li>
                              <li>
                                <span className="d-md-none">Course</span>
                                <p>
                                  {order.testBundleId?.course?.name}{' '}
                                  {order.batchId?.batchId?.course?.refId?.name}{' '}
                                  {order.interviewId?.interview?.course?.name}
                                </p>
                              </li>
                              <li>
                                <span className="d-md-none">Amount </span>
                                <p>
                                  &#8377;
                                  {order.order?.orderAmount
                                    ? order.order?.orderAmount
                                    : ''}
                                  {order.order?.amount
                                    ? Number(order.order?.amount / 100).toFixed(
                                        2
                                      )
                                    : ''}
                                </p>
                              </li>
                              <li>
                                <span className="d-md-none">
                                  Payment Status
                                </span>
                                <p>{order?.payment_status} </p>
                              </li>
                              <li>
                                <span className="d-md-none">Time</span>
                                <p>
                                  {order?.updatedAt
                                    ? moment(order?.updatedAt).format(
                                        'DD MMMM YYYY h:mma'
                                      )
                                    : '--'}
                                </p>
                              </li>
                              <li>
                                <span className="d-md-none"></span>
                                <p>
                                  <a
                                    target="_blank"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      pdfFileDownload(order);
                                    }}
                                  >
                                    Download
                                  </a>
                                </p>
                              </li>
                            </ul>
                          </div>
                        ))
                      : null}
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        ) : (
          <div>You have not enrolled any item yet</div>
        )}
      </div>
    </div>
  );
};

export default eduPro;
