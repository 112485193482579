import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PageTemplate,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IExam {
  name: string;
  course: ObjectId | PopulatedDocument;
  displayPicture: string;
  examId?: string;
  active?: boolean;
  pageTemplate?: PageTemplate;
}

export interface IExamDocument extends IExam, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IExamListResponse {
  totalItems: number;
  exams: IExamDocument[];
}
export interface IExamListParams extends IListParams {
  course?: ObjectId;
}

export interface IUpdateExam {
  id: string;
  displayOrder: number;
}
export interface IExamListUpdate {
  message: string;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IExamDocument>(`/exams/${id}`, { params });
};

const create = (exam: IExam) => {
  return client.post<IExamDocument>(`/exams`, exam);
};

const update = (exam: IExamDocument) => {
  return client.put<IExamDocument>(`/exams/${exam._id}`, exam);
};

const remove = (id: string) => {
  return client.delete(`/exams/${id}`);
};

const list = (params: IExamListParams) => {
  return client.get<IExamListResponse>(`/exams/`, { params });
};

const updateList = (examList: IUpdateExam) => {
  return client.post<IExamListUpdate>(`/exams/order`, examList);
};

export const exam = { get, create, update, remove, list, updateList };
