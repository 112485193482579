import EducrackAPI from '@lipihipi/client-sdk';
import { Button, Modal, PageHeader } from '@lipihipi/ec-ui';
import { DateTime, Form, Select, FileUpload } from '@lipihipi/form';
import React from 'react';
import swal from 'sweetalert';
import { validationSchema } from './helper';

const VideoUploadModal = ({ isOpen, onRequestClose, uploadFile, onCancelRequest, videoUpload, sessionId, afterSuccessVideoUpload }: any) => {
    const handleUpload = (values: any) => {
        let uploadValue;
        
        if(values.recordedFile !='' && values.pdfRecordedFile != ''){
            uploadValue={
                recordedFile:values.recordedFile,
                pdfRecordedFile:values.pdfRecordedFile
            }
        }
        else if(values.recordedFile===''){
            uploadValue={
                pdfRecordedFile:values.pdfRecordedFile
            }
        }
        else if(values.pdfRecordedFile===''){
            uploadValue={
                recordedFile:values.recordedFile
            }
        }
        
        videoUpload(uploadValue, sessionId).then(({ status, data }: any) => {
            if (status === 200) {
                swal({
                    title: 'Success',
                    text: 'Video and PDF File Upload Successfully',
                    icon: 'success',
                    dangerMode: false,
                }).then(() => {
                    //afterSuccessVideoUpload();
                    onRequestClose(false);
                    window.location.reload();
                });
            } else {
                swal({
                    title: 'Error',
                    text: 'Server Error!',
                    icon: 'error',
                    dangerMode: true,
                });
            }
        });
    };

    const deleteFile = (values: any, sessionId: any, fileType: any) => {
        if (fileType === 'video') {
            let uploadValue = {
                recordedFile: values.recordedFile
            }
            videoUpload(uploadValue, sessionId).then(({ status, data }: any) => {
                if (status === 200) {
                    swal({
                        title: 'Success',
                        text: `${fileType} Delete Successfully`,
                        icon: 'success',
                        dangerMode: false,
                    }).then(() => {
                        //afterSuccessVideoUpload();
                        onRequestClose(false);
                        window.location.reload();
                    })
                } else {
                    swal({
                        title: 'Error',
                        text: 'Server Error!',
                        icon: 'error',
                        dangerMode: true,
                    });
                }
            });
        }
        else {
            let uploadValue = {
                pdfRecordedFile: values.recordedFile
            }
            videoUpload(uploadValue, sessionId).then(({ status, data }: any) => {
                if (status === 200) {
                    swal({
                        title: 'Success',
                        text: `${fileType} Delete Successfully`,
                        icon: 'success',
                        dangerMode: false,
                    }).then(() => {
                        //afterSuccessVideoUpload();
                        onRequestClose(false);
                        window.location.reload();
                    })
                } else {
                    swal({
                        title: 'Error',
                        text: 'Server Error!',
                        icon: 'error',
                        dangerMode: true,
                    });
                }
            });
        }
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} >
            <>
                <Form
                    initialValues={{ recordedFile: '' }}
                    style={{ minWidth: '50vw' }}
                    onSubmit={(values: any) => {
                        handleUpload(values);
                        values = {};
                    }}
                    render={({ values }: any) => {
                        return (
                            <>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="text-secondary" style={{ fontSize: '12px' }}>
                                            Attach File (Files supported: Image, Video, Music)
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 my-2">
                                        <FileUpload
                                            name="recordedFile"
                                            upload={uploadFile}
                                            accept={[
                                                'video/mp4',
                                                'application/pdf'
                                            ]}
                                            label={'File'}
                                            type={'single'}
                                            maxSize={'2gb'}
                                            onCancel={onCancelRequest}
                                            isCancelAllowed={true}
                                            required
                                        />
                                    </div>
                                    {sessionId?.recordedFile &&
                                        <div>
                                            <p>{sessionId?.recordedFile}</p>
                                            <Button shape="primary" className="ml-3" onClick={() => deleteFile(values, sessionId, "video")}>Delete</Button>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="text-secondary" style={{ fontSize: '12px' }}>
                                            Attach File (Files supported: Word, Excel, PDF, PowerPoint)
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 my-2">
                                        <FileUpload
                                            name="pdfRecordedFile"
                                            upload={uploadFile}
                                            accept={[
                                                'application/pdf',
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                'application/vnd.ms-excel',
                                                'application/msword',
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                'application/vnd.ms-powerpoint',
                                                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                                'text/plain'
                                            ]}
                                            label={'PDF File'}
                                            type={'single'}
                                            maxSize={'2gb'}
                                            onCancel={onCancelRequest}
                                            isCancelAllowed={true}
                                            required
                                        />
                                    </div>
                                    {sessionId?.pdfRecordedFile &&
                                        <div>
                                            <p>{sessionId?.pdfRecordedFile}</p>
                                            <Button shape="primary" className="ml-3" onClick={() => deleteFile(values, sessionId, "PDF File")}>Delete</Button>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="mt-3">
                                        {/* <Button shape="primary" className="ml-3" onClick={() => videoUpload(values, sessionId)}>Save</Button> */}
                                        <Button shape={'primary'} type="submit">
                                            Submit
                                        </Button>
                                        <Button shape="primary" className="ml-3" onClick={() => onRequestClose(false)}>Close</Button>
                                    </div>
                                </div>

                            </>
                        )
                    }}
                />

            </>
        </Modal >
    );
};

export default VideoUploadModal;