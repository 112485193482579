import React, { useEffect, useState } from 'react';
import {
  Select,
  Form,
  FileUpload,
  RichTextEditor,
} from '@lipihipi/form';
import { FaTrashAlt } from 'react-icons/fa';
import { Button } from '@lipihipi/ec-ui';
import { MdClose } from 'react-icons/md';
import { MdCheck } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import CreatePostSchema from './CreatePost.schema';

const mySelectObj = [
  { label: 'Normal Post', value: 'normal' },
  { label: 'Quiz Post', value: 'quiz' },
];

const CreatePost = ({
  createAsset,
  createPost,
  setOpen,
  User,
  wallArr,
  setWallArr,
  courseOptions,
  getAssetUrl,
  isEdit,
  editData,
  updateWall,
  showModal,
}: any) => {
  const [state, setState] = useState({
    course: '',
    type: '',
    educator: '',
    allowComment: true,
    description: '',
    displayPicture: '',
    disableSelect: false,
    selectedSubject: '',
  });

  useEffect(() => {
    if (isEdit) {
      setState(editData);
      setOptions(editData?.options);
    }
  }, [isEdit]);

  const [addMore, setAddMore] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [commentTxt, setCommentTxt] = useState<string>('');
  const [inValid, setInvalid] = useState<any>(null);

  const SelectHandler = (val: any) => {
    setState(prevState => ({
      ...prevState,
      type: val.value,
    }));
  };

  const SelectCourseHandler = (val: any) => {
    setState(prevState => ({
      ...prevState,
      course: val.value,
    }));
  };

  // const commentHandler = (val: boolean) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     allowComment: val,
  //   }));
  // };

  const AddCommentHandler = () => {
    setOptions([...options, { text: commentTxt, isAnswer: false }]);
    setCommentTxt('');
    setAddMore(false);
  };

  const deletehandler = (val: string) => {
    setOptions([...options.filter(i => i.text !== val)]);
  };
  const addOptionForm = (
    <>
      <div className="add-option-form">
        <input
          type="text"
          value={commentTxt}
          onChange={e => setCommentTxt(e.target.value)}
          className="primary-form-control"
          placeholder="Add Option"
        />
        <div
          className="clear"
          onClick={() => {
            setAddMore(false);
            setCommentTxt('');
          }}
        >
          <MdClose />
        </div>
        <div
          className="add"
          onClick={() => commentTxt.length && AddCommentHandler()}
        >
          <MdCheck />
        </div>
      </div>
    </>
  );
  const list = () =>
    options.map((i, index) => (
      <ul className="quiz-list-options" key={index}>
        <li>{index + 1}</li>
        <li>{i.text}</li>
        <li onClick={() => deletehandler(i.text)}>
          <FaTrashAlt />
        </li>
      </ul>
    ));

  const selectAnswer = (val: any) => {
    setOptions(
      options.map((i: any) => {
        if (i.text === val.text) {
          return { text: i.text, isAnswer: !i.isAnswer };
        }
        return { text: i.text, isAnswer: i.isAnswer ? false : i.isAnswer };
      })
    );
  };

  const optionGroup = () => (
    <div className="option-wrapper">
      <div className="quiz_list">
        {options.length !== 0 && (
          <span className="heading">Correct answer :</span>
        )}
        {options.map((i, index) => (
          <div
            className={`list-icon ${i.isAnswer ? 'selected-icon' : ''}`}
            style={{ cursor: 'pointer' }}
            key={index}
            onClick={() => {
              selectAnswer(i);
              setInvalid(null);
            }}
          >
            {index + 1}
          </div>
        ))}
        <span
          style={{
            fontSize: '12px',
            color: 'red',
          }}
        >
          {inValid}
        </span>
      </div>
      {options.length < 4 && (
        <div className="add-more-options">
          <div onClick={() => setAddMore(true)}>
            more option
            <MdAdd />
          </div>
        </div>
      )}
    </div>
  );

  const handleSubmit = (values: any) => {
    const optionItem = options.find((i: any) => i.isAnswer);
    let data = {
      isCommentAllowed: state.allowComment,
      postType: values.type,
      displayPicture: values.displayPicture || '',
      text: values.description,
      options: options,
      course: values.course,
    };
    if (!optionItem && data?.postType === 'quiz') {
      setInvalid('Select an answer');
      return;
    }

    const fun = isEdit ? updateWall : createPost;
    fun({ _id: values?._id, ...data }).then((res: any) => {
      setOpen(false);
      showModal({ show: false, data: {} });
      setWallArr([
        {
          ...res.data,
          addedBy: { name: User.name, _id: res.data.addedBy },
          comments: [],
          commentsCount: 0,
          isResponded: false,
          likes: 0,
          response: [],
          views: 0,
        },
        ...wallArr,
      ]);
    });
  };

  return (
    <>
      <Form
        initialValues={state}
        style={{ minWidth: '50vw' }}
        validationSchema={CreatePostSchema}
        onSubmit={handleSubmit}
      >
        <div>
          <div>
            <Select
              id="type"
              name="type"
              placeholder="Post Type"
              label="Select Post Type"
              options={mySelectObj}
              onChange={SelectHandler}
              required
            />
          </div>

          <div>
            <Select
              id="course"
              name="course"
              label="Course"
              placeholder="Select Course"
              options={courseOptions}
              onChange={SelectCourseHandler}
              required
            />
          </div>

          <div>
            {/*<TextArea
              id="description"
              name="description"
              label="Description"
              placeholder="Start from here"
              required
            />*/}
            <RichTextEditor
              id={'description'}
              name={'description'}
              label="Description"
              required
              uploadFile={createAsset}
              getAssetUrl={getAssetUrl}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <span className="text-secondary" style={{ fontSize: '12px' }}>
                Attach File (Files supported: Image, Video, Music)
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 my-2">
              <FileUpload
                name="displayPicture"
                upload={createAsset}
                accept={[
                  'image/jpeg',
                  'image/jpg',
                  'image/png',
                  'audio/mpeg',
                  'video/mp4',
                ]}
                type={'single'}
                required
                maxSize={'1gb'}
                label={''}
              />
            </div>
          </div>
          <div className="modal-attachment">
            {state.type === 'quiz' && (
              <>
                {list()}
                {optionGroup()}
                <div className="quiz-list">{addMore && addOptionForm}</div>
              </>
            )}

            {/* <div className="custom-checkbox text-center my-3">
              <CheckBox
                id={'allowComments'}
                name={'allowComments'}
                label={'Allow Comments'}
                onChange={e => commentHandler(e.target.checked)}
              />
            </div> */}
            <div className="text-center">
              <Button
                shape={'primary'}
                type="submit"
                className="primary-button"
              >
                Submit
              </Button>
            </div>
            <div className="text-center"></div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreatePost;
