import React from 'react';
import ReactTooltip from 'react-tooltip';

export const Tooltip = ({ children }: any) => {
  return (
    <div>
      {children}
      <ReactTooltip />
    </div>
  );
};
