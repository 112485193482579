import React, { useState } from 'react';
// @ts-ignore
import Files from 'react-butterfiles';
import { useField } from 'formik';
import { ErrorMessage } from '../error/error';
import {
  IFileToUpload,
  IFileUploadError,
  IFileUploadProps,
} from './file-uploader.types';
import { FilePreviewContainer } from './file-upload.styled';

import { FileUploading } from './file-uploading';
import { FileError } from './file-error';
import { FilePreview } from './file-preview';
import { Label } from '../label/label';

export const FileUpload = ({
  label,
  required,
  name,
  upload,
  maxSize,
  accept,
  type,
  isCancelAllowed,
  onCancel,
  onChange,
}: IFileUploadProps) => {
  const [field, meta, helpers] = useField({ name });
  // TODO Use field arrays
  const multiple = type === 'multiple';
  const [state, setState] = useState<{
    files: IFileToUpload[];
    errors: IFileUploadError[];
  }>({
    files: [],
    errors: [],
  });
  const onSuccess = (files: IFileToUpload[]) => {
    //@ts-ignore
    setState({ files: [...state.files, ...files], errors: [] });
    Promise.all(
      files.map(fileToUpload => {
        return upload(
          { file: fileToUpload.src.file },
          {
            onUploadProgress: (event: any) => {
              const { loaded, total } = event;
              fileToUpload.uploadPercent = Math.floor((loaded / total) * 100);
              setState({ files, errors: [] });
            },
          }
        );
      })
    )
      .then(responses => {
        const keys = responses.map(response => response.data.key);
        setState({ files: [], errors: [] });
        if (multiple) {
          helpers.setValue([...field.value, ...keys]);
          onChange && onChange([...field.value, ...keys])
        } else {
          helpers.setValue(keys[0]);
          onChange && onChange(keys[0])
        }
      })
      .catch(err => {
        console.log(err);
        setState({ files: [], errors: [] });
        helpers.setValue(undefined);
      });
    const fileToUpload = files[0];
    fileToUpload.isUploading = true;
  };

  const onError = (errors: IFileUploadError[]) => {
    setState({ files: state.files, errors: errors });
  };

  const handleCloseError = (error: IFileUploadError) => {
    setState({
      files: state.files,
      errors: state.errors.filter(e => e.id !== error.id),
    });
  };

  const handleDelete = (value: string) => {
    helpers.setValue(
      multiple ? field.value.filter((v: string) => v !== value) : undefined
    );
  };

  return (
    <Files
      multiple={multiple}
      maxSize={maxSize}
      accept={accept}
      onSuccess={(files: any) => onSuccess(files)}
      onError={(errors: any) => onError(errors)}
    >
      {({ browseFiles }: any) => (
        <>
          <Label label={label} required={required} />

          <div className="custom-file">
            <label onClick={browseFiles} className="custom-file-label">
              Upload File Here
            </label>
          </div>

          <FilePreviewContainer className='uploaded-files'>
            {(multiple ? field.value : field.value ? [field.value] : []).map(
              (value: string) => (
                <FilePreview key={value} file={value} onDelete={handleDelete} />
              )
            )}
            {state.files.map(file => (
              <FileUploading
                file={file}
                key={file.id}
                isCancelAllowed={isCancelAllowed}
                onCancel={onCancel}
              />
            ))}

            {state.errors.map(error => (
              <FileError
                error={error}
                onClose={handleCloseError}
                key={error.id}
              />
            ))}
          </FilePreviewContainer>
          <ErrorMessage meta={meta} />
        </>
      )}
    </Files>
  );
};

export default FileUpload;
