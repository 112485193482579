import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PageTemplate,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export enum pageType {
  'BLOG',
  'FREE_RESOURCE',
  'NO_SEO',
  'DESIGNER',
}

export interface ICMS extends PageTemplate {
  url?: string;
  displayPicture?: string;
  pageType: pageType;
  activate?: boolean;
  course?: string;
  category?: string;
  subCategory?: string;
  subSubCategory?: string;
  exam?: string[];
  uploadedFile?: string[];
  tags?: string[];
  isFree?: boolean;
  featured?: boolean;
}

export interface ICMSDocument extends ICMS, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface ICMSListResponse {
  totalItems: number;
  cms: ICMSDocument[];
}

export interface ICMSList extends IListParams {
  exam?: string;
  pageType?: string;
  activate?: boolean;
  course?: string;
  category?: string;
  subCategory?: string;
  subSubCategory?: string;
}

const get: TGetResource<ICMSDocument> = (id, params) => {
  return client.get<ICMSDocument>(`/cms/${id}`, { params });
};

const create: TCreateResource<ICMS, ICMSDocument> = (cms: ICMS) => {
  return client.post<ICMSDocument>(`/cms`, cms);
};

const update: TUpdateResource<ICMSDocument> = cms => {
  return client.put(`/cms/${cms._id}`, cms);
};

const patch: TUpdateResource<ICMSDocument> = cms => {
  return client.patch(`/cms/${cms._id}`, cms);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/cms/${id}`);
};

const list: TListResource<ICMSList, ICMSListResponse> = params => {
  return client.get(`/cms/`, { params });
};

export const cms = { get, create, update, remove, list, patch };
