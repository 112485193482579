import { course } from './course';
import { auth } from './auth';
import { asset } from './asset';
import { educator } from './educator';
import { exam } from './exam';
import { section } from './section';
import { subject } from './subject';
import { topic } from './topic';
import { courseware } from './courseware';
import { question } from './question';
import { user } from './user';
import { studentProfile } from './student-profile';
import { questionSet } from './question-set';
import { wall } from './wall';
import { wallComment } from './wall-comment';
import { wallResponse } from './wall-response';
import { doubt } from './doubt';
import { discussions } from './discussion';
import { doubtComment } from './doubt-comment';
import { doubtResponse } from './doubt-response';
import { client } from './client';
import { subscriber } from './subscriber';
import { interview } from './interview';
import { resume } from './resume';
import { userInterview } from './user-interview';
import { cms } from './cms';
import { media } from './media';
import { userGroup } from './user-group';
import { support } from './support';
import { payment } from './payment';
import { assignment } from './assignment';
import { batch as batchV2 } from './batch';
import { notifications } from './user-notification';
import { follow } from './follow';
import { coupon } from './coupon';
import { session } from './live-session';
import { tenant } from './tenant';
import { EnvironmentService } from './env.service';
import { category } from './category';
import { faq } from './faq';
import { testimonial } from './testimonials';
import { pressAndMedia } from './pressAndMedia';
import { program } from './program';
import { test } from './test/test';
import { testBundleV2 } from './test/test-bundle';
import { testAttempt } from './test/test-attempt';
import { userTestBundle } from './test/user-test-bundle';
import { userTest } from './test/user-test';
import { freeResource } from './free-resource';
import { center } from './center';
import axios from 'axios';
import { psychometricQuestion } from './psychometric-test/questions';
import { psychometricTest } from './psychometric-test/test';
import { psychometricTestAttempt } from './psychometric-test/test-attempt';
import { psychometricUserTest } from './psychometric-test/user-test';
import { bgv } from './bgv';
import { transaction } from './transaction';

const studentSiteUrl = () => {
  const env = EnvironmentService.getEnv();
  let url: string = '';
  if (env === 'PROD') {
    url = 'https://student.educrack.com/';
  } else if (env === 'PREDEV') {
    url = 'https://uat.student.educrack.com/';
  } else {
    url = 'https://dev.student.educrack.com/';
  }
  return url;
};

const EducrackAPI = {
  asset,
  auth,
  course,
  courseware,
  batchV2,
  exam,
  educator,
  section,
  subject,
  topic,
  user,
  wallResponse,
  wallComment,
  wall,
  discussions,
  doubtResponse,
  doubtComment,
  doubt,
  question,
  questionSet,
  studentProfile,
  subscriber,
  interview,
  resume,
  userInterview,
  cms,
  media,
  userGroup,
  support,
  payment,
  assignment,
  notifications,
  follow,
  coupon,
  session,
  category,
  faq,
  testimonial,
  pressAndMedia,
  test,
  testBundleV2,
  testAttempt,
  userTestBundle,
  userTest,
  program,
  freeResource,
  center,
  tenant,
  psychometricQuestion,
  psychometricTest,
  psychometricTestAttempt,
  psychometricUserTest,
  bgv,
  transaction,
  studentSiteUrl,
  setToken: (token: string) => {
    client.defaults.headers.authorization = `Bearer ${token}`;
  },
  getConfig: () => {
    return EnvironmentService.getConfig();
  },
  setENV: (env: string): Promise<any> => {
    EnvironmentService.setEnv(env);
    let url;
    switch (env) {
      case 'live':
        url = 'https://api.lexiconeduversity.com/api/v1/';
        break;
      case 'local':
        url = 'http://localhost:9000/api/v1/';
        break;
      case 'predev':
        url = 'https://uat-dashboard.educrack.com/api/v1/';
        break;
      default:
        url = 'https://test.api.educrack.com/api/v1/';
        break;
    }
    client.defaults.baseURL = url;
    return axios
      .get(`${url}config`)
      .then(({ data }: any) => {
        EnvironmentService.setConfig(data);
      })
      .catch(err => {
        console.log(err);
      });
  },
};

export default EducrackAPI;
