import Swal from 'sweetalert2';

export const toggleFullScreen = () => {
  try {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen = docEl.requestFullscreen;
    const cancelFullScreen = doc.exitFullscreen;

    if (!doc.fullscreenElement) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  } catch {
    console.log('error');
  }
};

export const stopPrintScreen = () => {
  var inpFld = document.createElement('input');
  inpFld.setAttribute('value', '.');
  inpFld.setAttribute('width', '0');
  inpFld.style.height = '0px';
  inpFld.style.width = '0px';
  inpFld.style.border = '0px';
  document.body.appendChild(inpFld);
  inpFld.select();
  document.execCommand('copy');
  inpFld.remove();
};

export const loadWIRISplugins = () => {
  // @ts-ignore
  if (window !== undefined) {
    // @ts-ignore
    const _window = window as any;
    const script = _window.document.getElementById('WIRISplugins') || false;
    if (!!script) {
      script.parentElement.removeChild(script);
    }
    const url =
      'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    const jsDemoImagesTransform = _window.document.createElement('script');
    jsDemoImagesTransform.type = 'text/javascript';
    jsDemoImagesTransform.id = 'WIRISplugins';
    jsDemoImagesTransform.src = url;
    _window.document.head.appendChild(jsDemoImagesTransform);
  }
};

export const setOnlineStatus = (status: boolean) => {
  if (status) {
    sweetAlert({
      icon: 'success',
      title: 'Yeah...',
      text: 'You are back online, you can proceed now.',
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonText: 'Ok',
    });
  } else {
    sweetAlert({
      icon: 'error',
      title: 'Oops...',
      text: 'You are offline, please get back once you are online.',
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  }
};

export const sweetAlert = async ({
  icon,
  title,
  text,
  allowOutsideClick,
  showConfirmButton,
  confirmButtonText,
}: any) => {
  const data = await Swal.fire({
    icon: icon,
    title: title,
    text: text,
    allowOutsideClick: allowOutsideClick,
    showConfirmButton: showConfirmButton,
    confirmButtonText: confirmButtonText,
  });
  return data;
  console.log(data);
};

/**
 * gets first section from section list which is not disabled
 * @returns {object} : section
 */
export const getDefaultActiveSection = (
  sectionList: any,
  submittedSections = []
) => {
  for (let i = 0; i < sectionList.length; i++) {
    const sectionId = sectionList[i].section?._id
      ? sectionList[i].section?._id
      : sectionList[i].section;
    if (
      !submittedSections.find((section: any) => section?.section === sectionId)
    ) {
      return sectionList[i];
    }
  }
};

export const getFirstQuestionOfActiveSection = (
  questionList = [],
  sectionId: string = '',
  submittedSection = [''],
  lastSubmittedQuestionId: string
) => {
  const lastSubmittedQuestion: any =
    questionList.length &&
    questionList?.find((q: any) => q?._id === lastSubmittedQuestionId);
  if (lastSubmittedQuestion) return lastSubmittedQuestion;
  if (!questionList.length) return null;
  if (!submittedSection?.includes(sectionId)) {
    return questionList[0];
  }
  if (!sectionId && questionList.length) return questionList[0];

  const question = questionList.find(
    (question: any) => question?.section?._id === sectionId
  );
  return question ? question : questionList[0];
};

export const checkApiError = (error: any) => {
  if (
    error?.status === 422 &&
    (error?.data?.message === 'Time Up.Test Already Submitted.' ||
      error?.data.message === 'Time Up.Test Already Submitted."s')
  ) {
    const data: any = sweetAlert({
      title: "Time's Up.",
      text: "You can't submit the test.",
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'OK',
    });
    if (data?.isConfirmed) {
      window.close();
    }
  }
  if (error?.status === 404) {
    Swal.fire({
      title: "Time's Up.",
      text: "Sorry, you can't submit the test.",
      icon: 'error',
      confirmButtonText: 'OK',
    }).then(() => {
      window.close();
    });
  }
  return;
};

/**
 * Method to get test over time
 * @returns {Date Object}
 */
export const getTestOverTime = (
  startTime: any,
  testDurationInMins: any = 0
) => {
  const startTimeDate = new Date(startTime);
  return new Date(startTimeDate.getTime() + testDurationInMins * 60000);
};

export const getQuestionData = (questionList: any) => {
  const count = {
    ANSWERED: 0,
    'NOT ANSWERED': 0,
    'MARKED FOR REVIEW': 0,
    'NOT VISITED': 0,
  };
  questionList &&
    questionList.forEach((question: any) => {
      if (question?.isMarkForReview) {
        count['MARKED FOR REVIEW'] = count['MARKED FOR REVIEW'] + 1;
      }
      if (question?.status === 'ANSWERED') {
        count['ANSWERED'] = count['ANSWERED'] + 1;
      } else if (question?.status === 'NOT VISITED') {
        count['NOT VISITED'] = count['NOT VISITED'] + 1;
      } else {
        count['NOT ANSWERED'] = count['NOT ANSWERED'] + 1;
      }
    });
  return count;
};
