import React from 'react';
import Key from './key';

const Keyboard = ({ keyClick }: any) => {
  const x = <span>&#x1D4B3;</span>;
  const inv = <sup>-1</sup>;
  const pow2 = <sup>2</sup>;
  const pow3 = <sup>3</sup>;

  return (
    <div className='keyboard'>
      <ul>
        <li>
          <Key
            keyLog="sin("
            Tag="sin"
            math="trig"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog="cos("
            Tag="cos"
            math="trig"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog="tan("
            Tag="tan"
            math="trig"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog=""
            Tag="M+"
            math="log"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            Tag={[x, inv]}
            keyLog="^-1"
            math="inv"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            Tag={[x, pow2]}
            keyLog="^2"
            math="sqr"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog="^3"
            Tag={[x, pow3]}
            math="power"
            keyClick={keyClick}
          />
        </li>

        <li>
          <Key
            Tag="&radic;"
            keyLog="&radic;("
            math="sqrt"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog=""
            Tag="hyp"
            math="log"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            Tag="ln"
            keyLog="ln("
            math="log"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog=""
            Tag="ENG"
            math=""
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            Tag="^"
            keyLog="^"
            math="power"
            keyClick={keyClick}
          />
        </li>
        <li><Key Tag="Ans" keyLog="Ans" math="ans" keyClick={keyClick} /></li>
        <li>
          <Key Tag="EXP" keyLog="E" math="exponent" keyClick={keyClick} />
        </li>
        <li>
          <Key
            keyLog=""
            Tag="RCL"
            math=""
            keyClick={keyClick}
          />
        </li>

        <li>
          <Key
            keyLog=""
            Tag=","
            math="log"
            keyClick={keyClick}
          />
        </li>

        <li>

          <Key
            Tag="log"
            keyLog="log("
            math="log"
            keyClick={keyClick}
          />
        </li>

        <li>
          <Key
            Tag="DEL"
            math="delete"
            keyClick={keyClick}
          />

        </li>
      </ul>
      <ul>
        <li>
          <Key
            keyLog="("
            Tag="("
            math="prnths"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog=")"
            Tag=")"
            math="prnths"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key
            keyLog="-"
            Tag="(&minus;)"
            math="sub"
            keyClick={keyClick}
          />
        </li>
        <li>
          <Key Tag="7" keyLog="7" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="8" keyLog="8" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="9" keyLog="9" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="4" keyLog="4" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="5" keyLog="5" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="6" keyLog="6" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="1" keyLog="1" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="2" keyLog="2" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="3" keyLog="3" math="number" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="0" keyLog="0" math="log" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="." keyLog="." math="comma" keyClick={keyClick} />
        </li>
        <li>
          <Key Tag="=" math="equals" keyClick={keyClick} />
        </li>
      </ul>
      <ul>
        <li>
          <Key
            Tag="AC"
            math="clear"
            keyClick={keyClick}
          />
        </li>

        <li>
          <Key
            Tag="&divide;"
            keyLog="&divide;"
            math="divide"
            keyClick={keyClick}
          />
        </li>

        <li>
          <Key
            Tag="&times;"
            keyLog="&times;"
            math="multiply"
            keyClick={keyClick}
          />
        </li>

        <li>
          <Key Tag="&minus;" keyLog="-" math="subtract" keyClick={keyClick} />
        </li>

        <li>
          <Key Tag="+" keyLog="+" math="sum" keyClick={keyClick} />
        </li>
      </ul>
    </div>

    // <>
    //   <div className="keyboard-row">
    //     <Key
    //       className="sm-button"
    //       Tag={[x, inv]}
    //       keyLog="^-1"
    //       math="inv"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       Tag="&radic;"
    //       keyLog="&radic;("
    //       math="sqrt"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       Tag={[x, pow2]}
    //       keyLog="^2"
    //       math="sqr"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       Tag="^"
    //       keyLog="^"
    //       math="power"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       Tag="log"
    //       keyLog="log("
    //       math="log"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       Tag="ln"
    //       keyLog="ln("
    //       math="log"
    //       keyClick={keyClick}
    //     />
    //   </div>
    //   <div className="keyboard-row">
    //     <Key
    //       className="sm-button"
    //       keyLog="-"
    //       Tag="(&minus;)"
    //       math="sub"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog="^3"
    //       Tag={[x, pow3]}
    //       math="power"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog=""
    //       Tag="hyp"
    //       math="log"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog="sin("
    //       Tag="sin"
    //       math="trig"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog="cos("
    //       Tag="cos"
    //       math="trig"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog="tan("
    //       Tag="tan"
    //       math="trig"
    //       keyClick={keyClick}
    //     />
    //   </div>
    //   <div className="keyboard-row">
    //     <Key
    //       className="sm-button"
    //       keyLog=""
    //       Tag="RCL"
    //       math=""
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog=""
    //       Tag="ENG"
    //       math=""
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog="("
    //       Tag="("
    //       math="prnths"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog=")"
    //       Tag=")"
    //       math="prnths"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog=""
    //       Tag=","
    //       math="log"
    //       keyClick={keyClick}
    //     />
    //     <Key
    //       className="sm-button"
    //       keyLog=""
    //       Tag="M+"
    //       math="log"
    //       keyClick={keyClick}
    //     />
    //   </div>
    //   <div className="keyboard-row">
    //     <Key Tag="1" keyLog="1" math="number" keyClick={keyClick} />
    //     <Key Tag="2" keyLog="2" math="number" keyClick={keyClick} />
    //     <Key Tag="3" keyLog="3" math="number" keyClick={keyClick} />
    //     <Key
    //       className="orange-button"
    //       Tag="DEL"
    //       math="delete"
    //       keyClick={keyClick}
    //     />

    //   </div>
    //   <div className="keyboard-row">
    //     <Key Tag="4" keyLog="4" math="number" keyClick={keyClick} />
    //     <Key Tag="5" keyLog="5" math="number" keyClick={keyClick} />
    //     <Key Tag="6" keyLog="6" math="number" keyClick={keyClick} />


    //   </div>
    //   <div className="keyboard-row">
    //     <Key Tag="7" keyLog="7" math="number" keyClick={keyClick} />
    //     <Key Tag="8" keyLog="8" math="number" keyClick={keyClick} />
    //     <Key Tag="9" keyLog="9" math="number" keyClick={keyClick} />


    //   </div>
    //   <div className="keyboard-row">
    //     <Key Tag="0" keyLog="0" math="log" keyClick={keyClick} />
    //     <Key Tag="." keyLog="." math="comma" keyClick={keyClick} />
    //     <Key Tag="EXP" keyLog="E" math="exponent" keyClick={keyClick} />
    //     <Key Tag="Ans" keyLog="Ans" math="ans" keyClick={keyClick} />
    //     <Key Tag="=" math="equals" keyClick={keyClick} />
    //   </div>
    // </>
  );
};

export default Keyboard;
