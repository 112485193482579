import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IDoubtCommentListParams extends IListParams {
  user?: ObjectId;
  populate?: boolean;
}

export interface IDoubtComments {
  text: string;
  doubt: ObjectId | PopulatedDocument;
}

export interface IDoubtCommentsDocument extends IDoubtComments, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IDoubtCommentsListResponse {
  totalItems: number;
  doubtsComments: IDoubtCommentsDocument[];
}

const list = (doubtId: string, params?: IDoubtCommentListParams) => {
  return client.get<IDoubtCommentsDocument>(
    `/doubts/doubt-comments/${doubtId}`,
    { params }
  );
};

const create = (doubtComment: IDoubtComments) => {
  return client.post<IDoubtCommentsDocument>(
    `/doubts/doubt-comments`,
    doubtComment
  );
};

const remove = (commentId: string) => {
  return client.delete(`/doubts/doubt-comments/${commentId}`);
};

export const doubtComment = { create, remove, list };
