import { client } from './client';
import {
  IDocument,
  TCreateResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from 'types';
import { IListParams } from './types';

export interface IInfluencer {
  name?: string;
  emailAddress?: string;
  phoneNumber?: number;
}
export interface ICoupon {
  coupon?: string;
  couponName?: string;
  couponType: string;
  discount: number;
  maxTotalUsage: number;
  maxUsagePerUser?: number;
  groupName?: string;
  validityStartTime: Date;
  validityEndTime?: Date;
  active?: boolean;
  influencer?: IInfluencer;
}

export interface ICouponBulkUpload {
  file: string;
}

export interface ICouponResponse extends ICoupon, IDocument { }

export interface ICouponListResponse {
  totalItems: number;
  courses: ICouponResponse[];
}

const list: TListResource<IListParams, ICouponListResponse> = params => {
  return client.get(`/coupon`, { params });
};

const get: TGetResource<ICouponResponse> = (id, params) => {
  return client.get<ICouponResponse>(`/coupon/${id}`, { params });
};

const update = (id: string, coupon: ICouponResponse) => {
  const url = `/coupon/${id}`
  return client.put<ICouponResponse>(url, coupon );
};

const deactivate: TUpdateResource<ICouponResponse> = (id) => {
  return client.put(`/coupon/deactivate/${id}`);
};


const createCoupon: TCreateResource<ICoupon, ICouponResponse> = (
  body: ICoupon
) => {
  return client.post<ICouponResponse>(`/coupon`, body);
};

const bulkUploadCoupons = (coupons: ICouponBulkUpload) => {
  return client.post<any>(`/coupon/upload`, coupons);
};

export const coupon = { list, get, createCoupon, update, deactivate, bulkUploadCoupons };

