import * as Yup from 'yup';

export const FormSchemaSelectAssign = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  course: Yup.string().required('Course is required'),
});

export const FormSchemaAssignments = Yup.object().shape({
  startTime: Yup.mixed().required('Start Time is required'),
  // endTime: Yup.mixed().required('End Time is required'),
});
