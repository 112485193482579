import React, { useEffect, useState } from 'react';
import { PerformanceIndexType } from '../test-results-types';
import {
  barChartOptions,
  buildChartData,
  buildDropdownData,
  buildTopPerformerData,
  convertTime,
} from '../utility';
import { Form } from '@lipihipi/form';
import BarChart from '../bar-chart';
import { Select } from '../../../components/select/select';

const PerformanceIndex = ({
  attemptId,
  tableData,
  topPerformer,
  otherAttempts,
  isFullLength
}: PerformanceIndexType) => {
  const [performanceIndexData, setPerformanceIndexData] = useState<any>({
    dropdownValues: [],
    topPerformerArray: [],
    questionData: [],
    otherAttempts: [],
  });
  const [barChartData, setBarChartData] = useState({});

  useEffect(() => {
    getApiData(attemptId, tableData);
  }, [attemptId, tableData]);

  const getApiData = async (id: string, attemptData: any) => {
    try {
      const topPerformerData = await topPerformer(id);
      const performanceInsightGraph = await otherAttempts(id);
      const obj = { ...performanceIndexData };
      obj.dropdownValues = buildDropdownData(performanceInsightGraph?.data);
      obj.chartData = buildChartData(
        performanceInsightGraph?.data,
        obj.dropdownValues,
        false
      );
      obj.topPerformerArray = buildTopPerformerData(topPerformerData?.data);
      obj.otherAttempts = performanceInsightGraph?.data;
      obj.questionData = attemptData;
      setPerformanceIndexData(obj);
      setBarChartData(obj.chartData);
    } catch (err) {
      console.log(err);
    }
  };

  const renderQuestionData = (value: any) => {
    return (
      <>
        {value ? (
          value?.question?.map((ques: any, i: any) => (
              <div className="wrap">
                <div className="secondary-table--col" key={i}>
                  {i + 1}
                </div>
                <div className="secondary-table--col">
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ques?.question?.text,
                      }}
                    ></div>
                  </p>
                </div>
                <div className="secondary-table--col">{`${value?.totalAttempts}/${value?.totalTestAttempts} students`}</div>
                <div className="secondary-table--col">
                  <div className="correct-result">
                    {`${value?.correctAttempts}/${value?.totalTestAttempts} Students got it correct`}
                  </div>
                </div>
                <div className="secondary-table--col">
                  <span>{convertTime(ques?.timeTaken) || 'NA'}</span>
                  {/* <a>See Graph View</a> */}
                </div>
              </div>
          ))
        ) : (
          <h5>No data found.</h5>
        )}
      </>
    );
  };

  const handleDropdownChange = (event: any) => {
    if (event) {
      const newData = buildChartData(
        performanceIndexData?.otherAttempts,
        event,
        true
      );
      setBarChartData(newData);
    } else setBarChartData(performanceIndexData.chartData);
  };

  console.log(tableData, 'tableData');


  return (
    <>
      <div className="performance-insight">
        <div className="performance-insight_header">
          <h3 className="m-0">My Performance Insights</h3>
          {isFullLength && performanceIndexData?.dropdownValues?.length > 0 && (
            <Form initialValues={{}} onSubmit={handleDropdownChange}>
              <Select
                style={{ minWidth: 160 }}
                className="form-group mb-0"
                id="dropdown"
                name="dropdown"
                options={performanceIndexData?.dropdownValues}
                onChange={event => handleDropdownChange(event)}
                isMulti
              />
            </Form>
          )}
        </div>

        {isFullLength &&
          Object.keys(barChartData)?.length > 0 &&
          performanceIndexData?.dropdownValues?.length > 0 && (
            <div className="performance-insight_body">
              <BarChart
                options={{
                  ...barChartOptions,
                  maintainAspectRatio: false,
                }}
                data={barChartData}
                width={10}
                height={300}
              />
            </div>
          )}
      </div>

      <div className="secondary-table performance-index-table mt-5">
        <div className="secondary-table--header">
          <div className="secondary-table--row">
            <div className="secondary-table--col">Q.No</div>
            <div className="secondary-table--col">Question</div>
            <div className="secondary-table--col">Attempted</div>
            <div className="secondary-table--col">Results</div>
            <div className="secondary-table--col">Avg. Time Taken</div>
          </div>
        </div>
        <div className="secondary-table--body">
          <div className="secondary-table--row">
          {isFullLength
              ? Object.entries(tableData).map(([key, value]: any) => (
                  <>
                    <h5>Section: {key}</h5>
                    {value?.question ? (
                      renderQuestionData(value)
                    ) : (
                      <div>No Data Found</div>
                    )}
                  </>
                ))
              : Object.keys(tableData)?.length > 0 &&
                renderQuestionData(tableData)}
          </div>
        </div>
      </div>

      {isFullLength && performanceIndexData?.topPerformerArray?.length > 0 && (
        <div className="performance-insight mt-5">
          <div className="performance-insight_header">
            <h3>Top 50 Performers</h3>
          </div>

          <div className="performance-insight_body">
            <table className="table table-bordered">
              <tbody>
                {performanceIndexData?.topPerformerArray?.map((v: any) => (
                  <tr>
                    <td>{v?.name}</td>
                    <td>{`${v?.percentile}%`}</td>
                    <td>{v?.totalTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default PerformanceIndex;
