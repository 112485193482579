import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  PopulatedDocument,
  TListResource,
  TUpdateResource,
} from './types';

export enum IProgramType {
  LIVE = 'Live',
  RECORDED = 'Recorded',
}
export interface IProgram {
  course: string;
  name: string;
  price: number;
  actualPrice: number;
  duration: string;
  displayPicture: string;
  programType: IProgramType;
  syllabus?: string;
  videos?: string[];
  courseware?: ObjectId[] | PopulatedDocument[];
  status: string;
  testBundles?: string[];
  coupon?: any;
}

export interface IUpdateList {
  id: string;
  displayOrder: number;
}

export interface IProgramDocument extends IProgram, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IUpdateListResponse {
  message: string;
}

export interface IProgramListResponse {
  totalItems: number;
  programs: IProgramDocument[];
}

const get: TGetResource<IProgramDocument> = (id, params) => {
  return client.get<IProgramDocument>(`/programs/${id}`, { params });
};

const create: TCreateResource<IProgram, IProgramDocument> = (
  program: IProgram
) => {
  return client.post<IProgramDocument>(`/programs`, program);
};

const update: TUpdateResource<IProgramDocument> = program => {
  return client.put(`/programs/${program._id}`, program);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/programs/${id}`);
};

const list: TListResource<IListParams, IProgramListResponse> = params => {
  return client.get(`/programs/`, { params });
};

export const program = { get, create, update, remove, list };
