import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { ImFileText2 } from 'react-icons/im';
import { commonApiError } from '../subscriber-report';

const SubscriberTests = ({ getTests, _id, onTestBundleClick }: any) => {
  const [tests, setTests] = useState<any>({
    totalItems: 0,
    UserTestBundles: [],
  });
  const [visible, setVisible] = useState<any>(10);

  const loadMore = () => {
    setVisible(visible + 10);
  };

  useEffect(() => {
    getTests({ all: true, populate: true, user: _id })
      .then(({ data }: any) => {
        setTests(data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  }, []);

  return (
    <div className="lesson-wrapping">
      <p>List of Enrolled Test(ATT)</p>

      <div className="batch-accordion test-accordian">
        {tests?.UserTestBundles?.length > 0 ? (
          tests?.UserTestBundles?.slice(0, visible)?.map((test: any) => {
            return (
              <Accordion key={test?._id}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <p className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items--md-center pr-4">
                      <span style={{ fontSize: '15px' }}>{test?.name}</span>
                      <span>
                        <span
                          className="mr-3"
                          style={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                          {test?.course?.name || 'Not Available'}
                        </span>
                        <span style={{ fontSize: '12px' }}>
                          {test?.createdAt
                            ? moment(test?.createdAt).format(
                                'DD MMMM YYYY, h:mma'
                              )
                            : '--'}
                        </span>
                      </span>
                    </p>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ padding: '0 17px 17px' }}>
                      {test && (
                        <>
                          <div className="my-progress">
                            <div className="my-progress--name">
                              <span>
                                <ImFileText2 />
                              </span>
                              <p>Tests</p>
                            </div>
                            <div className="my-progress--bar">
                              <div className="primary-progress-bar">
                                <div className="wrap">
                                  <div
                                    className="bar-line"
                                    style={{ width: '30%' }}
                                  >
                                    Line
                                  </div>
                                </div>
                                <span>0/{test?.tests?.length}</span>
                              </div>
                              <p>
                                10 attended out of total 45 Tests assigned to
                                you
                              </p>
                            </div>
                            <div className="my-progress--action">
                              <a
                                // href="javascript:void(0)"
                                style={{ cursor: 'pointer' }}
                                onClick={() => onTestBundleClick(test?._id)}
                              >
                                View Details
                              </a>
                              {/* <span>Score</span> */}
                            </div>
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            );
          })
        ) : (
          <p>No Data Found</p>
        )}
      </div>
      {visible < tests?.UserTestBundles?.length && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={loadMore}>View More</p>
        </div>
      )}
      {visible >= tests?.UserTestBundles?.length && visible > 10 && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={() => setVisible(10)}>View less</p>
        </div>
      )}
    </div>
  );
};

export default SubscriberTests;
