import React, { useEffect, useState } from 'react';
import Pad from './Pad';

export const NumericKeyboard = ({ onChange, value }: any) => {
  const [display, setDisplay] = useState<any>('');
  const [position, setPosition] = useState<any>();

  useEffect(() => {
    if (value) {
      setDisplay(value);
      setPosition(value.length - 1);
    } else setDisplay('');
  }, [value]);

  // Pad buttons handlers
  const onDigitButtonClick = (digit: any) => {
    const prevData = [...display];
    if (position || position === 0) prevData.splice(position + 1, 0, digit);
    else prevData.push(digit);
    setDisplay(prevData.join(''));
    onChange(prevData.join(''));
    setPosition(prevData.join('').length - 1);
  };

  const onClearEntryButtonClick = () => {
    if (display) {
      const prevData = [...display];
      prevData.splice(position, 1);
      setDisplay(prevData.join(''));
      onChange(prevData.join(''));
      setPosition(0);
    }
  };

  const onArrowClick = (arrow: string) => {
    if (display) {
      if (arrow === '<') {
        // if (position === 0) setPosition(display.length - 2);
        setPosition(position - 1);
      } else {
        setPosition(position || 0 + 1);
      }
    }
  };

  return (
    <Pad
      onDigitButtonClick={onDigitButtonClick}
      onClearEntryButtonClick={onClearEntryButtonClick}
      onArrowClick={onArrowClick}
    />
  );
};

export default NumericKeyboard;
