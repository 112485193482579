import { IDocument, IListParams, ObjectId } from './types';
import { client } from './client';
import { IUser } from './user';
import { ICourseDocument } from 'course';

export interface ISubscriberListParams extends IListParams {
  course?: ObjectId;
  batch?: ObjectId;
}

export interface IBatchSubscription {
  user: string;
  status: string;
  createdAt: string;
}

export interface IBatch {
  name: string;
  course: ICourseDocument;
  batchSubscription: IBatchSubscription;
}

export interface ISubscriber {
  name: string;
  user: IUser;
  displayPicture: string;
  batch: IBatch;
}

export interface ISubscriberDocument extends ISubscriber, IDocument {}
export interface ISubscriberResponse {
  totalItems: number;
  students: ISubscriberDocument[];
}

const getSubscriber = (params: ISubscriberListParams) => {
  return client.get<ISubscriberResponse>(`/student-profile/students-list`, {
    params,
  });
};

export const subscriber = { getSubscriber };
