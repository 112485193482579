import EducrackAPI from '@lipihipi/client-sdk';
import { Button, Modal, PageHeader } from '@lipihipi/ec-ui';
import { DateTime, Form, Input, Select } from '@lipihipi/form';
import React from 'react';
import { commonApiError } from '../create/index';

const EditLiveModal = ({ isOpen, onRequestClose, filterData }: any) => {
  const mapOptions = (values: any[]) => {
    return values.map(value => ({ label: (value !== undefined ? value.name : ''), value: value._id }));
  };

  const handleLoadForSubject = (inputValue: string) => {
    return EducrackAPI.subject.list({ q: inputValue })
      .then(({ data }: any) => {
        return mapOptions(data.subjects);
      })
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  };

  const handleLoadForTopic = (inputValue: string) => {
    return EducrackAPI.topic.list({ q: inputValue })
      .then(({ data }: any) => {
        return mapOptions(data.topics);
      })
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  };

  const handleLoadForBatch = (inputValue: string) => {
    return EducrackAPI.batchV2.list({ q: inputValue })
      .then(({ data }: any) => {
        return mapOptions(data.batches);
      })
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  };

  const filterDataValues = (data: any) => {
    filterData(data);
    onRequestClose(true);
  };

  const resetFilterData = () => {
    window.location.reload();
    //filterData();
    onRequestClose(true);
  };

  const options = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
  ];

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} >
      <>
        <PageHeader title={'Filter By:'} />
        <Form
          initialValues={{
            startDate: '',
          }}
          onSubmit={filterDataValues}
          // validationSchema={formSchema}
          render={() => (
            <>
              <div className="row mt-3">
                <div className="col-md-6">
                  <DateTime
                    id={'startDate'}
                    label={'Start Date'}
                    name={'startDate'}
                  />
                </div>
                <div className="col-md-6">
                  <DateTime
                    id={'endDate'}
                    label={'End Date'}
                    name={'endDate'}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Select
                    id={'batch'}
                    name="batch"
                    label="Batch Name"
                    //@ts-ignore
                    options={handleLoadForBatch}
                    placeholder="Batch"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Select
                    id={'topics'}
                    name="topics"
                    label="Topics"
                    //@ts-ignore
                    options={handleLoadForTopic}
                    placeholder="Topics"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Input
                    id={'classTitle'}
                    name="classTitle"
                    type="text"
                    label="Class Title"
                    placeholder="Class Title"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Button shape="secondary" onClick={resetFilterData}>Reset All</Button>
                  <Button shape="primary" className="ml-3" type="submit">
                    Apply{' '}
                  </Button>
                </div>
              </div>

            </>
          )

          }
        />
      </>
    </Modal>
  );
};

export default EditLiveModal;
