import { Modal } from '@lipihipi/ec-ui';
import React from 'react';
import { TestInstructions } from '../test-instructions/test-instructions';
import { AiOutlineClose } from "react-icons/ai";

const InstructionModal = ({
  isOpen,
  onRequestClose,
  attempt,
  isFromTest,
}: any) => {
  return (
    <Modal heightDefault={true} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div style={{ width: '800px', maxWidth: '100%' }}>
        <button onClick={onRequestClose} style={{
          position: 'absolute',
          right: 0,
          top: 0,
          border: 'none',
          background: '#dfdfdf',
          width: 40,
          height: 40,
          borderRadius: 4,
          lineHeight: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <AiOutlineClose />
        </button>
        <TestInstructions
          attempt={attempt}
          onRequestClose={onRequestClose}
          isFromTest={isFromTest}
        />
      </div>
    </Modal>
  );
};

export default InstructionModal;
