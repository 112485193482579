import React from 'react';
import { Label } from '../label/label';
import { TagInputProps } from './tag-iput.types';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';

export const TagInput = ({
  id,
  placeholder,
  tags,
  takeSpace,
  label,
  limit,
  onChange,
  className,
  style,
  required,
}: TagInputProps) => {
  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      <ReactTagInput
        maxTags={limit}
        tags={tags}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};
