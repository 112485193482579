import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IBatchListParams extends IListParams {
  courseId?: ObjectId;
  status?: batchStatus;
  courseIds?: ObjectId;
  batchType?: string;
  program?: string;
  student?: string;
}

export enum progressType {
  'VIDEO',
  'SESSION',
}

export interface IProgressData extends IDocument {
  batchId: ObjectId;
  progessType: progressType;
  progressDetail: string;
}
export enum IBatchType {
  LIVE = 'Live',
  RECORDED = 'Recorded',
}

export interface IBatch extends IDocument {
  name: string;
  course: ObjectId | PopulatedDocument;
  program: ObjectId | PopulatedDocument;
  displayPicture: string;
  exams?: ObjectId[] | PopulatedDocument[];
  startDate: string;
  batchType: IBatchType;
  endDate?: string;
  startTime: ITime;
  endTime: ITime;
  days: Number[];
  videos: string[];
  isActive: Boolean;
}

export interface IBatchSubscriptionDocument extends IDocument {
  batchId: IBatch;
  addedBy: ObjectId | PopulatedUserDocument;
}
export interface IBatchDocument extends IBatch, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IBatchListResponse {
  totalItems: number;
  batches: IBatchDocument[];
}

export interface IBatchSectionItem {
  courseware?: ObjectId | PopulatedDocument;
  test?: ObjectId | PopulatedDocument;
  displayOrder?: Number;
}
export interface IBatchSection {
  batchId?: ObjectId;
  name: string;
  items: IBatchSectionItem[];
  displayOrder?: Number;
}

export interface IBatchSectionDocument extends IBatchSection, IDocument {}

export enum batchStatus {
  'INIT',
  'DRAFT',
  'RECONCILE',
  'INACTIVE',
  'PUBLISH',
}

export enum status {
  'INIT',
  'PUBLISH',
}
export interface ITime {
  hh: string;
  mm: string;
}

const get = (id: string) => {
  return client.get<IBatchDocument>(`/batches/${id}`, {});
};

const create = (batch: IBatch) => {
  return client.post<IBatchDocument>(`/batches`, batch);
};

const update = (batch: IBatchDocument) => {
  return client.put<IBatchDocument>(`/batches/${batch._id}`, batch);
};

const publish = (batchId: string, publish: boolean = true) => {
  const url = `/batches/${batchId}/publish`;
  return client.put<IBatchDocument>(url, { publish });
};

const remove = (id: string) => {
  return client.delete(`/batches/${id}`);
};

const list = (params: IBatchListParams) => {
  return client.get<IBatchListResponse>(`/batches/`, { params });
};

const purchaseBatch = (batch: IBatch) => {
  const url = `/batches/${batch._id}/buynow`;
  return client.post<IBatch>(url, batch);
};

const listPurchasedBatches = (params?: IBatchListParams) => {
  return client.get<IBatchSubscriptionDocument>(`/batches/me`, { params });
};

const getBatchStudents = (batchId: string, params: IListParams) => {
  const url = `/batches/${batchId}/students`;
  return client.get<IBatchSubscriptionDocument>(url, { params });
};

const addBatchProgress = (id: string, data: IProgressData) => {
  return client.post<any>(`/batches/${id}/progress`, data);
};

const getPDFDownload = (id: string) => {
  return client.get<any>(`/batches/${id}/pdf`, { responseType: 'blob' });
};

const getSections = (batchId: string, params: IListParams) => {
  return client.get<IBatchSectionDocument[]>(`/batches/${batchId}/section`, {
    params,
  });
};

const createSection = (batchId: string, section: IBatchSection) => {
  return client.post<IBatchSectionDocument>(
    `/batches/${batchId}/section`,
    section
  );
};

const updateSection = (batchId: string, section: IBatchSectionDocument) => {
  return client.patch<IBatchSectionDocument>(
    `/batches/${batchId}/section/${section._id}`,
    section
  );
};

const removeSection = (batchId: string, sectionId: string) => {
  return client.delete(`/batches/${batchId}/section/${sectionId}`);
};

export const batch = {
  get,
  create,
  update,
  remove,
  list,
  publish,
  purchaseBatch,
  listPurchasedBatches,
  getBatchStudents,
  addBatchProgress,
  getPDFDownload,
  getSections,
  createSection,
  updateSection,
  removeSection,
};
