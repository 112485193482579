import { PageHeader, Loader } from '@lipihipi/ec-ui';
import { CheckBox, Form, Input, Select } from '@lipihipi/form';
import React, { useEffect, useState } from 'react';
import { Button } from '@lipihipi/ec-ui';
import swal from 'sweetalert';
import { ProfileSchema } from './profile-schema';
import { IProfileProps } from './profile.types';
import user from '../../images/user.png';
import Dropzone from '../Dropzone';
import Label from '../Dropzone/Label';
import { FieldArray } from 'formik';
const genderOptions = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Other', value: 'OTHER' },
];

const initialValues = {
  name: '',
  email: '',
  mobile: '',
  displayPicture: '',
  gender: '',
  eduId: '',
  isPersonWithDisability: false,
  kycFile: [
    {
      documentType: 'AADHAR',
      asset: '',
      value: '',
    },
    {
      documentType: 'PAN',
      asset: '',
      value: '',
    },
  ],
};

const Profile = ({
  _id,
  breadCrumbs,
  getStudentProfile,
  uploadFile,
  getAssetUrl,
  onUpdate,
  getFollowDetails,
  onFollowClick,
  upload,
  getPrivateAsset,
}: IProfileProps) => {
  const [studentProfile, setStudentProfile] = useState<any>(initialValues);
  const [followers, setFollowers] = useState<any>({
    follows: [],
    totalItems: 0,
  });
  const [followings, setFollowings] = useState<any>({
    follows: [],
    totalItems: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    getStudentProfile(_id, { populate: true }).then(
      ({ data }: any) => {
        if (data.kycFile.length > 0) {
          setStudentProfile(data);
        } else {
          setStudentProfile({
            ...data,
            kycFile: [
              {
                documentType: 'AADHAR',
                asset: '',
                value: '',
              },
              {
                documentType: 'PAN',
                asset: '',
                value: '',
              },
            ],
          });
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    getFollowDetails({
      requestType: 'FOLLOWER',
      accepted: '1',
      reject: '0',
    }).then(({ data }: any) => {
      setFollowers(data);
      setIsLoading(false);
    });

    getFollowDetails({
      requestType: 'FOLLOWING',
      accepted: '1',
      reject: '0',
    }).then(({ data }: any) => {
      setFollowings(data);
      setIsLoading(false);
    });
  }, []);

  const handleFileSelect = (e: any) => {
    setIsLoading(true);
    uploadFile({ file: e.target.files[0] }).then(
      (res: any) => {
        setIsLoading(false);
        setStudentProfile((prevState: any) => ({
          ...prevState,
          displayPicture: res.data.key,
        }));
      },
      () => {
        setIsLoading(false);
      }
    );
  };
  const handleSubmit = (values: any) => {
    let payload: any = {};
    if(values.displayPicture){
      payload.displayPicture = values.displayPicture
    }
    payload.name = values.name;
    if(values.kycFile[0].asset != ''){
      payload.kycFile = [
        {
          asset:values.kycFile[0].asset,
          document:values.kycFile[0].documentType,
          value:values.kycFile[0].value,
        }
        ];
    }else if(values.kycFile[1].asset != ''){
      payload.kycFile = [
        {
          asset:values.kycFile[1].asset,
          document:values.kycFile[1].documentType,
          value:values.kycFile[1].value,
        }
        ];
    }else if(values.kycFile[0].asset != '' && values.kycFile[1].asset != ''){
      payload.kycFile = values.kycFile
    }
    if (values?.isPersonWithDisability)
      payload.isPersonWithDisability = values?.isPersonWithDisability;
    if (values.gender) {
      payload.gender = values.gender;
    }

    setIsLoading(true);
    onUpdate(studentProfile._id, payload)
      .then(({ status }: any) => {
        setIsLoading(false);
        if (status === 200) {
          swal({
            title: 'Success',
            text: 'Student Profile Updated',
            icon: 'success',
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server error!',
            icon: 'error',
            dangerMode: false,
          });
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err)});
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="d-flex flex-wrap">
        <div className="main-mid">
          <PageHeader
            breadCrumbs={
              breadCrumbs || [{ title: 'Profile', link: '/profile' }]
            }
          />

          <div className="profile-box">
            <div className="profile-box--header">
              <div className="image">
                {studentProfile?.displayPicture ? (
                  <img
                    src={getAssetUrl(studentProfile?.displayPicture)}
                    alt="Icon"
                  />
                ) : (
                  <img src={user} alt="Icon" />
                )}

                <label>
                  <input
                    type="file"
                    accept="image/.png,image/.jpg,image/*.jpeg,image/*"
                    onChange={handleFileSelect}
                    name="displayPicture"
                  />
                  Change Profile Photo
                </label>
              </div>
              <ul>
                <li>
                  <span>{studentProfile?.posts || 0}</span>Posts
                </li>
                <li
                  onClick={() => {
                    onFollowClick(studentProfile?._id, 'Followers');
                  }}
                >
                  <span>{followers?.totalItems || 0}</span>
                  Followers
                </li>
                <li
                  onClick={() =>
                    onFollowClick(studentProfile?._id, 'Following')
                  }
                >
                  <span>{followings?.totalItems || 0}</span>
                  Following
                </li>
              </ul>
            </div>
            <Form
              initialValues={studentProfile}
              validationSchema={ProfileSchema}
              onSubmit={handleSubmit}
              render={({ values }: any) => (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <Input name="name" label="Name" />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          name="email"
                          className="m-0"
                          label="Email ID"
                          type="email"
                          disabled
                          // required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          name="mobile"
                          className="m-0"
                          label="Mobile no."
                          type="num"
                          disabled
                          // required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Select
                        name={'gender'}
                        id={'gender'}
                        label={'Gender'}
                        options={genderOptions}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input name={'eduId'} label={'Enrollment Id'} disabled />
                    </div>
                    <div className="col-md-6" style={{ marginTop: '30px' }}>
                      <CheckBox
                        id="isPersonWithDisability"
                        name="isPersonWithDisability"
                        label="Are you physically challenged person?"
                      />
                    </div>
                  </div>
                  {/*
                   // @ts-ignore */}
                  <FieldArray name="kycFile">
                    {() =>
                      values.kycFile.map((kyc: any, index: number) => (
                        <>
                          <Input
                            name={`kycFile.${index}.value`}
                            label={
                              kyc.documentType === 'AADHAR'
                                ? 'Aadhar No'
                                : 'Pan No'
                            }
                            type="num"
                            // required
                          />

                          <div className="form-group">
                            <Label
                              label={
                                kyc.documentType === 'AADHAR'
                                  ? 'Aadhar Image'
                                  : 'Pan Image'
                              }
                            />
                            <Dropzone
                              accept="image/jpeg, image/png"
                              name={`kycFile.${index}.asset`}
                              multiple={false}
                              getAssetUrl={getPrivateAsset}
                              upload={upload}
                            />
                          </div>
                        </>
                      ))
                    }
                  </FieldArray>
                  <div className="button-group m-t-30">
                    <Button
                      shape={'popup'}
                      className={isLoading ? 'ml-0 loading-state ' : 'ml-0 '}
                      type="submit"
                    >
                      {isLoading ? <span className="loader"></span> : 'Update'}
                    </Button>
                    <Button shape="secondary" className="d-none">
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
