import { PageHeader, Tab, Tabs } from '@lipihipi/ec-ui';
import React, { useEffect, useState } from 'react';
import { EnrolledBatches } from './components/enrolledBatches';
import { EnrolledTests } from './components/enrolledTests';
import { EnrolledInterviews } from './components/enrolledInterviews';
import { IProgressProps } from './progress.types';

const MyProgress = ({
  purchaseBatch,
  testBundle,
  allInterview,
  onDetailClick,
  onTestBundleClick,
  onInterviewClick,
  getBatch,
  getTestDetails,
}: IProgressProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [purchasedBatch, setPurchasedBatch] = useState<any>({});
  const [interview, setDataInter] = useState<any>({});
  const [testList, setTestBundleEnroll] = useState<any>({});
  const [state, setState] = useState<any>('batches');

  useEffect(() => {
    setState('batches');
  }, []);


  useEffect(() => {
    getBatches();
    userInter();
    userTest();
  }, []);
  console.log(purchasedBatch)

  const getBatches = async () => {
    await purchaseBatch({ all: true, populate: true })
      .then((res: any) => {
        setIsLoading(false);
        setPurchasedBatch(res?.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const userInter = async () => {
    await allInterview({ populate: true, all: true })
      .then((res: any) => {
        setDataInter(res?.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const userTest = async () => {
    await testBundle({ populate: true, all: true })
      .then((res: any) => {
        setIsLoading(false);
        setTestBundleEnroll(res?.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <div className="d-flex flex-wrap">
      <div className="main-mid">
        <PageHeader
          title={'Track  and analyse your progress '}
          description={
            'Please find below the list of items you are involved , they could be Batched you have enrolled or Test series you might have purchased'
          }
          breadCrumbs={[{ title: 'My Progress' }]}
        />
        {
          (purchasedBatch?.totalItems > 0 ||
            testList?.totalItems > 0 ||
            interview?.totalItems > 0) ?
            (
              <>
                <Tabs active={state} onClick={setState}>
                  <Tab id={'batches'} title={'Purchased Batches'}>
                    <EnrolledBatches
                      purchaseBatch={purchasedBatch}
                      onDetailClick={onDetailClick}
                      getBatch={getBatch}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </Tab>
                  <Tab id={'tests'} title={'Purchased Tests'}>
                    <EnrolledTests
                      testBundle={testList}
                      onTestBundleClick={onTestBundleClick}
                      getTestDetails={getTestDetails}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </Tab>
                  <Tab id={'interviews'} title={'Purchased Interview'}>
                    <EnrolledInterviews
                      allInterview={interview}
                      onInterviewClick={onInterviewClick}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </Tab>
                </Tabs>
              </>
            ) : (
              <p>Not enrolled any item yet</p>
            )
        }

      </div>
    </div>
  );
};

export default MyProgress;
