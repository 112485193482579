import React from 'react';
import { Form, Select } from '@lipihipi/form';
import { Button } from '@lipihipi/ec-ui';
import { BiCalendarAlt } from 'react-icons/bi';
import DatePicker from 'react-datepicker';

const DoubtFilters = ({
  initialValues,
  user,
  params,
  setParams,
  courseOptions,
  educatorOptions,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: any) => {
  return (
    <div className="filter">
      <h3>Fliter and Sorting</h3>
      <Form
        initialValues={{ ...initialValues }}
        render={({
          values,
          errors,
          setFieldValue,
          resetForm,
          //@ts-ignore
          ...rest
        }: any) => {
          return (
            <div className="row">
              <div className="col-md-12">
                <Select
                  id={'course'}
                  name={'course'}
                  placeholder={'Course'}
                  options={
                    courseOptions
                      ? [{ label: 'ALL', value: '' }, ...courseOptions]
                      : []
                  }
                  onChange={(e: any) => {
                    setParams({ ...params, course: e.value, page: 1 });
                  }}
                />
              </div>

              {user?.role === 'admin' || user?.role === 'superadmin' ? (
                <div className="col-md-12">
                  <Select
                    id={'educator'}
                    name={'educator'}
                    placeholder={'Educators'}
                    options={[{ label: 'ALL', value: '' }, ...educatorOptions]}
                    onChange={(e: any) => {
                      setParams({ ...params, educator: e.value, page: 1 });
                    }}
                  />
                </div>
              ) : null}

              <div className="col-md-12">
                <Select
                  id={'filter_answered'}
                  name={'filter_answered'}
                  placeholder={'Filter Resolved Doubts'}
                  options={[
                    { label: 'ALL', value: '' },
                    { label: 'Answered', value: 'answer' },
                    { label: 'Unanswered', value: 'not_answer' },
                  ]}
                  onChange={(e: any) => {
                    setParams({ ...params, filter_answered: e.value });
                  }}
                />
              </div>

              <div className="col-md-12">
                <div className="datepicker-wrap form-group">
                  <DatePicker
                    id={'startDate'}
                    name={'startDate'}
                    className="primary-form-control"
                    selected={startDate}
                    selectsStart
                    placeholderText="Start Date"
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat={'MMMM-dd-yyyy'}
                    onChange={(date: any) => {
                      setParams({ ...params, startDate: date });
                      setStartDate(date);
                    }}
                  />
                  <BiCalendarAlt />
                </div>
              </div>
              <div className="col-md-12">
                <div className="datepicker-wrap form-group">
                  <DatePicker
                    id={'endDate'}
                    name={'endDate'}
                    className="primary-form-control"
                    selected={endDate}
                    selectsStart
                    dateFormat={'MMMM-dd-yyyy'}
                    placeholderText="End Date"
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(date: any) => {
                      setParams({ ...params, endDate: date });
                      setEndDate(date);
                    }}
                    minDate={startDate}
                    maxDate={new Date()}
                  />
                  <BiCalendarAlt />
                </div>
              </div>
              <div className="col-md-6">
                <Button
                  type={'button'}
                  shape={'secondary'}
                  onClick={() => {
                    resetForm();
                    setParams(initialValues);
                    setStartDate(null);
                    setEndDate(null);
                  }}
                >
                  All Clear
                </Button>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default DoubtFilters;
