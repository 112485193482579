import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PDF = ({ getTestAttempt, attemptId }: any) => {
  const [reportData, setReportData] = useState<any>();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data } = await getTestAttempt(attemptId, { populate: true });

    if (data?.achievedScore) {
      const reportPercentage = getPercentage(data?.achievedScore);
      const chartData = buildChartData(reportPercentage);
      console.log(chartData);
      setReportData({ reportPercentage, chartData, user: data?.user });
    }
  };

  const getPercentage = (data: any) => {
    const total: any = Object.values(data).reduce(
      (accumulator: any, value: any) => {
        return accumulator + value;
      },
      0
    );
    const d: string = ((data?.d / total) * 100).toFixed(2);
    const i: string = ((data?.i / total) * 100).toFixed(2);
    const s: string = ((data?.s / total) * 100).toFixed(2);
    const c: string = ((data?.c / total) * 100).toFixed(2);
    return { d, i, s, c };
  };

  const buildChartData = ({ d, i, s, c }: any) => {
    const chartData = {
      labels: ['Eagle', 'Peacock', 'Dove', 'Owl'],
      // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
      datasets: [
        {
          data: [d, i, s, c],
          // you can set indiviual colors for each bar
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 159, 64, 0.3)',
            'rgba(255, 206, 86, 0.3)',
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(255, 99, 132, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    return chartData;
  };

  console.log(`${(parseFloat(reportData?.reportPercentage?.s) + parseFloat(reportData?.reportPercentage?.i)).toFixed(2)}%`);


  return (
    <div className="p-5">
      <table
        cellPadding={0}
        cellSpacing={0}
        style={{
          border: '1px solid #eee',
          maxWidth: 750,
          margin: 'auto',
          width: '100%',
          background: '#fff',
        }}
      >
        <tr>
          <td
            style={{
              padding: 20,
            }}
          >
            {/* Top Logo Section */}
            {/* <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                marginBottom: 20,
                width: '100%',
              }}
            >
              <tr>
                <td>
                  <img
                    src="https://dummyimage.com/100x100/000/fff.png"
                    alt=""
                  />
                </td>
                <td align="right">
                  <img
                    src="https://dummyimage.com/100x100/000/fff.png"
                    alt=""
                  />
                </td>
              </tr>
            </table> */}
            {/* Top Logo Section Ends */}

            {/* Headign Section */}
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                marginBottom: 20,
                width: '100%',
              }}
            >
              <tr>
                <td
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <h3 style={{ marginBottom: 5 }}>
                    Personality Behavioural Psychometric Analysis
                  </h3>

                  <p style={{ marginBottom: 0 }}>
                    {reportData?.user?.name}
                    {reportData?.user?.email && (
                      <>({reportData?.user?.email})</>
                    )}
                  </p>
                </td>
              </tr>
            </table>
            {/* Headign Section Ends */}

            {/* Chart Section */}
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                border: '1px solid #eee',
                marginBottom: 20,
                width: '100%',
              }}
            >
              <tr>
                <td
                  style={{
                    padding: 20,
                    textAlign: 'center',
                  }}
                >
                  <h3 style={{ marginBottom: 10 }}>Psychometric Analysis</h3>

                  {reportData?.chartData && (
                    <div style={{ width: 200, margin: '0 auto' }}>
                      <Pie data={reportData?.chartData} options={{}} />
                    </div>
                  )}
                </td>
              </tr>
            </table>
            {/* Chart Section Ends */}

            {/* Info Section */}
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                tableLayout: 'fixed',
                marginBottom: 20,
                border: '1px solid #eee',
                width: '100%',
              }}
            >
              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                    borderRight: '1px solid #eee',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://fl.audubon.org/sites/default/files/styles/article_hero_inline/public/apa-2017_bald_eagle_a1_4853_1_kimberly-cerimele_kk.jpg?itok=r85jlofy"
                    alt=""
                  />
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                    borderRight: '1px solid #eee',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://media.istockphoto.com/id/180699358/photo/peacock.jpg?s=612x612&w=0&k=20&c=10hdZK3_qCEWp4-cPm4cXFBVXm2GxOS1nXYZB8kmYBQ="
                    alt=""
                  />
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                    borderRight: '1px solid #eee',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://optimise2.assets-servd.host/steadfast-tern/production/collared-dove-portrait.jpg?w=1200&h=900&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1623144351&s=33c3c7d735543073800be721c0ad7592"
                    alt=""
                  />
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://images.pexels.com/photos/86596/owl-bird-eyes-eagle-owl-86596.jpeg?cs=srgb&dl=pexels-pixabay-86596.jpg&fm=jpg"
                    alt=""
                  />
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Eagle
                    <br />
                    (Achiever)
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Peacock
                    <br />
                    (Creative)
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Dove
                    <br />
                    (People Person)
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Owl
                    <br />
                    (Detailer)
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      color: '#00A689',
                      fontSize: 26,
                      marginBottom: 0,
                      fontWeight: '600',
                    }}
                  >
                    {`${reportData?.reportPercentage?.d}%`}
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      color: '#00A689',
                      fontSize: 26,
                      marginBottom: 0,
                      fontWeight: '600',
                    }}
                  >
                    {`${reportData?.reportPercentage?.i}%`}
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      color: '#00A689',
                      fontSize: 26,
                      marginBottom: 0,
                      fontWeight: '600',
                    }}
                  >
                    {`${reportData?.reportPercentage?.s}%`}
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      color: '#00A689',
                      fontSize: 26,
                      marginBottom: 0,
                      fontWeight: '600',
                    }}
                  >
                    {`${reportData?.reportPercentage?.c}%`}
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      color: '#00A689',
                      fontSize: 26,
                      marginBottom: 0,
                      fontWeight: '600',
                    }}
                  >
                    Personality Traits
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Dominant
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Influencer
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Steady
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Complaint
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Goal Oriented
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Inducer
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Submissive
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Conscientious
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Big Picture Person
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Talkative
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Warm
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Analytical
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Low Advice Tolerance
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Gregarious
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Friendly
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Accurate
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Independent
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Enthusiastic
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Patient
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Perfectionists
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Competitive
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Impulsive
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Supportive
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Structured
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Challenging
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Spontaneous
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Calm
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Organized
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Determined
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Excited at start
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Empathetic
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Deep Thinkers
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Multi-Tasker
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Exaggerators
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Non-judgemental
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Detailers
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Assertive
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Generalists
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Reliable
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Critical
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Change Oriented
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Creative
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Dependable
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Cautious
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Argumentative
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Problem solvers
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Harmonious
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Process Oriented
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Control Oriented
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Flamboyant
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Followers
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Risk Averse
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    High Expectation
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Colourful
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Good Listeners
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Logic Drivers
                  </p>
                </td>
              </tr>
            </table>
            {/* Info Section Ends */}

            {/* Info Section */}
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                tableLayout: 'fixed',
                marginBottom: 20,
                border: '1px solid #eee',
                width: '100%',
              }}
            >
              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                    borderRight: '1px solid #eee',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://fl.audubon.org/sites/default/files/styles/article_hero_inline/public/apa-2017_bald_eagle_a1_4853_1_kimberly-cerimele_kk.jpg?itok=r85jlofy"
                    alt=""
                  />
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                    borderRight: '1px solid #eee',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://media.istockphoto.com/id/180699358/photo/peacock.jpg?s=612x612&w=0&k=20&c=10hdZK3_qCEWp4-cPm4cXFBVXm2GxOS1nXYZB8kmYBQ="
                    alt=""
                  />
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                    borderRight: '1px solid #eee',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://optimise2.assets-servd.host/steadfast-tern/production/collared-dove-portrait.jpg?w=1200&h=900&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1623144351&s=33c3c7d735543073800be721c0ad7592"
                    alt=""
                  />
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    background: '#000',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block',
                    }}
                    src="https://images.pexels.com/photos/86596/owl-bird-eyes-eagle-owl-86596.jpeg?cs=srgb&dl=pexels-pixabay-86596.jpg&fm=jpg"
                    alt=""
                  />
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Eagle
                    <br />
                    (Achiever)
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Peacock
                    <br />
                    (Creative)
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Dove
                    <br />
                    (People Person)
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                    }}
                  >
                    Owl
                    <br />
                    (Detailer)
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      color: '#00A689',
                      fontSize: 26,
                      marginBottom: 0,
                      fontWeight: '600',
                    }}
                  >
                    Work/Career Orientation
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Entrepreneurs
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Creative thinking
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    HR (People Facing)
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Finance
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Business Heads
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Show Business
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Therapists
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Legal
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Negotiators
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Advertising
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Mentoring
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    HR (Operations)
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Target chasers
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Fashion
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Life-Coaching
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Quality Analysts
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Surgeons
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Story-telling
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Anthropologists
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Research
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Role assigners
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Innovation
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Team Builders
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Auditors
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Strategy Makers
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Sales/BD
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Psychologists
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Photography
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Consultants
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Art
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderRight: '1px solid #eee',
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Social well-wishers
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    borderTop: '1px solid #eee',
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Classical Arts
                  </p>
                </td>
              </tr>
            </table>
            {/* Info Section Ends */}

            {/* Motivation Section */}
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                marginBottom: 20,
                width: '100%',
              }}
            >
              <tr>
                <td
                  colSpan={2}
                  style={{
                    padding: 20,
                    textAlign: 'center',
                  }}
                >
                  <h3 style={{ marginBottom: 0 }}>Motivation Orientation</h3>
                </td>
              </tr>

              <tr>
                <td
                  align="right"
                  style={{
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      width: 180,
                      height: 180,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      border: '5px solid #1ea087',
                      background: '#f5f7fa',
                      color: '#1ea087',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        marginBottom: 0,
                        fontWeight: '600',
                      }}
                    >
                      Towards
                    </p>
                    <span>(Go-for-it)</span>
                    <strong style={{ fontSize: 26, fontWeight: '800' }}>
                    {`${(parseFloat(reportData?.reportPercentage?.d) + parseFloat(reportData?.reportPercentage?.i)).toFixed(2)}%`}

                    </strong>
                  </div>
                </td>
                <td
                  align="left"
                  style={{
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      width: 180,
                      height: 180,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      border: '5px solid #1ea087',
                      background: '#f5f7fa',
                      color: '#1ea087',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        marginBottom: 0,
                        fontWeight: '600',
                      }}
                    >
                      Away-from
                    </p>
                    <span>(Cautious)</span>
                    <strong style={{ fontSize: 26, fontWeight: '800' }}>
                      {`${(parseFloat(reportData?.reportPercentage?.s) +
                        parseFloat(reportData?.reportPercentage?.c)).toFixed(2)}%`}
                    </strong>
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  align="right"
                  style={{
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      width: 180,
                      height: 180,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      border: '5px solid #1ea087',
                      background: '#f5f7fa',
                      color: '#1ea087',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        marginBottom: 0,
                        fontWeight: '600',
                      }}
                    >
                      Extrinsic
                    </p>
                    <span>(External push required)</span>
                    <strong style={{ fontSize: 26, fontWeight: '800' }}>
                      {`${(parseFloat(reportData?.reportPercentage?.i) +
                        parseFloat(reportData?.reportPercentage?.s)).toFixed(2)}%`}
                    </strong>
                  </div>
                </td>
                <td
                  align="left"
                  style={{
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      width: 180,
                      height: 180,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      border: '5px solid #1ea087',
                      background: '#f5f7fa',
                      color: '#1ea087',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        marginBottom: 0,
                        fontWeight: '600',
                      }}
                    >
                      Intrinsic
                    </p>
                    <span>(Internally motivated)</span>
                    <strong style={{ fontSize: 26, fontWeight: '800' }}>
                      {`${(parseFloat(reportData?.reportPercentage?.d) +
                        parseFloat(reportData?.reportPercentage?.c)).toFixed(2)}%`}
                    </strong>
                  </div>
                </td>
              </tr>
            </table>

            <p>
              Human Personality is a mix  of  the  4  personality types , viz Eagle, Peacock, Dove  and  Owl.
              All of these Personality  types  have  their  own  distinctive  features, however none  of  the
              style  can  be  said  to be a superior  style compared  to  others. All human  beings will have all
              these  styles  present  in them , albeit  the Dominant  style may  be  one  or  two  out of  the
              four.
            </p>

            <p>
              Based  on  which  is  the  dominant style of personality basis the assessment, a description is
              given here for the  personality traits, motivations, fears, their response to different stimuli, their
              interaction etc.
            </p>

            <h5 style={{ marginBottom: 5, fontWeight: 'bold' }}>
              Eagle style Personality
            </h5>
            <p>
              Candidates exhibiting the dominant Eagle-style personality generally prioritize exerting a
              significant influence on their surroundings and overcoming obstacles in order to achieve desired
              outcomes.
            </p>

            <p>
              Eagle-style people are motivated by the desire to win, engage in competition, and achieve
              success. They prioritize taking action, embracing challenges, and attaining tangible outcomes.
              They are often characterized as straightforward and demanding, possessing strong
              determination and tenacious nature. Candidates with dominant Eagle styles tend to express
              their opinions openly but may exhibit skepticism and a tendency to question others.
            </p>

            <p>
              The Eagles’ fear revolves around being perceived as vulnerable or taken advantage of by
              others. They hold values such as competence, action, tangible results, personal freedom, and
              embracing challenges.
            </p>

            <p>
              One potential pitfall for the Eagles is their excessive focus on winning, which can lead to a
              win/lose mentality. They exert influence on others through assertiveness, persistence, and a
              competitive mindset. During conflicts, Eagles actively voice their concerns and seek to rectify
              any imbalances. To enhance their effectiveness, Eagles could benefit from cultivating patience
              and empathy.
            </p>

            <p>
              <strong style={{fontWeight: 'bold'}}>Goals in the style of an eagle typically include:</strong>
            </p>

            <ul style={{marginTop: 0}}>
              <li>Aspire to achieve distinctive accomplishments.</li>
              <li>Venture into unexplored opportunities.</li>
              <li>Retain authority over the audience.</li>
              <li>Attain self-reliance.</li>
              <li>Deliver tangible and impactful outcomes.</li>
            </ul>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                The Eagle style challenge may require additional energy expenditure to:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Demonstrate patience</li>
              <li>Exhibit sensitivity</li>
              <li>Elaborate on the specifics</li>
              <li>Facilitate thoughtful deliberation</li>
            </ul>

            <h5 style={{ marginBottom: 5, fontWeight: 'bold' }}>
              Peacock style Personality
            </h5>

            <p>
              Candidates exhibiting the dominant Peacock style personality often prioritize molding their
              surroundings through the act of influencing or persuading others.
            </p>

            <p>
              Peacock styles draw inspiration from creativity, social recognition, group activities, and
              relationships. They prioritize proactive behavior, collaboration, and the expression of
              enthusiasm. People with this style are often described as warm, trusting, optimistic, magnetic,
              enthusiastic, and persuasive.
            </p>

            <p>
              Their fears include the loss of influence, disapproval, being ignored, and rejection. They highly
              value coaching and counseling, freedom of expression, and democratic relationships.
            </p>

            <p>
              Peacock styles tend to overuse creativity, optimism and praise. They influence others through
              their charm, optimism, and energy. In times of conflict, they express their feelings and engage in
              gossip.
            </p>

            <p>
              To improve effectiveness, individuals with this style could benefit from being more objective and
              following through on tasks. Their patterns typically include the Creator, Promoter, Persuader,
              Counselor, and Appraiser.
            </p>

            <p>
              <strong
                style={{ fontWeight: 'bold' }}
              >
                The typical aspirations of peacock styles encompass:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Achieving triumph with a touch of elegance</li>
              <li>Cultivating friendships and pursuing happiness</li>
              <li>Gaining status through authority and prestige</li>
              <li>Seeking approval and striving for popularity</li>
              <li>Creating a sense of enthusiasm</li>
            </ul>

            <p>
              <strong
                style={{ fontWeight: 'bold' }}
              >
                Peacock-style individuals may find it necessary to exert additional effort in the following areas:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Establishing order and structure through creativity</li>
              <li>Conducting thorough research</li>
              <li>Resisting impulsive behaviors</li>
              <li>Communicating honestly and straightforwardly</li>
              <li>Maintaining sustained focus over extended periods of time</li>
              <li>Seeing tasks and commitments through to their conclusion</li>
            </ul>

            <p>
              Leaders with a Peacock style are enthusiastic about embracing novel ideas and actively pursue
              them. They have the ability to invigorate teams and generate excitement for projects. However,
              they may also exhibit unpredictable leadership behaviors, disregarding the importance of
              maintaining consistency and exercising caution.
            </p>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                The Peacock leadership style frequently demonstrates the following characteristics:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Inspiring energy</li>
              <li>Venturing into uncharted territories</li>
              <li>Providing affirmation and support</li>
            </ul>

            <h5 style={{ marginBottom: 5, fontWeight: 'bold' }}>
              Dove style Personality
            </h5>

            <p>
              Candidates who possess dominant Dove style personality often prioritize collaboration with
              others under the given circumstances to accomplish a task.
            </p>

            <p>
              The motivations behind Dove styles include cooperation, the desire to assist others, and
              genuine appreciation. They place a high priority on offering support, working together, and
              maintaining a sense of stability. Typically, they are characterized as calm, patient, predictable,
              deliberate, stable, and consistent.
            </p>

            <p>
              Dove styles have certain fears, such as change, the loss of stability, offending others, and
              letting people down. They value loyalty, helping others, and security. However, they may
              sometimes overuse traits like modesty, passive resistance, and compromise.
            </p>

            <p>
              Dove styles influence others through accommodation and by consistently performing well.
              During conflicts, they tend to listen to others' perspectives while keeping their own needs to
              themselves. To enhance their effectiveness, Doves could benefit from displaying more self-
              confidence and expressing their true feelings.
            </p>

            <p>
              Some common Dove patterns include the Specialist, Achiever, Agent, and Investigator.
            </p>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                The objectives typically associated with Dove styles include:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Nurturing balance and stability</li>
              <li>Pursuing collective achievements</li>
              <li>Seeking validation within the group</li>
              <li>Attaining influence through official roles and positions of power</li>
              <li>Upholding the existing order and maintaining control over the surroundings</li>
            </ul>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                Dove individuals might find it necessary to invest additional effort in the following areas:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Swiftly adjusting to uncertain circumstances or vague requirements.</li>
              <li>Balancing multiple tasks or responsibilities simultaneously.</li>
              <li>Advocating for their own achievements or merits.</li>
              <li>Addressing conflicts or difficult conversations directly.</li>
              <li>Overcoming hesitation or uncertainty when making decisions.</li>
              <li>Avoiding excessive accommodation or overextending themselves.</li>
            </ul>

            <p>
              Leaders with a Dove-style approach exhibit a preference for collaboration, seeking consensus
              and fostering mutually beneficial outcomes. Nonetheless, they may occasionally demonstrate
              passivity and excessive trust, which can lead to exploitation by others who take advantage of
              their supportive and patient demeanor.
            </p>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                The leadership traits commonly associated with the Dove style include:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>inclusiveness</li>
              <li>humility</li>
              <li>affirmation</li>
            </ul>

            <h5 style={{ marginBottom: 5, fontWeight: 'bold' }}>
              Owl-style Personality
            </h5>

            <p>
              Candidates who possess  Owl style personality as dominant trait ,prioritize the conscientious
              execution of tasks within the given circumstances to guarantee high standards and precision.
            </p>

            <p>
              Owl individuals are driven by the desire to acquire knowledge, demonstrate their expertise, and
              produce high-quality work. They prioritize the importance of accuracy, stability, and challenging
              assumptions. They are often characterized as meticulous, analytical, methodical, diplomatic,
              precise, and considerate.
            </p>

            <p>
              They have concerns about facing criticism and making mistakes, and they may feel
              uncomfortable with strong emotional displays. Their core values revolve around delivering
              quality outcomes and ensuring accuracy.
            </p>

            <p>
              However, there are certain tendencies that owls may exhibit excessively, such as excessive
              analysis and restraint. When influencing others, they rely on logical reasoning and uphold strict
              standards. During conflicts, they tend to emphasize logic and objectivity, overpowering others
              with facts.
            </p>

            <p>
              To enhance their effectiveness, owls could benefit from acknowledging the emotions of others
              and looking beyond mere data. By considering the feelings of others and adopting a broader
              perspective, they can improve their ability to influence and engage with others.
              The three main patterns of owls are the Objective Thinker, the Perfectionist, and the
              Practitioner.
            </p>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                The goals typically associated with Owl styles include:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Adhering to objective processes</li>
              <li>Ensuring accuracy and correctness</li>
              <li>Exhibiting stability and reliability</li>
              <li>Acquiring knowledge and expertise</li>
              <li>Sustaining personal growth</li>
            </ul>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                Owls may find it challenging to:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>Resist the tendency to be excessively critical, overanalyze situations, and withdraw from others.</li>
              <li>Release control and assign tasks to others.</li>
              <li>Find a middle ground and make compromises that benefit the entire team.</li>
              <li>Engage in social gatherings and participate in celebrations.</li>
              <li>Make swift decisions when needed.</li>
            </ul>

            <p>
              Leaders who embody the Owl style tend to exhibit qualities of conscientiousness, discipline, and
              diligence, enabling them to achieve excellent results by meticulously analyzing and strategizing.
              However, their risk-averse nature and perfectionistic tendencies may cause them to overlook
              the importance of the human factor.
            </p>

            <p>
              <strong style={{ fontWeight: 'bold' }}>
                The following leadership traits are commonly associated with the Owl style:
              </strong>
            </p>

            <ul style={{ marginTop: 0 }}>
              <li>thoughtful</li>
              <li>modest</li>
              <li>determined</li>
            </ul>

            <p>
              As mentioned  at the beginning, these  are  the  dominant  traits of different  personality  types.
              A human being  however behaves  and  responds to  different  situations  as  per  which  style
              is  dominant  in  him, and  also  because of  circumstances  with the acquired styles.
            </p>
            {/* Motivation Section Ends */}

            {/* <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                tableLayout: 'fixed',
                marginBottom: 20,
                width: '100%',
              }}
            >
              <tr>
                <td>
                  <p style={{ marginBottom: 0 }}>
                    Contact us in case of any queries or discussions on the
                    report. Our experts will help you help you further on the
                    assessment. Call Amarendra on{' '}
                    <a
                      style={{
                        color: 'rgb(0, 166, 137)',
                        textDecoration: 'underline',
                      }}
                      href="tel:+919890976767"
                    >
                      +919890976767
                    </a>{' '}
                    or email{' '}
                    <a
                      style={{
                        color: 'rgb(0, 166, 137)',
                        textDecoration: 'underline',
                      }}
                      href="mailto:Amarendra@agileneuro.com"
                    >
                      Amarendra@agileneuro.com
                    </a>
                    . Visit{' '}
                    <a
                      style={{
                        color: 'rgb(0, 166, 137)',
                        textDecoration: 'underline',
                      }}
                      target="_blank"
                      href="www.agileneuro.com"
                    >
                      www.agileneuro.com
                    </a>{' '}
                    to know about our upcoming Certification courses on various
                    topics related to Psychology.
                  </p>
                </td>
              </tr>
            </table>

            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                tableLayout: 'fixed',
                marginTop: 50,
                width: '100%',
              }}
            >
              <tr>
                <td>
                  <p style={{ marginBottom: 0 }}>
                    <a
                      style={{
                        color: 'rgb(0, 166, 137)',
                        textDecoration: 'underline',
                      }}
                      target="_blank"
                      href="www.agileneuro.com"
                    >
                      www.agileneuro.com
                    </a>
                  </p>
                </td>

                <td align="center">
                  <p style={{ marginBottom: 0 }}>
                    &copy; Copyright of Agile Neuro
                  </p>
                </td>

                <td align="right">
                  <p style={{ marginBottom: 0 }}>
                    <a
                      style={{
                        color: 'rgb(0, 166, 137)',
                        textDecoration: 'underline',
                      }}
                      target="_blank"
                      href="info@agileneuro.com"
                    >
                      info@agileneuro.com
                    </a>
                  </p>
                </td>
              </tr>
            </table> */}
          </td>
        </tr>
      </table>
    </div>
  );
};

// export default PDF;
