import { client } from './client';
import { IDocument } from './types';

export interface ILoginParams {
  email?: string;
  mobile?: string;
  password?: string;
}
// export interface ILoginParamsNew {
//   mobile?: string;
//   email?: string;
//   password?: string;
// }

export interface IGoogleLogin {
  token: string;
  googleId: string;
}

export interface IPermission extends IDocument {
  label: string;
  value: string;
}

export enum GrantResources {
  WALL = 'WALL',
  DOUBTS = 'DOUBTS',
  CMS = 'CMS',
  COURSE = 'COURSE',
  MANAGE_BATCH = 'MANAGE_BATCH',
  MANAGE_EDUCATORS = 'MANAGE_EDUCATORS',
  USER_INTERVIEW = 'USER_INTERVIEW',
  QUESTIONS = 'QUESTIONS',
  TESTS = 'TESTS',
  TEST_BUNDLES = 'TEST_BUNDLES',
  LIBRARY = 'LIBRARY',
  SUPPORT = 'SUPPORT',
  USERS = 'USERS',
  MANAGE_SUBSCRIBERS = 'MANAGE_SUBSCRIBERS',
  STUDENT_PROFILE = 'STUDENT_PROFILE',
  INTERVIEW = 'INTERVIEW',
  REFERRAL = 'REFERRAL',
  REPORT = 'REPORT',
  PAYMENT = 'PAYMENT',
  LIVE_SESSIONS = 'LIVE_SESSIONS',
  MANAGE_ASSIGNMENTS = 'MANAGE_ASSIGNMENTS',
  COUPON = 'COUPON',
  EXAM = 'EXAM',
  USER_TEST_ATTEMPTS = 'USER_TEST_ATTEMPTS',
  USER_TEST_ENROLLMENT = 'USER_TEST_ENROLLMENT',
  USER_TEST_BUNDLE_ENROLLMENT = 'USER_TEST_BUNDLE_ENROLLMENT',
  ASSETS = 'ASSETS',
  EDU_MEET_FEED = 'EDU_MEET_FEED',
  RESUME = 'RESUME',
  MANAGE_MARKETING = 'MANAGE_MARKETING',
  PSYCHOMETRIC_TEST = 'PSYCHOMETRIC_TEST',
}

export interface IGrantPermissions extends Array<IPermission> {}

const login = (params: ILoginParams) => {
  return client.post('/auth/local', params).then(response => {
    client.defaults.headers.authorization = `Bearer ${response.data.token}`;

    return response;
  });
};

const loginOtp = (params: ILoginParams) => {
  return client.post('/auth/local/otp', params).then(response => {
    client.defaults.headers.authorization = `Bearer ${response.data.token}`;

    return response;
  });
};

// const loginNew = (params: ILoginParamsNew) => {
//   return client.post('/auth/local/new', params).then(response => {
//     if(response.data.token){
//       client.defaults.headers.authorization = `Bearer ${response.data.token}`;
//     }
//     return response;
//   });
// };

// const googleLogin = (params: IGoogleLogin) => {
//   return client.post('/auth/local/login/google', params).then(response => {
//     client.defaults.headers.authorization = `Bearer ${response.data.token}`;

//     return response;
//   });
// };

const grantPermissions = () => {
  return client.get<IGrantPermissions>('/auth/permissions');
};

export const auth = {
  login,
  loginOtp,
  // googleLogin,
  grantPermissions,
  GrantResources,
  // loginNew,
};
