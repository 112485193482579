import React from 'react';
import { FieldMetaProps, useFormikContext } from 'formik';

const ErrorMessage = ({ meta }: { meta: FieldMetaProps<any> }) => {
  const { submitCount } = useFormikContext();
  const showError = (submitCount > 0 || meta.touched) && meta.error;
  return showError ? (
    <div className="invalid-feedback" style={{ display: 'block' }}>
      {showError ? meta.error : null}
      &nbsp;
    </div>
  ) : null;
};

export default ErrorMessage;
