import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
} from '../types';
import { IQuestionSetDocument } from '../question-set';

export interface IUserTestAttemptDocument extends IUserTestAttempt, IDocument {
  user: ObjectId | PopulatedUserDocument;
}

export interface IUserTestAttemptListParams extends IListParams {
  test?: ObjectId;
  user?: ObjectId;
}

export interface IUserTestAttemptListResponse {
  testAttempts: IUserTestAttempt[];
  totalItems: number;
}

export interface IUserTestAttempt {
  test: string | IQuestionSetDocument;
  status: string;
  startedAt: string;
  finishedAt: string;
  achievedScore: number;
  answers: IUserAttemptAnswer[];
}

export enum IAnswer {
  YES = 'YES',
  NO = 'NO',
}
export interface IUserAttemptAnswer {
  questionId: ObjectId;
  selectedAnswer: IAnswer;
  isMarkForReview?: boolean;
  timeTaken?: number;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IUserTestAttemptDocument>(
    `/psychometric/test-attempt/${id}`,
    {
      params,
    }
  );
};

const create = (UserTestAttempt: IUserTestAttempt) => {
  return client.post<IUserTestAttemptDocument>(
    `/psychometric/test-attempt`,
    UserTestAttempt
  );
};

const update = (id: string, UserTestAttempt: IUserTestAttemptDocument) => {
  return client.put<IUserTestAttemptDocument>(
    `/psychometric/test-attempt/${id}`,
    UserTestAttempt
  );
};

const submitAnswer = (id: string, UserAnswer: IUserAttemptAnswer) => {
  return client.put<IUserTestAttemptDocument>(
    `/psychometric/test-attempt/${id}/submit-answer`,
    UserAnswer
  );
};

const submitAttempt = (id: string, UserTestAttempt?: Object) => {
  return client.put<IUserTestAttemptDocument>(
    `/psychometric/test-attempt/${id}/finish`,
    UserTestAttempt
  );
};

const list = (params: IUserTestAttemptListParams) => {
  return client.get<IUserTestAttemptListResponse>(
    `/psychometric/test-attempt/`,
    {
      params,
    }
  );
};

export const psychometricTestAttempt = {
  get,
  create,
  update,
  list,
  submitAnswer,
  submitAttempt,
};
