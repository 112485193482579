import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface ITestimonial {
  name: string;
  active?: boolean;
  displayPicture: string;
  shortDescription: string;
  description: string;
  displayOrder?: number;
}

export interface ITestimonialDocument extends ITestimonial, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface ITestimonialListResponse {
  totalItems: number;
  testimonials: ITestimonialDocument[];
}

const get: TGetResource<ITestimonialDocument> = (id, params) => {
  return client.get<ITestimonialDocument>(`/testimonials/${id}`, { params });
};

const create: TCreateResource<ITestimonial, ITestimonialDocument> = (
  testimonial: ITestimonial
) => {
  return client.post<ITestimonialDocument>(`/testimonials`, testimonial);
};

const update: TUpdateResource<ITestimonialDocument> = testimonial => {
  return client.put(`/testimonials/${testimonial._id}`, testimonial);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/testimonials/${id}`);
};

const list: TListResource<IListParams, ITestimonialListResponse> = params => {
  return client.get(`/testimonials/`, { params });
};

export const testimonial = { get, create, update, remove, list };
