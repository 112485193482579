import React, { FC, useState } from 'react';
import { ILoginParams } from '@lipihipi/client-sdk/dist/auth';
import { LeftPanel } from '../../common';
import LoginForm from './LoginForm';
import { useHistory, useLocation } from 'react-router-dom';
import EducrackAPI from '@lipihipi/client-sdk';

const Login: FC<any> = ({ login, setToken, imageBaseUrl }: any) => {
  const [error, setError] = useState();
  const [user, setUser] = useState();
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const encodedString = query.get('mobile');

  let mobile: any = null;
  if (encodedString) {
    mobile = atob(encodedString);
  }

  React.useEffect(() => {
    if (mobile) {
      handleSubmit({ mobile: mobile });
    }
  }, [mobile]);

  const handleSubmit = async (values: ILoginParams) => {
    try {
      let response;
      response = await login(values);

      if (!response.data.user.hasPassword) {
        const user = {
          id: response.data.user._id,
          mobile: response.data.user.mobile,
        };
        localStorage.setItem('user', JSON.stringify(user));
        history.push('/otp-verify');
      } else if (response.data.token) {
        setToken(response.data.token);
      } else {
        setUser(response.data.user);
      }
    } catch (error) {
      setError(error.data.message);
    }
  };

  const { styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};

  return (
    <main className="user-structure">
      <LeftPanel
        imageBaseUrl={imageBaseUrl}
        title={styles?.onboardingText || ''}
      />

      <LoginForm
        handleSubmit={handleSubmit}
        error={error}
        user={user}
        mobile={mobile || ''}
      />
    </main>
  );
};

export default Login;
