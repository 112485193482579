import React from 'react';
import ReactModal from 'react-modal';
import { ModalProps } from './modal.types';

// const customStyles = {
//   content: {
//     minWidth: 420,
//     top: '50%',
//     left: '50%',
//     maxHeight: '80%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     border: '0px',
//     boxShadow: '0px 0px 5px gray',
//   },
//   overlay: {
//     background: 'rgba(0,0,0,80%)',
//   },
// };

export const Modal = ({
  children,
  isOpen,
  heightDefault,
  onRequestClose,
}: ModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // style={customStyles}
      className={
        'primary-modal ' + (heightDefault === true ? 'height-default' : null)
      }
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};
