import React, { useEffect, useState } from 'react';
import { PageHeader, PaginatedTable, Tab, Tabs, Loader } from '@lipihipi/ec-ui';
import { ISupportListParams } from '@lipihipi/client-sdk/dist/support';
import { ISupportListProps } from './user-support-list.types';
import { BiChevronRight } from 'react-icons/bi';
import moment from 'moment';
import { Form, Input, Select } from '@lipihipi/form';

const TimeSpan = [
  { label: 'Today', value: 'TODAY' },
  { label: 'Yesterday', value: 'YESTERDAY' },
  { label: 'A week', value: 'WEEK' },
  { label: 'A month', value: 'MONTH' },
];

export const SupportTicketList = ({
  isAdmin,
  onGetChat,
  getSupportTickets,
  onAdd,
  breadCrumbs,
}: ISupportListProps) => {
  const [params, setParams] = React.useState<any>({
    page: 1,
    status: null,
  });
  const [activeTabs, setActiveTabs] = useState<string>('Open');
  const [openStatus, setOpenStatus] = useState<any>([]);
  const [closedStatus, setClosedStatus] = useState<any>([]);
  const [activeTickets, setActiveTickets] = useState<any>([]);
  const [studentData, setStudentData] = useState<any>({});
  const [supportData, setSupportData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (getSupportTickets) {
      getSupportTickets({
        populate: true,
        ...params,
        status: activeTabs.toUpperCase(),
      })
        .then(
          ({ data }: any) => {
            setIsLoading(false);
            const _openState: any = [];
            const _closedState: any = [];

            setStudentData(data);
            setOpenStatus(_openState);
            setClosedStatus(_closedState);
            setActiveTickets(data?.tickets);
          },
          () => {
            setIsLoading(false);
          }
        )
        .catch((err: any) => console.log(err));
    }
  }, [params, activeTabs]);

  useEffect(() => {
    if (getSupportTickets) {
      getSupportTickets({ populate: true, ...params }).then(
        ({ data }: any) => {
          setIsLoading(false);
          setSupportData(data);
        },
        () => {
          setIsLoading(false);
        }
      );
    }
  }, [params]);

  const handleSearch = (values: ISupportListParams) => {
    console.log(values);
    setParams({
      ...values,
      page: 1,
    });
  };

  const onClickTab = (elem: any, data: any) => {
    setParams({ ...params, page: 1 });
    setIsLoading(false);
    setActiveTickets(data);
    setActiveTabs(elem);
  };

  const renderTabs = () => {
    return (
      <>
        {isLoading && <Loader />}
        <div className="support-table">
          <PaginatedTable
            onPageChange={page => {
              setParams({ ...params, page });
            }}
            totalItems={studentData.totalItems}
            currentPage={params.page || 1}
            itemsPerPage={10}
            data={activeTickets}
            columns={[
              {
                dataRenderer: (data: any) => (
                  <div
                    className="subject"
                    onClick={() => {
                      onGetChat(data.ticketId);
                    }}
                  >
                    {data.ticketId ? data.ticketId : 'No Ticket Id'}
                  </div>
                ),
                width: 'calc(100% - (160px + 160px + 110px + 125px + 60px))',
              },
              {
                dataRenderer: (data: any) => (
                  <div
                    className="subject ellipses"
                    dangerouslySetInnerHTML={{
                      __html: data?.text,
                    }}
                  ></div>
                ),
                width: '200px',
              },
              {
                dataRenderer: (data: any) => (
                  <div
                    className="subject"
                    onClick={() => {
                      onGetChat(data.ticketId);
                    }}
                  >
                    {data.replies ? `${data.replies} Replies` : `No  Replies`}
                  </div>
                ),
                width: '180px',
              },
              {
                dataRenderer: (data: any) => (
                  <div
                    className="subject"
                    onClick={() => {
                      onGetChat(data.ticketId);
                    }}
                  >
                    {data.createdAt
                      ? moment(data.createdAt).fromNow()
                      : `No Time Elapsed`}
                  </div>
                ),
                width: '165px',
              },
              {
                dataRenderer: (data: any) => (
                  <div
                    className="status cursor-pointer "
                    onClick={() => {
                      onGetChat(data.ticketId);
                    }}
                  >
                    <BiChevronRight size={'20px'} color={'#239F87'} />
                  </div>
                ),
                width: '60px',
              },
            ]}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="main-mid">
        <PageHeader
          title="Support Tickets"
          breadCrumbs={
            breadCrumbs || [
              { title: 'Home', link: '/dashbpard' },
              { title: 'Support', link: '/support' },
            ]
          }
          component={
            !isAdmin ? (
              <button className="btn btn-primary" onClick={() => onAdd()}>
                Create New Ticket
              </button>
            ) : (
              ''
            )
          }
        />

        {isAdmin ? (
          <>
            <Form
              initialValues={params}
              onSubmit={handleSearch}
              render={({ submitForm }: any) => (
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <Input
                      label="Search"
                      id="searchSupport"
                      name="q"
                      placeholder="Search the content here"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <Select
                      label="Created within"
                      id="timeSpan"
                      name="timeSpan"
                      placeholder="Created within"
                      onChange={submitForm}
                      options={[{ label: 'All', value: '' }, ...TimeSpan]}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <Select
                      label="Status"
                      id="status"
                      name="status"
                      placeholder="Status"
                      onChange={submitForm}
                      options={[
                        { label: 'All', value: '' },
                        { label: 'Open', value: 'OPEN' },
                        { label: 'Closed', value: 'CLOSED' },
                      ]}
                    />
                  </div>
                  <button type="submit" className="d-none">
                    Search
                  </button>
                </div>
              )}
            />

            <PaginatedTable
              onPageChange={(page: number) => {
                setParams({ ...params, page });
              }}
              totalItems={supportData?.totalItems}
              currentPage={params.page || 1}
              itemsPerPage={10}
              data={supportData?.tickets}
              columns={[
                {
                  dataRenderer: (data: any) => (
                    <div
                      className="subject"
                      onClick={() => {
                        onGetChat(data.ticketId);
                      }}
                    >
                      {data.ticketId ? data.ticketId : 'No Ticket Id'}
                    </div>
                  ),
                  title: 'Ticket No.',
                  width:
                    'calc(100% - (200px + 200px + 150px + 100px + 100px + 60px))',
                },
                {
                  dataRenderer: (data: any) => (
                    <div
                      className="subject cursor-pointer"
                      onClick={() => {
                        onGetChat(data.ticketId);
                      }}
                    >
                      {data.addedBy ? data.addedBy.name : 'Not Available'}
                    </div>
                  ),
                  title: 'Created By',
                  width: '200px',
                },
                {
                  dataRenderer: (data: any) => (
                    <div
                      className="subject"
                      onClick={() => {
                        onGetChat(data.ticketId);
                      }}
                    >
                      {data.replies ? `${data.replies} Replies` : `No  Replies`}
                    </div>
                  ),
                  title: 'Replies',
                  width: '200px',
                },
                {
                  dataRenderer: (data: any) => (
                    <div
                      className="subject"
                      onClick={() => {
                        onGetChat(data.ticketId);
                      }}
                    >
                      {data.createdAt
                        ? moment(data.createdAt).fromNow()
                        : `No Time Elapsed`}
                    </div>
                  ),
                  title: 'Time Elapsed',
                  width: '150px',
                },
                {
                  dataRenderer: (data: any) => (
                    <div
                      className={
                        'status cursor-pointer ' +
                        (data.status === 'OPEN' ? 'open' : 'approved')
                      }
                      onClick={() => {
                        onGetChat(data.ticketId);
                      }}
                    >
                      {data.status ? data.status : `No Status`}
                    </div>
                  ),
                  title: 'Status',
                  width: '200px',
                },
                {
                  dataRenderer: (data: any) => (
                    <div
                      className="status cursor-pointer "
                      onClick={() => {
                        onGetChat(data.ticketId);
                      }}
                    >
                      <BiChevronRight size={'20px'} color={'#239F87'} />
                    </div>
                  ),
                  width: '60px',
                },
              ]}
            />
          </>
        ) : (
          <div className="student-tabs">
            <Tabs
              active={activeTabs}
              onClick={(e: any) => {
                const data = e === 'Open' ? openStatus : closedStatus;
                onClickTab(e, data);
              }}
            >
              <Tab id={'Open'} title={'Open'}>
                {renderTabs()}
              </Tab>
              <Tab id={'Closed'} title={'Closed'}>
                {renderTabs()}
              </Tab>
            </Tabs>
          </div>
        )}
      </section>
    </>
  );
};
