import EducrackAPI from '@lipihipi/client-sdk';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  InputHTMLAttributes,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { LeftPanel } from '../../common';

function usePrevious<T>(value?: T) {
  const ref = useRef<T>();
  useEffect(() => {
    //On load component sendotp and remove senOtp button.write another useeffctech()
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface OTPVerificationProps {
  imageBaseUrl: any;
  sendOtpParameter: (value: any) => void;
  verifyMobileParameter: (value: any) => void;
  setToken: (value: string) => void;
}

interface OTPInputProps extends InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
}

const SingleOTPInput = memo((props: OTPInputProps) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);
  useEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <li>
      <input
        type="number"
        className="form-control"
        ref={inputRef}
        maxLength={1}
        {...rest}
      />
    </li>
  );
});

const OTPVerification: FC<OTPVerificationProps> = ({
  imageBaseUrl,
  sendOtpParameter,
  verifyMobileParameter,
  setToken,
}: OTPVerificationProps) => {
  const length = 4;
  const seconds = 30;

  const [activeInput, setActiveInput] = useState(0);
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [error, setError] = useState(false);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));
  const [OTP, setOTP] = useState('');
  const [otpInput, setOtpInput] = useState(false);
  const history = useHistory();
  const [item, setItem] = useState<any>([]);

  useEffect(() => {
    const user: any = localStorage.getItem('user');
    if (user) {
      setItem(JSON.parse(user));
    }
  }, []);

  const intervalRef = useRef<any>();

  let values: any = item.id;
  useEffect(() => {
    if (item.mobile) {
      handleSubmit(values);
    }
    //New code:-//On load component sendotp and remove senOtp button.write another useeffctech()
  }, [item]);

  // handle countdown timer
  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    intervalRef.current = intervalId;
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // handle Resend OTP
  const onResendOTP = () => {
    setTimeLeft(seconds);
    //onResend();
    handleSubmit(values);
  };

  // Helper to return OTP from inputs
  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join('');
    setOTP(otpValue);
  };

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      const val = !value || /\d/.test(value) ? value : '';
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput]
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  /****************************************************************************************************** */

  const handleSubmit = async (values: any) => {
    const response: any = await sendOtpParameter(values);
    console.log('respo of OTP SEND=', response);
    if (response.status == 200) {
      setOtpInput(true);
      console.log('SMS SEND succesfully');
    } else {
      console.log('ERROR IN SMS SEND');
    }
  };

  const onSubmitOTP = useCallback(async () => {
    var values: any = {
      id: item.id,
      otp: OTP,
      getToken: true,
    };
    try {
      const response: any = await verifyMobileParameter(values);
      if (response.status) {
        setToken(response.data.token);
        history.push('/create-password');
      }
    } catch (err) {
      if (err.data && err.status === 422) {
        setError(true);
      }
    }
    clearInterval(intervalRef.current);
  }, [OTP]);

  const { name, styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};

  /****************************************************************************************************** */
  return (
    <main className="user-structure">
      <LeftPanel
        imageBaseUrl={imageBaseUrl}
        title={styles?.onboardingText || ''}
      />
      <div className="user-structure--form">
        <div className="wrap">
          <h2>{`Login to ${name}`}</h2>
          <h3>Enter the 4 digit OTP code</h3>
          {otpInput && (
            <>
              <div className="otp-box">
                <p>{`Send to ${item.mobile}`}</p>
                <ul>
                  {Array(4)
                    .fill('')
                    .map((_, index) => (
                      <SingleOTPInput
                        key={`SingleInput-${index}`}
                        focus={activeInput === index}
                        autoFocus={true}
                        value={otpValues && otpValues[index]}
                        onFocus={handleOnFocus(index)}
                        onChange={handleOnChange}
                        onKeyDown={handleOnKeyDown}
                        onBlur={onBlur}
                      />
                    ))}
                </ul>
                {error && <p>Invalid Otp</p>}
                {!!timeLeft && (
                  <p>{`Auto detecting the code 00:${timeLeft} seconds`}</p>
                )}
                {(!timeLeft || error) && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={onResendOTP}
                  >
                    Resend OTP
                  </button>
                )}
              </div>
              <div className="button-group">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={OTP.length !== length}
                  onClick={onSubmitOTP}
                >
                  Submit
                </button>
              </div>
            </>
          )}
          <div className="onboarding-info">
            <p>
              This site is protected by reCAPTCHA and Google{' '}
              <a href="/">Privacy Policy</a> and{' '}
              <a href="/">Terms of Service</a> apply.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};
export default OTPVerification;
