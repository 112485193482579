import React, { Component } from 'react';

import Button from '../StyledButton';

type MyProps = {
  className?: string;
  keyClick?: any;
  keyLog?: any;
  math?: any;
  Tag?: any;
};
class Key extends Component<MyProps> {
  render() {
    return (
      <Button
        className={this.props.className}
        onClick={this.props.keyClick.bind(
          this,
          this.props.keyLog,
          this.props.math
        )}
      >
        {this.props.Tag}
      </Button>
    );
  }
}

export default Key;
