import { client } from './client';
import {
  IListParams,
  ObjectId,
  TCreateResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface IBgvListResponse {
  totalItems: number;
  bgv: any[];
}

export interface ReportType {
  pan: 'pan';
  aadhar: 'aadhar';
  drivingLicense: 'drivingLicense';
  voterId: 'voterId';
}

export interface VerificationType {
  pan: 'pan';
  voter: 'voter';
  drivingLicense: 'drivingLicense';
  currentadd: 'currentadd';
  currentaddPostal: 'currentaddPostal';
  permanentaddDigital: 'permanentaddDigital';
  permanentaddPostal: 'permanentaddPostal';
  permanentadd: 'permanentadd';
  policeClearance: 'policeClearance';
  policeVerification: 'policeVerification';
  globalDatabase: 'globalDatabase';
  creditCheck: 'creditCheck';
  education: 'education';
  employment: 'employment';
}
export enum EducationLevel {
  'NO_EDUCATION' = 'NO_EDUCATION',
  'LESS_THEN_FIFTH_STD' = 'LESS_THEN_FIFTH_STD',
  'FIFTH_STD' = 'FIFTH_STD',
  'EIGHT_STD' = 'EIGHT_STD',
  'TENTH_STD' = 'TENTH_STD',
  'TWELFTH_STD' = 'TWELFTH_STD',
  'DIPLOMA' = 'DIPLOMA',
  'GRADUATE' = 'GRADUATE',
  'MASTERS' = 'MASTERS',
  'PHD' = 'PHD',
  'POST_DOC' = 'POST_DOC',
  'POST_GRADUATE_DIPLOMA' = 'POST_GRADUATE_DIPLOMA',
  'PROFESSIONAL_COURSE' = 'PROFESSIONAL_COURSE',
  'OTHER' = 'OTHER',
}
export interface IEducation {
  id?: number;
  nameAsPerDocument: string;
  issueDate: string;
  registrationNumber: string;
  level: string | EducationLevel;
  nameOfInstitute: string;
  yearOfPassing?: string;
  degree?: string;
  fieldOfStudy?: string;
  durationInMonths?: string;
  grade?: string;
  nameOfBoardUniversity?: string;
}
export interface IEmployment {
  id?: string;
  nameAsPerEmployerRecords: string;
  employeeId?: string;
  employerName: string;
  lastDesignation?: string;
  jobDescription?: string;
  lastWorkingCity?: string;
  joiningDate?: string;
  lastWorkingDate?: string;
  annualCompensation?: Number;
  salaryslip?: string;
  appointmentletter?: string;
  experienceletter?: string;
  managerName?: string;
  managerEmail?: string;
  managerPhone?: string;
  hrName?: string;
  hrEmail?: string;
}

export interface IAddress {
  addressId: string;
  co?: string;
  line1: string;
  line2?: string;
  locality: string;
  landmark?: string;
  vtc?: string;
  district?: string;
  state: string;
  pincode: string;
}

export interface IAddresses {
  permanentAddress?: IAddress;
  currentAddress?: IAddress;
}

export interface IBgvParams extends IListParams {
  from?: Date;
  to?: Date;
  center?: ObjectId;
}

export interface ICreate {
  _id?: string;
  name: string;
  gender: string;
  dob: string;
  professionId: string;
  otherProfession?: string;
  employeeId: string;
  city: string;
  fathersName: string;
  phone: string;
  alternatePhone?: string;
  email: string;
  uid: string;
  hasConsent: boolean;
  consentText: string;
}

export interface IAddDocument {
  files?: string[];
  documentUID: string;
  nameAsPerDocument: string;
  legalGuardianName?: string;
  dob?: string;
}

const get: TGetResource<any> = (id, params) => {
  return client.get(`/bgv/${id}`, { params });
};

const create: TCreateResource<any, any> = (data: ICreate) => {
  return client.post(`/bgv`, data);
};

const addAddress: TCreateResource<any, any> = (data: IAddresses) => {
  return client.post(`/bgv/address`, data);
};

const addDocument = (docType: ReportType, data: IAddDocument) => {
  return client.post(`/bgv/doc/${docType}`, data);
};

const addEduction = (data: IEducation) => {
  return client.post(`/bgv/add/education`, data);
};

const addImage = (id: string, data: { displayPicture: string }) => {
  return client.post(`/bgv/${id}/profile-image`, data);
};

const addEmployment = (data: IEmployment) => {
  return client.post(`/bgv/add/employment`, data);
};

const update: TUpdateResource<ICreate> = (data: ICreate) => {
  return client.put(`/bgv/${data._id}`, data);
};

const list: TListResource<IListParams, IBgvListResponse> = params => {
  return client.get(`/bgv/`, { params });
};

const report = (reportType: ReportType, id: string) => {
  return client.get(`/bgv/report/${reportType}/${id}`);
};

const initiateVerification: TCreateResource<any, any> = (
  verificationType: VerificationType,
  id
) => {
  return client.post(`/bgv/${verificationType}/${id}`, {});
};

const usageReport = (params?: IBgvParams) => {
  return client.get(`/bgv/usage-report`, { params });
};

export const bgv = {
  list,
  get,
  create,
  addAddress,
  addDocument,
  addEduction,
  addEmployment,
  report,
  update,
  initiateVerification,
  usageReport,
  addImage,
};
