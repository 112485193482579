import React from 'react';
import ReactPlayer from 'react-player';

interface IVideoPlayerProps {
  url: string;
  controls: boolean;
  config?: Object;
}

export const VideoPlayer = ({ url, controls, config }: IVideoPlayerProps) => {
  return (
    <ReactPlayer
      url={url}
      controls={controls}
      config={config}
      className="rounded-video-player"
    />
  );
};
