import styled, { css } from 'styled-components';
import { media } from '../media';

interface RowProps {
  spacing?: number;
}

function percentage(val: number) {
  return (100 * val) / 12 + '%';
}

const mediaCss = (device: string, value?: number) =>
  value &&
  css`
    ${device} {
      flex: 0 0 ${percentage(value)};
      max-width: ${percentage(value)};
    }
  `;

interface ColProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

export const Col = styled.div<ColProps>`
  flex: 0 0 100%;
  max-width: 100%;
  ${({ xs }) => mediaCss(media.phone, xs)}
  ${({ sm }) => mediaCss(media.tablet, sm)}
  ${({ md }) => mediaCss(media.desktop, md)}
  ${({ lg }) => mediaCss(media.large, lg)}
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-flow: wrap;
  margin-left: -${({ spacing = 30 }) => spacing / 2}px;
  margin-right: -${({ spacing = 30 }) => spacing / 2}px;
  ${Col} {
    padding-left: ${({ spacing = 30 }) => spacing / 2}px;
    padding-right: ${({ spacing = 30 }) => spacing / 2}px;
  }
`;

export const Container = styled.div<RowProps>`
  padding-left: ${({ spacing = 30 }) => spacing / 2}px;
  padding-right: ${({ spacing = 30 }) => spacing / 2}px;
  ${Row} {
    margin-left: -${({ spacing = 30 }) => spacing / 2}px;
    margin-right: -${({ spacing = 30 }) => spacing / 2}px;
  }
`;
