import { client } from './client';
import { IListParams, ObjectId } from './types';

export enum Module {
  TestBundleV2 = 'TestBundleV2',
  PsychometricTest = 'PsychometricTest',
  Interview = 'Interview',
}

export enum Action {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}
export interface ITransactionParams extends IListParams {
  from?: Date;
  to?: Date;
  module?: Module;
  center?: ObjectId;
  action?: Action;
}

const report = (params?: ITransactionParams) => {
  return client.get(`/transaction/report`, { params });
};

const list = (params: ITransactionParams) => {
  return client.get(`/transaction/`, { params });
};

export const transaction = { report, list };
