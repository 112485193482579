import React, { useEffect, useRef, useState } from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import CheckboxGroup from 'react-checkbox-group';
import Styled from 'styled-components';
import { Form, Label } from '@lipihipi/form';
import { NumericKeyboard } from '../..';
import FileUpload from '../../components/file-upload/file-upload';
import { FilePreviewContainer } from '../../components/file-upload/file-upload.styled';
import { FilePreview } from '../../components/file-upload/file-preview';

const MarksPill = Styled.span`
  background-color: #239f87;
  color: #fff;
  font-size: 16px;
`;

export const TextWrapper = Styled.div`
  font-size: 20px;
`;

const Mcq = ({
  createAsset,
  activeQuestion,
  // selectedOption,
  // negativeMarking,
  setSelectedOption,
  // typedAnswe rsOptions,
  onChangeInputValue,
  getAssetUrl,
  virtualNumericKeyboard,
  enablePhysicalKeyboard = false,
  onAnswerChange,
}: any) => {
  const keyboard: any = useRef(null);
  const [selectedField, setSelectedField] = useState<any>(`0`);
  const [typedAnswers, setTypedAnswers] = useState<any>([]);

  useEffect(() => {
    setSelectedField(selectedField);
    if (keyboard?.current) keyboard.current.setInput('');
  }, [selectedField]);

  const {
    displayOrder,
    // status,
    parentQuestion = {},
    question = {},
    points,
    negativePoints,
    selectedAnswers,
    answerImages = [],
  } = activeQuestion;

  useEffect(() => {
    if (
      activeQuestion?.question?.options?.length > 0 &&
      activeQuestion?.question?.type === 'FIB'
    ) {
      const obj: any = {};
      activeQuestion?.question?.options?.forEach((v: any, index: number) => {
        const value =
          activeQuestion?.typedAnswers?.length > 0
            ? activeQuestion.typedAnswers[index]
            : '';
        obj[v?._id] = value;
      });
      setTypedAnswers(obj);
    } else setTypedAnswers(activeQuestion.typedAnswers);
  }, [activeQuestion]);

  const {
    // explanation = '',
    options,
    type,
    text = '',
    supportedDocuments = [],
  } = question;

  const { questionType = '', commonData = '', fileUrl = '' } = parentQuestion;

  const createQuestionTextMarkup = () => {
    return { __html: text };
  };

  const createQuestionDescriptionMarkup = () => {
    return { __html: commonData };
  };

  const onChange = (input: any) => {
    const obj = { ...typedAnswers };
    obj[selectedField] = input;
    onChangeInputValue(Object.values(obj));
  };

  const showVirtualKeyboard = () =>
    ((virtualNumericKeyboard && enablePhysicalKeyboard) ||
      virtualNumericKeyboard) && (
      <div
        id="keyboard"
        className={`numeric-keyboard ${
          virtualNumericKeyboard ? 'draggable' : ''
        }`}
        draggable={virtualNumericKeyboard}
      >
        <NumericKeyboard
          onChange={onChange}
          value={typedAnswers[selectedField]}
        />
      </div>
    );

  return (
    <>
      <div
        className="row question-responses align-items-start"
        style={{ maxHeight: '100%', overflow: 'auto' }}
      >
        {/* question description */}
        {(questionType === 'COMMON DATA' ||
          questionType === 'LISTEN' ||
          questionType === 'DESCRIPTIVE') && (
          <TextWrapper
            className="col-7"
            style={{ borderRight: '1px solid #B6B6B6', minHeight: '480px' }}
          >
            {fileUrl && (
              <audio controls>
                <source src={getAssetUrl(fileUrl)} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
            )}
            {supportedDocuments?.length > 0 && (
              <div className='supported-docs mb-4'>
                <h6>Supported Documents</h6>
                <FilePreviewContainer className='uploaded-files'>
                  {supportedDocuments.map((value: string) => (
                    <FilePreview key={value} file={value} />
                  ))}
                </FilePreviewContainer>
              </div>
            )}
            {commonData && (
              <TextWrapper className="font-weight-bolder">
                Description
              </TextWrapper>
            )}

            <div
              dangerouslySetInnerHTML={createQuestionDescriptionMarkup()}
            ></div>
          </TextWrapper>
        )}

        <div
          className={
            questionType === 'COMMON DATA' ||
            questionType === 'LISTEN' ||
            questionType === 'DESCRIPTIVE'
              ? 'col-md-5 position-sticky'
              : 'col-md-12'
          }
        >
          <div className="d-flex align-items-center mb-3">
            <span className="mr-2 font-weight-bolder" style={{ flexShrink: 0 }}>
              Question {displayOrder ? displayOrder : ''}:
            </span>
            <MarksPill className="badge badge-pill mb-0">
              Marks: {points ? `+${points}` : ''}{' '}
              {negativePoints ? `/ -${negativePoints}` : ''}
            </MarksPill>
          </div>

          {/* question text */}
          <TextWrapper className="d-flex">
            <div dangerouslySetInnerHTML={createQuestionTextMarkup()}></div>
          </TextWrapper>
          {/* question options */}
          {/* "MA" stands for multi answer type question */}
          {type === 'MA' ? (
            <>
              <CheckboxGroup
                name="fruits"
                value={selectedAnswers}
                onChange={setSelectedOption}
              >
                {Checkbox => (
                  <div className="d-flex flex-column">
                    {options &&
                      options.map((option: any) => (
                        <label
                          key={option._id}
                          className="d-flex align-items-center"
                        >
                          <Checkbox value={option._id} />
                          {/* <OptionText className="ml-3">{option.text}</OptionText> */}
                          <span
                            className="ml-3"
                            dangerouslySetInnerHTML={{ __html: option.text }}
                          ></span>
                        </label>
                      ))}
                  </div>
                )}
              </CheckboxGroup>
              {showVirtualKeyboard()}
            </>
          ) : type === 'FIB' ? (
            <span>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <ul className="fib-textbox">
                    {options &&
                      options.map((option: any, index: number) => {
                        const value =
                          activeQuestion?.question?.type === 'FIB' &&
                          activeQuestion?.question?.options?.length > 1
                            ? typedAnswers[option?._id]
                            : activeQuestion?.typedAnswers[0];

                        return (
                          <li key={option._id}>
                            <Label label={index + 1} />
                            <input
                              type="text"
                              className="form-control"
                              id={`typedAnswers[${option?._id}]`}
                              name={`${option?._id}`}
                              value={value}
                              onChange={(e: any) => {
                                if (enablePhysicalKeyboard) {
                                  const obj = { ...typedAnswers };
                                  obj[e.target.name] = e.target.value;
                                  onChangeInputValue(Object.values(obj));
                                }
                              }}
                              onClick={() => setSelectedField(`${option?._id}`)}
                            />
                          </li>
                          // <label

                          //   className="d-flex align-items-center"
                          // >
                          //   <Label label={index + 1} />

                          // </label>
                        );
                      })}
                  </ul>
                </div>
              </div>
              {showVirtualKeyboard()}
            </span>
          ) : (
            <>
              {type === 'RE-ARR' ? (
                <span>
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      {options &&
                        options.map((option: any, index: number) => {
                          return (
                            <label
                              key={option._id}
                              className="d-flex align-items-center"
                            >
                              <Label label={index + 1} />
                              <span
                                className="ml-3"
                                dangerouslySetInnerHTML={{
                                  __html: option.text,
                                }}
                              ></span>
                            </label>
                          );
                        })}
                    </div>
                  </div>
                </span>
              ) : (
                <RadioGroup
                  name="fruit"
                  selectedValue={
                    (selectedAnswers?.length && selectedAnswers[0]) || null
                  }
                  onChange={setSelectedOption}
                  className="d-flex flex-column"
                >
                  {options &&
                    options.map((option: any) => (
                      <label
                        key={option._id}
                        className="d-flex align-items-center"
                      >
                        <Radio value={option._id} />
                        {/* <span className="ml-3">{option.text}</span> */}
                        <span
                          className="ml-3"
                          dangerouslySetInnerHTML={{ __html: option.text }}
                        ></span>
                      </label>
                    ))}
                  {showVirtualKeyboard()}
                </RadioGroup>
              )}
            </>
          )}
          {type === 'DSC' ? (
            <div className='descriptive-type mt-3'>
              <textarea
                style={{ width: '100%' }}
                rows={5}
                className='mb-2 form-control'
                id={`typedAnswers[0]`}
                name={`0`}
                placeholder='Enter here...'
                value={typedAnswers?.length ? typedAnswers[0] : ''}
                onChange={(e: any) => {
                  if (enablePhysicalKeyboard) {
                    const arr = [...typedAnswers];
                    arr[e.target.name] = e.target.value;
                    onChangeInputValue(arr);
                  }
                }}
                onClick={() => setSelectedField(`0`)}
              />
              <Form className="supported-docs" initialValues={{ answerImages }}>
                <FileUpload
                  name="answerImages"
                  upload={createAsset}
                  accept={[
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'text/plain',
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                  ]}
                  label={'Attach Files (PDF, DOC, DOCX, TEXT, PNG, JPEG, JPG)'}
                  type={'multiple'}
                  maxSize={'1gb'}
                  onChange={(v: any) => {
                    onAnswerChange(v);
                  }}
                />
              </Form>
              {showVirtualKeyboard()}
            </div>
          ) : null}
          {type === 'RE-ARR' && (
            <div className="mt-3">
              <div className="form-group">
                <input
                  type="text"
                  id="typedAnswer"
                  name="typedAnswer"
                  className="form-control"
                  required
                  placeholder="Please enter answer in form of number. ex: 1234"
                  value={typedAnswers?.length > 0 ? typedAnswers[0] : ''}
                  onChange={(e: any) => {
                    if (enablePhysicalKeyboard) {
                      const arr = [];
                      arr[0] = e.target.value > 0 ? e.target.value : '';
                      onChangeInputValue(arr);
                    }
                  }}
                  onClick={() => setSelectedField('0')}
                />
                {showVirtualKeyboard()}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Mcq;
