import { Modal } from '@lipihipi/ec-ui';
import { Form, TextArea } from '@lipihipi/form';
import React from 'react';
import { Button } from '@lipihipi/ec-ui';

import * as Yup from 'yup';

export const ModelSchema = Yup.object().shape({
  explanation: Yup.string()
  .required('Details are Required')
  .min(10, 'Must be more than 10 character')
});
const ExplanationModal = ({
  isOpen,
  onRequestClose,
  onSubmitQuery
}:any) => {
  return (
    <Modal heightDefault={true} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="redeem-modal">
        <h3 className="text-center">Feedback</h3>
        <Form
        validationSchema={ModelSchema}
        onSubmit={onSubmitQuery}
        initialValues={{}}>
          <TextArea
            id={'explanation'}
            name={'explanation'}
            required
          />

          <div className="d-flex justify-content-center mt-4">
            <Button shape={'secondary'} className="px-4" onClick={onRequestClose} type="button">Close</Button>
            <Button shape={'primary'} className="px-4 ml-3" type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default ExplanationModal;
