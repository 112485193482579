import React, { useState, useEffect } from 'react';
import { Button } from '@lipihipi/ec-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CreateDoubt } from '../index';
import PostDoubt from './PostDoubt';
import { Select, Form } from '@lipihipi/form';
import DoubtFilters from './DoubtFilters';

const Doubt = ({
  // imageBaseUrl,
  selectedCourse,
  user,
  createDoubt,
  updateDoubt,
  removeDoubt,
  listDoubt,
  // getMyBatchSubjects,
  getMyBatchSubjectTeachers,
  getAssetUrl,
  createAsset,
  removeDoubtComment,
  createDoubtComment,
  createDoubtResponse,
  updateDoubtResponse,
  getCourses,
  getEducators,
  getPurchasedBatchList,
}: // getTopicList
any) => {
  const initialValues = {
    page: 1,
    populate: true,
    isRecent: true,
    course: null,
    user: null,
    startDate: '',
    endDate: '',
  };

  const [isOpen, setOpen] = useState<boolean>(false);
  const [doubtArr, setDoubtArr] = useState<any>([]);
  const [courseOptions, setCourseOptions] = useState<any>([]);
  const [educatorOptions, setEducatorOptions] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [params, setParams] = useState<any>({ ...initialValues });
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  useEffect(() => {
    getDoubtFeeds();
  }, [params, user]);

  useEffect(() => {
    if (user?.role && selectedCourse?._id) {
      setParams({ ...params, course: selectedCourse?._id });
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (user.role === 'admin' || user.role === 'superadmin') {
      getCourses({ all: true, populate: true }).then(({ data }: any) => {
        setCourseOptions(
          data.courses.map((i: any) => ({ label: i.name, value: i._id }))
        );
      });

      getEducators({ all: true, populate: true }).then((res: any) => {
        setEducatorOptions(
          res.data.educators.map((i: any) => ({
            label: i?.name,
            value: i?._id,
          }))
        );
      });
    }

    if (user.role === 'user') {
      let data = user?.selectedCourses || [];
      setCourseOptions(
        data.map((i: any) => {
          return {
            label: i.course.name,
            value: i.course._id,
          };
        })
      );
    } else {
      let data = user?.subjectDetail;

      setCourseOptions(
        data?.course?.map((course: any) => {
          return {
            label: course.name,
            value: course._id,
          };
        })
      );
    }
  }, []);

  const getDoubtFeeds = () => {
    if (user.role === 'user' && !params.course) {
      return;
    }
    listDoubt(params).then((res: any) => {
      let doubts = res?.data?.doubts;
      if (params.page === 1) {
        setDoubtArr(doubts);
      } else {
        setDoubtArr([...doubtArr, ...doubts]);
      }

      if (doubts.length === 10) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    });
  };

  const renderDoubtFeed = () => {
    return doubtArr.map((item: any) => {
      return (
        <div key={item._id}>
          <PostDoubt
            item={item}
            createDoubtResponse={createDoubtResponse}
            User={user}
            doubtArr={doubtArr}
            setDoubtArr={setDoubtArr}
            updateDoubtResponse={updateDoubtResponse}
            createComment={createDoubtComment}
            getDoubtFeeds={getDoubtFeeds}
            removeComment={removeDoubtComment}
            removePost={removeDoubt}
            getAssetUrl={getAssetUrl}
            updateDoubt={updateDoubt}
          />
        </div>
      );
    });
  };

  const isVisible = () => {
    if (user.role === 'user') return true;
    return false;
  };

  return (
    <>
      <CreateDoubt
        isOpen={isOpen}
        createAsset={createAsset}
        createDoubt={createDoubt}
        setOpen={setOpen}
        User={user}
        doubtArr={doubtArr}
        setDoubtArr={setDoubtArr}
        selectedCourse={selectedCourse}
        // getMyBatchSubjects={getMyBatchSubjects}
        getMyBatchSubjectTeachers={getMyBatchSubjectTeachers}
        getPurchasedBatchList={getPurchasedBatchList}
        // getTopicList={getTopicList}
      />
      <section className="main-structure educrack-wall">
        <div className="wall-grid">
          <div className="content">
            {isVisible() && (
              <div className="create-button">
                <Button shape={'primary'} onClick={() => setOpen(true)}>
                  Ask a question
                </Button>
              </div>
            )}

            {user.role === 'user' ? (
              <Form
                initialValues={initialValues}
                render={(_: any) => {
                  return (
                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-md-4">
                        <Select
                          id={'filter_answered'}
                          name={'filter_answered'}
                          placeholder={'Filter Resolved Doubts'}
                          options={[
                            { label: 'ALL', value: '' },
                            { label: 'Answered', value: 'answer' },
                            { label: 'Unanswered', value: 'not_answer' },
                          ]}
                          onChange={(e: any) => {
                            setParams({ ...params, filter_answered: e.value });
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            ) : null}

            <InfiniteScroll
              dataLength={10}
              next={() => setParams({ ...params, page: params.page + 1 })}
              hasMore={hasMore}
              loader=""
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {renderDoubtFeed()}
            </InfiniteScroll>
          </div>

          {user.role !== 'user' ? (
            <DoubtFilters
              initialValues={initialValues}
              user={user}
              params={params}
              setParams={setParams}
              courseOptions={courseOptions}
              educatorOptions={educatorOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          ) : (
            <div className="filter"></div>
          )}
        </div>
      </section>
    </>
  );
};

export default Doubt;
