import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { ImFileText2 } from 'react-icons/im';
import moment from 'moment';
import { Loader } from '@lipihipi/ec-ui';

export const EnrolledTests = ({
  testBundle,
  onTestBundleClick,
  getTestDetails,
  isLoading,
  setIsLoading,
}: any) => {
  const [visible, setVisible] = useState<any>(10);
  const [testAttempt, setTestAttempt] = useState<any>({});

  const loadMore = () => {
    setVisible(visible + 10);
  };

  const _getTest = (_id: any) => {
    getTestDetails(_id, { populate: true }).then(({ data }: any) => {
      setIsLoading(false);
      const newTestData = { ...testAttempt };
      newTestData[_id] = data;
      setTestAttempt(newTestData);
    });
  };

  const attemptedTests = (arr: any) => {
    let result = [];
    result = arr?.filter((item: any) => {
      return item?.status === 'FINISHED' || item?.status === 'IN PROGRESS';
    });
    return result?.length || 0;
  };

  return (
    <>
      {isLoading && <Loader />}
      {testBundle?.UserTestBundles?.length > 0 && (
        <div className="lesson-wrapping">
          <p>List of enrolled Test series </p>

          <div className="batch-accordion test-accordian" data-name="tests">
            {testBundle?.UserTestBundles?.slice(0, visible)?.map(
              (tests: any) => {
                return (
                  <Accordion key={tests?._id} data-test-id={tests?._id}>
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => _getTest(tests?._id)}
                      >
                        <p className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items--md-center pr-4">
                          <span style={{ fontSize: '15px' }}>
                            {tests?.name || 'Not Available'}
                          </span>
                          <span>
                            {/* <span
                              className="mr-3"
                              style={{ fontSize: '12px', fontWeight: 'bold' }}
                            >
                              {tests?.course?.name || 'Not Available'}
                            </span> */}
                            <span style={{ fontSize: '12px' }}>
                              {tests?.createdAt
                                ? moment(tests?.createdAt).format(
                                    'DD MMMM YYYY, h:mma'
                                  )
                                : '--'}
                            </span>
                          </span>
                        </p>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '0 17px 17px' }}>
                          <div className="my-progress">
                            <div className="my-progress--name">
                              <span>
                                <ImFileText2 />
                              </span>
                              <p>Tests</p>
                            </div>
                            <div className="my-progress--bar">
                              <div className="primary-progress-bar">
                                <div className="wrap">
                                  <div
                                    className="bar-line"
                                    style={{
                                      width:
                                        `calc(${attemptedTests(
                                          testAttempt[tests?._id]?.testAttempts
                                        )}/${tests?.tests?.length}*100%)` ||
                                        '0px',
                                    }}
                                  >
                                    Line
                                  </div>
                                </div>
                                <span>
                                  {`${attemptedTests(
                                    testAttempt[tests?._id]?.testAttempts
                                  )}` || 0}
                                  /{tests?.tests?.length || 0}
                                </span>
                              </div>
                              <p>
                                {`${attemptedTests(
                                  testAttempt[tests?._id]?.testAttempts
                                )}`}{' '}
                                attended out of total{' '}
                                {tests?.tests?.length || 0} Tests assigned to
                                you
                              </p>
                            </div>
                            <div className="my-progress--action">
                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  onTestBundleClick(tests?._id);
                                }}
                              >
                                View Details
                              </a>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              }
            )}
          </div>
          {visible < testBundle?.UserTestBundleEnrollments?.length && (
            <div
              className="text-center"
              style={{
                fontSize: '15px',
                lineHeight: '18px',
                cursor: 'pointer',
                marginTop: '20px',
                color: '#00a689',
              }}
            >
              <p onClick={loadMore} id="test-viewMore">
                View More
              </p>
            </div>
          )}
          {visible >= testBundle?.UserTestBundleEnrollments?.length &&
            visible > 10 && (
              <div
                className="text-center"
                style={{
                  fontSize: '15px',
                  lineHeight: '18px',
                  cursor: 'pointer',
                  marginTop: '20px',
                  color: '#00a689',
                }}
              >
                <p onClick={() => setVisible(10)} id="viewLess">
                  View less
                </p>
              </div>
            )}
        </div>
      )}
    </>
  );
};
