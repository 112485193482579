import React from 'react';
import { PageHeader } from '@lipihipi/ec-ui';
import Styled from 'styled-components';

const ObjectBox = Styled.embed<{ src: string }>`
max-width: 85vw;
height: auto;
margin:10px;
margin-left:0;
src: src(${({ src }: any) => src});`;

const PdfBox = Styled.iframe<{ src: string }>`
width: 90%;
height: 100vh;
margin:10px;
src: src(${({ src }: any) => src});`;

const ResourceDetail = ({
  breadCrumbs,
  resourceName,
  getAssetUrl,
}: any) => {
  return (
    <div className="d-flex flex-wrap">
      <div className="main-mid">
        <PageHeader
          breadCrumbs={
            breadCrumbs || [
              { title: 'Home', link: '/dashboard' },
              { title: 'Manage Batches', link: '/' },
              { title: 'Test', link: '/' },
            ]
          }
        />

        <h3 className="primary-heading">
          {''}
        </h3> 

        <div className="mt-5">
          {resourceName ? (
            resourceName?.replace(/^.*\./, '') === 'pdf' ? (
              <PdfBox src={getAssetUrl(resourceName)} />
            ) : resourceName?.replace(/^.*\./, '') === 'mp4' ? (
              <video
                style={{ borderRadius: '20px' }}
                width="100%"
                controls
                controlsList="nodownload"
                disablePictureInPicture
              >
                <source src={getAssetUrl(resourceName)} type="video/mp4" />
              </video>
            ) : (
              <ObjectBox src={getAssetUrl(resourceName)} />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default ResourceDetail;
