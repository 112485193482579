import { TextWrapper } from '../components/mcq/mcq';
import React from 'react';
import { Radio, RadioGroup } from 'react-radio-group';

const MCQ = ({ activeQuestion, setSelectedOption }: any) => {
  const createQuestionTextMarkup = () => {
    return { __html: activeQuestion?.text };
  };
  return (
    <div
      className="row question-responses align-items-start"
      style={{ maxHeight: '100%', overflow: 'auto' }}
    >
      <div className="col-md-12">
        <div className="d-flex align-items-center mb-3">
          <span className="mr-2 font-weight-bolder" style={{ flexShrink: 0 }}>
            Question{' '}
            {activeQuestion?.displayOrder ? activeQuestion?.displayOrder : ''}:
          </span>
        </div>

        {/* question text */}
        <TextWrapper className="d-flex">
          <div dangerouslySetInnerHTML={createQuestionTextMarkup()}></div>
        </TextWrapper>
        <RadioGroup
          name="fruit"
          selectedValue={
            (activeQuestion?.selectedAnswer &&
              activeQuestion?.selectedAnswer) ||
            null
          }
          onChange={setSelectedOption}
          className="d-flex flex-column"
        >
          <label key="YES" className="d-flex align-items-center">
            <Radio value={'YES'} />
            <span
              className="ml-3"
              dangerouslySetInnerHTML={{ __html: 'Definitely Yes' }}
            ></span>
          </label>
          <label key="NO" className="d-flex align-items-center">
            <Radio value={'NO'} />
            <span
              className="ml-3"
              dangerouslySetInnerHTML={{ __html: 'No' }}
            ></span>
          </label>
        </RadioGroup>
      </div>
    </div>
  );
};

export default MCQ;
