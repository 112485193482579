import { Button } from '@lipihipi/ec-ui';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import React, { useRef } from 'react';
interface Props {
    data: any;
    setModal: any;

}

const ResumeTemplate: React.FC<Props> = ({ data, setModal }) => {
    const resumeRef = useRef(null);

    const handleDownload = () => {
        const opt = {
            margin: 1,
            filename: 'resume.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        html2pdf()
            .from(resumeRef.current)
            .set(opt)
            .save();
    };
    return (
        <div >
            <button onClick={handleDownload}>Download PDF</button>
            <div id="resume-content" ref={resumeRef}>
                <div style={{ backgroundColor: '#000' }}>
                    <div style={{ maxWidth: '850px', width: '100%', margin: '0 auto', backgroundColor: '#fff' }}>
                        <div style={{ width: '70%', margin: '0 auto', backgroundColor: '#ebe6e6', textAlign: 'center', padding: '60px 0 20px', fontSize: '32px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                            {data.firstName} {data.lastName}
                        </div>
                        <div style={{ width: '70%', margin: '0 auto', backgroundColor: '#f9f9f9', letterSpacing: '1px', fontSize: '14px', textTransform: 'uppercase', textAlign: 'center', padding: '10px 0', border: '0px solid #ebe6e6' }}>
                            {data.designation || 'Fresher'}
                        </div>
                    </div>
                    <div style={{ width: '90%', textAlign: 'center', margin: '0 auto', paddingTop: '40px', paddingBottom: '40px', borderTop: '3px solid #ebe6e6' }}>
                        <div style={{ fontSize: '16px', lineHeight: '150%' }}>{data.objective}</div>
                    </div>

                    <div style={{ width: '100%', borderTop: '3px solid #ebe6e6', padding: '40px', display: 'flex' }}>
                        <div style={{ paddingRight: '40px', width: '35%', borderRight: '1.5px solid #ebe6e6' }}>
                            {data.educations.length > 0 && (
                                <div style={{ marginBottom: '50px' }}>
                                    <h3 style={{ textTransform: 'uppercase', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', color: '#333', borderBottom: '2px solid #ebe6e6', paddingBottom: '8px', marginBottom: '16px' }}>
                                        Education
                                    </h3>
                                    {data.educations.map((event: any, index: any) => (
                                        <div key={index} style={{ marginTop: index === 0 ? '0' : '30px' }}>
                                            <h4 style={{ fontSize: '14px', color: '#333', marginBottom: '5px' }}>{event.degree}</h4>
                                            <i style={{ display: 'block', fontSize: '12px', color: '#777', marginBottom: '5px' }}>{event.schoolName}</i>
                                            <i style={{ display: 'block', fontSize: '12px', color: '#777' }}>{event.percentage} | {event.passedYear}</i>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {data.skills.length > 0 && (
                                <div>
                                    <h3 style={{ textTransform: 'uppercase', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', color: '#333', borderBottom: '2px solid #ebe6e6', paddingBottom: '8px', marginBottom: '16px' }}>
                                        Skills
                                    </h3>
                                    <ul style={{ padding: '0', margin: '0', listStyle: 'none' }}>
                                        {data.skills.map((skill: any, index: any) => (
                                            <li key={index} style={{ display: 'inline-block', fontSize: '14px', color: '#333', marginRight: '10px' }}>
                                                {skill.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div style={{ paddingLeft: '40px', width: '65%' }}>
                            {data.experiences.length > 0 && (
                                <div style={{ marginBottom: '50px' }}>
                                    <h3 style={{ textTransform: 'uppercase', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', color: '#333', borderBottom: '2px solid #ebe6e6', paddingBottom: '8px', marginBottom: '16px' }}>
                                        Experience
                                    </h3>
                                    {data.experiences.map((event: any, index: any) => (
                                        <div key={index} style={{ marginTop: index === 0 ? '0' : '30px' }}>
                                            <h4 style={{ fontSize: '14px', color: '#333', marginBottom: '5px' }}>{event.position}</h4>
                                            <i style={{ display: 'block', fontSize: '12px', color: '#777', marginBottom: '5px' }}>{event.companyName}</i>
                                            <i style={{ display: 'block', fontSize: '12px', color: '#777' }}>{event.startDate} - {event.endDate}</i>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {data.internships.length > 0 && (
                                <div style={{ marginBottom: '50px' }}>
                                    <h3 style={{ textTransform: 'uppercase', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', color: '#333', borderBottom: '2px solid #ebe6e6', paddingBottom: '8px', marginBottom: '16px' }}>
                                        Internships
                                    </h3>
                                    {data.internships.map((event: any, index: any) => (
                                        <div key={index} style={{ marginTop: index === 0 ? '0' : '30px' }}>
                                            <h4 style={{ fontSize: '14px', color: '#333', marginBottom: '5px' }}>{event.position}</h4>
                                            <i style={{ display: 'block', fontSize: '12px', color: '#777', marginBottom: '5px' }}>{event.companyName}</i>
                                            <i style={{ display: 'block', fontSize: '12px', color: '#777' }}>{event.startDate} - {event.endDate}</i>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {data.projects.length > 0 && (
                                <div>
                                    <h3 style={{ textTransform: 'uppercase', fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px', color: '#333', borderBottom: '2px solid #ebe6e6', paddingBottom: '8px', marginBottom: '16px' }}>
                                        Projects
                                    </h3>
                                    {data.projects.map((project: any, index: any) => (
                                        <div key={index} style={{ marginTop: index === 0 ? '0' : '30px' }}>
                                            <h4 style={{ fontSize: '14px', color: '#333', marginBottom: '5px' }}>{project.company}</h4>
                                            <h5 style={{ fontSize: '14px', color: '#333', marginBottom: '5px' }}>
                                                <a href={project.url} style={{ color: '#333', textDecoration: 'none' }}>{project.projectName}</a>
                                            </h5>
                                            <p style={{ fontSize: '12px', color: '#777', marginBottom: '5px' }}>{project.description}</p>
                                            <p style={{ fontSize: '12px', color: '#777', marginBottom: '5px' }}>
                                                <strong>Technologies:</strong> {project.technology.join(', ')}
                                            </p>
                                            <p style={{ fontSize: '12px', color: '#777' }}>
                                                <strong>Team Size:</strong> {project.teamSize} | <strong>Year:</strong> {project.year}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ width: '70%', margin: '0 auto', textAlign: 'center', paddingBottom: '40px', fontSize: '14px', letterSpacing: '1px' }}>
                        <p style={{ marginBottom: '10px' }}>
                            <strong>Hobbies:</strong> {data.hobbies}
                        </p>
                        <p style={{ marginBottom: '10px' }}>
                            <strong>Languages Known:</strong> {data.languageKnown.join(', ')}
                        </p>
                        <p style={{ marginBottom: '10px' }}>
                            <strong>Nationality:</strong> {data.nationality}
                        </p>
                    </div>
                    <Button type="button" onClick={() =>
                        setModal(false)} shape="primary" >Close</Button>
                </div>
            </div>
        </div>
    );
};

export default ResumeTemplate;
