import { client } from './client';
import {
  IDocument,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
} from './types';

interface IEducation {
  schoolName: string;
  degree: string;
  percentage: string;
  passedYear: number;
  passedMonth: number;
  isStudying: boolean;
}

interface IInternship {
  companyName: string;
  position: string;
  startDate: string;
  endDate: string;
  isWorking: boolean;
}

interface IExperiece {
  companyName: string;
  position: string;
  startDate: string;
  endDate: string;
  isWorking: boolean;
}
interface ISkill {
  name: string;
  rating: number;
}

interface IProject {
  projectName: string;
  company: string;
  technology: string;
  url: string;
  teamSize: number;
  year: number;
  description: string;
}

export interface IResume extends IDocument {
  firstName: string;
  lastName: string;
  emailId: string;
  mobile: string;
  gender: string;
  passport: boolean;
  city: string;
  pincode: string;
  languageKnown: string;
  nationality: string;
  fatherName: string;
  fatherOccupation: string;
  motherName: string;
  motherOccupation: string;
  address: string;
  objective: string;
  hobbies: string;
  educations: IEducation[];
  internships: IInternship[];
  experiences: IExperiece[];
  skills: ISkill[];
  projects: IProject[];
}

export interface IResumeDocument extends IResume, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IResumeListResponse {
  totalItems: number;
  resume: IResumeDocument[];
}

export enum ResumeTemplate {
  Template1 = '1',
  Template2 = '2',
  Template3 = '3',
  Template4 = '4',
}

const get: TGetResource<IResumeDocument> = (id: string, params) => {
  return client.get<IResumeDocument>(`/resume/${id}`, { params });
};

const me: TGetResource<IResumeDocument> = () => {
  return client.get<IResumeDocument>(`/resume/me`);
};

const create: TCreateResource<IResume, IResumeDocument> = (resume: IResume) => {
  return client.post<IResumeDocument>(`/resume`, resume);
};

const update = (id: string, resume: IResume) => {
  return client.patch(`/resume/${id}`, resume);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/resume/${id}`);
};

const getPDF = (params: { template?: ResumeTemplate; html?: boolean }) => {
  //client.defaults.responseType = 'arraybuffer';
  return client.get(`/resume/pdf`, { responseType: 'arraybuffer', params });
};

export const resume = { get, create, update, remove, me, getPDF };
