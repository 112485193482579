import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface ICategory {
  name: string;
  displayPicture?: string;
  parent?: string;
}

export interface IUpdateList {
  id: string;
  displayOrder: number;
}

export interface ICategoryList extends IListParams {
  parent?: string;
  course?: string;
}

export interface ICategoryDocument extends ICategory, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface ICategoryListResponse {
  totalItems: number;
  categories: ICategoryDocument[];
}

const create: TCreateResource<ICategory, ICategoryDocument> = (
  category: ICategory
) => {
  return client.post<ICategoryDocument>(`/category`, category);
};

const update: TUpdateResource<ICategoryDocument> = category => {
  return client.put(`/category/${category._id}`, category);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/category/${id}`);
};

const list: TListResource<ICategoryList, ICategoryListResponse> = params => {
  return client.get(`/category/`, { params });
};

const activeList: TListResource<
  ICategoryList,
  ICategoryListResponse
> = params => {
  return client.get(`/category/${params.course}`, { params });
};

export const category = { create, update, remove, list, activeList };
