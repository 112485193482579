import EducrackAPI from '@lipihipi/client-sdk';
import React, { useState, useEffect } from 'react';
import { PsychometricTestReport } from '@lipihipi/ec-test-bundles';
import { commonApiError } from '../subscriber-report';

const SubscriberPyschometricTests = ({ _id }: any) => {
  const [tests, setTests] = useState<any>({
    totalItems: 0,
    tests: [],
  });
  const [visible, setVisible] = useState<any>(10);

  const loadMore = () => {
    setVisible(visible + 10);
  };

  useEffect(() => {
    EducrackAPI.psychometricUserTest
      .list({ user: _id, populate: true })
      .then(({ data }: any) => {
        setTests(data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  }, []);

  return (
    <div className="lesson-wrapping">
      <p>Pyschometric Test</p>
      {tests.length > 0 ? (
        tests?.slice(0, visible)?.map((item: any) => {
          <PsychometricTestReport
            attemptId={item._id}
            getTestAttempt={EducrackAPI.psychometricTestAttempt.get}
          />;
        })
      ) : (
        <p>No Data Available</p>
      )}
      {visible < tests?.length && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={loadMore}>View More</p>
        </div>
      )}
      {visible >= tests?.length && visible > 10 && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={() => setVisible(10)}>View less</p>
        </div>
      )}
    </div>
  );
};

export default SubscriberPyschometricTests;
