import React from 'react';
import {
  BiChevronsLeft,
  BiChevronLeft,
  BiChevronRight,
  BiChevronsRight,
} from 'react-icons/bi';

interface IPaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
}: IPaginationProps) => {
  const pages = Math.ceil(totalItems / itemsPerPage);

  const pageNumbers = [];

  for (let i = 0; i < pages; i++) {
    pageNumbers.push(i + 1);
  }

  return (
    <>
      <ul className="primary-pagination">
        <li className={`${currentPage === 1 ? 'disabled' : ''}`}>
          <a href="javascript:void(0)" onClick={() => onPageChange(0)}>
            <BiChevronsLeft />
          </a>
        </li>
        <li className={`${currentPage === 1 ? 'disabled' : ''}`}>
          <a
            href="javascript:void(0)"
            onClick={() => currentPage !== 1 && onPageChange(currentPage - 1)}
          >
            <BiChevronLeft />
          </a>
        </li>

        {pageNumbers.map(number => {
          if (
            (number > 15 && number > currentPage + 7) ||
            number < currentPage - 7
          ) {
            return null;
          }

          // if ((number > 5 && number > currentPage + 2) || (number < 5 && number < currentPage - 1)) {
          //   // if (number < currentPage + 2 && number > currentPage - 2 && number > 5) {
          //   return null;
          // }
          return (
            <li
              key={number}
              className={`${currentPage === number ? 'active' : ''}`}
            >
              <a href="javascript:void(0)" onClick={() => onPageChange(number)}>
                {number}
              </a>
            </li>
          );
        })}
        <li className={`${currentPage === pages ? 'disabled' : ''}`}>
          <a
            href="javascript:void(0)"
            onClick={() =>
              currentPage !== pages && onPageChange(currentPage + 1)
            }
          >
            <BiChevronRight />
          </a>
        </li>

        <li className={`${currentPage === pages ? 'disabled' : ''}`}>
          <a href="javascript:void(0)" onClick={() => onPageChange(pages)}>
            <BiChevronsRight />
          </a>
        </li>
      </ul>
    </>
  );
};

export { Pagination };
