import React from 'react';
import EducrackAPI from '@lipihipi/client-sdk';
import { ResumeTemplate } from './helper';
import { PageHeader } from '@lipihipi/ec-ui';

const ResumeListing: React.FC<any> = ({
  title,
  description,
  breadCrumbs,
  resumeThumbnail,
}) => {
  const getResume = (values: any) => {
    //@ts-ignore
    EducrackAPI.resume.getPDF({ template: values }).then((res: any) => {
      const fileURL = URL.createObjectURL(
        new Blob([res.data], { type: 'application/pdf' })
      );
      let tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('target', '_blank');
      tempLink.click();
    });
  };
  return (
    <>
      <PageHeader
        title={title || 'Resume Template'}
        description={description || ''}
        breadCrumbs={breadCrumbs || [{ title: 'Resume Template', link: '#' }]}
      />
      <ul className="resume-template-grid">
        <li>
          <img src={resumeThumbnail?.first} alt="" />
          <div className="overlay">
            <a
              className="educrack-primary-btn"
              href="javascript:void(0)"
              onClick={() => getResume(ResumeTemplate.Template1)}
            >
              <span>Preview & Download</span>
            </a>
          </div>
        </li>

        <li>
          <img src={resumeThumbnail?.second} alt="" />
          <div className="overlay">
            <a
              className="educrack-primary-btn"
              href="javascript:void(0)"
              onClick={() => getResume(ResumeTemplate.Template2)}
            >
              <span>Preview & Download</span>
            </a>
          </div>
        </li>

        <li>
          <img src={resumeThumbnail?.third} alt="" />
          <div className="overlay">
            <a
              className="educrack-primary-btn"
              href="javascript:void(0)"
              onClick={() => getResume(ResumeTemplate.Template3)}
            >
              <span>Preview & Download</span>
            </a>
          </div>
        </li>

        <li>
          <img src={resumeThumbnail?.fourth} alt="" />
          <div className="overlay">
            <a
              className="educrack-primary-btn"
              href="javascript:void(0)"
              onClick={() => getResume(ResumeTemplate.Template4)}
            >
              <span>Preview & Download</span>
            </a>
          </div>
        </li>

        {/* <li>
          <img
            src="https://cdn-images.zety.com/images/zety/landings/examples/teacher-resume-example@3x.png"
            alt=""
          />
          <div className="overlay">
            <a
              className="educrack-primary-btn"
              href="javascript:void(0)"
              onClick={() => getResume(ResumeTemplate.Template5)}
            >
              <span>Preview & Download</span>
            </a>
          </div>
        </li> */}
      </ul>
    </>
  );
};

export default ResumeListing;
