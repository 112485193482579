import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface ISupportListParams extends IListParams {
  populate?: boolean;
  ticketId?: string;
  status?: string;
  category?: string;
}

export interface ISupport {
  ticketId?: string;
  text: string;
  category: string;
  status: string;
  displayPicture?: string;
  question?: ObjectId | PopulatedDocument;
}

export interface ISupportDocument extends ISupport, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface ISupportListResponse extends Array<ISupportDocument> {}

const create = (support: ISupport) => {
  return client.post<ISupportDocument>(`/support`, support);
};

const get = (ticketId: string, params?: ISupportListParams) => {
  return client.get<ISupportDocument>(`/support/${ticketId}`, { params });
};

const list = (params?: ISupportListParams) => {
  return client.get<ISupportListResponse>(`/support`, { params });
};

export const support = { create, list, get };
