import React, { FC, useState, useEffect } from 'react';
import { PageHeader , Pagination , Loader} from '@lipihipi/ec-ui';
import moment from 'moment';

interface INotification  {
  imageBaseUrl: string;
  notifications :any;
  markReadNotifications: any;
  viewNotificationDetails: any;
  title?: any;
  breadCrumbs?: any;
}

const Notification: FC<any> = ({
  imageBaseUrl,
  notifications,
  markReadNotifications,
  viewNotificationDetails,
  title,
  breadCrumbs,
  }: INotification) => {

    const [isLoading, setLoading] = useState<boolean>(true);
    console.log('imageBaseUrl', imageBaseUrl);

    const [params, setParams] = useState<any>({
      populate: true,
      page: 1,
      isRead: true
    });

    const [notificationList, setNotificationList] = useState<any>({
      notifications: [],
      totalItems: 0
    });

    useEffect(() => {
      notifications(params)
      .then(({data}: any) => {
        setNotificationList(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    }, [params]);

    const readNotifications = (notification:any) => {
    if(notification.isRead) {
      viewNotificationDetails(notification);
    }else{
      markReadNotifications(notification._id)
      .then(() => {
        viewNotificationDetails(notification);
      })
      .catch(() => {});
    }
  }

  return (
    <div className="main-mid">
      <PageHeader
          title={ title || "Notifications"}
          breadCrumbs={breadCrumbs || [
            { title: "Notifications" },
          ]}
      />
        {isLoading && <Loader/>}
        {notificationList.totalItems ? (
            <ul className="notification-page">
              {notificationList.notifications.map((item: any) => {
                return (
                  <li key={item._id} className={item.isRead ? '' : 'active'} onClick={()=>{readNotifications(item)}}>
                    <div className="wrap">
                      <strong>{item.event}</strong>
                      <span>{moment(item.createdAt).format('DD MMMM YYYY h:mma')}</span>
                    </div>
                    <p>{item.module}</p>
                  </li>
                )
              })}
            </ul>
          ) : isLoading ? null : <p>No notification for You</p>}

        {notificationList.totalItems > 1 && (
        <div className="mt-3">
          <Pagination
            totalItems={notificationList.totalItems}
            itemsPerPage={10}
            currentPage={params.page || 1}
            onPageChange={page => setParams({ ...params, page })}
          />
        </div>
      )}
  </div>
  )
};

export default Notification;
