import React, { useState, useEffect } from 'react';
import moment from 'moment';
import '@lipihipi/theme';
import { ActionButton, Button, ListItemAction, Menu, PageHeader, PaginatedTable, Tab, Tabs } from '@lipihipi/ec-ui';
import EditLiveModal from './edit-live';
import { JoinModal } from '../../components/join-modal';
import VideoUploadModal from './videoUpload'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { confirmAlert } from 'react-confirm-alert';
import { saveAs } from 'file-saver';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ResourceDetail from '../../components/ResourceDetails';
import { commonApiError } from '../create/index';

interface IListSession {
  user: any;
  getSessions: any;
  onAdd: any;
  onEdit: any;
  joinSession: any;
  uploadFile: any;
  onCancelRequest: any;
  videoUpload: any;
  afterSuccessVideoUpload: any;
  deleteSession: any;
  currentModulePermission?: any[];
  onResourceClick: any;
}

const formatSessionDate = (sDate: any, sessionTime: any) => {
  const datestr =
    moment(`${moment(sDate).format('YYYY-MM-DD')} ${sessionTime.hh}:${sessionTime.mm}:00`);
  return datestr;
}

const prettyTimefromNow = (dateTime: Date) => {
  const diff = moment(dateTime).diff(moment(new Date()));
  const duration = moment.duration(diff);
  const dur = {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
  if (dur.days > 0) {
    return `${dur.days} days`;
  } else if (dur.hours > 0) {
    return `${dur.hours} hours`;
  } else if (dur.minutes > 0) {
    return `${dur.minutes} mins`;
  } else {
    return `${dur.seconds} secs`;
  }
};

const JoinColumn = ({
  user,
  session,
  onClickJoin,
  onEdit,
}: any) => {
  const startDate: any = formatSessionDate(session?.sessionDate, session?.sessionStartTime);
  const remainingTime: any = moment.duration(startDate.diff(moment())).asMinutes();
  return (
    <>
      {!session?.meeting_number || remainingTime > 5 ? (
        (user?.role === "admin" || user?.role === "superadmin") && (
          <div style={{ color: 'red', textAlign: "center" }} onClick={() => onEdit(session?._id)}> Edit </div>
        )
      ) : (
        <Button shape="primary" onClick={onClickJoin}>Join</Button>
      )}
      {!session?.meeting_number || remainingTime > 0 ? (
        <div className="primary-text mt-1">
          Time Rem : {prettyTimefromNow(startDate)}
        </div>
      ) : (
        <div className="primary-text mt-1">
          Streaming Now
        </div>
      )}
    </>
  )
};

const ListSession = ({
  user,
  getSessions,
  onEdit,
  onAdd,
  joinSession,
  uploadFile,
  onCancelRequest,
  videoUpload,
  afterSuccessVideoUpload,
  deleteSession,
  currentModulePermission,
  onResourceClick
}: IListSession) => {
  const [params, setParams] = useState<any>({
    page: 1,
    perPage: 10,
    type: 'pending'
  });

  const [sessions, setSessions] = useState<any>({
    sessions: [],
    totalItems: 0,
  });
  const [selectedSession, setSelectedSession] = useState(null);

  const [selectedSessionVideo, setSelectedSessionVideo] = useState(null);

  const [isOpen, setIsOpen] = useState<{
    visible: boolean;
  }>({
    visible: false,
  });;

  const [modal, setModal] = React.useState<{
    visible: boolean;
    _id?: string;
  }>({
    visible: false,
  });

  const [state, setState] = useState('Upcoming Classes');

  const tabChange = (input: string) => {
    setState(input);
    var type;
    if (input == 'Past Classes') {
      type = 'done'
    }
    else {
      type = 'pending'
    }
    setParams({ ...params, type: type })
  };

  const handleOpen = () => {
    setModal({ visible: true });
  };
  const handleClose = () => {
    setModal({ visible: false });
  };

  const handleVideoUpload = (data: any) => {
    setSelectedSessionVideo(data)
  };

  const handleVideoView = (path: any) => {
    onResourceClick(path);
  };

  const changeDateFormat = (date: any) => {
    var formatDate = "";
    if (date) {
      formatDate = date.toISOString().slice(0, 10)
      return formatDate
    }
    return formatDate;
  }

  const handleFilterData = (data: any) => {
    if (data != undefined) {
      var startDate = changeDateFormat(data?.startDate);
      var endDate = changeDateFormat(data?.endDate);
      setParams({ ...params, topic: data?.topics, batch: data?.batch, startDate: startDate, endDate: endDate, classTitle: data?.classTitle });
    }
    else {
      const { topic, batch, startDate, endDate, ...rest } = params
      setParams({ rest });
    }
  }

  const handleDeleteSession = (data: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you really want to delete this session? This process cannot be undone.',
      buttons: [
        {
          label: 'Delete',
          onClick: () => {
            deleteSession(data._id);
            setParams({ ...params })
          }
        },
        {
          label: 'Cancel',
          onClick: () => { }
        }
      ]
    });
  };

  const getData = () => {
    getSessions({ ...params, populate: true }).then(
      (response: any) => {
        setSessions(response.data);
      }
    )
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  };
  useEffect(getData, [params]);

  return (
    <section className="main-structure">
      {/* {currentModulePermission?.includes('read') ? ( */}
      <>
        <div className="row">
          <div className="col-md-12">
            <PageHeader
              title={'Live Class Planning'}
              breadCrumbs={[
                { link: '/', title: 'Home' },
                { link: '/admin', title: 'Live Classes' },
              ]}
              component={
                <div style={{ display: 'flex', marginLeft: 'auto' }}>
                  {currentModulePermission?.includes('create') && (
                    <Button shape="primary" onClick={() => onAdd()}>New Live Session</Button>
                  )}
                  <Button shape="popup" className="ml-3" onClick={() => handleOpen()}>
                    Filter
                  </Button>
                </div>
              }
            />

            <Tabs active={state} onClick={tabChange}>
              <Tab id={'Upcoming Classes'} title={'Upcoming Classes'}>
                <PaginatedTable
                  data={sessions.sessions}
                  columns={[
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="status approved">{moment(data.sessionDate).format('DD/MM/YYYY')}</div>
                          <div className="primary-text">{moment(data.sessionDate).format('dddd')}</div>
                          <div className="primary-text" style={{ color: (data.isFree || data.openToAll) ? 'red' : 'teal', fontWeight: 'bold' }}>
                            {(data.isFree || data.openToAll) ? 'Free' : 'Paid'}
                          </div>
                        </>
                      ),
                      title: 'Date & Day',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="primary-text">
                            {data.batches.map((value: any) => {
                              return value.name.length ? value.name : ''
                            }).join(', ')}
                          </div>
                        </>
                      ),
                      title: 'Batch Name',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="primary-text">{data?.educator[0]?.name}</div>
                        </>
                      ),
                      title: 'Educator Name',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          {/* <div className="primary-text">{data.subject.name}</div> */}
                          <div className="primary-text">{data.topics.map((e: any) => e.name).join(',')}</div>
                          <div className="primary-text">{data.sessionName}</div>
                        </>
                      ),
                      title: 'Topic/ Title',
                      width:
                        'calc(100% - (15% + 10% + 10% + 10% + 10%  + 10% + 10%))',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="primary-text">{Math.floor(data.sessionInterval / 60)} hr {data.sessionInterval % 60} mins</div>
                        </>
                      ),
                      title: 'Duration',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="primary-text">
                            {moment(data?.sessionStartTime.hh + ':' + data?.sessionStartTime.mm, "HH:mm").format("LT")}
                          </div>
                        </>
                      ),
                      title: 'Start',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <JoinColumn
                          user={user}
                          session={data}
                          onClickJoin={() => setSelectedSession(data)}
                          onEdit={onEdit}
                        />
                      ),

                      width: '13%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <ListItemAction>
                          <ActionButton>
                            {currentModulePermission?.includes('delete') ? <BsThreeDotsVertical /> : ""}
                          </ActionButton>
                          <Menu>
                            <li onClick={() => handleDeleteSession(data)}>Delete</li>
                          </Menu>
                        </ListItemAction>
                      ),
                      title: '',
                      width: '12%',
                    }
                  ]}
                  totalItems={sessions.totalItems}
                  onPageChange={(page: any) => {
                    setParams({ ...params, page: page });
                  }}
                  itemsPerPage={10}
                  currentPage={params.page || 1}
                />
              </Tab>
              <Tab id={'Past Classes'} title={'Past Classes'}>
                <PaginatedTable
                  data={sessions.sessions}
                  columns={[
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="status approved">{moment(data.sessionDate).format('DD/MM/YYYY')}</div>
                          <div className="primary-text">{moment(data.sessionDate).format('dddd')}</div>
                          <div className="primary-text" style={{ color: (data.isFree || data.openToAll) ? 'red' : 'teal', fontWeight: 'bold' }}>
                            {(data.isFree || data.openToAll) ? 'Free' : 'Paid'}
                          </div>
                        </>
                      ),
                      title: 'Date & Day',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <div className="primary-text">
                          {data.batches.map((value: any) => {
                            return value.name.length ? value.name : ''
                          }).join(', ')}
                        </div>
                      ),
                      title: 'Batch Name',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="primary-text">{data?.educator?.name}</div>
                        </>
                      ),
                      title: 'Educator Name',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          {/* <div className="primary-text">{data.subject.name}</div> */}
                          <div className="primary-text">{data.topics.map((e: any) => e.name).join(',')}</div>
                          <div className="primary-text">{data.sessionName}</div>
                        </>
                      ),
                      title: 'Topic/ Title',
                      width:
                        'calc(100% - (15% + 10% + 10% + 10% + 10%  + 10% + 10%))',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="primary-text">{Math.floor(data.sessionInterval / 60)} hr {data.sessionInterval % 60} mins</div>
                        </>
                      ),
                      title: 'Duration',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          <div className="primary-text">
                            {moment(data?.sessionStartTime.hh + ':' + data?.sessionStartTime.mm, "HH:mm").format("LT")}
                          </div>
                        </>
                      ),
                      title: 'Start',
                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          {!data.recordedFile && !data.pdfRecordedFile ? <p className="primary-text">No File Exist</p> :
                            (user?.role === "educator" ?
                              (<a className="status approved" target='_blank' onClick={() => handleVideoView(data)}>
                                View File
                              </a>) : <p className="primary-text">File Exist</p>)}

                        </>
                      ),

                      width: '10%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <>
                          {(user?.role === "admin" || user?.role === "superadmin") && (
                            <div style={{ color: 'red', textAlign: "center" }} onClick={() => onEdit(data?._id)}> Edit </div>
                          )}
                        </>
                      ),
                      title: '',
                      width: '5%',
                    },
                    {
                      dataRenderer: (data: any) => (
                        <ListItemAction>
                          {user?.role !== "educator" ?
                            <ActionButton>
                              {user?.role !== "educator" ? <BsThreeDotsVertical /> : ""}
                            </ActionButton> : ""}
                          {user?.role !== "educator" ?
                            <Menu>
                              {!data.recordedFile ? "" : <li onClick={() => handleVideoView(data.recordedFile)} >View File</li>}
                              {!data.pdfRecordedFile ? "" : <li onClick={() => handleVideoView(data.pdfRecordedFile)} >View PDF File</li>}
                              <li onClick={() => handleVideoUpload(data)}>Edit/Upload</li>
                            </Menu> : ""}
                        </ListItemAction>
                      ),
                      title: '',
                      width: '10%',
                    }
                  ]}
                  totalItems={sessions.totalItems}
                  onPageChange={(page: any) => {
                    setParams({ ...params, page: page });
                  }}
                  itemsPerPage={10}
                  currentPage={params.page || 1}
                />
              </Tab>
            </Tabs>
          </div>
          <div className="col-md-3">
            <EditLiveModal
              _id={modal._id}
              isOpen={modal.visible}
              onRequestClose={handleClose}
              onRequestOpen={handleOpen}
              filterData={handleFilterData}
            />
          </div>
          <div className="col-md-3">
            <VideoUploadModal
              isOpen={!!selectedSessionVideo}
              onRequestClose={() => setSelectedSessionVideo(null)}
              uploadFile={uploadFile}
              onCancelRequest={onCancelRequest}
              videoUpload={videoUpload}
              sessionId={selectedSessionVideo}
              afterSuccessVideoUpload={afterSuccessVideoUpload}
            />
          </div>
        </div>
        {
          selectedSession && (
            <JoinModal
              isOpen={!!selectedSession}
              onRequestClose={() => setSelectedSession(null)}
              session={selectedSession}
              joinSession={joinSession}
            />

          )
        }
      </>
      {/* ) :
        (
          <div>
            <h4>Sorry!, you don't have permission to see this.</h4>
          </div>
        )} */}
    </section >
  );
};

export default ListSession;
