import React, { useState } from 'react';
import { PageHeader, Button, Loader } from '@lipihipi/ec-ui';
import { ISupportRepliesProps } from './replies.types';
import { ISupport } from '@lipihipi/client-sdk/dist/support';
import { Form, TextArea, CheckBox } from '@lipihipi/form';
import { MdDoneAll, MdCancel } from 'react-icons/md';
import { GrAttachment } from 'react-icons/gr';
import moment from 'moment';
import DisplayImage from './displayImage.modal';
import { RepliesSchema } from '../support.schema';

const styles = {
  color: '#00A689',
};

export const SupportReplies = ({
  ticketId,
  onCreate,
  getSupportChat,
  isAdmin,
  breadCrumbs,
  getAssetUrl,
  createAsset,
}: ISupportRepliesProps) => {
  const initialValues = {
    text: '',
    tickets: [],
    displayPicture: '',
  };

  const [supportTicketMessage, setSupportTicketMessage] = useState<any>(
    initialValues
  );
  const [modalState, setModalState] = useState<any>({
    isOpen: false,
    modalUrl: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  React.useEffect(() => {
    if (ticketId && getSupportChat) {
      getSupportChat(ticketId, { populate: true }).then(({ data }: any) => {
        setIsLoading(false);
        setSupportTicketMessage(data);
      });
    }
  }, [ticketId, getSupportChat]);

  const handleFileSelect = (e: any) => {
    setIsLoading(true);
    createAsset({ file: e.target.files[0] }).then((res: any) => {
      setIsLoading(false);
      setSupportTicketMessage((prevState: any) => ({
        ...prevState,
        displayPicture: res?.data?.key,
      }));
    });
  };

  const removeFile = () => {
    setSupportTicketMessage((prevState: any) => ({
      ...prevState,
      displayPicture: '',
    }));
  };

  const handleSubmit = (values: ISupport, { resetForm }: any) => {
    values.ticketId = ticketId;
    values.displayPicture = supportTicketMessage.displayPicture;
    supportTicketMessage.tickets.map((category: any) => {
      return (values.category = category.category);
    });
    setIsLoading(true);
    onCreate(values).then(() => {
      if (ticketId && getSupportChat) {
        getSupportChat(ticketId, { populate: true }).then(({ data }: any) => {
          setIsLoading(false);
          setSupportTicketMessage(data);
        });
      }
    });
    resetForm({ values: initialValues });
    removeFile();
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="main-mid">
        <PageHeader
          title={`${ticketId}` || `Support Ticket Replies`}
          breadCrumbs={
            breadCrumbs
              ? breadCrumbs(ticketId)
              : [
                  { title: 'Support', link: '/support' },
                  {
                    title: `${ticketId}`,
                    link: `/support/${ticketId}`,
                  },
                ]
          }
        />

        <div className="avtar-with-text">
          <div
            className="wrap p-3"
            style={{ width: '100%', maxWidth: '700px' }}
          >
            {supportTicketMessage.tickets.map((chat: any) => {
              const date = new Date(chat.createdAt);
              return (
                <>
                  <div className="wrap" key={chat._id}>
                    <p className="avtar-name mb-2" style={styles}>
                      {chat.addedBy.name} :
                    </p>
                    <p
                      className="avtar-email text-dark"
                      style={{
                        textAlign: 'right',
                        fontSize: '12px',
                        marginTop: '-10px',
                      }}
                    >
                      {moment(date).fromNow()}
                    </p>

                    {chat?.displayPicture ? (
                      <img
                        width="150px"
                        src={getAssetUrl(chat?.displayPicture)}
                        alt="image"
                        style={{
                          borderRadius: '10px',
                          margin: '10px 0',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setModalState({
                            isOpen: true,
                            modalUrl: getAssetUrl(chat?.displayPicture),
                          });
                        }}
                      />
                    ) : null}

                    {/* <p
                    className="avtar-email text-secondary mb-3"
                    style={{ width: '85%' }}
                  >
                    {chat.text}
                  </p> */}
                    <div
                      className="avtar-email text-secondary mb-3"
                      style={{ width: '85%', fontSize: '13px' }}
                      dangerouslySetInnerHTML={{
                        __html: chat?.text,
                      }}
                    ></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div>
          {supportTicketMessage?.tickets[0]?.status !== 'CLOSED' ? (
            <Form
              initialValues={{ text: '' }}
              onSubmit={handleSubmit}
              className="mt-3"
              style={{ width: '100%', maxWidth: '700px' }}
              validationSchema={RepliesSchema}
            >
              <TextArea
                className="wrap"
                name="text"
                id="text"
                placeholder="Type Message Here..."
                style={{
                  marginBottom: '10px',
                }}
              />

              <div className="row">
                <div className="col-md-6">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="primary-upload-ui">
                      <input
                        accept="image/.png,image/.jpg,image/*.jpeg,image/*"
                        type="file"
                        // disabled={!!complaints.file}
                        onChange={handleFileSelect}
                        id="upload"
                      />
                      <label htmlFor="upload">
                        <GrAttachment />
                      </label>
                    </div>
                    <p
                      className="text-secondary"
                      style={{ paddingLeft: '10px', fontSize: '14px' }}
                    >
                      Attach Image
                    </p>
                  </div>
                  <div className="my-3">
                    {supportTicketMessage?.displayPicture && (
                      <div className="modal-image-box d-flex">
                        {
                          <img
                            width="180px"
                            src={getAssetUrl(
                              supportTicketMessage?.displayPicture
                            )}
                            alt="image"
                            style={{ borderRadius: '10px' }}
                          />
                        }
                        <span className="cross mx-2" onClick={removeFile}>
                          <MdCancel color="#000" size="20px" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {/* <Button className="btn-primary" shape="primary" type="submit">
                  Send
                </Button> */}
                <Button
                  shape={'primary'}
                  className={isLoading ? 'loading-state ' : ' '}
                  type="submit"
                >
                  {isLoading ? <span className="loader"></span> : 'Send'}
                </Button>
                {isAdmin ? (
                  <CheckBox
                    id="isResolved"
                    label="Mark Resolved"
                    name="isResolved"
                  />
                ) : (
                  ''
                )}
              </div>
            </Form>
          ) : (
            <div
              style={{
                color: '#00A689',
                fontSize: '24px',
                textTransform: 'uppercase',
                textAlign: 'center',
                width: '100%',
                maxWidth: '700px',
              }}
              className="font-weight-bolder"
            >
              Issue Resolved
              <MdDoneAll className="mx-2" />
            </div>
          )}
        </div>
        <DisplayImage
          isOpen={modalState.isOpen}
          url={modalState.modalUrl}
          onRequestClose={() => setModalState({ isOpen: false, modalUrl: '' })}
        />
      </section>
    </>
  );
};
