import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { ImFileText2 } from 'react-icons/im';
import { HiOutlineLightBulb } from 'react-icons/hi';
import moment from 'moment';
import { Loader } from '@lipihipi/ec-ui';
import EducrackAPI from '@lipihipi/client-sdk';

export const EnrolledBatches = ({
  purchaseBatch,
  onDetailClick,
  getBatch,
  isLoading,
  setIsLoading,
}: any) => {
  const [batchData, setBatchData] = useState<any>({});
  const [visible, setVisible] = useState<any>(10);

  const loadMore = () => {
    setVisible(visible + 10);
  };

  const _batchData = (id: any) => {
    getBatch(id, { populate: true }).then(({ data }: any) => {
      setIsLoading(false);
      const newBatchData = { ...batchData };
      newBatchData[id] = data;
      setBatchData(newBatchData);
    });
  };

  const testData = (id: any) => {
    let combineTest: any = [];
    let values: any;
    batchData[id]?.program?.testBundles.map((item: any) => {
      //@ts-ignore
      EducrackAPI.testBundleV2.get(item, { populate: true, batch: id }).then(({ data }: any) => {
        values = data?.data?.tests;
        combineTest = [...combineTest, ...values];
      })
    })
    return [...combineTest];
  };

  const attemptedTests = (arr1: any[], arr2: any[]) => {
    let result = [];
    result = arr1.filter((item: any) => {
      return arr2.find((element: any) => {
        return (
          element?.refId?._id === item?.test &&
          (item?.status === 'FINISHED' || item?.status === 'IN PROGRESS')
        );
      });
    });
    return result?.length;
  };

  const batchTestPercents = (id: any) => {
    const totalTests = (testData(id)).length || 0;
    const batchTestAttempts = batchData[id]?.testAttempts || [];
    const batchTests = testData(id) || [];
    const result =
      attemptedTests(batchTestAttempts, batchTests) / totalTests || 0;
    return result;
  };

  const batchTestRatio = (id: any) => {
    const totalTests = (testData(id)).length || 0;
    const batchTestAttempts = batchData[id]?.testAttempts || [];
    const batchTests = testData(id) || [];

    const testAttempt = attemptedTests(batchTestAttempts, batchTests);

    return `${testAttempt || 0}/${totalTests || 0}`;
  };

  const batchTestDetails = (id: any) => {
    const batchTestAttempts = batchData[id]?.testAttempts || [];
    const batchTests = testData(id) || [];

    return attemptedTests(batchTestAttempts, batchTests);
  };

  const lessonPercent = (id: any) => {
    let totalCount = 0;
    const a =
      batchData[id]?.courseware?.length > 0 &&
      batchData[id]?.courseware?.map(
        (v: any) => (totalCount += v?.refId?.files?.length || 0)
      );
    console.log(a);

    if (totalCount >= batchData[id]?.syllabus?.length) {
      return batchData[id]?.lessonAttended || 0 / totalCount || 0;
    } else {
      return (
        batchData[id]?.lessonAttended ||
        0 / batchData[id]?.syllabus?.length ||
        0
      );
    }
  };

  const lessonRatio = (id: any) => {
    let totalCount = 0;
    const a =
      batchData[id]?.syllabus?.length > 0 &&
      batchData[id]?.syllabus?.map(
        (v: any) => (totalCount += v?.refId?.files?.length || 0)
      );
    console.log(a);
    if (totalCount >= batchData[id]?.syllabus?.length) {
      return `${batchData[id]?.lessonAttended || 0}/${totalCount || 0}`;
    } else {
      return `${batchData[id]?.lessonAttended || 0}/${batchData[id]?.syllabus
        ?.length || 0}`;
    }
  };

  const lessonDetails = (id: any) => {
    let totalCount = 0;
    const a =
      batchData[id]?.syllabus?.length > 0 &&
      batchData[id]?.syllabus?.map(
        (v: any) => (totalCount += v?.refId?.files?.length || 0)
      );
    console.log(a);
    if (totalCount >= batchData[id]?.syllabus?.length) {
      return `Attended ${batchData[id]?.lessonAttended ||
        0} classes, Total classes ${totalCount || 0} classes`;
    } else {
      return `Attended ${batchData[id]?.lessonAttended ||
        0} classes, Total classes ${batchData[id]?.syllabus?.length ||
        0} classes`;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {purchaseBatch?.batches?.length > 0 && (
        <div className="lesson-wrapping">
          <p>List of Enrolled Batches</p>

          <div className="batch-accordion test-accordian" data-name="batches">
            {purchaseBatch?.batches?.slice(0, visible)?.map((batch: any) => {
              return (
                <Accordion key={batch?._id} data-batch-id={batch?.batchId?._id}>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      onClick={() => _batchData(batch?.batchId?._id)}
                    >
                      <p className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items--md-center pr-4">
                        <span style={{ fontSize: '15px' }}>
                          {batch?.batchId?.name}
                        </span>
                        <span>
                          <span
                            style={{ color: '#00a795', fontSize: '12px' }}
                            className="mr-3"
                          >
                            {batch?.batchId?.batchType === 'recorded'
                              ? 'Recorded'
                              : 'Remote'}
                          </span>
                          <span style={{ fontSize: '12px' }}>
                            {batch?.createdAt
                              ? moment(batch?.createdAt).format(
                                'DD MMMM YYYY, h:mma'
                              )
                              : '--'}
                          </span>
                        </span>
                      </p>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body style={{ padding: '0 17px 17px' }}>
                        {/* <div className="my-progress d-none">
                            <div className="my-progress--name">
                              <span>
                                <BsCalendar />
                              </span>
                              <p>Remote Class</p>
                            </div>
                            <div className="my-progress--bar">
                              <div className="primary-progress-bar">
                                <div className="wrap">
                                  <div
                                    className="bar-line"
                                    style={{ width: '40%' }}
                                  >
                                    Line
                                  </div>
                                </div>
                                <span>76/108</span>
                              </div>
                              <p>
                                Total classes 108, Present in 76 classes and
                                Absent in 32 classes
                              </p>
                            </div>
                            <div className="my-progress--action">
                              <a href="/">View Details</a>
                            </div>
                          </div> */}

                        {batchData && (
                          <>
                            <div className="my-progress">
                              <div className="my-progress--name">
                                <span>
                                  <ImFileText2 />
                                </span>
                                <p>Tests</p>
                              </div>
                              <div className="my-progress--bar">
                                <div className="primary-progress-bar">
                                  <div className="wrap">
                                    <div
                                      className="bar-line"
                                      style={{
                                        width: `calc( ${batchTestPercents(
                                          batch?.batchId?._id
                                        )}*100%)`,
                                      }}
                                    >
                                      Line
                                    </div>
                                  </div>
                                  <span>
                                    {batchTestRatio(batch?.batchId?._id)}
                                  </span>
                                </div>
                                <p>
                                  {batchTestDetails(batch?.batchId?._id)}{' '}
                                  attended out of total{' '}
                                  {
                                    batchData[batch?.batchId?._id]?.tests
                                      ?.length
                                  }{' '}
                                  Tests assigned to you
                                </p>
                              </div>
                              <div className="my-progress--action">
                                <a
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    onDetailClick(batch?.batchId?._id, 'Test');
                                  }}
                                >
                                  View Details
                                </a>
                              </div>
                            </div>

                            <div className="my-progress">
                              <div className="my-progress--name">
                                <span>
                                  <HiOutlineLightBulb />
                                </span>
                                <p>Recorded Lessons</p>
                              </div>
                              <div className="my-progress--bar">
                                <div className="primary-progress-bar">
                                  <div className="wrap">
                                    <div
                                      className="bar-line"
                                      style={{
                                        width: `calc(${lessonPercent(
                                          batch?.batchId?._id
                                        )}*100%)`,
                                      }}
                                    >
                                      Line
                                    </div>
                                  </div>
                                  <span>
                                    {lessonRatio(batch?.batchId?._id)}
                                  </span>
                                </div>
                                <p>{lessonDetails(batch?.batchId?._id)}</p>
                              </div>
                              <div className="my-progress--action">
                                <a
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    onDetailClick(
                                      batch?.batchId?._id,
                                      'Lessons'
                                    );
                                  }}
                                >
                                  View Details
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              );
            })}
          </div>
          {visible < purchaseBatch?.batches?.length && (
            <div
              className="text-center"
              style={{
                fontSize: '15px',
                lineHeight: '18px',
                cursor: 'pointer',
                marginTop: '20px',
                color: '#00a689',
              }}
            >
              <p onClick={loadMore} id="batch-viewMore">
                View More
              </p>
            </div>
          )}
          {visible >= purchaseBatch?.batches?.length && visible > 10 && (
            <div
              className="text-center"
              style={{
                fontSize: '15px',
                lineHeight: '18px',
                cursor: 'pointer',
                marginTop: '20px',
                color: '#00a689',
              }}
            >
              <p onClick={() => setVisible(10)} id="viewLess">
                View less
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};
