import React from 'react';

// Components
import { DashboardLayoutProps } from './dashboard-layout.types';
// import { Header } from './../header/header';
// import { Sidebar } from './../../components/sidebar/sidebar';
// import { RewardPoints } from './../../components/reward-points/reward-points';
// import { AdvertisingBanner } from './../../components/advertising-banner/advertising-banner';

export const DashboardLayout = ({
  className,
  children,
  activeDropdownValue,
  dropdownValues,
  handleDropdownChange,
  ...rest
}: DashboardLayoutProps) => {
  return (
    <div {...rest}>{children}</div>
    // <div className="d-flex flex-wrap">
    //   <div className="main-mid">
    //     <div {...rest}>{children}</div>
    //   </div>
    // </div>
    // <React.Fragment>
    //   {/* <Header
    //     activeDropdownValue={activeDropdownValue}
    //     dropdownValues={dropdownValues}
    //     handleDropdownChange={handleDropdownChange}
    //     showRightContent
    //   /> */}

    //   <div className="container">
    //     <div className="row no-gutters">
    //       {/* <div className="col-auto pr-4">
    //         <Sidebar />
    //       </div> */}

    //       <div className="col">
    //         <div {...rest}>{children}</div>
    //       </div>

    //       {/* <div className="col-auto pt-4">
    //         <RewardPoints />
    //         <AdvertisingBanner>Advertising Banner</AdvertisingBanner>
    //       </div> */}
    //     </div>
    //   </div>
    // </React.Fragment>
  );
};
