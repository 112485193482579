import React, { useState, useEffect } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiX } from 'react-icons/bi';
import { FiThumbsUp, FiMessageSquare } from 'react-icons/fi';
import adminUser from './images/default-user.png';
import user from './images/user.png';
import moment from 'moment';
import { ActionButton, ListItemAction, Menu } from '@lipihipi/ec-ui';
import Swal from 'sweetalert2';

const CommentSection = ({
  createComment,
  type,
  item,
  User,
  removeComment,
  getAssetUrl,
}: any) => {
  const [txt, setTxt] = useState<string>('');
  const [commentsArr, setcommentsArr] = useState<any>(item.comments);

  const [visible, setVisible] = useState<any>(item?.comments?.slice(0, 2));
  const removeCommentHandler = (id: string) => {
    removeComment(id).then(() => {
      setcommentsArr(commentsArr.filter((i: any) => i._id !== id));
    });
  };

  const savehandler = () => {
    createComment({
      text: txt,
      ...(type === 'post' && { wall: item._id }),
    }).then((res: any) => {
      setcommentsArr([
        {
          text: txt,
          _id: res.data._id,
          addedBy: { name: User.name, _id: User._id },
        },
        ...commentsArr,
      ]);
      setVisible([
        {
          text: txt,
          _id: res.data._id,
          addedBy: { name: User.name, _id: User._id },
        },
        ...commentsArr,
      ]);
      setTxt('');
    });
  };

  const showAll = () => {
    setVisible(commentsArr);
  };
  const hideAll = () => {
    setVisible(commentsArr.slice(0, 2));
  };
  return (
    <>
      <div className="post-card--footer">
        <div className="added-comment">
          {visible.map((i: any) => (
            <div className="wrap">
              <img
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '50%',
                }}
                src={
                  i?.addedBy?.displayPicture && i?.addedBy?.displayPicture
                    ? getAssetUrl(i?.addedBy?.displayPicture)
                    : adminUser
                }
                alt="user"
              />
              <div className="comment">
                <span>{i?.addedBy?.name}</span>
                <p>{i.text}</p>
              </div>
              {User.role === 'admin' ||
                (User.role === 'superadmin' && User._id === i?.addedBy?._id && (
                  <div
                    className="comment-remove"
                    onClick={() => removeCommentHandler(i._id)}
                  >
                    <FaTrashAlt />
                  </div>
                ))}
            </div>
          ))}
          {commentsArr.length <= 2 ? (
            ''
          ) : visible.length == 2 ? (
            <div className="text-center">
              <a href="javascript:void(0)" onClick={showAll}>
                View all
              </a>
            </div>
          ) : (
            <div className="text-center">
              <a href="javascript:void(0)" onClick={hideAll}>
                Hide all
              </a>
            </div>
          )}
        </div>

        <div className="add-comment">
          <img src={user} alt="user" />

          <input
            placeholder={type === 'post' ? 'Write a comment' : 'Answer now'}
            value={txt}
            onChange={(e: any) => setTxt(e.target.value)}
          />

          {txt.length !== 0 && (
            <div className="comment-action">
              <span onClick={() => setTxt('')}>
                <BiX />
              </span>
              <span onClick={savehandler}>
                <FaTelegramPlane />
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="wallpost-card_comment">
        <div className="comment-text-wrapper"></div>
        <div className="comment-input-box">
          <div className="comment-user-box"></div>
        </div>
      </div>
    </>
  );
};

const WallPost = ({
  item,
  courseName,
  // subjects,
  removePost,
  User,
  currentModulePermission,
  createWallResponse,
  updateWallResponse,
  type,
  wallArr,
  setWallArr,
  getAssetUrl,
  updateWall,
  createComment,
  removeComment,
  showModal,
}: any) => {
  const [selected, setSelected] = useState<any>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [like, setLike] = useState<any>({ value: false, _id: '' });
  const [likeCount, setLikeCount] = useState<number>(0);
  const [commentsArr, setcommentsArr] = useState<any>([]);

  useEffect(() => {
    setLikeCount(item.likes);
    if (item.isResponded && item.response[0].selectedOptions.length !== 0) {
      setSubmitted(true);
      setSelected([...item.response[0].selectedOptions]);
    }
    if (item.isResponded) {
      setLike({ value: !!item.response[0].isLiked, _id: item.response[0]._id });
    }
    setcommentsArr(item.comments);
  }, []);

  const likesHandler = () => {
    console.log(like._id, 'like._id');

    let fn = like._id
      ? updateWallResponse({
          isLiked: !like.value,
          _id: like._id,
        })
      : createWallResponse({
          isLiked: !like.value,
          ...(type === 'post' && { wall: item._id }),
        });
    fn.then((res: any) => {
      setLike({
        value: res.data.isLiked,
        _id: res.data._id,
      });
      setLikeCount(res.data.isLiked ? likeCount + 1 : likeCount - 1);
    });
  };

  const remove = () => {
    removePost(item._id).then(() => {
      setWallArr(wallArr.filter((i: any) => i._id !== item._id));
    });
  };

  const blockPost = (isBlocked: boolean) => {
    updateWall({ _id: item._id, isBlocked: isBlocked }).then((res: any) => {
      const blockedPostArr = wallArr?.map((blockedPost: any) => {
        if (blockedPost?._id === res?.data?._id) {
          return res?.data;
        } else {
          return blockedPost;
        }
      });
      setWallArr(blockedPostArr);
    });
  };

  const selecthandler = (e: any) => {
    if (e.target.checked) {
      setSelected([...selected, e.target.id]);
    } else setSelected(selected.filter((i: any) => i !== e.target.id));
  };

  const handleFormSubmit = () => {
    let fn = item.isResponded
      ? updateWallResponse({
          selectedOptions: selected,
          _id: item.response[0]._id,
        })
      : createWallResponse({
          selectedOptions: selected,
          wall: item._id,
        });
    fn.then(() => setSubmitted(true));
  };

  const quizForm = () => {
    return (
      <div className="wall-quiz">
        {item.options.map((i: any) => (
          <div>
            <div className="wall-checkbox">
              <label
                className="pl-1 text-uppercase text-secondary"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <input
                  type="checkbox"
                  checked={selected.includes(i._id)}
                  id={i._id}
                  disabled={submitted}
                  onChange={selecthandler}
                />
                <span style={{ paddingLeft: '10px' }}>{i.text}</span>
              </label>
            </div>
          </div>
        ))}

        {submitted &&
          item?.options?.map((answer: any) => {
            if (answer?.isAnswer) {
              return (
                <p className="text-secondary">
                  Correct Answer is :{' '}
                  <span style={{ textTransform: 'uppercase' }}>
                    {answer?.text}
                  </span>
                </p>
              );
            }
            return;
          })}

        {!submitted && (
          <button
            className="primary-button"
            disabled={!selected.length}
            onClick={handleFormSubmit}
          >
            Submit
          </button>
        )}
      </div>
    );
  };

  const deletePost = (post: any) => {
    console.log(post);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this file?',
      icon: 'warning',
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        remove();
      } else {
        showModal({
          show: false,
          data: {},
        });
      }
    });
  };

  const buildData = (post: any) => {
    return {
      ...post,
      allowComments: post?.isCommentAllowed || false,
      type: post?.postType || false,
      displayPicture: post?.displayPicture || '',
      options: post?.options,
      course: post?.course?._id,
      description: post?.text,
    };
  };

  return (
    <div className="post-card" id={item?._id}>
      <div className="post-card--header">
        <div className="wrap">
          <img
            src={
              item?.addedBy?.displayPicture
                ? getAssetUrl(item?.addedBy?.displayPicture)
                : User.role === 'admin' || User.role === 'superadmin'
                ? adminUser
                : user
            }
            alt="user"
          />
          <div className="user-info">
            <p
              className="text-secondary"
              style={{ fontSize: '16px', fontWeight: 'bold' }}
            >
              {item?.addedBy?.name}
            </p>
            {courseName?.length ? (
              <span className="text-truncate" style={{ width: '350px' }}>
                {courseName ? `${courseName} | ` : ''}
              </span>
            ) : null}
            <span>{moment(item?.createdAt).calendar()}</span>
          </div>
        </div>
        {User?.role === 'admin' ||
          (User?.role === 'superadmin' && (
            <div className="d-flex align-items-center justify-content-end">
              {item?.isBlocked && (
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: '#ff0000',
                  }}
                >
                  Blocked
                </span>
              )}
              <div className="wall-dropdown">
                <ListItemAction className="action-button">
                  <ActionButton>
                    <BiDotsVerticalRounded />
                  </ActionButton>
                  <Menu
                    style={{
                      padding: '0',
                      borderRadius: '8px',
                      minWidth: '100px',
                      margin: '25px -25px',
                    }}
                  >
                    {currentModulePermission?.includes('update') && (
                      <li
                        onClick={() =>
                          showModal({
                            show: true,
                            data: buildData(item),
                          })
                        }
                        style={{ borderRadius: '8px', fontSize: '12px' }}
                      >
                        Edit Post
                      </li>
                    )}
                    {currentModulePermission?.includes('delete') && (
                      <li
                        style={{
                          borderRadius: '8px',
                          fontSize: '12px',
                        }}
                        onClick={() => deletePost(item)}
                      >
                        Delete Post
                      </li>
                    )}
                    {item?.isBlocked ? (
                      <li
                        style={{
                          borderRadius: '8px',
                          fontSize: '12px',
                        }}
                        onClick={() => blockPost(false)}
                      >
                        Unblock this post
                      </li>
                    ) : (
                      <li
                        style={{
                          borderRadius: '8px',
                          fontSize: '12px',
                        }}
                        onClick={() => blockPost(true)}
                      >
                        Block this post
                      </li>
                    )}
                  </Menu>
                </ListItemAction>
              </div>
            </div>
          ))}
        {/* {User?.role === 'admin' ||
            (User?.role === 'superadmin' && (
              <div className="d-flex align-items-center justify-content-end">
                {item?.isBlocked && (
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      color: '#ff0000',
                    }}
                  >
                    Blocked
                  </span>
                )}
                <div className="wall-dropdown">
                  <ListItemAction className="action-button">
                    <ActionButton>
                      <BiDotsVerticalRounded />
                    </ActionButton>
                    <Menu
                      style={{
                        padding: '0',
                        borderRadius: '8px',
                        minWidth: '100px',
                        margin: '25px -25px',
                      }}
                    >
                      <li
                        onClick={remove}
                        style={{ borderRadius: '8px', fontSize: '12px' }}
                      >
                        Remove Post
                      </li>
                      {item?.isBlocked ? (
                        <li
                          style={{
                            borderRadius: '8px',
                            fontSize: '12px',
                          }}
                          onClick={() => blockPost(false)}
                        >
                          Unblock this post
                        </li>
                      ) : (
                        <li
                          style={{
                            borderRadius: '8px',
                            fontSize: '12px',
                          }}
                          onClick={() => blockPost(true)}
                        >
                          Block this post
                        </li>
                      )}
                    </Menu>
                  </ListItemAction>
                </div>
              </div>
            ))} */}
      </div>

      {item?.displayPicture && (
        <div className="post-card--body">
          {item?.displayPicture?.toString().endsWith('mp4') ? (
            <video
              width="100%"
              controls
              controlsList="nodownload"
              disablePictureInPicture
            >
              <source
                src={getAssetUrl(item?.displayPicture)}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : item?.displayPicture?.toString().endsWith('mp3') ? (
            <div>
              <audio controls style={{ width: '100%' }}>
                <source
                  src={getAssetUrl(item?.displayPicture)}
                  type="audio/mpeg"
                />
                Your browser does not support the audio tag.
              </audio>
            </div>
          ) : (
            <img
              className="wallpost-img"
              src={getAssetUrl(item?.displayPicture)}
            />
          )}
        </div>
      )}

      <div className="post-card--content">
        <div
          style={{ fontSize: '16px' }}
          dangerouslySetInnerHTML={{ __html: item.text }}
        ></div>
        {/*<p style={{ fontSize: '16px' }}>{item.text}</p>*/}
      </div>

      <div className="post-card--action">
        {item.postType === 'quiz' && item.options.length !== 0 && quizForm()}
        <div className="wrap">
          {type === 'post' && (
            <div
              onClick={likesHandler}
              className={`action ${like.value ? 'active' : ''} `}
            >
              <FiThumbsUp />
              <strong>Like</strong>
              <span>{likeCount}</span>
            </div>
          )}
          {item.isCommentAllowed ? (
            <div className="action">
              <FiMessageSquare />
              <strong>Comment</strong>
              <span>{commentsArr.length}</span>
            </div>
          ) : null}
        </div>
      </div>

      {(item.isCommentAllowed || type === 'doubt' || type === 'post') && (
        <CommentSection
          createComment={createComment}
          type={type}
          item={item}
          User={User}
          removeComment={removeComment}
          getAssetUrl={getAssetUrl}
        />
      )}
    </div>
  );
};

export default WallPost;
