import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IDoubtResponseListParams extends IListParams {
  user?: ObjectId;
  populate?: boolean;
  doubt?: ObjectId;
}

export interface IDoubtResponses {
  doubt: ObjectId | PopulatedDocument;
  isLiked: boolean;
  isViewed: boolean;
}
export interface IDoubtUpdateParams extends IDoubtResponses {
  _id: ObjectId;
}

export interface IDoubtResponsesDocument extends IDoubtResponses, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IDoubtResponsesListResponse {
  totalItems: number;
  doubtResponses: IDoubtResponsesDocument[];
}

const list = (doubtId: string, params?: IDoubtResponseListParams) => {
  return client.get<IDoubtResponsesDocument>(
    `/doubts/doubt-responses/${doubtId}`,
    { params }
  );
};

const get = (params?: IDoubtResponseListParams) => {
  return client.get<IDoubtResponsesDocument>(`/doubts/doubt-responses/`, {
    params,
  });
};

const create = (doubtReq: IDoubtResponses) => {
  return client.post<IDoubtResponsesDocument>(
    `/doubts/doubt-responses`,
    doubtReq
  );
};

const update = (doubtReq: IDoubtUpdateParams) => {
  return client.put<IDoubtResponsesDocument>(
    `/doubts/doubt-responses/${doubtReq._id}`,
    doubtReq
  );
};
const getLikesCount = (doubtId?: string) => {
  return client.get<Object>(`/doubts/doubt-likes/${doubtId}`);
};

const getViewsCount = (doubtId: string) => {
  return client.get<Object>(`/doubts/doubt-views/${doubtId}`);
};

export const doubtResponse = {
  get,
  create,
  update,
  list,
  getViewsCount,
  getLikesCount,
};
