import { client } from './client';

import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface ITime {
  hh: string;
  mm: string;
}

export enum meetingStatus {
  'NOTSTARTED',
  'STARTED',
  'FINISHED',
}
export enum status {
  'INIT',
  'PUBLISH',
}
export enum materialType {
  'MATERIAL',
  'NOTES',
}

export interface ISessionMaterial {
  coursewareId: ObjectId | PopulatedUserDocument;
  orderId: number;
  type: materialType;
}

export interface ISessionListParams extends IListParams {
  batch?: ObjectId;
  sessionDate?: Date;
  topic?: ObjectId;
  educator?: ObjectId;
  subject?: ObjectId;
  type?: string;
  openToAll?: boolean;
  isFree?: boolean;
  startDate?: Date;
  endDate?: Date;
  day?: number;
}

export interface ISessionDocument extends IDocument {
  sessionStartTime?: ITime;
  sessionEndTime?: ITime;
  batches?: ObjectId[] | PopulatedDocument[];
  topics?: ObjectId[] | PopulatedUserDocument[];
  status?: status;
  openToAll?: boolean;
  isFree?: boolean;
  sessionName?: string;
  sessionInterval?: Number;
  subject?: ObjectId | PopulatedUserDocument;
  course?: ObjectId | PopulatedUserDocument;
  educator?: ObjectId | PopulatedUserDocument;
  sessionDate?: Date;
  description?: string;
  sessionFiles?: ISessionMaterial[];
  meeting_number?: string;
  meetingStatus?: meetingStatus;
  recordedFile: string;
  pdfRecordedFile: string;
}

export interface ISessionCredential extends IDocument {
  mn?: string;
  pass?: string;
  role?: Number;
  lang?: string;
  china?: Number;
  apiKey?: string;
  signature?: string;
  email?: string;
  name?: string;
  meetingStatus?: meetingStatus;
  joinUrl: string;
}

export interface IVideoFileUpload {
  recordedFile?: string;
  pdfRecordedFile?: string;
}

const list = (params: ISessionListParams) => {
  const url = `/live-sessions`;
  return client.get<ISessionDocument[]>(url, { params });
};

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<ISessionDocument>(`/live-sessions/${id}`, { params });
};

const create = (options: ISessionDocument) => {
  return client.post<ISessionDocument>(`/live-sessions`, options);
};

const update = (session: ISessionDocument) => {
  return client.put<ISessionDocument>(`/live-sessions/${session._id}`, session);
};

const deleteSession = (id: string) => {
  return client.delete<any>(`/live-sessions/${id}`);
};

const joinMeeting = (session: ISessionDocument) => {
  return client.post<ISessionCredential>(`/live-sessions/joinMeeting`, {
    id: session._id,
  });
};
const createMeeting = (session: ISessionDocument) => {
  return client.post<any>(`/live-sessions/createMeeting`, {
    id: session._id,
  });
};

const uploadVideoFile = (
  recordedFile: IVideoFileUpload,
  session: ISessionDocument
) => {
  return client.put<any>(`/live-sessions/${session._id}/upload`, recordedFile);
};

export const session = {
  get,
  create,
  list,
  update,
  deleteSession,
  joinMeeting,
  createMeeting,
  uploadVideoFile,
};

export default session;
