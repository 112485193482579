import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from '../types';
import { IQuestionSetDocument } from '../question-set';

export interface IUserBundleEnrollmentDocument
  extends IUserBundleEnrollment,
    IDocument {
  user: ObjectId | PopulatedUserDocument;
}

export interface IBundleListParams extends IListParams {
  course?: ObjectId;
  user?: ObjectId;
  subject?: ObjectId;
}

export interface IUserBundleEnrollmentListResponse {
  Bundles: IUserBundleEnrollment[];
  totalItems: number;
}

export interface ITestItem {
  displayOrder: number;
  test: string | IQuestionSetDocument;
  isActive: boolean;
}

export interface IUserBundleEnrollment {
  name: string;
  description: string;
  instruction: string;
  displayPicture: string;
  course: string | PopulatedDocument;
  tests: ITestItem[];

  testBundle: string;
  completedTest: number;
  inProgressTest: number;
}

export interface ISubscribeBundle {
  testBundle: string;
  user?: ObjectId;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IUserBundleEnrollmentDocument>(
    `/testv2/user-test-bundles/${id}`,
    {
      params,
    }
  );
};

const create = (BundleEnrollment: ISubscribeBundle) => {
  return client.post<IUserBundleEnrollmentDocument>(
    `/testv2/user-test-bundles`,
    BundleEnrollment
  );
};

const update = (
  id: string,
  BundleEnrollment: IUserBundleEnrollmentDocument
) => {
  return client.put<IUserBundleEnrollmentDocument>(
    `/testv2/user-test-bundles/${id || BundleEnrollment._id}`,
    BundleEnrollment
  );
};

// const remove = (id: string) => {
//   return client.delete(`/user-test-bundles/${id}`);
// };

const list = (params: IBundleListParams) => {
  return client.get<IUserBundleEnrollmentListResponse>(
    `/testv2/user-test-bundles/`,
    {
      params,
    }
  );
};

const report = () => {
  return client.get<any>(`/testv2/user-test-bundles/report`);
};

export const userTestBundle = { get, create, update, list, report };
