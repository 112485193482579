import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export enum ResourceType {
  FILE = 'FILE',
  TEST = 'TEST',
}
export interface IFreeResource {
  title?: string;
  course: string;
  type: ResourceType;
  exam: string;
  subject?: string;
  section?: string;
  topic?: string;
  educator?: string;
  file?: string;
  thumbnail?: string;
  tests?: string;
  examType?: string;
}

export interface IFreeResourceDocument extends IFreeResource, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IFreeResourceListResponse {
  totalItems: number;
  freeResources: IFreeResourceDocument[];
}

const get: TGetResource<IFreeResourceDocument> = (id, params) => {
  return client.get<IFreeResourceDocument>(`/free-resource/${id}`, { params });
};

const create: TCreateResource<IFreeResource, IFreeResourceDocument> = (
  freeResource: IFreeResource
) => {
  return client.post<IFreeResourceDocument>(`/free-resource`, freeResource);
};

const update: TUpdateResource<IFreeResourceDocument> = freeResource => {
  return client.patch(`/free-resource/${freeResource._id}`, freeResource);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/free-resource/${id}`);
};

const list: TListResource<IListParams, IFreeResourceListResponse> = params => {
  return client.get(`/free-resource/`, { params });
};

export const freeResource = { get, create, update, remove, list };
