import React, { FC } from 'react';
import { Input, Form } from '@lipihipi/form';
import { useHistory } from 'react-router-dom';
import { validationSchema } from '../../../utils/validations';

const ResetPasswordForm: FC<any> = (props: any) => {
	const history = useHistory();
	return (
		<div className="user-structure--form">
			<div className="wrap">
			<h2>Reset Password</h2>
        <Form
          initialValues={{ password: ''}}
          validationSchema={validationSchema.password}
          onSubmit={props.handleSubmit}
        >
          <Input
            id="password"
            name="password"
            type="password"
            label="Password"
            required
          />
          <Input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            required
          />

          <div className="button-group">
            <button type="submit" className="btn btn-primary">
              Reset Password
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => history.push('/login')}
            >
              Login
            </button>
          </div>
        </Form>
			</div>
		</div>
	);
};

export default ResetPasswordForm;
