import React, { FC } from 'react';
import { Input, Form } from '@lipihipi/form';
import { useHistory } from 'react-router-dom';
import { validationSchema } from '../../../utils/validations';

const ForgotPasswordForm: FC<any> = (props: any) => {
	const history = useHistory();
	return (
		<div className="user-structure--form">
			<div className="wrap">
			<h2>Forgot Password</h2>
        <Form
          initialValues={{ mobile: ''}}
          validationSchema={validationSchema.forgotPassword}
          onSubmit={props.handleSubmit}
        >
          <Input id="mobile-number" name="mobile" type="tel" label="Mobile Number" maxLength={10} required />
		  
          <div className="button-group">
            <button type="submit" className="btn btn-primary">
              Send OTP
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => history.push('/login')}
            >
              Login
            </button>
          </div>
        </Form>
			</div>
		</div>
	);
};

export default ForgotPasswordForm;
