import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { commonApiError } from '../subscriber-report';

const SubscriberInterview = ({ getInterviews, _id, onInterviewClick }: any) => {
  const [interviews, setInterviews] = useState<any>({
    totalItems: 0,
    userInterview: [],
  });
  const [visible, setVisible] = useState<any>(10);

  const loadMore = () => {
    setVisible(visible + 10);
  };

  useEffect(() => {
    getInterviews({ all: true, populate: true, user: _id })
      .then(({ data }: any) => {
        setInterviews(data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  }, []);

  return (
    <div className="lesson-wrapping">
      <p>List of Enrolled IntAna</p>

      <div className="batch-accordion test-accordian">
        {interviews?.userInterview?.length > 0 ? (
          interviews?.userInterview
            ?.slice(0, visible)
            ?.map((interview: any) => {
              return (
                <Accordion key={interview?._id}>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <p className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items--md-center pr-4">
                        <span style={{ fontSize: '15px' }}>
                          {interview?.interview?.name}
                        </span>
                        <span>
                          <span style={{ fontSize: '12px' }}>
                            {interview?.createdAt
                              ? moment(interview?.createdAt).format(
                                  'DD MMMM YYYY, h:mma'
                                )
                              : '--'}
                          </span>
                        </span>
                      </p>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body style={{ padding: '0 17px 17px' }}>
                        {interview && (
                          <>
                            <div className="my-progress">
                              <div className="my-progress--name">
                                <span>
                                  <HiOutlineLightBulb />
                                </span>
                                <p>Interviews</p>
                              </div>
                              <div className="my-progress--bar">
                                <div className="primary-progress-bar">
                                  <div className="wrap">
                                    <div
                                      className="bar-line"
                                      style={{
                                        width: `calc(${interview?.attended?.length}/${interview?.interview?.noOfLinks}*100%)`,
                                      }}
                                    >
                                      Line
                                    </div>
                                  </div>
                                  <span>
                                    {interview?.attended?.length || 0}/
                                    {interview?.interview?.noOfLinks || 0}
                                  </span>
                                </div>
                                <p>
                                  {interview?.attended?.length} Interviews
                                  attended out of{' '}
                                  {interview?.interview?.noOfLinks} total
                                  interviews
                                </p>
                              </div>
                              <div className="my-progress--action">
                                <a
                                  // href="javascript:void(0)"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    onInterviewClick(interview?._id)
                                  }
                                >
                                  View Details
                                </a>
                                {/* <span>Score</span> */}
                              </div>
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              );
            })
        ) : (
          <p>No Data Found</p>
        )}
      </div>
      {visible < interviews?.userInterview?.length && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={loadMore}>View More</p>
        </div>
      )}
      {visible >= interviews?.userInterview?.length && visible > 10 && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={() => setVisible(10)}>View less</p>
        </div>
      )}
    </div>
  );
};

export default SubscriberInterview;
