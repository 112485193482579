import React from 'react';
import Styled from 'styled-components';
import { TextAreaProps } from './text-area.types';
import { useField } from 'formik';
import { Label } from '../label/label';
import { ErrorMessage } from '../error/error';
import { useFormikContext } from 'formik';

const CustomTextArea = Styled.textarea`

    &::placeholder{
        color: #919191;
        font-size: 16px;
        font-weight: normal;
    };

    &:focus{
        box-shadow: none;
        border: 1px solid #239F87;
    }
`;

export const TextArea = ({
  id,
  name,
  placeholder,
  label,
  required,
  className,
  style,
  rows,
  cols,
}: TextAreaProps) => {
  const [field, meta] = useField({ name });
  const { submitCount } = useFormikContext();
  const showError = (submitCount > 0 || meta.touched) && meta.error;
  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} required={required} />
      <CustomTextArea
        id={id}
        className={`primary-form-control ${showError && 'is-invalid'}`}
        placeholder={placeholder}
        rows={rows || 4}
        cols={cols || 40}
        {...field}
      />
      <ErrorMessage meta={meta} />
    </div>
  );
};
