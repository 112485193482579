import React, { useState } from 'react';
import {
  PageHeader,
  PaginatedTable,
  ListItemAction,
  ActionButton,
  Menu,
  Loader,
} from '@lipihipi/ec-ui';
import { Form, Select } from '@lipihipi/form';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Styled from 'styled-components';
import { IFreeResourceListResponse } from '@lipihipi/client-sdk/dist/free-resource';
import swal from 'sweetalert';
interface IListing {
  title?: string;
  breadCrumbs?: any[];
  getResources: any;
  getCourses: any;
  getAssetUrl: any;
  onEdit: (id: string) => void;
  onDelete: any;
  getSections: any;
  getTopics: any;
  onCreateResource: any;
  getExams: any;
}

const Avatar = Styled.div<{ url: string }>`
	background-image: url(${({ url }: any) => url});
`;
export const getComponent = (onClick: any, name: string) => {
  return (
    <Form className="add-question" initialValues={{ createResource: '' }}>
      <Select
        id="createFreeResource"
        name="createFreeResource"
        placeholder={name}
        options={[
          { label: 'Test', value: 'test' },
          { label: 'PDF/Video', value: 'file' },
        ]}
        onChange={(event: any) => {
          onClick(event.value);
        }}
      />
    </Form>
  );
};
export const Listing: React.FC<IListing> = ({
  title,
  breadCrumbs,
  getResources,
  getCourses,
  getAssetUrl,
  onEdit,
  onDelete,
  getSections,
  getTopics,
  onCreateResource,
  getExams,
}) => {
  const [resourceList, setResourceList] = React.useState<
    IFreeResourceListResponse
  >({
    totalItems: 0,
    freeResources: [],
  });
  const [params, setParams] = useState<any>({
    page: 1,
    isRecent: true,
    populate: true,
  });
  const [courses, setCourses] = React.useState<any>({
    totalItems: 0,
    courses: [],
  });
  const [sections, setSections] = React.useState<any>({
    totalItems: 0,
    sections: [],
  });
  const [topics, setTopics] = React.useState<any>({
    totalItems: 0,
    topics: [],
  });
  const [exams, setExams] = React.useState<any>({
    totalItems: 0,
    exams: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  React.useEffect(() => {
    getCourseList();
    getSectionnList();
    getTopicList();
    getExamList();
    fetchFreeResource();
  }, []);
  const fetchFreeResource = () => {
    getResources({ ...params, populate: true }).then((res: any) => {
      setIsLoading(false)
      setResourceList(res.data);
    });
  };
  const getCourseList = () => {
    getCourses({ all: true }).then((response: any) => {
      setCourses(response.data);
    });
  };
  const getSectionnList = () => {
    getSections({ all: true }).then((response: any) => {
      setSections(response.data);
    });
  };
  const getTopicList = () => {
    getTopics({ all: true }).then((response: any) => {
      setTopics(response.data);
    });
  };
  const getExamList = () => {
    getExams({ all: true }).then((response: any) => {
      setExams(response.data);
    });
  };

  const mapOptions = (values: any[]) => {
    return values.map(value => ({ label: value?.name, value: value?._id }));
  };

  React.useEffect(fetchFreeResource, [params]);

  const handleSearch = (values: any) => {
    setParams({
      page: 1,
      // q: values.q,
      exam: values.exam,
      course: values.course,
      // educator: values.educator,
      section: values.section,
      topic: values.topic,
      populate: true,
    });
  };

  const onDeleteHandler = (id: any) => {
    setIsLoading(true)

    onDelete(id).then(({ status }: any) => {
      if (status === 204) {
        setIsLoading(false)
        swal({
          title: 'Success',
          text: 'CMS Data Deleted',
          icon: 'success',
        });
        fetchFreeResource();
      } else {
      setIsLoading(false)
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
        });
      }
    }).catch(()=>{
      setIsLoading(false)
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
        });
    });
  };

  return (
    <>
    {isLoading && <Loader />}

    <section className="main-structure">
      <PageHeader
        title={title || 'Manage Free Resources'}
        breadCrumbs={breadCrumbs || [{ title: 'Free Resource' }]}
        component={getComponent(onCreateResource, 'Create Resource')}
      />

      <Form
        initialValues={{
          q: '',
          exam: '',
          course: '',
          section: '',
          topic: '',
        }}
        onSubmit={handleSearch}
        render={({ submitForm }: any) => {
          return (
            <>
              <div className="row">
                <div className="col-4 col-md-4">
                  <Select
                    id="course"
                    name="course"
                    label="Course"
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapOptions(
                        courses?.courses !== undefined ? courses?.courses : []
                      ),
                    ]}
                    onChange={(_: any) => {
                      submitForm();
                    }}
                  />
                </div>

                <div className="col-4 col-md-4">
                  <Select
                    id="exam"
                    name="exam"
                    label="Exam"
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapOptions(
                        exams.exams !== undefined ? exams.exams : []
                      ),
                    ]}
                    onChange={(_: any) => {
                      submitForm();
                    }}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <Select
                    id="section"
                    name="section"
                    label="Section"
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapOptions(
                        sections?.sections !== undefined
                          ? sections?.sections
                          : []
                      ),
                    ]}
                    onChange={(_: any) => {
                      submitForm();
                    }}
                  />
                </div>

                <div className="col-4 col-md-4">
                  <Select
                    id="topic"
                    name="topic"
                    label="Topic"
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapOptions(
                        topics?.topics !== undefined ? topics?.topics : []
                      ),
                    ]}
                    onChange={(_: any) => {
                      submitForm();
                    }}
                  />
                </div>
              </div>
            </>
          );
        }}
      />

      <PaginatedTable
        data={resourceList?.freeResources}
        columns={[
          {
            dataRenderer: (data: any) => (
              <>
                <div className="avtar-with-text">
                  <Avatar
                    className="primary-avtar"
                    // url={'https://educrack-dev.s3.ap-south-1.amazonaws.com/asset-1665577401690.png'}
                    url={getAssetUrl(
                      data?.thumbnail
                        ? data.thumbnail
                        : 'asset-1665577401690.png'
                    )}
                  />
                  <div className="wrap">
                    <div className="primary-text">{data.title}</div>
                  </div>
                </div>
              </>
            ),
            title: 'Title of Resource',
            width: 'calc(100% - (200px + 200px + 200px + 200px + 70px))',
          },
          {
            dataRenderer: (data: any) => (
              <div className="primary-text">{data.exam.name}</div>
            ),
            title: 'Exam',
            width: '270px',
          },
          {
            dataRenderer: (data: any) => (
              <div className="primary-text">
                {data.section ? data.section.name : '-'}
              </div>
            ),
            title: 'Section',
            width: '270px',
          },
          {
            dataRenderer: (data: any) => (
              <div className="primary-text">
                {data.topic ? data.topic.name : '-'}
              </div>
            ),
            title: 'Topic',
            width: '260px',
          },
          {
            dataRenderer: (data: any) => (
              <ListItemAction className="action-button">
                <ActionButton>
                  <BsThreeDotsVertical />
                </ActionButton>
                <Menu>
                  <li onClick={() => onEdit(data?._id)}>Edit</li>
                  <li onClick={() => onDeleteHandler(data?._id)}>Delete</li>
                </Menu>
              </ListItemAction>
            ),
            title: '',
            width: '70px',
          },
        ]}
        totalItems={resourceList?.totalItems}
        onPageChange={(page: any) => {
          setParams({ ...params, page: page });
        }}
        itemsPerPage={10}
        currentPage={params.page || 1}
      />
    </section>
    </>
  );
};
