import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IDoubtDocument extends IDoubt, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}
export interface IOption {
  text: string;
  isAnswer: boolean;
}

export enum answered {
  'answer',
  'not_answer',
  'both',
}

export interface IDoubtListParams extends IListParams {
  topic?: ObjectId;
  // subject?: ObjectId;
  user?: ObjectId;
  populate?: boolean;
  course?: ObjectId;
  educator?: ObjectId;
  startDate: Date;
  endDate: Date;
  filter_answered: answered;
}

export interface IDoubt {
  text?: string;
  displayPicture?: string;
  topic?: ObjectId | PopulatedDocument;
  // subjects?: ObjectId | PopulatedDocument;
  withdraw?: boolean;
  course?: ObjectId | PopulatedDocument;
  educator?: ObjectId | PopulatedDocument;
  thanks?: boolean;
  isBlocked: Boolean;
}

export interface IDoubtDocument extends IDoubt, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}
export interface doubtList extends IDoubtDocument {
  views: number;
  likes: number;
  isCommented: boolean;
  response: any[];
  commentsCount: number;
}
export interface IDoubtListResponse {
  doubts: doubtList[];
}

export interface ICount {
  counts: Number;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IDoubtDocument>(`/doubts/${id}`, { params });
};

const create = (wall: IDoubt) => {
  return client.post<IDoubtDocument>(`/doubts`, wall);
};

const update = (doubt: IDoubtDocument) => {
  return client.put<IDoubtDocument>(`/doubts/${doubt._id}`, doubt);
};

const remove = (id: string) => {
  return client.delete(`/doubts/${id}`);
};

const list = (params: IDoubtListParams) => {
  return client.get<IDoubtListResponse>(`/doubts/`, { params });
};

const appreciations = (educatorId: string) => {
  return client.get<ICount>(`/doubts/appreciations-count/${educatorId}`, {});
};

export const doubt = { get, create, update, remove, list, appreciations };
