import React, { useState, useEffect } from 'react';
import { Form, TextArea, FileUpload } from '@lipihipi/form';
import { Button, Modal } from '@lipihipi/ec-ui';
import { CreateDoubtSchema } from '../index';
import { AiOutlineClose } from 'react-icons/ai';
import { ImWarning } from 'react-icons/im';

// const mapOptions = (values: any[]) => {
//   return values.map(value => ({ label: value.name, value: value._id }));
// };

const CreateDoubt = ({
  isOpen,
  createAsset,
  createDoubt,
  setOpen,
  User,
  doubtArr,
  setDoubtArr,
  selectedCourse,
  // getMyBatchSubjects,
  getMyBatchSubjectTeachers,
  getPurchasedBatchList,
  // getTopicList
}: any) => {
  // const [subjectOptions, setSubjectOptions] = useState<any>([]);
  const [batches, setBatches] = useState<any>([]);
  const [educatorOptions, setEducatorOptions] = useState<any>([]);
  console.log(educatorOptions)
  // const [topics, setTopics] = useState<any>([]);
  useEffect(() => {
    if (selectedCourse) {
      _getMyBatchSubjectTeachers();
      _getPurchasedBatchList();
      // _getTopicList();
    }
  }, [selectedCourse]);

  // const _getTopicList = () => {
  //   getTopicList({
  //     all: true,
  //     populate: true,
  //   }).then(({ data }: any) => {
  //     console.log(data)
  //     setTopics(data.topics);
  //   });
  // };

  const _getMyBatchSubjectTeachers = () => {
    getMyBatchSubjectTeachers({
      course:selectedCourse._id,
      all: true,
      populate: true,
    }).then(({ data }: any) => {
      setEducatorOptions(data.users);
    });
  };
  const _getPurchasedBatchList = () =>{
    getPurchasedBatchList({
      courseId:selectedCourse._id,
      all:true
    }).then(({data}:any)=>{
      // console.log(data)
      setBatches(data)
    });
  };

  // const getTopicHandler = (data: any) => {
  //   const _topics =
  //     topics.find((item: any) => item.topics._id === data.value)
  //       ?.topics || [];
  //   setTopics(_topics);
  // };
  const handleSubmit = (values: any) => {
    const payload = {
      text: values.text,
      displayPicture: values.displayPicture,
      educator: values.educator || '',
      subject: values.subject,
      course: values.course,
    };
    createDoubt(payload).then((res: any) => {
      setOpen(false);
      setDoubtArr([
        {
          ...res.data,
          addedBy: { name: User.name, _id: res.data.addedBy },
          comments: [],
          commentsCount: 0,
          isResponded: false,
          likes: 0,
          response: [],
          views: 0,
        },
        ...doubtArr,
      ]);
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        heightDefault={true}
      >
        {batches?.totalItems > 0 ? (
          <Form
            initialValues={{
              displayPicture: '',
              // subject: '',
              educator: '',
              text: '',
              course: selectedCourse._id,
              // topic:''
            }}
            validationSchema={CreateDoubtSchema}
            onSubmit={handleSubmit}
            render={({
              values,
              errors,
              setFieldValue,
              submitForm,
              //@ts-ignores
              ...rest
            }: any) => {
              return (
                <>
                  <div className="row">
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <Select
                          id="subject"
                          name="subject"
                          label="Select Subject"
                          placeholder="Subject"
                          options={subjectOptions}
                          required
                          onChange={SelectSubjectHandler}
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <Select
                          id="topic"
                          name="topic"
                          label="Topics"
                          placeholder="Select topics"
                          options={...mapOptions(topics)}
                          onChange={(data: any) => {
                            // setFieldValue('topic', '');
                            setTimeout(() => {
                              // submitForm();
                              getTopicHandler(data);
                            });
                          }}
                        />
                      </div>
                    </div> */}
                    <div className="col-sm-12">
                      {/* <div className="form-group">
                        <Select
                          id="educator"
                          name="educator"
                          label="Select Educator"
                          placeholder="Educator"
                          options={...mapOptions(educatorOptions)}
                          required
                        />
                      </div> */}
                    </div>
                    <div className="col-sm-12">
                      <TextArea
                        id="text"
                        name="text"
                        placeholder="Start from here..."
                        required
                      />
                      <p className="primary-helper-text">
                        Attach File (Files supported: Image, Video, Music)
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <div className="my-2 form-group">
                        <FileUpload
                          name="displayPicture"
                          upload={createAsset}
                          accept={[
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'audio/mpeg',
                            'video/mp4',
                          ]}
                          type={'single'}
                          required
                          maxSize={'1gb'}
                          label={''}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="text-center my-3">
                        <Button type="submit" shape="primary" onClick={handleSubmit}>
                          Post
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        ) : (
          <div>
            <AiOutlineClose
              style={{ float: 'right', cursor: 'pointer' }}
              size={20}
              onClick={() => setOpen(false)}
            />
            <div
              style={{
                height: '250px',
                maxWidth: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                margin: '0 auto',
              }}
            >
              <ImWarning color="red" size={40} />
              <h4 className="mt-3">
                Please purchase atleast one batch to ask a question
              </h4>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CreateDoubt;
