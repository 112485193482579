import { PageHeader, Tab, Tabs, Button } from '@lipihipi/ec-ui';
import { CheckBox, Form, Input, Select } from '@lipihipi/form';
import { commonApiError } from '../coupon/index';
import React, { useEffect } from 'react';
//import { MdSearch } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import swal from 'sweetalert';


export default function RewardCreate(props: any) {
  const {
    programList,
    testList,
    interviewList,
    getCourses,
    createCoupon,
    title,
    breadCrumbs,
    onSuccessAddEdit
  } = props;

  const [state, setState] = React.useState('program');
  const [list, setList] = React.useState([]);
  const [testsList, setTestsList] = React.useState([]);
  const [interviewsList, setinterviewsList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [courses, setCourses] = React.useState([]);
  const [courseId, setCourseID] = React.useState('');
  const [courseIDDetails, setCourseIDDetails] = React.useState([]);
  const [selectedBatch, setSelectedBatch] = React.useState([]);
  const [noEndDate, setNoEndDate] = React.useState(false);
  const [deSelect, setDeSelect] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const type = [
    { label: 'Influencer', value: 'INFLUENCER' },
    { label: 'Global Promotional', value: 'GLOBAL_PROMOTIONAL' }
  ];

  const [data, setData] = React.useState<any>({
    name: '',
    emailAddress: '',
    phoneNumber: '',
    coupon: '',
    discount: 0,
    maxUsagePerUser: 0,
    maxTotalUsage: 0,
    StartDate: '',
    EndDate: '',
    couponType: '',
    course: [],
    associateModule: [],
  });

  var selectedBatchArray: Array<any> = [];
  var selectedCourseArray: Array<any> = [];

  useEffect(() => {
    if (courseIDDetails.length != 0) {
      setList([]);
      programList({})
        .then((data: any) => {
          let filterProgram = data?.data?.programs.filter((item: any) => item.course === courseIDDetails[0]);
          setList(filterProgram);
        })
        .catch((err: any) => {
          commonApiError(err);
        });
    }
    else {
      setList([]);
    }
  }, [courseIDDetails, courseId]);

  useEffect(() => {
    if (courseIDDetails.length != 0) {
      if (state === 'testbundle') {
        testList({ courseIds: courseIDDetails, all: 1 })
          .then((data: any) => {
            var values = data.data.testBundles;
            const names = values.map((o: { _id: any; }) => o._id)
            const filteredTestData = values.filter(({ _id }: any, index: number) => !names.includes(_id, index + 1));
            setTestsList(filteredTestData);
          })
          .catch((err: any) => {
            commonApiError(err);
          });
      }
      else if (state === 'interview') {
        interviewList({ courseIds: courseIDDetails, all: 1 })
          .then((data: any) => {
            var values = data.data.interviews;
            const names = values.map((o: { _id: any; }) => o._id)
            const filteredTestData = values.filter(({ _id }: any, index: number) => !names.includes(_id, index + 1));
            setinterviewsList(filteredTestData);
          })
          .catch((err: any) => {
            commonApiError(err);
          });
      }
    }

    else {
      setinterviewsList([]);
      setTestsList([]);
    }
  }, [courseId, courseIDDetails, state]);

  useEffect(() => {
    getCourses({ all: true })
      .then(({ data }: any) => setCourses(data.courses))
      .catch((err: any) => {
        commonApiError(err);
      });
  }, []);

  useEffect(() => {
    setDeSelect(false);
  }, [deSelect]);

  // useEffect(() => {
  //   batchList({ q: searchBatch }).then(({ data }: any) => setList(data.batches));
  // }, [searchBatch]);

  const createCouponPayload = (values: any) => {
    var selectedModule: any = [];
    selectedBatch.map((item: any) => {
      selectedModule.push({
        module_id: item.id,
        module_type: item.module.toUpperCase(),
      });
    });
    var payload = {
      coupon: values.coupon,
      couponName: '',
      course: courseIDDetails,
      couponType: values.couponType,
      discount: values.discount,
      maxTotalUsage: values.maxTotalUsage,
      maxUsagePerUser: values.maxUsagePerUser,
      validityStartTime: startDate,
      validityEndTime: endDate,
      groupName: '',
      name: values.name,
      emailAddress: values.emailAddress,
      phoneNumber: values.phoneNumber,
      createdBy: '',
      active: values.active,
      associateModule: selectedModule,
    };
    return payload;
  };

  const handleTypeChange = (event: any) => {
    if (event.value === 'GLOBAL_PROMOTIONAL') {
      setDisable(true);
    }
    else {
      setDisable(false);
    }
  }

  const handleSave = (values: any) => {
    var payload = createCouponPayload(values);
    setData(payload);
    let fn = createCoupon(payload);
    fn.then(({ status }: any) => {
      if (status === 200) {
        swal({
          title: 'Success',
          text: 'Coupon Create Successfully',
          icon: 'success',
          dangerMode: false,
        }).then(function () {
          onSuccessAddEdit();
          window.location.reload();
        });
      } else {
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
          dangerMode: true,
        });
      }
    })
      .catch((err: any) => {
        commonApiError(err);
      });
  };

  const handleCourseSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectedCourseArray.push(event.target.id);
      var coursesID = [...courseIDDetails, event.target.id];
      setCourseIDDetails(coursesID);
      setCourseID(event.target.id);
    }
    else {
      selectedCourseArray = courseIDDetails.filter(l => l !== event.target.id);
      setCourseIDDetails(selectedCourseArray);
      setCourseID(event.target.id);
    }
  };

  const setSelectedBatchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      if (selectedBatchArray.includes(event.target.id)) {
      }
      selectedBatchArray.push({
        id: event.target.id,
        name: event.target.name,
        module: state,
      });
    } else {
      selectedBatchArray = selectedBatchArray.filter(
        l => l.name !== event.target.name
      );
    }
  };

  const setDeselectedBatchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event) {
      selectedBatchArray.push({
        id: event.target.id,
        name: event.target.name,
        module: state,
      });
    }
  };

  const onAttachClick = () => {
    var totalModule = selectedBatch.concat(selectedBatchArray);
    const names = totalModule.map(o => o.name);
    const filtered = totalModule.filter(
      ({ name }, index) => !names.includes(name, index + 1)
    );
    setSelectedBatch(filtered);
  };

  const setEndDateValue = () => {
    noEndDate ? setNoEndDate(false) : (setNoEndDate(true), setEndDate(null))
  };

  const onDeAttachClick = () => {
    selectedBatchArray.map((item: any) => {
      selectedBatch.map((values, index) => {
        if (values.name === item.name) {
          selectedBatch.splice(index, 1);
        }
      });
    });
    setSelectedBatch(selectedBatch);
    setDeSelect(true);
  };
  return (
    <section className="main-structure">
      <PageHeader
        title={title || 'Create Coupon'}
        breadCrumbs={
          breadCrumbs || [
            { title: 'Home', link: '/dashboard' },
            { title: 'Manage Rewards and Coupons', link: '/dashboard' },
            { title: 'Create' },
          ]
        }
      />

      <Form
        initialValues={data}
        onSubmit={handleSave}
        render={() => (
          <>
            <div className="row">
              <div className="col-md-4">
                <Input
                  id="name"
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Name"
                  disabled={disable}
                />
              </div>
              <div className="col-md-4">
                <Input
                  id="emailAddress"
                  name="emailAddress"
                  type="email"
                  label="Email ID"
                  placeholder="Email Address"
                  disabled={disable}
                />
              </div>
              <div className="col-md-4">
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                  disabled={disable}
                />
              </div>
              <div className="col-md-4">
                <Input
                  id="coupon"
                  name="coupon"
                  type="text"
                  label="Coupon Code"
                  placeholder="Coupon Code"
                  required
                />
              </div>
              <div className='col-md-4'>
                <Select
                  id="couponType"
                  name="couponType"
                  label="Coupon Type"
                  placeholder="Select Type"
                  options={type}
                  onChange={e => handleTypeChange(e)}
                  required
                />
              </div>
            </div>

            <div className="applicable-on-courses mt-5">
              <p>Applicable on Courses</p>
              <ul>
                {courses.map((dataValue: any) => {
                  return (
                    <li>
                      <CheckBox
                        id={dataValue._id}
                        name={dataValue.name}
                        label={dataValue.name}
                        onChange={event => handleCourseSelect(event)}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="applicable-for mt-5">
              <p>Applicable for</p>
              <Tabs active={state} onClick={setState}>
                <Tab id={'program'} title={'program'}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="applicable-box">
                        {/* <p>Select Batch(s) for coupon discount</p> */}
                        <div className="wrap">
                          {/* <div className="wrap_head">
                            <Input
                              prefix={<MdSearch />}
                              id="searchBatches"
                              name="q"
                              placeholder="Search batches/test bundles"
                              onChange={(e) => { console.log(e, 'event') }}
                            />
                          </div> */}
                          <div className="wrap_body">
                            <ul>
                              {list.map((dataValue: any) => {
                                return (
                                  <li>
                                    <CheckBox
                                      id={dataValue._id}
                                      name={dataValue.name}
                                      label={dataValue.name}
                                      onChange={e => setSelectedBatchValue(e)}
                                    />
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div
                            className="wrap_foot attach"
                            onClick={onAttachClick}
                          >
                            Attach to coupon
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="applicable-box">
                        {/* <p>Selected Batche(s)</p> */}
                        <div className="wrap">
                          {/* <div className="wrap_head">
                            <Input
                              prefix={<MdSearch />}
                              id="searchBatches"
                              name="q"
                              placeholder="Search batches/test bundles"
                            />
                          </div> */}
                          <div className="wrap_body">
                            <ul>
                              {selectedBatch.map((dataValue: any) => {
                                if (dataValue.module === 'program') {
                                  return (
                                    <li>
                                      <CheckBox
                                        id={dataValue._id}
                                        name={dataValue.name}
                                        label={dataValue.name}
                                        onChange={e =>
                                          setDeselectedBatchValue(e)
                                        }
                                      />
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                          <div
                            className="wrap_foot detach"
                            onClick={onDeAttachClick}
                          >
                            Detach from Coupon
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab id={'testbundle'} title={'Test Bundle'}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="applicable-box">
                        {/* <p>Select Test(s) for coupon discount</p> */}
                        <div className="wrap">
                          {/* <div className="wrap_head">
                            <Input
                              prefix={<MdSearch />}
                              id="q"
                              name="q"
                              placeholder="Search test bundles"
                            />
                          </div> */}
                          <div className="wrap_body">
                            <ul>
                              {testsList.map((dataValue: any) => {
                                return (
                                  <li>
                                    <CheckBox
                                      id={dataValue._id}
                                      name={dataValue.name}
                                      label={dataValue.name}
                                      onChange={e => setSelectedBatchValue(e)}
                                    />
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div
                            className="wrap_foot attach"
                            onClick={onAttachClick}
                          >
                            Attach to coupon
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="applicable-box">
                        {/* <p>Selected Test(s)</p> */}
                        <div className="wrap">
                          {/* <div className="wrap_head">
                            <Input
                              prefix={<MdSearch />}
                              id="q"
                              name="q"
                              placeholder="Search batches/test bundles"
                            />
                          </div> */}
                          <div className="wrap_body">
                            <ul>
                              {selectedBatch.map((dataValue: any) => {
                                if (dataValue.module === 'testbundle') {
                                  return (
                                    <li>
                                      <CheckBox
                                        id={dataValue._id}
                                        name={dataValue.name}
                                        label={dataValue.name}
                                        onChange={e =>
                                          setDeselectedBatchValue(e)
                                        }
                                      />
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                          <div
                            className="wrap_foot detach"
                            onClick={onDeAttachClick}
                          >
                            Detach from Coupon
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab id={'interview'} title={'AI Mock Interviews'}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="applicable-box">
                        {/* <p>Select Mock Interview(s) for coupon discount</p> */}
                        <div className="wrap">
                          {/* <div className="wrap_head">
                            <Input
                              prefix={<MdSearch />}
                              id="q"
                              name="q"
                              placeholder="Search mock interview"
                            />
                          </div> */}
                          <div className="wrap_body">
                            <ul>
                              {interviewsList.map((dataValue: any) => {
                                return (
                                  <li>
                                    <CheckBox
                                      id={dataValue._id}
                                      name={dataValue.name}
                                      label={dataValue.name}
                                      onChange={e => setSelectedBatchValue(e)}
                                    />
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div
                            className="wrap_foot attach"
                            onClick={onAttachClick}
                          >
                            Attach to coupon
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="applicable-box">
                        {/* <p>Selected Test(s)</p> */}
                        <div className="wrap">
                          {/* <div className="wrap_head">
                            <Input
                              prefix={<MdSearch />}
                              id="q"
                              name="q"
                              placeholder="Search batches/test bundles"
                            />
                          </div> */}
                          <div className="wrap_body">
                            <ul>
                              {selectedBatch.map((dataValue: any) => {
                                if (dataValue.module === 'interview') {
                                  return (
                                    <li>
                                      <CheckBox
                                        id={dataValue._id}
                                        name={dataValue.name}
                                        label={dataValue.name}
                                        onChange={e =>
                                          setDeselectedBatchValue(e)
                                        }
                                      />
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                          <div
                            className="wrap_foot detach"
                            onClick={onDeAttachClick}
                          >
                            Detach from Coupon
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>

            <div className="row mt-5">
              <div className="col-md-4">
                <Input
                  id="discount"
                  name="discount"
                  type="number"
                  label="Discount(%)"
                  required
                />
              </div>
              <div className="col-md-4">
                <Input
                  id="maxUsagePerUser"
                  name="maxUsagePerUser"
                  type="number"
                  label="Per user usage Limit"
                  required
                />
              </div>
              <div className="col-md-4">
                <Input
                  id="maxTotalUsage"
                  name="maxTotalUsage"
                  type="number"
                  label="Coupon Max usage limit (total)"
                  required
                />
              </div>
              <div className="col-md-4">
                <div className="datepicker-wrap form-group">
                  <DatePicker
                    id="validityStartTime"
                    selected={startDate}
                    className="primary-form-control"
                    onChange={(date: any) => {
                      //handleSave({ startDate: date });
                      setStartDate(date);
                    }}
                    selectsStart
                    placeholderText="Start Date"
                    startDate={startDate}
                    endDate={endDate}
                    required
                  />
                  <FaCalendarAlt />
                </div>
              </div>
              <div className="col-md-4">
                <div className="datepicker-wrap form-group">
                  <DatePicker
                    id="validityEndTime"
                    selected={endDate}
                    className="primary-form-control"
                    onChange={(date: any) => {
                      //handleSave({ endDate: date });
                      setEndDate(date);
                    }}
                    selectsStart
                    placeholderText="End Date"
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    disabled={noEndDate}
                    required
                  />
                  <FaCalendarAlt />
                </div>
              </div>
              <div className="col-md-4" style={{ paddingTop: 10 }}>
                <CheckBox
                  id="end"
                  name="end"
                  label="No End Date"
                  onChange={setEndDateValue}
                />
              </div>
            </div>

            <div className="mt-3">
              <Button shape="primary" type="submit">
                Finish
              </Button>
            </div>
          </>
        )}
      />
    </section>
  );
}
