import React, { FC, useEffect, useState } from 'react';
import { LeftPanel } from '../../common';
import { useHistory } from 'react-router-dom';
import CreatePasswordForm from './CreatePasswordForm';
import EducrackAPI from '@lipihipi/client-sdk';

interface ICreatePassword {
  createPassword: any;
  imageBaseUrl: any;
}

const CreatePassword: FC<ICreatePassword> = ({
  createPassword,
  imageBaseUrl,
}: ICreatePassword) => {
  const [item, setItem] = useState<any>();

  const history = useHistory();
  const handleSubmit = async (values: any) => {
    const payload: any = values.password;
    const response = await createPassword(payload);
    if (response.status === 200) {
      if (item) {
        console.log('hello');
        history.push('/course/wall');
        localStorage.removeItem('forgotPassword');
      } else {
        history.push('/personal-detail');
      }
    }
  };
  useEffect(() => {
    const password: any = localStorage.getItem('forgotPassword');
    if (password) {
      setItem(JSON.parse(password));
    }
  }, []);

  const { styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};

  return (
    <main className="user-structure">
      <LeftPanel
        imageBaseUrl={imageBaseUrl}
        title={styles?.onboardingText || ''}
      />
      <CreatePasswordForm handleSubmit={handleSubmit} />
    </main>
  );
};

export default CreatePassword;
