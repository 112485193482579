import React, { useState } from 'react';
import { Modal, Button } from '@lipihipi/ec-ui';
import { FileUpload, Form, Label, Select, AutoSuggest } from '@lipihipi/form';
import swal from 'sweetalert';
import { IStudentCourseFormProps } from './subscriber-list.types';
// import { IStudentBulkUpload } from '@lipihipi/client-sdk/dist/user/user';
import { FormSchema } from './bulk-upload.schema';
import { commonApiError } from './subscriber-report';

const mapOptions = (values: any[]) => {
  return values.map(value => ({ label: value.name, value: value._id }));
};

export const BulkUploadForm = ({
  isOpen,
  onRequestClose,
  courses,
  onCreate,
  upload,
  group,
  getGroup,
}: IStudentCourseFormProps) => {
  const [state, setState] = useState({});

  const onRequestCloseHandler = () => {
    setState({});
    onRequestClose();
  };

  const handleSubmit = (values: any) => {
    setState(values);
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('course', values.course);
    formData.append('group', values.group);

    onCreate(formData)
      .then(({ status }: any) => {
        if (status === 200) {
          swal({
            title: 'Success',
            text: 'Candidate Created',
            icon: 'success',
            dangerMode: false,
          }).then(() => {
            getGroup();
            onRequestCloseHandler();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestCloseHandler}>
      <header className="text-center mb-4">
        <Label
          id="data"
          label="Create Candidate Group and Upload Data"
          className="text-dark font-weight-bolder text-capitalize"
        />
        <br />
        <a
          style={{ color: 'blue' }}
          className="font-weight-bolder text-capitalize"
          href="./candidates_bulk_upload_sample_file.xlsx"
          target="_blank"
          download
          rel="noreferrer"
        >
          Download Sample file
        </a>
      </header>
      <Form
        initialValues={state}
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
        render={({ setFieldValue, ...rest }: any) => {
          console.log('rest', rest);

          return (
            <>
              <Select
                id="course"
                label="Course"
                name="course"
                placeholder={'Select Course'}
                options={[...mapOptions(courses.courses)]}
              />

              <div className="form-group">
                <AutoSuggest
                  name="group"
                  suggetions={group}
                  setFieldValue={setFieldValue}
                  required
                  label="Group Name"
                  placeholder="Enter Group Name"
                />
              </div>

              <div className="form-group">
                <label>Upload Excel File</label>
                <FileUpload
                  name="file"
                  accept={[
                    '.csv',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                  ]}
                  label={''}
                  type={'single'}
                  maxSize={'10mb'}
                  upload={upload}
                />
              </div>

              <div className="form-group d-flex justify-content-center mt-4">
                <Button className="px-5" shape="primary" type="submit">
                  SAVE
                </Button>
              </div>
            </>
          );
        }}
      />
    </Modal>
  );
};
