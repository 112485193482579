import React from 'react';

import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({options, data, width, height } : any) => {
  return (
    <Bar
      options={options}
      data={data}
      width={width}
      height={height}
    />
  );
};

export default BarChart;
