import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ImageContainer, Image, IconButton } from './image-cropper.styled';

export const ImagePreview = ({ file, onDeletePreview }: any) => {
  return (
    <ImageContainer>
      <IconButton
        onClick={() => onDeletePreview(file)}
        className="d-flex justify-content-center align-items-center"
      >
        <AiFillCloseCircle />
      </IconButton>
      <Image src={file} alt="" />
    </ImageContainer>
  );
};
