import { ImagePreview } from './image-preview';
import { DocumentPreview } from './document-previewer';
import React from 'react';

const isImage = (file: string) => {
  const ext = file.split('.').pop() || '';
  return ['png', 'jpg', 'jpeg', 'gif'].indexOf(ext) !== -1;
};

export const FilePreview = ({
  file,
  onDelete,
  target="",
}: {
  file: string;
  onDelete: (file: string) => void;
  target: string;
}) => {
  return isImage(file) ? (
    <ImagePreview file={file} onDelete={onDelete} />
  ) : (
    <DocumentPreview file={file} onDelete={onDelete} target={target}/>
  );
};
