import styled from 'styled-components';

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Button = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: none;
`;

export const ImageContainer = styled.div`
  margin-right: 5px;
  margin-top: 5px;
  display: inline-block;
  position: relative;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d0d7dd;
  &:hover ${Button} {
    display: block;
  }
`;

export const IconButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: white;
  position: absolute;
  right: 0px;
  top: 0px;
  &:hover {
    cursor: pointer;
  }
`;
