import _ from 'lodash';
import React from 'react';
import { CircularProgress } from '../../components/circular-progress/circular-progress';
import { ResultsScores } from '../../components/results-scores/results-scores';

export const ResultsReport = ({
  userName,
  testName,
  resultMsg,
  // achievedScore,
  totalMarks,
  rankScored,
  rankTotal,
  // percentile,
  // accuracy,
  timeSpent,
  imageBaseUrl,
  tableData,
  examType,
  ...rest
}: any) => {
  const { accuracy, percentile, achievedScore } = tableData;

  const showTableData = () => {
    return Object.entries(tableData.detailedSolution).map(
      ([key, value]: any) => (
        <tr>
          <td align="center">{key}</td>
          <td align="center">{value?.totalQuestions}</td>
          <td align="center">{value?.right}</td>
          <td align="center">{value?.wrong}</td>
          <td align="center">{value?.skipped}</td>
          <td align="center">{value?.notAnswered}</td>
          <td align="center">{value?.score}</td>
          <td align="center">{value?.percentile}</td>
        </tr>
      )
    );
  };

  return (
    <>
      <div className="row" {...rest}>
        <div className="col-lg-5 align-self-center mb-4 mb-lg-0">
          <div className="d-flex justify-content-center flex-column flex-sm-row align-items-center mb-3 mb-md-5">
            <div className="mr-md-5 mb-3 mb-lg-0">
              <CircularProgress
                title="Total Score"
                progressValue={achievedScore ? achievedScore.toFixed(2) : 0}
                totalProgress={totalMarks}
                primaryColor="#50B167"
              />
            </div>

            <CircularProgress
              progressValue={rankScored}
              totalProgress={rankTotal}
              primaryColor="#FFA901"
              title="Rank"
            />
          </div>

          <ResultsScores
            totalCorrectAnswer={tableData.totalCorrectAnswer}
            totalWrongAnswer={tableData.totalWrongAnswer}
            totalSkippedAnswer={tableData.totalSkippedAnswer}
            totalNotAnswered={tableData?.totalNotAnswered}
          />
        </div>
        {examType === 'FULL LENGTH' &&
          !_.isEmpty(tableData?.detailedSolution) &&
          Object.keys(tableData).length > 0 && (
            <div className="col-lg-7">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Section</th>
                      <th>Total Qs.</th>
                      {/* <th>Attempted</th> */}
                      <th>Right</th>
                      <th>Wrong</th>
                      <th>Skipped</th>
                      <th>Not Answered</th>
                      <th>Score</th>
                      <th>Percentile</th>
                    </tr>
                  </thead>
                  <tbody>{showTableData()}</tbody>
                </table>
              </div>

              <div className="percentage-info">
                <p>
                  Percentile<span>{percentile ? percentile + '%' : '0%'}</span>
                </p>
                <p>
                  Accuracy<span>{accuracy ? accuracy + '%' : '0%'}</span>
                </p>
                <p>
                  Time Spent<span>{timeSpent ? timeSpent : '0min'}</span>
                </p>
              </div>
            </div>
          )}
      </div>
    </>
  );
};
