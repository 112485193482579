import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface IGroup {
  name: string;
}

export interface IGroupDocument extends IGroup, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IGroupListResponse {
  totalItems: number;
  group: IGroupDocument[];
}

const get: TGetResource<IGroupDocument> = (id, params) => {
  return client.get<IGroupDocument>(`/user-group/${id}`, { params });
};

const create: TCreateResource<IGroup, IGroupDocument> = (group: IGroup) => {
  return client.post<IGroupDocument>(`/user-group`, group);
};

const update: TUpdateResource<IGroupDocument> = group => {
  return client.patch(`/user-group/${group._id}`, group);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/user-group/${id}`);
};

const list: TListResource<IListParams, IGroupListResponse> = params => {
  return client.get(`/user-group/`, { params });
};

export const userGroup = { get, create, update, remove, list };
