import React, { useState, useEffect } from 'react';
import { PageHeader, Tabs, Tab } from '@lipihipi/ec-ui';
import { SelectAssignee, SelectAssignment } from '../../index';

const tabs = [
  {
    label: 'Select Assignee',
    value: 'assign-assignment',
  },
  {
    label: 'Select Assignment',
    value: 'select-assignment',
  },
];

export interface IAssignToProps {
  _id?: any;
  title?: any;
  breadCrumbs?: any;
  getCourses: any;
  getBatches: any;
  getGroups: any;
  getStudents: any;
  getBatchStudents: any;
  createAssignment: any;
  updateAssignment: any;
  getTests: any;
  getLibrary: any;
  getAssignment: any;
  getSectionSubjects: any;
  onAddEditSuccess: any;
}

export const AssignTo = ({
  _id,
  title,
  breadCrumbs,
  getCourses,
  getBatches,
  getGroups,
  getStudents,
  getBatchStudents,
  createAssignment,
  updateAssignment,
  getTests,
  getLibrary,
  getAssignment,
  getSectionSubjects,
  onAddEditSuccess,
}: IAssignToProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [assignment, setAssignment] = useState<any>({});

  useEffect(() => {
    if (_id) {
      _getAssignment(_id);
    }
  }, [_id]);

  const _getAssignment = (id: any) => {
    getAssignment(id, { all: true, populate: true })
      .then((res: any) => {
        setAssignment(res.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const setAssignmentHandler = (data: any) => {
    setAssignment(data);
  };

  const handleNextTab = () => {
    setActiveTab(activeTab + 1);
  };

  const handlePreviousTab = () => {
    setActiveTab(activeTab - 1);
  };

  return (
    <section className="main-structure">
      <div className="primary-tabs">
        <PageHeader
          title={title || 'Assign an Assignment'}
          breadCrumbs={
            breadCrumbs || [
              { title: 'Home', link: '/dashboard' },
              { title: 'Assign', link: '/' },
            ]
          }
        />

        <Tabs active={tabs[activeTab].value} onClick={() => {}}>
          <Tab id={tabs[0].value} title={tabs[0].label}>
            <SelectAssignee
              assignment={assignment}
              getCourses={getCourses}
              getBatches={getBatches}
              getGroups={getGroups}
              getStudents={getStudents}
              getBatchStudents={getBatchStudents}
              setAssignmentCallback={setAssignmentHandler}
              createAssignment={createAssignment}
              updateAssignment={updateAssignment}
              nextStep={handleNextTab}
            />
          </Tab>
          <Tab id={tabs[1].value} title={tabs[1].label}>
            <SelectAssignment
              assignment={assignment}
              getAssignment={_getAssignment}
              getSectionSubjects={getSectionSubjects}
              getTests={getTests}
              getLibrary={getLibrary}
              updateAssignment={updateAssignment}
              previousStep={handlePreviousTab}
              onAddEditSuccess={onAddEditSuccess}
            />
          </Tab>
        </Tabs>
      </div>
    </section>
  );
};

export default AssignTo;
