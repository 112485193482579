import * as Yup from 'yup';

export const FormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email()
    .required('Email is required'),
  groups: Yup.string(),
  mobile: Yup.string().required('Mobile number is required'),
});
