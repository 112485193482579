import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import check from '../../images/svg/check.svg';

interface ICategoryListingProps {
  data: Array<any>;
  multipleSelection?: boolean;
  getSelectedItems?: (value: Array<string> | string, item?: any) => void;
}

const CategoryListing: FC<ICategoryListingProps> = ({
  data,
  multipleSelection,
  getSelectedItems,
}: ICategoryListingProps) => {
  const [listData, setListData] = useState(data || []);
  const [selectedItem, setSelectedItem] = useState<Array<string> | string>(
    multipleSelection ? [] : ''
  );

  useEffect(() => setListData(data), [data]);

  useEffect(() => {
    if (getSelectedItems) {
      const itemObj = listData.find(item => item._id === selectedItem);
      getSelectedItems(selectedItem, itemObj);
    }
  }, [selectedItem]);

  const handleSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    setSelectedItem((prev: Array<string> | string) => {
      if (multipleSelection && Array.isArray(prev)) {
        return checked
          ? [...prev, value]
          : [...prev].filter(item => item !== value);
      } else {
        // uncheck all items except current
        document.querySelectorAll('input:checked').forEach((element: any) => {
          if (element.value !== value) element.checked = false;
        });
        return checked ? value : '';
      }
    });
  };

  return (
    <ul>
      {listData && listData.map((item: any) => (
        <li key={item._id}>
          <input
            type="checkbox"
            name={item.name}
            value={item._id}
            onChange={handleSelectionChange}
          />
          <span>
            <img src={check} alt="Check" />
          </span>
          {item.name}
        </li>
      ))}
    </ul>
  );
};

export default CategoryListing;
