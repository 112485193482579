import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from '../types';
import { ITestDocument } from './test';

export interface IBundleDocument extends IBundle, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IBundleListParams extends IListParams {
  course?: ObjectId;
  addedBy?: ObjectId;
}

export interface IBundleListResponse {
  Bundles: IBundle[];
  totalItems: number;
}

export interface ITestItem {
  displayOrder: number;
  test: string | ITestDocument;
  isActive: boolean;
  isFree: boolean;
  startDate?: Date;
  endDate?: Date;
}

export interface IBundle {
  name: string;
  description: string;
  instruction: string;
  displayPicture: string;
  course: string | PopulatedDocument;
  price: number;
  actualPrice?: number;
  status?: status;
  tests: ITestItem[];
  isDeleted: boolean;
  displayOrder?: number;
}

export enum status {
  'INACTIVE',
  'PUBLISH',
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IBundleDocument>(`/testv2/test-bundles/${id}`, { params });
};

const create = (Bundle: IBundle) => {
  return client.post<IBundleDocument>(`/testv2/test-bundles`, Bundle);
};

const update = (id: string, Bundle: IBundleDocument) => {
  return client.patch<IBundleDocument>(
    `/testv2/test-bundles/${id || Bundle._id}`,
    Bundle
  );
};

const remove = (id: string) => {
  return client.delete(`/testv2/test-bundles/${id}`);
};

const list = (params: IBundleListParams) => {
  return client.get<IBundleListResponse>(`/testv2/test-bundles/`, { params });
};

export const testBundleV2 = { get, create, update, remove, list };
