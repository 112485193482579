import React, { useEffect, useState } from 'react';
import { PageHeader, Loader } from '@lipihipi/ec-ui';
import Styled from 'styled-components';

const ObjectBox = Styled.embed<{ src: string }>`
width: 800px;
height: auto;
min-height: 450px;
margin:10px;
margin-left:0;
src: src(${({ src }: any) => src});`;

const PdfBox = Styled.embed<{ src: string }>`
width: 90vw;
height: auto;
min-height: 100vh;
margin:10px;
margin-left:0;
src: src(${({ src }: any) => src});`;

interface IStudentResourceDetail {
  breadCrumbs?: any[];
  getResource: any;
  getAssetUrl: any;
  _id: any;
}

export const StudentResourceDetail: React.FC<IStudentResourceDetail> = ({
  breadCrumbs,
  getResource,
  getAssetUrl,
  _id,
}) => {
  const [resource, setResource] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    _getResource();
  }, []);
  const _getResource = () => {
    getResource(_id).then((res: any) => {
      setResource(res.data);
      setLoading(false);
    });
  };
  console.log(resource);

  return (
    <div className="d-flex flex-wrap">
      {isLoading && <Loader />}
      <div className="main-mid">
        <PageHeader breadCrumbs={breadCrumbs || [{ title: 'Free Resource' }]} />

        <h3 className="primary-heading">{resource?.title}</h3>
        <div className="resource-detail-box">
          {resource?.file ? (
            resource?.file.replace(/^.*\./, '') === 'pdf' ? (
              // <PdfBox src={getAssetUrl(resource?.file)} />
              <PdfBox src={`${getAssetUrl(resource?.file)}#toolbar=0`} />
            ) : resource.file.replace(/^.*\./, '') === 'mp4' ? (
              <video
                style={{ borderRadius: '20px' }}
                width="100%"
                controls
                controlsList="nodownload"
                disablePictureInPicture
              >
                <source src={getAssetUrl(resource?.file)} type="video/mp4" />
              </video>
            ) : (
              <ObjectBox src={getAssetUrl(resource?.file)} />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
