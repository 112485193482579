import React, { FC } from 'react';
import { LeftPanel } from '../../common';
import { useHistory } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';
import swal from 'sweetalert';

interface IResetPassword {
  userId: string;
  token: string;
  resetPassword: any;
  validatePasswordLink: any;
  imageBaseUrl: any;
}

const ResetPassword: FC<IResetPassword> = ({
  userId,
  token,
  resetPassword,
  imageBaseUrl,
}: IResetPassword) => {
  const history = useHistory();
  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        userId,
        token,
        password: values.password,
      };
      const response = await resetPassword(payload);
      if (response.status === 200) {
        swal({
          title: 'Success',
          text: 'New Password has been set successfully',
          icon: 'success',
          dangerMode: false,
        }).then(() => {
          history.push('/login');
        });
      } else {
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
          dangerMode: true,
        });
      }
    } catch (err) {
      swal({
        title: 'Error',
        text: err.data?.message || 'Server Error!',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  return (
    <main className="user-structure">
      <LeftPanel imageBaseUrl={imageBaseUrl} />
      <ResetPasswordForm
        handleSubmit={handleSubmit}
        initialErrors={{ email: 'Invalid' }}
      />
    </main>
  );
};

export default ResetPassword;
