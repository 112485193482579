import * as Yup from 'yup';
import moment from 'moment';

export const validationSchema = () => {
  return Yup.object().shape({
    course: Yup.string(),
    batches: Yup.array().min(1,"Batch is required"),
    topics: Yup.array().min(1,'Topic is required'),
    //subject: Yup.string().required('Subject is required'),
    educator: Yup.string().required('Educator is required'),
    sessionName: Yup.string().required('Class Title is required'),
    sessionDate: Yup.date().required('Session Date is required'),
    sessionStartTime: Yup.date(),
    sessionEndTime: Yup.date().when("sessionStartTime",(sessionStartTime:any,yup:any) => sessionStartTime && yup.min(sessionStartTime,"End Time cannot be before start time."))
  });
};
export const calculateSessionInterval = (sessionStartTime: any, sessionEndTime: any) => {
    const startTime = moment(
      `${sessionStartTime.hh}:${sessionStartTime.mm}`,
      "HH:mm"
    );
    const endTime = moment(
      `${sessionEndTime.hh}:${sessionEndTime.mm}`,
      "HH:mm"
    );
    return moment.duration(moment(endTime).diff(startTime)).asMinutes();
};
