import { client } from './client';
import {
  PopulatedUserDocument,
  PopulatedDocument,
  IDocument,
  IListParams,
  ObjectId,
  TCreateResource,
  TListResource,
  TUpdateResource,
  TGetResource,
} from './types';

enum IAssginType {
  GROUP = 'GROUP',
  BATCH = 'BATCH',
  NOGROUP = 'NOGROUP',
}

export interface IAssign {
  name: string;
  course: ObjectId;
  assign_type: IAssginType;
  students?: ObjectId[] | PopulatedDocument[];
  batch?: ObjectId | PopulatedDocument;
  group?: ObjectId | PopulatedDocument;
  tests?: ObjectId[] | PopulatedDocument[];
  library?: ObjectId[] | PopulatedDocument[];
  startTime: Date;
  endTime: Date;
  active: boolean;
}

export interface IAssignDocument extends IAssign, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
  testAttempts?: {
    status?: string;
    latestAttempt?: string;
    test?: ObjectId | string;
  };
}

export interface IAssignListResponse {
  totalItems: number;
  assignments: IAssignDocument[];
}
export interface IAssignQueryParam extends IListParams {
  type: IAssginType;
  batch?: string;
  group?: string;
  startTime?: string;
  endTime?: string;
  course?: string;
}

const list: TListResource<IAssignQueryParam, IAssignListResponse> = params => {
  return client.get(`/assignments/`, { params });
};

// function listBatchGroup() {
//   return client.get(`/assignments/batch-group/`);
// }

// const listStudents = (assign_type: string, id: string) => {
//   return client.get(`/assignments/student-list/${assign_type}/${id}?`);
// };
const create: TCreateResource<IAssign, IAssignDocument> = (
  assignment: IAssign
) => {
  return client.post<IAssignDocument>(`/assignments`, assignment);
};

const update: TUpdateResource<IAssignDocument> = assignment => {
  return client.patch(`/assignments/${assignment._id}`, assignment);
};
const get: TGetResource<IAssignDocument> = (id, params) => {
  return client.get<IAssignDocument>(`/assignments/${id}`, { params });
};

export const assignment = {
  create,
  update,
  list,
  get,
  // listBatchGroup,
  // listStudents,
};
