import React from 'react';
import { Loader, PageHeader, PaginatedTable } from '@lipihipi/ec-ui';
import moment from 'moment';
import swal from 'sweetalert';

export const commonApiError = (
  error: any,
  setLoading?: any,
  history?: any,
  url?: string
) => {
  swal({
    title: 'Error',
    text: error?.message|| error?.data?.message || 'Something went wrong, please try after some time.',
    icon: 'error',
  }).then(() => {
    setLoading(false);
    if (url) {
      history.push('/dashboard');
    }
  });
};

interface ICouponDetail {
  couponId: string;
  getCoupon: any;
  title?: string;
  breadCrumbs?: any[];
}

const CouponDetails = ({
  couponId,
  getCoupon,
  title,
  breadCrumbs,
}: ICouponDetail) => {
  const [loading, setLoading] = React.useState(true);
  const [params, setParams] = React.useState<{ page: number; q?: string }>({
    page: 1,
  });

  const [coupon, setCoupon] = React.useState<any>({});
  const [userLength, setUserLength] = React.useState<any>(0);

  React.useEffect(() => {
    getCoupon(couponId)
      .then(({ data }: any) => {
        setCoupon(data);
        setUserLength(data.appliesTo.length);
        setLoading(false);
      })
      .catch((err: any) => {
        commonApiError(err);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="main-structure">
          <PageHeader
            title={title || `Coupon code: ${coupon?.coupon}`}
            breadCrumbs={
              breadCrumbs || [
                { title: 'Dashboard', link: '#' },
                { title: 'Manage Rewards and Coupons', link: '#' },
                { title: `${coupon?.coupon}` },
              ]
            }
          />
          <div className="coupon-details">
            <h3>
              {coupon?.influencer?.name}
              <span>({coupon?.couponType})</span>
            </h3>
            <ul>
              <li>{coupon?.influencer?.emailAddress}</li>
              <li>
                <strong>Start Date -</strong>
                {moment(coupon?.validityStartTime).format('MMM DD YYYY')}
              </li>
              <li>
                <strong>Expiry Date -</strong>
                {coupon?.validityEndTime !== null ? moment(coupon?.validityEndTime).format('MMM DD YYYY') : '---'}
              </li>
              <li className="danger">Total Buyer -{userLength}</li>
              <li className="danger">
                Total Sales(in Rupees) -{coupon?.totalSale}
              </li>
            </ul>
          </div>

          <PaginatedTable
            data={coupon.appliesTo}
            columns={[
              {
                dataRenderer: (_data: any, index: number) => (
                  <>
                    <div className="primary-text">{index + 1}</div>
                  </>
                ),
                title: 'S.No',
                width: '80px',
              },
              {
                dataRenderer: (data: any) => (
                  <>
                    <div className="primary-text">{data.user_id?.name}</div>
                  </>
                ),
                title: 'Name',
                width: 'calc(100% - (80px + 300px + 200px + 150px))',
              },
              {
                dataRenderer: (data: any) => (
                  <div className="primary-text">{data.user_id?.mobile}</div>
                ),
                title: 'Contact',
                width: '300px',
              },
              {
                dataRenderer: (data: any) => (
                  <div className="primary-text">{data._id}</div>
                ),
                title: 'Purchased Item',
                width: '200px',
              },
              {
                dataRenderer: (data: any) => (
                  <div className="primary-text">{(data.purchased_date).substring(0, 10)}</div>
                ),
                title: 'Purchased Date',
                width: '150px',
              },
            ]}
            totalItems={10}
            onPageChange={(page: any) => {
              setParams({ ...params, page: page });
            }}
            itemsPerPage={10}
            currentPage={params.page || 1}
          />
        </section>
      )}
    </>
  );
};

export default CouponDetails;
