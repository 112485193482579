import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';
import Swal from 'sweetalert2';
import { Loader, Tab, Tabs } from '@lipihipi/ec-ui';

import { ResultsReport } from './../../components/results-report/results-report';
import { ResultsScores } from './../../components/results-scores/results-scores';
import Question from './question/question';
import {
  barChartOptions,
  buildSectionWiseQuestionData,
  getQuestionList,
  getSections,
  getTotalTimeSpent,
  mapStatusToBackgroundColor,
  mapStatusToBorder,
  mapStatusToColor,
  prepareChartData,
} from './utility';
import PerformanceIndex from './performance-index/performance-index';
import { TestResultsType } from './test-results-types';
import BarChart from './bar-chart';
import EducrackAPI from '@lipihipi/client-sdk';

export const commonApiError = (error: any, history?: any, url?: string) => {
  Swal.fire({
    title: 'Error',
    text: error?.message || 'Something went wrong, please try after some time.',
    icon: 'error',
  }).then(() => {
    if (url) {
      history.push('/dashboard');
    }
  });
};

const Circle = Styled.li`
  background: ${(props: any) =>
    props.status
      ? mapStatusToBackgroundColor(props.status, props.active, props?.isCorrect)
      : 'transparent'};
  color: ${(props: any) =>
    props.status ? mapStatusToColor(props.status, props.active) : '#fff'};
    border: ${(props: any) => mapStatusToBorder(props.status, props.active)};
  font-weight: ${(props: any) => (props.active ? 700 : 400)};
`;

export const TestResultsNew = ({
  user,
  attemptId,
  getTestAttempt,
  imageBaseUrl,
  onTestInProgress,
  onNoTestResultReady,
  questionAnalysisData,
  topPerformer,
  otherAttempts,
  getAssetUrl,
}: TestResultsType) => {
  const [state, setState] = useState('Overview');
  const [attempt, setAttempt] = useState<any>({});
  const [tableData, setTableData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function init() {
      try {
        if (attemptId) {
          const { data } = await getTestAttempt(attemptId, { populate: true });
          const questionAnalysis: any = await questionAnalysisData(attemptId);
          const quesArray: any = [];
          let counter = 1;
          data?.test?.questionSets?.forEach((set: any) => {
            set?.sections?.forEach((sec: any) => {
              sec?.questions?.forEach((q: any) => {
                const section = data?.sections?.find(
                  (v: any) => v?.section?._id === sec?.section
                );
                quesArray.push({
                  ...q,
                  displayOrder: counter,
                  section: section?.section,
                });
                counter = counter + 1;
              });
            });
          });
          data.questions = quesArray;
          buildTableData(data, questionAnalysis?.data);
          checkTestResultStatus(data);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
        Swal.fire({
          title: 'No Result',
          text: 'This test has no result for you, please start a test!',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: false,
        }).then(onNoTestResultReady);
      }
    }
    init();
  }, []);

  const buildTableData = (data: any, questionAnalysis: any) => {
    const tableValues: any = {};
    let tableObj: any = {};

    tableValues.totalQuestions = data?.questions?.length;
    tableValues.achievedScore = data?.achievedScore;
    tableValues.accuracy = data?.accuracy;
    tableValues.percentile = data?.percentile?.toFixed(2);
    tableValues.rank = data?.rank;
    tableValues.rankTotal = data?.rankTotal;

    tableValues.detailedSolution = buildSectionWiseQuestionData(
      data,
      tableObj,
      questionAnalysis
    );
    tableValues.testAnalysis = getQuestionList(data?.questions);
    tableValues.chartData = prepareChartData(data);
    tableValues.totalCorrectAnswer = Object.values(tableValues?.testAnalysis)
      .map((v: any) => v?.right || 0)
      .reduce((a: number, b: number) => a + b);
    tableValues.totalSkippedAnswer = Object.values(tableValues?.testAnalysis)
      .map((v: any) => v?.skipped || 0)
      .reduce((a: number, b: number) => a + b);
    tableValues.totalWrongAnswer = Object.values(tableValues?.testAnalysis)
      .map((v: any) => v?.wrong || 0)
      .reduce((a: number, b: number) => a + b);
    tableValues.totalNotAnswered = Object.values(tableValues?.testAnalysis)
      .map((v: any) => v?.notAnswered || 0)
      .reduce((a: number, b: number) => a + b);
    setTableData(tableValues);

    setAttempt(data);
    setLoading(false);
  };

  const checkTestResultStatus = (test: any) => {
    setLoading(false);
    if (test?.status === 'IN PROGRESS') {
      Swal.fire({
        title: 'Test in Progress!',
        text: 'Your test is IN-PROGRESS, please continue on that!',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: false,
      }).then(() => onTestInProgress(test?.test?._id));
    } else if (test?.status !== 'FINISHED') {
      Swal.fire({
        title: 'No Result',
        text: 'This test has no result for you, please start a test!',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: false,
      }).then(onNoTestResultReady);
    }
  };

  const { logo } = EducrackAPI.getConfig();

  return (
    <>
      {loading && <Loader />}
      <header className="secondary-header">
        <img
          src={EducrackAPI.asset.getAssetUrl(logo?.key)}
          alt="logo"
          width="120px"
        />
        <p className="m-0">Result: {attempt?.test?.name || ''}</p>
        <span>
          {user?.name || ''}
          <img
            src={
              user && user?.displayPicture
                ? getAssetUrl(user?.displayPicture)
                : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMPSURBVEhLrZZZTyJBEIBl8AAVDzzwIBokECWEw3f/vA++GmJi1Bhj4k1UTkHw/MauHQemp8Xd/RKxq2iqqquqaybw8fExZIQNT09P9Xr9+fm52+2+v7+PjIyEw+GJiYmZmZlAICD7fDA5aLVa19fXd3d3OBBVL5ZlLS4urq+vT09Pi8qD3sHLy8vp6ent7S0LURnhKJlMZnJyUmQXGgePj4+Hh4eEL/LApFKpRCIhwh/6HVxeXp6cnLy+vor8S2KxWC6XcxemxwHWj4+P397eRP4rqEo+n3d8WOofVKvVf7cO5XL5/PxcBMcBOfkv1hU0SK1WU2txcHFx4aj8CAaDU1NTdOTo6KiofCDttIla2zWgF/f29jqdjlJ5GR4eXltbW1hYYKE0RHN2dmbutEKhQD3sE5A1g3XizWazy8vLjnXgHNvb27S/yDqurq74tB1wV780GmiGZDLJVBDZBUff2toypOv+/p6iWvwZsk+ks7OzInhgVKyurorggQgeHh6sZrNpmAdU1TzOzFnCuMWAFEkHg1NWPpg3MCUtxq9IOn4cduYNXK/vm6ylUqnIygfuv6x0kF7qZPLRaDR41IjggQZhpIugg862tEPcDSOEZ5kILrDOSGi32yLr4MFnhUIh9w3yQhfs7+8TqVMt+o/OPjo6otOVxo9IJGKPilKpZLhrQAR2uSzL/kEgwLUncPrHXGFm187Oju3g5ubm4OBA1L2Mj49zlebm5sgSBaev2T82Nkb7A13IlPRrhKWlJfvhww84++7ubt+FINJ4PI51AhGVDzjQjnqG1fz8vN1CnJ2Bo7QKpWGC/mgdmCVE2jeUGL1YZyE9SrDOqwdnQuTpqsRBIJObm5sifNVsY2NDrcUBCeFBqtopGo2urKwo/eBQf5UGTKXTaSfc71tGvxaLRZIzSN61cGiM8B7mHrE9bxVAnxCCCL+HbuYo7unQ7wBoKjrdPAS9EBZ19t5ZjQMFlwj8vu0Du9w7d+AOvg6ArzgyDe7tcQUWqRbWtaYVJgcO7CFjfDqbsUhODHaFoaFPopaZfyViswsAAAAASUVORK5CYII='
            }
            alt=""
          />
        </span>
      </header>
      <section className="result-container">
        <div className="result-container_tabs p-3 p-md-4">
          <Tabs active={state} onClick={setState}>
            <Tab id={'Overview'} title={'Overview'}>
              <div className="result-container_info p-3 px-md-4 py-md-5">
                <ResultsReport
                  userName="User Name"
                  testName={attempt?.test?.name}
                  resultMsg="Try harder!"
                  totalMarks={attempt?.test?.totalMarks}
                  rankScored={attempt?.rank || 0}
                  rankTotal={attempt?.rankTotal}
                  timeSpent={getTotalTimeSpent(attempt)}
                  imageBaseUrl={imageBaseUrl}
                  tableData={tableData}
                  examType={attempt?.test?.examType}
                />
              </div>
            </Tab>
            <Tab id={'Test-Analysis'} title={'Test Analysis'}>
              <div className="row">
                {tableData?.testAnalysis &&
                  Object.entries(tableData?.testAnalysis).map(
                    ([key, value]: any) => (
                      <div className="col-md-4 mb-3">
                        <div
                          className="analysis-block p-3"
                          style={{ minHeight: '310px' }}
                        >
                          <h4>{key}</h4>
                          <ul>
                            {value.question.map((que: any) => (
                              <>
                                <Circle
                                  // @ts-ignore
                                  status={que.status}
                                  isCorrect={que?.isCorrect}
                                >
                                  {que?.displayOrder}
                                </Circle>
                              </>
                            ))}
                          </ul>

                          <ResultsScores
                            totalCorrectAnswer={value.right}
                            totalWrongAnswer={value.wrong}
                            totalSkippedAnswer={value.skipped}
                            totalNotAnswered={value?.notAnswered}
                          />
                        </div>
                      </div>
                    )
                  )}
              </div>

              {attempt?.test?.examType === 'FULL LENGTH' && (
                <div className="mt-3 mt-md-5 topper-info">
                  {tableData?.chartData?.labels?.includes('Topper') ? (
                    <h3>You Vs. Topper</h3>
                  ) : (
                    <h3>You</h3>
                  )}
                  {tableData?.chartData && (
                    <div className="row">
                      <div
                        className={
                          tableData?.chartData?.labels?.includes('Topper')
                            ? 'col-md-6'
                            : 'col-md-4'
                        }
                      >
                        <BarChart
                          options={{
                            ...barChartOptions,
                            maintainAspectRatio: false,
                          }}
                          data={tableData?.chartData}
                          width={10}
                          height={300}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Tab>

            <Tab id={'Detailed-Solution'} title={'Detailed Solution'}>
              <Question
                tableData={tableData?.detailedSolution}
                imageBaseUrl={imageBaseUrl}
                sections={getSections(attempt)}
                examType={attempt?.test?.examType}
                attemptData={attempt}
              />
            </Tab>

            <Tab id={'Performance-Index'} title={'Performance Index'}>
              <PerformanceIndex
                attemptId={attemptId}
                tableData={tableData?.detailedSolution}
                isFullLength={attempt?.test?.examType === 'FULL LENGTH'}
                topPerformer={topPerformer}
                otherAttempts={otherAttempts}
              />
            </Tab>

            {/* <Tab id={'Video-Analysis'} title={'Video Analysis'}>
              <div className="video-analysis">
                {attempt && attempt?.test?.video ? (
                  <video
                    controls
                    controlsList="nodownload"
                    height="90%"
                    width="90%"
                  >
                    <source
                      src={getAssetUrl(attempt?.test?.video)}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <h5>Sorry!, no video found.</h5>
                )}
              </div>
            </Tab> */}
          </Tabs>
        </div>
      </section>
    </>
  );
};
