import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { HiOutlineLightBulb } from 'react-icons/hi';
import moment from 'moment';
import { Loader } from '@lipihipi/ec-ui';

export const EnrolledInterviews = ({
  allInterview,
  onInterviewClick,
  isLoading,
}: any) => {
  const [visible, setVisible] = useState<any>(10);

  const loadMore = () => {
    setVisible(visible + 10);
  };

  return (
    <>
      {isLoading && <Loader />}
      {allInterview?.userInterview?.length > 0 && (
        <div className="lesson-wrapping">
          <p>List of enrolled Interviews</p>

          <div
            className="batch-accordion test-accordian"
            data-name="interviews"
          >
            {allInterview?.userInterview
              ?.slice(0, visible)
              ?.map((interviews: any) => {
                return (
                  <Accordion
                    key={interviews?._id}
                    data-interview-id={interviews?._id}
                  >
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        <p className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items--md-center pr-4">
                          <span style={{ fontSize: '15px' }}>
                            {interviews?.interview?.name || 'Nan'}
                          </span>
                          <span style={{ fontSize: '12px' }}>
                            {interviews?.createdAt
                              ? moment(interviews?.createdAt).format(
                                  'DD MMMM YYYY, h:mma'
                                )
                              : '--'}
                          </span>
                        </p>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '0 17px 17px' }}>
                          <div className="my-progress">
                            <div className="my-progress--name">
                              <span>
                                <HiOutlineLightBulb />
                              </span>
                              <p>Interviews</p>
                            </div>
                            <div className="my-progress--bar">
                              <div className="primary-progress-bar">
                                <div className="wrap">
                                  <div
                                    className="bar-line"
                                    style={{
                                      width: `calc(${interviews?.attended?.length}/${interviews?.interview?.noOfLinks}*100%)`,
                                    }}
                                  >
                                    Line
                                  </div>
                                </div>
                                <span>
                                  {interviews?.attended?.length || 0}/
                                  {interviews?.interview?.noOfLinks || 0}
                                </span>
                              </div>
                              <p>
                                {interviews?.attended?.length} Interviews
                                attended out of{' '}
                                {interviews?.interview?.noOfLinks} total
                                interviews
                              </p>
                            </div>
                            <div className="my-progress--action">
                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  onInterviewClick(interviews?._id);
                                }}
                              >
                                View Details
                              </a>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              })}
          </div>
          {visible < allInterview?.userInterview?.length && (
            <div
              className="text-center"
              style={{
                fontSize: '15px',
                lineHeight: '18px',
                cursor: 'pointer',
                marginTop: '20px',
                color: '#00a689',
              }}
            >
              <p onClick={loadMore} id="interview-viewMore">
                View More
              </p>
            </div>
          )}
          {visible >= allInterview?.userInterview?.length && visible > 10 && (
            <div
              className="text-center"
              style={{
                fontSize: '15px',
                lineHeight: '18px',
                cursor: 'pointer',
                marginTop: '20px',
                color: '#00a689',
              }}
            >
              <p onClick={() => setVisible(10)} id="viewLess">
                View less
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};
