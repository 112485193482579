import React from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

interface IMenuHeader {
  title?: string;
  breadCrumbs?: { title: string; link?: string }[];
  component?: React.ReactNode;
  fluid?: boolean;
  description?: string;
}

const PageHeader = ({
  title,
  component,
  breadCrumbs,
  description,
}: IMenuHeader) => {
  const handleBack = () => {
    window.history.back();
  };
  return (
    // <Container className={fluid ? 'container-fluid' : 'container'}>
    //   <div>
    //     <h2 className="mb-0">{title}</h2>
    //     <Breadcrumb>
    //       {breadCrumbs &&
    //         breadCrumbs.map((breadcrumb, index) => {
    //           const isFirst = index === 0;
    //           return (
    //             <>
    //               {!isFirst && (
    //                 <li className={'icon'}>{<MdKeyboardArrowRight />}</li>
    //               )}
    //               <li key={index}>
    //                 {breadcrumb.link ? (
    //                   <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
    //                 ) : (
    //                   breadcrumb.title
    //                 )}
    //               </li>
    //             </>
    //           );
    //         })}
    //     </Breadcrumb>
    //   </div>
    //   {component && (
    //     <div className="row" role="tablist">
    //       <div className="col-auto">{component}</div>
    //     </div>
    //   )}
    // </Container>

    // <div className="primary-page-header">
    //   <div className="wrap">
    //     <h2 className="mb-0">{title}</h2>
    //     <Breadcrumb>
    //       {breadCrumbs &&
    //         breadCrumbs.map((breadcrumb, index) => {
    //           const isFirst = index === 0;
    //           return (
    //             <>
    //               {!isFirst && (
    //                 <li className={'icon'}>{<MdKeyboardArrowRight />}</li>
    //               )}
    //               <li key={index}>
    //                 {breadcrumb.link ? (
    //                   <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
    //                 ) : (
    //                   breadcrumb.title
    //                 )}
    //               </li>
    //             </>
    //           );
    //         })}
    //     </Breadcrumb>
    //   </div>
    //   {component && <>{component}</>}
    // </div>
    <>
      <ul className="primary-breacrumbs mb-3">
        <li>
          <a href="javascript:void(0)" onClick={handleBack}>
            <span className="back mr-0">
              <IoMdArrowRoundBack color="#00a689" width="16" height="16" />
            </span>
          </a>
        </li>
        {breadCrumbs &&
          breadCrumbs.map((breadcrumb, index) => {
            // const isFirst = index === 0;
            const isLast = index === breadCrumbs.length - 1;
            return (
              <>
                <li key={index}>
                  {breadcrumb.link ? (
                    <Link
                      className={isLast ? 'active' : ''}
                      to={breadcrumb.link}
                    >
                      {breadcrumb.title}
                    </Link>
                  ) : (
                    breadcrumb.title
                  )}
                </li>
              </>
            );
          })}
      </ul>
      {title || description ? (
        <div className="primary-page-header">
          <div className="wrap">
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
          </div>
          {component && <>{component}</>}
        </div>
      ) : null}
    </>
  );
};

export { PageHeader };
