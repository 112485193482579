import React from 'react';
import { useState, useEffect } from 'react';
import { TestTimerProps } from './test-timer.types';
import Countdown from 'react-countdown';
import moment from 'moment';

export const TestTimer = ({ testOverTime, handleTimeOver }: TestTimerProps) => {
  const [timer, setTimer] = useState('');

  useEffect(() => {
    if (
      moment().format('YYYY-MM-DDTHH:mm:ss') !==
      moment(testOverTime).format('YYYY-MM-DDTHH:mm:ss')
    ) {
      setTimer(moment(testOverTime).format('YYYY-MM-DDTHH:mm:ss'));
    }
  }, [testOverTime]);

  function add_zero(your_number: any, length = 2) {
    var num = '' + your_number;
    while (num.length < length) {
      num = '0' + num;
    }
    return num;
  }

  const renderer = ({ hours, minutes, seconds }: any) => {
    // Render a countdown
    return (
      <span>
        {add_zero(hours)}:{add_zero(minutes)}:{add_zero(seconds)}
      </span>
    );
  };

  // console.log("Timer Rendered");
  // console.log(testOverTime.getTime());
  // console.log(`Remaining: ${(testOverTime.getTime()-(new Date()).getTime())/100}`)

  return (
    <React.Fragment>
      {timer && (
        <>
          <p>Time Left :</p>
          <Countdown
            date={testOverTime.getTime()}
            key={testOverTime.getTime()}
            onComplete={handleTimeOver}
            daysInHours={true}
            renderer={renderer}
          />
        </>

      )}
    </React.Fragment>
  );
};
