export class EnvironmentService {
  public static env: string;
  public static config: any;
  public static setEnv(env: string) {
    this.env = env;
  }

  public static getEnv() {
    return this.env;
  }

  public static setConfig(config: any) {
    this.config = config;
  }
  public static getConfig() {
    return this.config;
  }
}
