import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PageTemplate,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
  TUpdateListResource,
} from './types';

export interface ICourse {
  name: string;
  displayPicture: string;
  courseId?: string;
  pageTemplate?: PageTemplate;
  active?: boolean;
  consentRequired?: boolean;
}

export interface IUpdateList {
  id: string;
  displayOrder: number;
}

export interface ICourseDocument extends ICourse, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IUpdateListResponse {
  message: string;
}

export interface ICourseListResponse {
  totalItems: number;
  courses: ICourseDocument[];
}

const get: TGetResource<ICourseDocument> = (id, params) => {
  return client.get<ICourseDocument>(`/courses/${id}`, { params });
};

const create: TCreateResource<ICourse, ICourseDocument> = (course: ICourse) => {
  return client.post<ICourseDocument>(`/courses`, course);
};

const update: TUpdateResource<ICourseDocument> = course => {
  return client.put(`/courses/${course._id}`, course);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/courses/${id}`);
};

const list: TListResource<IListParams, ICourseListResponse> = params => {
  return client.get(`/courses/`, { params });
};

const updateList: TUpdateListResource<IUpdateList, IUpdateListResponse> = (
  courseList: IUpdateList
) => {
  return client.post<IUpdateListResponse>(`/courses/order`, courseList);
};

export const course = { get, create, update, remove, list, updateList };
