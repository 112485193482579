import React from 'react';
import Log from './log';
import Result from './result';
import { ScreenProps } from './types';

const Screen = ({ log, handleLogChange, result }: ScreenProps) => (
  <div className="screen">
    <Log log={log} handleLogChange={handleLogChange} />
    <Result result={result} />
  </div>
);

export default Screen;
