import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PageTemplate,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
} from './types';

export interface IMedia extends PageTemplate {
  name: string;
}

export interface IMediaDocument extends IMedia, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface ICMSListResponse {
  totalItems: number;
  media: IMediaDocument[];
}

const get: TGetResource<IMediaDocument> = (id, params) => {
  return client.get<IMediaDocument>(`/media/${id}`, { params });
};

const create: TCreateResource<IMedia, IMediaDocument> = (cms: IMedia) => {
  return client.post<IMediaDocument>(`/media`, cms);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/media/${id}`);
};

const list: TListResource<IListParams, ICMSListResponse> = params => {
  return client.get(`/media/`, { params });
};

export const media = { get, create, remove, list };
