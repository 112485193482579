import React, { useEffect, useState } from 'react';
import Keyboard from './keyboard';
import Screen from './screen';

const math = require('mathjs');
const he = require('he');

// import './calc.css';

export const ScientificCalculator = () => {
  const [calcData, setCalcData] = useState({
    result: null,
    log: '',
    clickedEquals: false,
    ans: 0,
  });

  useEffect(() => {
    window.onerror = () =>
      setCalcData((oldData: any) => ({
        ...oldData,
        ...{ log: 'Syntax Error', clickedEquals: true },
      }));
    window.addEventListener('keypress', e => {
      if (e.key === 'Enter') {
        handleEqualsClick(calcData?.log);
      }

      if (e.key.match(/[+\-^]/)) {
        if (calcData?.clickedEquals)
          setCalcData((oldData: any) => ({
            ...oldData,
            ...{ log: 'Ans', clickedEquals: false },
          }));
      }

      const mathEntities: any = {
        '*': he.decode('&#x000D7;'),
        '/': he.decode('&divide;'),
      };

      if (e.key.match(/[*/]/)) {
        e.preventDefault();
        const clickedEquals = calcData?.clickedEquals;
        if (clickedEquals)
          setCalcData((oldData: any) => ({
            ...oldData,
            ...{ log: `Ans${mathEntities[e.key]}`, clickedEquals: false },
          }));
        else
          setCalcData((oldData: any) => ({
            ...oldData,
            ...{ log: calcData?.log + mathEntities[e.key] },
          }));
      }

      if (!e.key.match(/[+\-^*/]|Enter/)) {
        if (calcData?.clickedEquals)
          setCalcData((oldData: any) => ({
            ...oldData,
            ...{ log: '', clickedEquals: false },
          }));
      }
    });
  }, []);

  const keyClick = (keyLog: any, maths: any) => {
    const currentLog = calcData?.log;
    if (maths === 'clear') {
      setCalcData((oldData: any) => ({
        ...oldData,
        ...{ log: '', result: null },
      }));
    }

    if (maths === 'delete') {
      if (currentLog.charAt(currentLog.length - 2).match(/[ns]/)) {
        setCalcData((oldData: any) => ({
          ...oldData,
          ...{ log: currentLog.slice(0, currentLog.length - 4) },
        }));} else {
        setCalcData((oldData: any) => ({
          ...oldData,
          ...{ log: currentLog.slice(0, currentLog.length - 1) },
        }));}
    }

    if (maths === 'equals') {
      handleEqualsClick(currentLog);
    }

    if (maths.match(/trig|log|number|comma|prnths|ans|sqrt|exponent/)) {
      if (calcData?.clickedEquals)
        setCalcData((oldData: any) => ({
          ...oldData,
          ...{ log: keyLog, clickedEquals: false },
        }));
      //   this.setState({ log: keyLog, clickedEquals: false });
      else
        setCalcData((oldData: any) => ({
          ...oldData,
          ...{ log: currentLog + keyLog },
        }));
      }

    if (maths.match(/sum|sub|multiply|divide|power|sqr|inv/)) {
      if (calcData?.clickedEquals)
        setCalcData((oldData: any) => ({
          ...oldData,
          ...{ log: `Ans${keyLog}`, clickedEquals: false },
        }));
      else
        setCalcData((oldData: any) => ({
          ...oldData,
          ...{ log: currentLog + keyLog },
        }));
    }
  };

  const handleEqualsClick = (currentLog: any) => {
    const times = he.decode('&#x000D7;');
    const divide = he.decode('&divide;');
    const sqrt = he.decode('&radic;');
    const sqrtReg = new RegExp(sqrt, 'g');

    // change log so it's understanable to mathjs eval() method
    const newLog = currentLog
      .replace(times, '*')
      .replace(divide, '/')
      .replace(/Ans/g, `(${calcData?.ans.toString()})`)
      .replace(/E/g, '10^')
      .replace(/log/g, 'log10')
      .replace(/ln/g, 'log')
      .replace(sqrtReg, 'sqrt');
    // const parser = math.parser()
    let result = math.evaluate(newLog);
    let finalResult: any;

    if (currentLog === '') {
      result = 0;
    }

    // trim result if too long
    if (result.toString().length > 11) {
      finalResult = result.toString().slice(0, 11);
    } else finalResult = result;

    setCalcData((oldData: any) => ({
      ...oldData,
      ...{ ans: finalResult, result: finalResult, clickedEquals: true },
    }));
  };

  const handleLogChange = (input: any) => {
    setCalcData((oldData: any) => ({ ...oldData, ...{ log: input } }));
  };
  return (
    <>
      <Screen
        log={calcData?.log}
        result={calcData?.result}
        handleLogChange={handleLogChange}
      />
      <Keyboard keyClick={keyClick} />
    </>
  );
};
