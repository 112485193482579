import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface IPressArticle {
  name: string;
  url: string;
}

export interface IPressAndMedia {
  name?: string;
  active?: boolean;
  slug?: string;
  displayPicture: string;
  article?: IPressArticle[];
}

export interface IPressAndMediaDocument extends IPressAndMedia, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IPressAndMediaListResponse {
  totalItems: number;
  pressAndMedias: IPressAndMediaDocument[];
}

const get: TGetResource<IPressAndMediaDocument> = (id, params) => {
  return client.get<IPressAndMediaDocument>(`/press-media/${id}`, { params });
};

const create: TCreateResource<IPressAndMedia, IPressAndMediaDocument> = (
  pressAndMedia: IPressAndMedia
) => {
  return client.post<IPressAndMediaDocument>(`/press-media`, pressAndMedia);
};

const update: TUpdateResource<IPressAndMediaDocument> = pressAndMedia => {
  return client.put(`/press-media/${pressAndMedia._id}`, pressAndMedia);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/press-media/${id}`);
};

const list: TListResource<IListParams, IPressAndMediaListResponse> = params => {
  return client.get(`/press-media/`, { params });
};

const addArticle = (id: string, body: IPressArticle) => {
  return client.post(`/press-media/${id}/article`, body);
};

const editArticle = (id: string, body: IPressArticle) => {
  return client.patch(`/press-media/${id}/article`, body);
};

const removeArticle = (id: string, articleId: string) => {
  return client.delete(`/press-media/${id}/article/${articleId}`);
};

export const pressAndMedia = {
  get,
  create,
  update,
  remove,
  list,
  addArticle,
  removeArticle,
  editArticle,
};
