import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IWallResponseListParams extends IListParams {
  user?: ObjectId;
  populate?: boolean;
  wall?: ObjectId;
}

export interface IWallResponses {
  wall: ObjectId | PopulatedDocument;
  isLiked: boolean;
  isViewed: boolean;
  selectedOptions: ObjectId[] | PopulatedDocument[];
}

export interface IWallResponsesDocument extends IWallResponses, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IWallResponsesListResponse {
  totalItems: number;
  wallResponses: IWallResponsesDocument[];
}

const list = (wallId: string, params?: IWallResponseListParams) => {
  return client.get<IWallResponsesDocument>(`/walls/wall-responses/${wallId}`, {
    params,
  });
};

const get = (params?: IWallResponseListParams) => {
  return client.get<IWallResponsesDocument>(`/walls/wall-responses/`, {
    params,
  });
};

const create = (wallResponse: IWallResponses) => {
  return client.post<IWallResponsesDocument>(
    `/walls/wall-responses`,
    wallResponse
  );
};

const update = (wallResponse: IWallResponsesDocument) => {
  return client.put<IWallResponsesDocument>(
    `/walls/wall-responses/${wallResponse._id}`,
    wallResponse
  );
};
const getLikesCount = (wallId?: string) => {
  return client.get<Object>(`/walls/wall-likes/${wallId}`);
};

const getViewsCount = (wallId: string) => {
  return client.get<Object>(`/walls/wall-views/${wallId}`);
};

export const wallResponse = {
  get,
  create,
  update,
  list,
  getViewsCount,
  getLikesCount,
};
