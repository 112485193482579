import { Button, Loader, PageHeader } from '@lipihipi/ec-ui';
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import swal from 'sweetalert';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { PaymentDetailsModal } from '@lipihipi/promotion';
import EduCrackAPI from '@lipihipi/client-sdk';

// Components
import { DashboardLayout } from '../../components/dashboard-layout/dashboard-layout';
import { TestCard } from '../../components/test-card/test-card';
import { commonApiError } from '../test-results/test-results-new';

export const TestBundleDetails = (props: any) => {
  const {
    role,
    breadCrumbs,
    imageBaseUrl,
    getAssetUrl,
    onTestInProgress,
    user,
  } = props;
  const [testDetails, setTestDetails] = useState<any>({});
  const [paymentModal, setPaymentModal] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    async function init() {
      try {
        const bundleId = props.bundleId;
        if (props.isSubsribed) {
          const { data } = await props.getTestBundleDetails(bundleId, {
            populate: true,
          });
          setTestDetails(data);
        } else {
          const { data } = await props.getTestBundleDeatilsToSubscribe(
            bundleId,
            {
              populate: true,
            }
          );
          setTestDetails(data);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        commonApiError(err);
      }
    }
    init();
  }, []);

  const onTestBundleSubscribeHandler = () => {
    try {
      setPaymentModal(testDetails._id);
    } catch (err) {
      console.error(err);
      commonApiError(err);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('testDuration')) {
      const timer = setInterval(() => {
        const timeData = localStorage.getItem('testDuration');
        if (Number(timeData) <= 0) {
          localStorage.removeItem('testDuration');
          clearInterval(timer);
          window.location.reload();
          return;
        }
        localStorage.setItem(
          'testDuration',
          (Number(timeData) - 1000).toString()
        );
      }, 1000);
    }
  }, []);

  const onTestActionClickHandler = async (testData: any) => {
    const {
      test,
      isFree = false,
      isTestEnabled = true,
      startDate,
      endDate,
    } = testData;
    const _window = window as any;
    const { tests = [] } = testDetails;

    const latestAttempt = getLatestAttempt(test._id);
    const activeSttemptObj = tests.find(
      (attempt: any) => attempt.test?._id === test._id
    );
    const testStatus = getAttemptCompleted(test._id);

    if (testStatus === 'SUBMITTED') {
      swal({
        title: 'Your result is not ready yet.',
        icon: 'success',
      });
      return;
    }

    // Check if proctored test is enabled
    if (test?.proctoredTest?.isEnabled) {
      if (
        activeSttemptObj.hasOwnProperty('isTestEnabled') &&
        !activeSttemptObj?.isTestEnabled &&
        testStatus === 'NOT ATTEMPTED'
      )
        return;
      const { startDate, endDate, isEndDateRequired } = test?.proctoredTest;
      const isBetween = moment().isBetween(startDate, endDate, undefined, '[]');
      if (latestAttempt && latestAttempt?.status === 'FINISHED') {
        test.isDisplayResult
          ? props.onTestAttemptResultClick(
              latestAttempt?.attempts[latestAttempt?.attempts?.length - 1]
            )
          : '';
        return;
      }
      if (moment().isBefore(moment(startDate)) && !isEndDateRequired) {
        swal({
          title: 'Sorry',
          text: `This test will be available from ${moment(startDate).format(
            'DD MMM YYYY h:mma'
          )}`,
          icon: 'success',
        }).then(() => window.location.reload());
        return;
      }
      if (moment().isAfter(moment(endDate))) {
        swal({
          title: 'Sorry',
          text: `This test was available till ${moment(endDate).format(
            'DD MMM YYYY h:mma'
          )}`,
          icon: 'success',
        }).then(() => window.location.reload());
        return;
      }
      if (isBetween || !isEndDateRequired) {
        setLoading(true);
        startTestNewWindow(test, test?.totalDurationInMinute * 60000 + 5000);
      } else {
        const text = generateText(startDate, endDate);
        swal({
          text: text,
          icon: 'success',
        }).then(() => _window.location.reload());
      }
      return;
    }

    if (props.isSubsribed || isFree) {
      //  this is for single attemt tests only
      const testDuration = test?.totalDurationInMinute * 60000 + 5000;
      if (latestAttempt?.status === 'IN PROGRESS') {
        localStorage.setItem('testDuration', JSON.stringify(testDuration));
        // const attemptId = getLatestAttempt(test._id , true);
        swal({
          title: 'Test in Progress!',
          text: 'Your test is IN-PROGRESS, please continue on that!',
          icon: 'warning',
        }).then(() =>
          onTestInProgress(
            latestAttempt?._id,
            test?.shinkanProctoredTest,
            testDetails?.procotoredUrl
          )
        );
        return;
      }
      if (test.isDisplayResult && latestAttempt?.status === 'FINISHED') {
        props.onTestAttemptResultClick(
          latestAttempt?.attempts[latestAttempt?.attempts?.length - 1]
        );
      } else if (test?.futureTest) {
        const text = generateText(startDate, endDate);
        swal({
          title: 'Future Test',
          text: text,
          icon: 'success',
        }).then(function() {});
      } else if (isTestEnabled || isFree) {
        setLoading(true);
        startTestNewWindow(test, testDuration);
      }
    }
  };

  const generateText = (startDate: any, endDate: any) => {
    let text = 'This test will be available soon, You will be notified';
    if (startDate && endDate) {
      const startTime = moment(startDate).format('DD MMM YYYY h:mma');
      const endTime = moment(endDate).format('DD MMM YYYY h:mma');
      text = `This test will be available between  ${startTime} to ${endTime}`;
    } else if (startDate) {
      const startTime = moment(startDate).format('DD MMM YYYY h:mma');
      text = `This test will be available on ${startTime}`;
    }
    return text;
  };

  // Function to start new test in new window
  const startTestNewWindow = async (test: any, testDuration: Number) => {
    try {
      const _window = window as any;
      const response: any = await props.userTestEnrollment({
        testId: test._id,
        bundleId: props.isSubsribed ? testDetails.testBundle : testDetails._id,
      });
      const { enrollmentId } = response?.data;

      const { data } = await props.listTestAttempt({ test: enrollmentId });

      if (data) {
        localStorage.setItem('testDuration', JSON.stringify(testDuration));
        swal({
          title: 'Success',
          text: 'Your test has been started in new window',
          icon: 'success',
        }).then(() => _window.location.reload());
        props.onTestClick(enrollmentId);
      }
    } catch (error) {
      commonApiError(error);
      setLoading(false);
    }
  };

  const { tests = [] } = testDetails;

  const fullLengthTests = tests.filter((item: any) =>
    item.test.examType === 'FULL LENGTH' ? item : null
  );
  const sectionBaseTests = tests.filter((item: any) =>
    item.test.examType === 'SECTION BASE' ? item : null
  );
  const subjectBaseTests = tests.filter((item: any) =>
    item.test.examType === 'SUBJECT BASE' ? item : null
  );
  const topicBaseTests = tests.filter((item: any) =>
    item.test.examType === 'TOPIC BASE' ? item : null
  );

  const getAttemptCompleted = (testId: string) => {
    const { testAttempts = [] } = testDetails;

    const activeSttemptObj = testAttempts.find(
      (attempt: any) => attempt.test === testId
    );
    return activeSttemptObj?.status;
  };

  const getLatestAttempt = (testId: string, flag: boolean = false) => {
    const { testAttempts = [] } = testDetails;

    const activeSttemptObj = testAttempts.find(
      (attempt: any) => attempt.test === testId
    );

    if (flag) {
      return activeSttemptObj;
      // return activeSttemptObj[activeSttemptObj?.length - 1] || null;
    }

    return activeSttemptObj;
    // return activeSttemptObj[activeSttemptObj?.length - 1] || null;
  };

  const getBreadCrumbs = (userRole: String) => {
    if (userRole === 'superadmin') {
      return [
        { title: 'Manage Students', link: '/manage-students' },
        { title: 'Students Report', link: '/reports/students' },
        { title: 'Test detail' },
      ];
    }
    return (
      breadCrumbs || [
        { title: 'Tests', link: '/course/tests' },
        { title: testDetails.name || '' },
      ]
    );
  };

  const cashFreePayment = (data: any) => {
    const obj = { ...data };
    obj.userName = user?.name;
    obj.userMobile = user?.mobile;
    obj.userEmail = user?.email;
    obj.productName = testDetails.name;
    props.initCashFreePayment(obj);
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <DashboardLayout>
        {/* Created a page header container because component container fluid was not working */}
        <PageHeader
          // title={testDetails.name || 'Test'}
          breadCrumbs={getBreadCrumbs(role)}
        />

        {role === 'user' && !isEmpty(testDetails) && (
          <>
            <div className="test-detail mb-4">
              <h3 className="heading">{testDetails.name || 'Test Bundle'}</h3>
              <div className="content">
                <div className="left">
                  <div className="info">
                    <div className="img">
                      <img
                        className="img-responsive"
                        src={
                          testDetails.displayPicture
                            ? getAssetUrl(testDetails.displayPicture)
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className="desc">
                    <ul>
                      <li>{testDetails.course?.name}</li>
                      <li>{testDetails.tests?.length}</li>
                    </ul>

                    {/* <p>{testDetails.description}</p> */}
                    <div className="editor-data mb-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: testDetails.description,
                        }}
                      ></div>
                    </div>

                    <div className="price-wrap">
                       
                      {parseInt(testDetails.price) !==0 ? (
                        <span>&#8377; {testDetails.price}</span>
                      ):(<span style={{'color':'#097461'}}>Free</span>)}

                      {onTestBundleSubscribeHandler && !props.isSubsribed && parseInt(testDetails.price) !==0 && (
                        <Button
                          className="px-5 ml-3"
                          shape="primary"
                          onClick={() => {
                            onTestBundleSubscribeHandler();
                          }}
                        >
                          Buy Now
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <h3>The above test series contains the following test sets</h3>
        <div className="batch-accordion mb-3 test-accordian">
          {/* fullLengthTests list   */}
          {fullLengthTests && fullLengthTests.length > 0 && (
            <Accordion defaultActiveKey="0" data-test-type="full-length-test">
              <Card>
                {/* Add actice class on card-header onclick on card-header for arrow rotate */}
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <p>Full Length Test ({fullLengthTests.length} Tests)</p>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ paddingBottom: '13px' }}>
                    <div className="test-wrap justify-content-start">
                      {fullLengthTests.map((item: any, index: number) => {
                        const { test = {}, isTestEnabled = true } = item;
                        return (
                          <TestCard
                            role={role}
                            key={index}
                            data={{
                              title: test.name,
                              questionsCount: test.totalQuestions,
                              duration: test.totalDurationInMinute,
                              futureTest: test.futureTest,
                              cost: '',
                              thumbnailUrl: test.displayPicture,
                              status: test.status,
                              isSubsribed: item.isFree || props.isSubsribed,
                              isTestEnabled: isTestEnabled,
                              paymentStatus: testDetails.payment_status,
                              _id: test._id,
                            }}
                            onClick={() => onTestActionClickHandler(item)}
                            // isAttemptCompleted={!!test.latestAttempt}
                            isAttemptCompleted={getAttemptCompleted(test._id)}
                            isDisplayResult={test?.isDisplayResult || false}
                            getAssetUrl={getAssetUrl}
                            imageBaseUrl={imageBaseUrl}
                          />
                        );
                      })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}

          {/* sectionBaseTests list   */}
          {sectionBaseTests && sectionBaseTests.length > 0 && (
            <Accordion defaultActiveKey="0" data-test-type="sectional-test">
              <Card>
                {/* Add actice class on card-header onclick on card-header for arrow rotate */}
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <p>Section Base Test ({sectionBaseTests.length} Tests)</p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ paddingBottom: '13px' }}>
                    <div className="test-wrap justify-content-start">
                      {sectionBaseTests.map((item: any, index: number) => {
                        const { test = {}, isTestEnabled = true } = item;
                        return (
                          <TestCard
                            role={role}
                            key={index}
                            data={{
                              title: test.name,
                              questionsCount: test.totalQuestions,
                              duration: test.totalDurationInMinute,
                              futureTest: test.futureTest,
                              cost: '',
                              thumbnailUrl: test.displayPicture,
                              status: test.status,
                              isSubsribed: item.isFree || props.isSubsribed,
                              isTestEnabled: isTestEnabled,
                              paymentStatus: testDetails.payment_status,
                              _id: test._id,
                            }}
                            onClick={() => onTestActionClickHandler(item)}
                            // isAttemptCompleted={!!test.latestAttempt}
                            isAttemptCompleted={getAttemptCompleted(test._id)}
                            isDisplayResult={test?.isDisplayResult || false}
                            getAssetUrl={getAssetUrl}
                            imageBaseUrl={imageBaseUrl}
                          />
                        );
                      })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}

          {/* subjectBaseTests list   */}
          {subjectBaseTests && subjectBaseTests.length > 0 && (
            <Accordion defaultActiveKey="0" data-test-type="subject-test">
              <Card>
                {/* Add actice class on card-header onclick on card-header for arrow rotate */}
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <p>Subject Base Test ({subjectBaseTests.length} Tests)</p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ paddingBottom: '13px' }}>
                    <div className="test-wrap justify-content-start">
                      {subjectBaseTests.map((item: any, index: number) => {
                        const { test = {}, isTestEnabled = true } = item;
                        return (
                          <TestCard
                            role={role}
                            key={index}
                            data={{
                              title: test.name,
                              questionsCount: test.totalQuestions,
                              futureTest: test.futureTest,
                              duration: test.totalDurationInMinute,
                              cost: '',
                              thumbnailUrl: test.displayPicture,
                              status: test.status,
                              isSubsribed: item.isFree || props.isSubsribed,
                              isTestEnabled: isTestEnabled,
                              paymentStatus: testDetails.payment_status,
                              _id: test._id,
                            }}
                            onClick={() => onTestActionClickHandler(item)}
                            // isAttemptCompleted={!!test.latestAttempt}
                            isAttemptCompleted={getAttemptCompleted(test._id)}
                            isDisplayResult={test?.isDisplayResult || false}
                            getAssetUrl={getAssetUrl}
                            imageBaseUrl={imageBaseUrl}
                          />
                        );
                      })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}

          {/* topicBaseTests list   */}
          {topicBaseTests && topicBaseTests.length > 0 && (
            <Accordion defaultActiveKey="0" data-test-type="topic-test">
              <Card>
                {/* Add actice class on card-header onclick on card-header for arrow rotate */}
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <p>Topic Base Test ({topicBaseTests.length} Tests)</p>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ paddingBottom: '13px' }}>
                    <div className="test-wrap justify-content-start">
                      {topicBaseTests.map((item: any, index: number) => {
                        const { test = {}, isTestEnabled = true } = item;
                        return (
                          <TestCard
                            role={role}
                            key={index}
                            data={{
                              title: test.name,
                              questionsCount: test.totalQuestions,
                              futureTest: test.futureTest,
                              duration: test.totalDurationInMinute,
                              cost: '',
                              thumbnailUrl: test.displayPicture,
                              status: test.status,
                              isSubsribed: item.isFree || props.isSubsribed,
                              isTestEnabled: isTestEnabled,
                              paymentStatus: testDetails.payment_status,
                              _id: test._id,
                            }}
                            onClick={() => onTestActionClickHandler(item)}
                            // isAttemptCompleted={!!test.latestAttempt}
                            isAttemptCompleted={getAttemptCompleted(test._id)}
                            isDisplayResult={test?.isDisplayResult || false}
                            getAssetUrl={getAssetUrl}
                            imageBaseUrl={imageBaseUrl}
                          />
                        );
                      })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}
        </div>
        {paymentModal && (
          <PaymentDetailsModal
            isOpen={!!paymentModal}
            onRequestClose={() => setPaymentModal(null)}
            resourceName={testDetails.name}
            id={testDetails._id}
            type={'TESTBUNDLE'}
            applyCoupon={EduCrackAPI.payment.applyStudentCoupon}
            modulePurchase={props.onTestBundleSubscribe}
            moduleData={testDetails}
            viewAllOption={props.onSubscribeSuccess}
            initCashFreePayment={cashFreePayment}
          />
        )}
      </DashboardLayout>
    </React.Fragment>
  );
};
