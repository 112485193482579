import React from 'react';
import { Button } from '@lipihipi/ec-ui';
import { Form, Input, Select } from '@lipihipi/form';
import { MdSearch } from 'react-icons/md';

const mapOptions = (values: any[]) => {
  return values.map(value => ({ label: value.name, value: value._id }));
};

export const Filter = ({ params, courses, groups, handleSearch }: any) => {
  return (
    <Form
      initialValues={params}
      onSubmit={handleSearch}
      render={({ submitForm }: any) => {
        return (
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <Input
                prefix={<MdSearch />}
                id="q"
                name="q"
                placeholder="Search the content here"
              />
            </div>
            <div className="col-6 col-md-3">
              <Select
                id="course"
                name="course"
                placeholder={'Course'}
                onChange={submitForm}
                options={[
                  { label: 'ALL', value: '' },
                  ...mapOptions(courses.courses),
                ]}
              />
            </div>
            <div className="col-6 col-md-3">
              <Select
                id="groupId"
                name="groupId"
                placeholder={'All User Groups'}
                onChange={submitForm}
                options={[
                  { label: 'No Group', value: '' },
                  ...mapOptions(groups),
                ]}
              />
            </div>
            <Button shape={'primary'} className="d-none" type={'submit'}>
              Search
            </Button>
          </div>
        );
      }}
    />
  );
};

export default Filter;
