import React from 'react';
import { Modal } from '@lipihipi/ec-ui';
import { FaTimes } from 'react-icons/fa';
import Styled from 'styled-components';

const ObjectBox = Styled.embed<{ src: string }>`
width: 100%;
height: 490px;
border-radius: 10px;
margin:10px;
margin-left:0px;
justify-content: center;
src: src(${({ src }: any) => src});`;

export const DisplayImage = ({ isOpen, onRequestClose, url }: any) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div style={{ height: '500px', width: '800px' }}>
        <FaTimes
          style={{
            float: 'right',
            cursor: 'pointer',
            fontSize: '20px',
            marginTop: '-20px',
          }}
          color="black"
          onClick={onRequestClose}
        />
        <div className="text-center mt-4">
          <ObjectBox src={url} />
        </div>
      </div>
    </Modal>
  );
};

export default DisplayImage;
