import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
  TCreateResource,
} from '../types';
import { IQuestionDocument, IQuestionItem } from '../question';

import { IUserTestAttemptDocument } from './test-attempt';

export interface IQuestionSetListParams extends IListParams {
  exam?: ObjectId;
  subject?: ObjectId;
  section?: ObjectId;
  topic?: ObjectId;
  user?: ObjectId;
  test?: ObjectId;
}
export interface IAssignListParams extends IListParams {
  user?: ObjectId;
  group?: ObjectId;
  test?: ObjectId;
}

export interface IQuestionList {
  displayOrder: number;
  section: ObjectId | PopulatedDocument;
  subject: ObjectId | PopulatedDocument;
  topic: ObjectId | PopulatedDocument;
  parentQuestion: ObjectId | IQuestionDocument;
  question: ObjectId | IQuestionItem;
  points: number;
  selectedAnswers: string[];
  status: string;
}

export interface ISectionSetting {
  isTimeLimitEnabled: boolean;
  timeMapping: [
    {
      section: string | PopulatedDocument;
      timeLimit: number;
    }
  ];
}

export interface IBooleanValueSetting {
  isEnabled: boolean;
  value: number;
}

export interface IUserTest {
  name: string;
  instruction: string;
  displayPicture: string;
  exam: ObjectId | PopulatedDocument;
  examType: string;
  questionSets?: any;
  totalTiming: IBooleanValueSetting;
  negativeMarking: IBooleanValueSetting;
  notAttempted: IBooleanValueSetting;
  totalQuestions: number;
  totalDurationInMinute: number;
  totalMarks: number;
  status: string;
  isDisplayResult: boolean;
  noOfAttempts?: number;
  sections: ObjectId[] | PopulatedDocument[];
  subjects: ObjectId[] | PopulatedDocument[];
  displayOrder: number;
  topics: ObjectId[] | PopulatedDocument[];
  test: string;
  testBundle: string;
  testBundleEnrollment: string;
  isActive: number;
  latestAttempt: string | IUserTestAttemptDocument;
}

export interface IUserTestListResponse {
  userTestEnrollments: IUserTest[];
  totalItems: number;
}

export interface IEnroll {
  testId: string;
  assignId?: string;
  batchId?: string;
  bundleId?: string;
}
export interface IEnrollResponse {
  enrollmentId: string;
}

export interface IUserTestDocument extends IUserTest, IDocument {
  user: ObjectId | PopulatedUserDocument;
}

export interface IAssignPayload {
  test: ObjectId;
  group?: ObjectId;
  user?: ObjectId;
}

const get = (id: string, params?: { populate: boolean; user?: string }) => {
  return client.get<IUserTestDocument>(`/testv2/user-test/${id}`, {
    params,
  });
};

const create: TCreateResource<IEnroll, IEnrollResponse> = (data: IEnroll) => {
  return client.post<IEnrollResponse>(`/testv2/user-test`, data);
};

const update = (id: string, questionSet: IUserTestDocument) => {
  return client.put<IUserTestDocument>(
    `/testv2/user-test/${id || questionSet._id}`,
    questionSet
  );
};

const list = (params: IQuestionSetListParams) => {
  return client.get<IUserTestListResponse>(`/testv2/user-test/`, {
    params,
  });
};

const assignList = (params: IAssignListParams) => {
  return client.get(`/testv2/user-test/assign`, { params });
};

const assign = (data: IAssignPayload) => {
  return client.post(`/testv2/user-test/assign`, data);
};

const assingVerify = (data: { token: string }) => {
  return client.post('/testv2/user-test/assign/verify', data).then(response => {
    client.defaults.headers.authorization = `Bearer ${response.data.token}`;
    return response;
  });
};

export const userTest = {
  get,
  update,
  list,
  create,
  assign,
  assingVerify,
  assignList,
};
