import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
} from '../types';
import { IQuestionSetDocument } from '../question-set';

export interface IUserTestAttemptDocument extends IUserTestAttempt, IDocument {
  user: ObjectId | PopulatedUserDocument;
}

export interface IUserTestAttemptListParams extends IListParams {
  test?: ObjectId;
  user?: ObjectId;
}

export interface IUserTestAttemptListResponse {
  testAttempts: IUserTestAttempt[];
  totalItems: number;
}

export interface IUserTestAttempt {
  test: string | IQuestionSetDocument;
  status: string;
  totalCorrectAnswer: number;
  totalWrongAnswer: number;
  totalSkippedAnswer: number;
  startedAt: string;
  finishedAt: string;
  achievedScore: number;
  percentile: number;
  accuracy: number;
  evaluated: boolean;
  answers: IUserAttemptAnswer[];
}

export interface IUserAttemptAnswer {
  questionId: ObjectId;
  selectedAnswers?: ObjectId[];
  typedAnswers?: string[];
  isMarkForReview?: boolean;
  isCorrect?: boolean;
  score?: number;
  comment?: string;
  answerImages?: string[];
  feedbackImages?: string[];
  timeTaken?: number;
}

export interface IQuestionAnalysis {
  questionId: ObjectId;
  totalAttempts: number;
  correctAttempts: number;
  averateTime: number;
  totalTestAttempts: number;
}
export interface ITopPerformer {
  test: ObjectId;
  testAttempt: ObjectId;
  bestScore: number;
  user: {
    name: string;
  };
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IUserTestAttemptDocument>(`/testv2/test-attempt/${id}`, {
    params,
  });
};

// const getResult = (test: string) => {
//   return client.get<IUserTestAttemptDocument>(
//     `/test-attempt/result/${test}`
//   );
// };

const create = (UserTestAttempt: IUserTestAttempt) => {
  return client.post<IUserTestAttemptDocument>(
    `/testv2/test-attempt`,
    UserTestAttempt
  );
};

const update = (id: string, UserTestAttempt: IUserTestAttemptDocument) => {
  return client.put<IUserTestAttemptDocument>(
    `/testv2/test-attempt/${id}`,
    UserTestAttempt
  );
};

const submitAnswer = (id: string, UserAnswer: IUserAttemptAnswer) => {
  return client.put<IUserTestAttemptDocument>(
    `/testv2/test-attempt/${id}/submit-answer`,
    UserAnswer
  );
};

const submitAttempt = (id: string, UserTestAttempt?: Object) => {
  return client.put<IUserTestAttemptDocument>(
    `/testv2/test-attempt/${id}/finish`,
    UserTestAttempt
  );
};

// const submitSection = (id: string, UserTestAttempt?: Object) => {
//   return client.put<IUserTestAttemptDocument>(
//     `/test-attempt/${id}/submit-section`,
//     UserTestAttempt
//   );
// };

// const remove = (id: string) => {
//   return client.delete(`/test-attempt/${id}`);
// };

const list = (params: IUserTestAttemptListParams) => {
  return client.get<IUserTestAttemptListResponse>(`/testv2/test-attempt/`, {
    params,
  });
};

const submitEvaluatedAnswer = (id: string, UserAnswer: IUserAttemptAnswer) => {
  return client.put<IUserTestAttemptDocument>(
    `/testv2/test-attempt/${id}/evaluate/answer`,
    UserAnswer
  );
};

const finishEvaluation = (id: string) => {
  return client.put<IUserTestAttemptDocument>(
    `/testv2/test-attempt/${id}/evaluate/finish`
  );
};

const questionAlalysisData = (id: string) => {
  return client.get<IQuestionAnalysis>(
    `testv2/test-attempt/question-analysis/${id}`
  );
};

const topPerformer = (id: string, params: { size?: number } = {}) => {
  return client.get<ITopPerformer>(`/testv2/test-attempt/top-performer/${id}`, {
    params,
  });
};

const otherAttempts = (id: string) => {
  return client.get<IUserTestAttempt[]>(
    `/testv2/test-attempt/performance/${id}`
  );
};

export const testAttempt = {
  get,
  create,
  update,
  // remove,
  list,
  submitAnswer,
  submitAttempt,
  // submitSection,
  // getResult,
  submitEvaluatedAnswer,
  finishEvaluation,
  questionAlalysisData,
  topPerformer,
  otherAttempts,
};
