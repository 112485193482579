import React from 'react';

interface loaderProps {
  imageUrl?: string;
}

export const Loader = ({ imageUrl }: loaderProps) => {
  return (
    <div className="full-page-loader">
      {/* TODO Change Path to PROD */}
      <img src={imageUrl || ''} alt="" />
      {/* <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
      <p>Loading...</p>
    </div>
  );
};
