import * as yup from 'yup';

const MOBILE_REGEX = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
const URL_VALIDATION_REGEX = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const mobile = yup
  .string()
  .matches(MOBILE_REGEX, 'Invalid Mobile No.')
  .required('Mobile No. is required');
const email = yup
  .string()
  .email()
  .required('Email is required');

const password = yup
  .string()
  .required('Password is required')
  .min(6, 'Password is too short - should be 6 chars minimum.');

const validationSchema = {
  name: yup.string().required('Mobile No. is required'),
 
  register: yup.object().shape({
    name: yup.string().required('Name is required'),
    email
    // password,
    // role:yup.string().required('Role is required'),
    // mobile: yup
    //   .string()
    //   .matches(MOBILE_REGEX, 'Invalid Mobile No.')
    //   .required('Mobile No. is required'),
  }),
  forgotPassword: yup.object().shape({
    mobile
  }),
  password: yup.object().shape({
    password,
    passwordConfirmation: yup.string()
     .oneOf([yup.ref('password')], 'Passwords must match')
  }),
};



export { validationSchema, URL_VALIDATION_REGEX };
