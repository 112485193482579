import { PageHeader, Pagination, Loader, Tab, Tabs } from '@lipihipi/ec-ui';
import React, { useEffect, useState } from 'react';
import { IBundleListResponse } from '@lipihipi/client-sdk/dist/test-bundle';

// Components
import { DashboardLayout } from '../../components/dashboard-layout/dashboard-layout';
import { TestTopicCard } from '../../components/test-topic-card/test-topic-card';
import { commonApiError } from '../test-results/test-results-new';

export const TestBundle = (props: any) => {
  const { selectedCourse, imageBaseUrl, getAssetUrl } = props;
  const [testList, setTestList] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [courseId, setCourseId] = useState<string>('');
  const [activeTab, setActiveTab] = useState('purchased');

  // const [subsribedTestBundleList, setSubsribedTestBundleList] =  React.useState<any>([]);

  const [params, setParams] = React.useState<any>({
    page: 1,
    // populate:true
  });

  const [testBundles, setTestBundles] = React.useState<IBundleListResponse>({
    totalItems: 0,
    Bundles: [],
  });
  /**
  |--------------------------------------------------
  | Effect hook to get test bundle list whenever selectedCourse changes
  |--------------------------------------------------
  */
  useEffect(() => {
    if (selectedCourse && selectedCourse._id) {
      fetchTestBundleList(selectedCourse._id);
      fetchBundleSet(selectedCourse._id);
      setCourseId(selectedCourse._id);
    }
  }, [selectedCourse]);

  async function fetchTestBundleList(courseId: String) {
    try {
      const { data } = await props.getTestBundlelist({
        course_id: courseId,
        all: true,
      });
      setTestList(data);
      setLoading(false);
    } catch (err) {
      commonApiError(err);
      console.error(err);
    }
  }

  const fetchBundleSet = (course_Id: String) => {
    if (!course_Id) return;
    props
      .fetchBundleList({ ...params, course: course_Id })
      .then((res: any) => {
        setTestBundles({
          totalItems: res.data.totalItems,
          Bundles: res.data.testBundles,
        });
        setLoading(false);
      })
      .catch((err: any) => {
        commonApiError(err);
      });
  };

  React.useEffect(() => {
    fetchBundleSet(courseId);
  }, [params]);

  console.log(testList);

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <DashboardLayout>
        <PageHeader
          title={
            props.title ||
            'Don’t stress. Do your best. Forget the rest. Get on the Test!'
          }
          description={
            props.description ||
            'How porepared are you? Test your progress at every step.'
          }
          breadCrumbs={props.breadCrumbs || [{ title: 'Tests' }]}
        />
        <Tabs active={activeTab} onClick={setActiveTab}>
          <Tab id={'purchased'} title={"Purchased Test's"}>
            <div className="row" data-bundle-type="purchased-bundles">
              {testList && testList?.UserTestBundles?.length
                ? testList?.UserTestBundles.map((test: any, index: any) => (
                    <TestTopicCard
                      key={index}
                      data={{
                        title: test.name,
                        testType: test.course?.name,
                        testCount: test.tests?.length,
                        duration: '',
                        cost: test.price,
                        thumbnailUrl: test.displayPicture,
                        isSubsribed: true,
                        _id: test._id,
                      }}
                      onClick={() => props.onTestBundleClick(test._id)}
                      getAssetUrl={getAssetUrl}
                      imageBaseUrl={imageBaseUrl}
                    />
                  ))
                : null}
            </div>
          </Tab>
          <Tab id={'available'} title={"Available Test's"}>
            {testBundles.Bundles && testBundles.Bundles.length ? (
              <div data-bundle-type="available-bundles">
                <h5>Available Test </h5>
                <div className="row">
                  {testBundles.Bundles.map((test: any, index) => (
                    <TestTopicCard
                      key={index}
                      data={{
                        title: test.name,
                        testType: test.course?.name,
                        testCount: test.tests?.length,
                        duration: '',
                        cost: test.price,
                        thumbnailUrl: test.displayPicture,
                        _id: test._id,
                      }}
                      getAssetUrl={getAssetUrl}
                      onClick={() =>
                        props.onTestBundleDetailsClickToSub(test._id)
                      }
                      imageBaseUrl={imageBaseUrl}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div>Test will be available soon</div>
            )}
          </Tab>
        </Tabs>
      </DashboardLayout>

      {activeTab === 'purchased'
        ? testList?.totalItems > 0
        : testBundles.totalItems > 10 && (
            <div className="mt-3">
              <Pagination
                totalItems={testBundles.totalItems}
                itemsPerPage={10}
                currentPage={params.page || 1}
                onPageChange={(page: number) => setParams({ ...params, page })}
              />
            </div>
          )}
    </React.Fragment>
  );
};
