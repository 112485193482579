import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from '../types';
import { IQuestionDocument, IQuestionItem } from '../question';

export interface ITestListParams extends IListParams {
  exam?: ObjectId;
  course?: ObjectId;
  subject?: ObjectId;
  section?: ObjectId;
  topic?: ObjectId;
  addedBy?: ObjectId;
}

export interface ITestListResponse {
  questionSets: ITest[];
  totalItems: number;
}

// export interface ITestSection {
//   section: ObjectId | PopulatedDocument;
//   questions: [
//     {
//       question: ObjectId | IQuestionDocument;
//       points: number;
//     }
//   ];
//   timeLimit: number;
// }

export interface IQuestion {
  displayOrder: number;
  subject: ObjectId | PopulatedDocument;
  topic: ObjectId | PopulatedDocument;
  question: ObjectId | IQuestionDocument;
  subQuestion: ObjectId | IQuestionItem;
  points: number;
  negativePoints?: number;
  isPartialMarking?: boolean;
}

export interface ISectionSetting {
  isTimeLimitEnabled: boolean;
  timeMapping: [
    {
      section: string | PopulatedDocument;
      timeLimit: number;
      isSubmitted: boolean;
    }
  ];
}

export interface IBooleanValueSetting {
  isEnabled: boolean;
  value: number;
}

export interface IProctoredTest {
  isEnabled: boolean;
  startDate: string | null;
  isEndDateRequired: false;
  endDate: string | null;
}

export interface QuestionSection {
  section: ObjectId;
  questions: IQuestion[];
  timeLimit: number;
}
export interface QuestionSet {
  name: string;
  sections: QuestionSection[];
}
export interface ITest {
  name: string;
  instruction: string;
  displayPicture: string;
  exam: ObjectId | PopulatedDocument;
  examType: string;
  isDescriptive: Boolean;
  sectionalSetting: ISectionSetting;
  totalTiming: IBooleanValueSetting;
  negativeMarking: IBooleanValueSetting;
  notAttempted: IBooleanValueSetting;
  totalQuestions: number;
  totalDurationInMinute: number;
  totalMarks: number;
  futureTest: boolean;
  isDisplayResult: boolean;
  isDisplayCalculator?: boolean;
  noOfAttempts?: number;
  sections: ObjectId[] | PopulatedDocument[];
  subjects: ObjectId[] | PopulatedDocument[];
  topics: ObjectId[] | PopulatedDocument[];
  proctoredTest?: IProctoredTest;
  video?: string;
  enableVirtualKeyboard?: string;
  enablePhysicalKeyboard?: string;
  questionSets?: any;
  editBy?: string;
  displayOrder?: number;
  isPublic?: boolean;
  slug?: string;
  isSectionalTimeLimit: boolean;
  shinkanProctoredTest: boolean;
  questionRandomization: boolean;
}

export interface ITestDocument extends ITest, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<ITestDocument>(`/testv2/test/${id}`, { params });
};

const create = (questionSet: ITest) => {
  return client.post<ITestDocument>(`/testv2/test`, questionSet);
};

const update = (id: string, questionSet: ITestDocument) => {
  return client.patch<ITestDocument>(
    `/testv2/test/${id || questionSet._id}`,
    questionSet
  );
};

const testReport = (params: ITestListParams) => {
  return client.get<ITestListResponse>(`/testv2/test/report`, {
    params,
  });
};

const list = (params: ITestListParams) => {
  return client.get<ITestListResponse>(`/testv2/test`, { params });
};

const publicList = (params: ITestListParams) => {
  return client.get<ITestListResponse>(`/testv2/test/public`, { params });
};

export const test = { get, create, update, list, testReport, publicList };
