// import { MdAttachFile } from 'react-icons/md';
import React from 'react';
import { IFileToUpload } from './file-uploader.types';
// import { UploadingContainer } from './file-upload.styled';
import { truncateFileName } from './utils';
import { AiFillCloseCircle } from 'react-icons/ai';

export const FileUploading = ({
  file,
  onCancel,
  isCancelAllowed,
}: {
  file: IFileToUpload;
  onCancel: any;
  isCancelAllowed: any;
}) => {
  const cancelFile = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <div className="file-uploading">
        <div
          className="remove-icon"
          onClick={() => {}}
          style={{ display: 'none' }}
        >
          <AiFillCloseCircle />
        </div>
        <div className="icon">
          {file.isUploading && <span className="loader loader-md"></span>}
        </div>
        {isCancelAllowed ? (
           <div
           className="remove-icon"
           onClick={cancelFile}
         >
           <AiFillCloseCircle />
         </div>
        ) : null}
        <div className="wrap">
          <p>{truncateFileName(file.name, 8)}</p>
          <small>
            {file.isUploading ? `${file.uploadPercent}%` : 'Uploaded.'}
          </small>
        </div>
      </div>
      {/* <UploadingContainer>
      <MdAttachFile className={'mr-12pt'} />
      <div className={'flex  mr-12pt'}>
        <div className="card-title">{truncateFileName(file.name, 8)}</div>
        <p className="flex text-black-50 lh-1 mb-0">
          <small>
            {file.isUploading ? `${file.uploadPercent}%` : 'Uploaded.'}
          </small>
        </p>
      </div>
      {file.isUploading && <span className="loader loader-sm"></span>}
      </UploadingContainer> */}
    </>
  );
};
