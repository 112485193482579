import { Form, Select } from '@lipihipi/form';
import { cloneDeep } from 'lodash';
import React from 'react';
import Explanation from '../explanation';
import { convertTime, difficultyLevels } from '../utility';

const Question = ({
  tableData,
  // imageBaseUrl,
  sections,
  examType,
  attemptData,
}: any) => {
  const [modal, setModal] = React.useState<any>({
    visible: false,
    questionData: '',
  });
  const [questions, setQuestions] = React.useState(tableData);
  const [filterData, setFilterData] = React.useState<any>({
    section: '',
    difficulty: '',
  });

  React.useEffect(() => {
    loadWIRISplugins();
  }, []);

  const handleClose = () => {
    setModal({ visible: false, questionData: '' });
  };

  const loadWIRISplugins = () => {
    // @ts-ignore
    if (window !== undefined) {
      // @ts-ignore
      const _window = window as any;
      const script = _window.document.getElementById('WIRISplugins') || false;
      if (!!script) {
        script.parentElement.removeChild(script);
      }
      const url =
        'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
      const jsDemoImagesTransform = _window.document.createElement('script');
      jsDemoImagesTransform.type = 'text/javascript';
      jsDemoImagesTransform.id = 'WIRISplugins';
      jsDemoImagesTransform.src = url;
      _window.document.head.appendChild(jsDemoImagesTransform);
    }
  };

  const handleSectionChange = (data: any) => {
    setFilterData((oldData: any) => ({
      ...oldData,
      section: data.label === 'Section Name/All' ? '' : data.label,
    }));
    if (data?.value === '' && filterData?.difficulty === '') {
      setQuestions(tableData);
      return;
    }

    const prevData = cloneDeep(tableData);
    if (filterData?.difficulty) {
      if (data?.value) {
        const currentData = prevData[data?.label];
        const arr = currentData.question.filter((v: any) => {
          return (v?.difficulty || v?.difficulty) === filterData?.difficulty;
        });
        currentData.question = arr;
        const obj: any = {};
        obj[data?.label] = currentData;
        setQuestions(obj);
        return;
      } else {
        Object.entries(prevData).map(([key]: any) => {
          if (key === filterData?.section) {
            if (data.label !== 'Section Name/All') {
              filterDropDownData(prevData, data?.label, filterData?.difficulty);
            }
          } else if (!data?.value) {
            setQuestions(tableData);
            return;
          } else {
            filterDropDownData(prevData, data?.label, filterData?.difficulty);
          }
        });
        setQuestions(prevData);
      }
    } else {
      if (data.label !== 'Section Name/All') {
        setDefaultData(prevData, data?.label);
      }
    }
  };

  const setDefaultData = (prevData: any, key: any) => {
    const filteredData = prevData[key];
    const obj: any = {};
    obj[key] = filteredData;
    setQuestions(obj);
  };

  const filterDropDownData = (prevData: any, key: any, condition: any) => {
    const currentData = prevData[key];
    const arr = currentData.question.filter((v: any) => {
      return v?.difficulty === condition;
    });
    currentData.question = arr;
  };

  const handleDifficultyChange = (data: any) => {
    setFilterData((oldData: any) => ({ ...oldData, difficulty: data.value }));
    const prevData = cloneDeep(tableData);
    if (data?.value === '' && filterData?.section === '') {
      setQuestions(tableData);
      return;
    }
    if (filterData?.section) {
      if (data?.value === '') {
        setDefaultData(prevData, filterData?.section);
        return;
      }
      if (filterData?.section) {
        const dataCheck = filterData?.section
          ? { [filterData?.section]: prevData[filterData?.section] }
          : prevData;
        filterDropDownData(dataCheck, filterData?.section, data?.value);
        setQuestions(dataCheck);
        return;
      } else {
        Object.entries(prevData).map(([key]: any) => {
          if (key === filterData?.section) {
            filterDropDownData(prevData, filterData?.section, data?.value);
          } else {
            prevData[key].question = [];
          }
        });
      }
    } else {
      Object.entries(prevData).map(([key]: any) => {
        filterDropDownData(prevData, key, data?.value);
      });
    }
    setQuestions(prevData);
  };

  const renderQuestionData = (value: any) => {
    return (
      <>
        {value?.length > 0 ? (
          value?.map((ques: any) => {
            return (
              <div className="wrap" key={ques?.displayOrder}>
                <div className="secondary-table--col">{ques?.displayOrder}</div>
                <div className="secondary-table--col">
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ques?.question?.text,
                      }}
                    ></div>
                  </p>
                </div>
                <div className="secondary-table--col">
                  {ques?.difficulty || 'NA'}
                </div>
                <div className="secondary-table--col">
                  +{ques?.points || '0'}/
                  {ques?.negativePoints ? `-${ques?.negativePoints}` : '0'}
                </div>
                <div className="secondary-table--col">
                  {ques?.score}
                </div>
                <div className="secondary-table--col">
                  {convertTime(ques?.timeTaken) || 'NA'}
                </div>
                <div className="secondary-table--col">
                  {ques?.status === 'ANSWERED' && ques.isCorrect && (
                    <span>
                      <img
                        src={`https://edu-g4u5lp3d896i.s3.ap-south-1.amazonaws.com/correct-answer-icon.png`}
                        alt=""
                      />
                    </span>
                  )}
                  {ques?.status === 'ANSWERED' && !ques.isCorrect && (
                    <span>
                      <img
                        src={`https://edu-g4u5lp3d896i.s3.ap-south-1.amazonaws.com/wrong-answer-icon.png`}
                        alt=""
                      />
                    </span>
                  )}
                  {ques?.status === 'NOT VISITED' && (
                    <span>
                      <img
                        src={`https://edu-g4u5lp3d896i.s3.ap-south-1.amazonaws.com/skip-answer-icon.png`}
                        alt=""
                      />
                    </span>
                  )}
                  {ques?.status === 'VISITED' && (
                    <span>
                      <img
                        src={`https://edu-g4u5lp3d896i.s3.ap-south-1.amazonaws.com/skip-answer-icon.png`}
                        alt=""
                      />
                    </span>
                  )}
                  {/* { ques?.status ===  "ANSWERED" ?   <span><img src={`${imageBaseUrl}correct-answer-icon.png`}  alt="" /></span>  :  <span> <img src={`${imageBaseUrl}wrong-answer-icon.png`} alt="" /> </span>} */}
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      setModal({
                        visible: true,
                        questionData: ques,
                      })
                    }
                  >
                    View Explanation
                  </a>
                </div>
              </div>
            );
          })
        ) : (
          <h5>No data found.</h5>
        )}
      </>
    );
  };

  return (
    <>
      {examType === 'FULL LENGTH' && sections?.length > 0 && (
        <Form initialValues={{ section: '' }}>
          <div className="row">
            <div className="col-md-1 align-self-center">
              <h6>Filter By:</h6>
            </div>
            <div className="col-md-2">
              <Select
                id="section"
                name="section"
                placeholder="Sections"
                options={[
                  { label: 'Section Name/All', value: '' },
                  ...sections,
                ]}
                onChange={event => handleSectionChange(event)}
              />
            </div>
            <div className="col-md-2">
              <Select
                id="difficulty"
                name="difficulty"
                placeholder="Difficulty Level"
                options={difficultyLevels}
                onChange={event => handleDifficultyChange(event)}
              />
            </div>
          </div>
        </Form>
      )}
      <div className="secondary-table detailed-question-table">
        <div className="secondary-table--header">
          <div className="secondary-table--row">
            <div className="secondary-table--col">Q.No</div>
            <div className="secondary-table--col">Question</div>
            <div className="secondary-table--col">Difficulty Level</div>
            <div className="secondary-table--col">Marks</div>
            <div className="secondary-table--col">Obtained Marks</div>
            <div className="secondary-table--col">Time Taken</div>
            <div className="secondary-table--col">Status</div>
          </div>
        </div>

        <div className="secondary-table--body">
          <div className="secondary-table--row">
            {examType === 'FULL LENGTH' && Object.keys(questions).length > 0
              ? Object.entries(questions).map(([key, value]: any) => (
                  <>
                    <h5>{key}</h5>
                    {value?.question ? (
                      renderQuestionData(value?.question)
                    ) : (
                      <div>No Data Found</div>
                    )}
                  </>
                ))
              : renderQuestionData(attemptData?.questions)}
          </div>
        </div>
      </div>
      <Explanation
        isOpen={modal.visible}
        questionList={modal.questionData}
        onRequestClose={handleClose}
      />
    </>
  );
};

export default Question;
