import React, { useState, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { Form, Select, CheckBox, Input } from '@lipihipi/form';
import { Button, Modal, Table } from '@lipihipi/ec-ui';
import EduCrackAPI from '@lipihipi/client-sdk';

const mapOptions = (values: any[]) => {
  return values.map(value => ({
    label: value?.name || '',
    value: value?._id || '',
  }));
};

export const SelectInterviewModal = ({
  isOpen,
  onRequestClose,
  assignment,
  sectionSubjects,
  setSelected,
  assignedInterview,
}: any) => {
  const [params, setParams] = useState<any>({
    populate: true,
    page: 1,
    course: assignment?.course?._id,
  });
  const [interviews, setInterview] = useState<any>({
    totalItems: 0,
    interviews: [],
  });
  const [selectedInterview, setSelectedInterview] = React.useState<any>([]);
  const [subjects, setSubjects] = React.useState<any>([]);
  const [topics, setTopics] = React.useState<any>([]);

  useEffect(() => {
    EduCrackAPI.interview
      .list({ ...params, all: true, populate: true })
      .then((res: any) => {
        setInterview(res.data);
      });
  }, [params]);

  useEffect(() => {
    const _subjects = sectionSubjects.map((item: any) => {
      return item.subject;
    });
    setSubjects(_subjects);
  }, [sectionSubjects]);

  const getTopicHandler = (data: any) => {
    const _topics =
      sectionSubjects.find((item: any) => item.subject._id === data.value)
        ?.topics || [];
    setTopics(_topics);
  };

  const handleSearch = (values: any) => {
    setParams((prev: any) => ({
      ...prev,
      q: values.q,
      subject: values.subject,
      topic: values.topic,
    }));
  };

  const handleCheckbox = (isChecked: boolean, value: any) => {
    if (isChecked) {
      setSelectedInterview([...selectedInterview, value]);
    } else {
      setSelectedInterview(
        selectedInterview.filter(
          (interview: any) => interview._id !== value._id
        )
      );
    }
  };

  const getCheckStatus = (_id: any) => {
    const data = assignedInterview.filter((item: any) => item._id === _id);
    return !!data.length;
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="question-bank">
        <div>
          <div style={{ marginBottom: '20px' }}>
            <h3>You are assigning to: {assignment?.name || '--'}</h3>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <div className="row">
              <div className="col-12 col-md-12">
                <Form
                  initialValues={{
                    q: '',
                    subject: '',
                    topic: '',
                  }}
                  onSubmit={handleSearch}
                  render={({ submitForm }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <Input
                              prefix={<MdSearch />}
                              id="q"
                              name="q"
                              label="Interview Name"
                              placeholder="Enter here to search for interview name"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <Select
                              id="subject"
                              name="subject"
                              label="Subjects"
                              placeholder="Select subjects"
                              options={[
                                { label: 'ALL', value: '' },
                                ...mapOptions(
                                  subjects !== undefined ? subjects : []
                                ),
                              ]}
                              onChange={(data: any) => {
                                submitForm();
                                getTopicHandler(data);
                              }}
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <Select
                              id="topic"
                              name="topic"
                              label="Topics"
                              placeholder="Select topics"
                              options={[
                                { label: 'ALL', value: '' },
                                ...mapOptions(
                                  topics !== undefined ? topics : []
                                ),
                              ]}
                              onChange={submitForm}
                            />
                          </div>

                          <Button
                            shape="primary"
                            className="d-none"
                            type="submit"
                          >
                            Search
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ minHeight: '100px' }}>
          <Table
            data={interviews.interviews}
            columns={[
              {
                dataRenderer: (data: any) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form
                        initialValues={{
                          [data._id]: getCheckStatus(data._id),
                        }}
                      >
                        <CheckBox
                          id={data._id}
                          name={data._id}
                          onChange={(event: any) => {
                            handleCheckbox(event.target.checked, data);
                          }}
                        />
                      </Form>

                      {data.name}
                    </div>
                  </>
                ),
                title: 'Interview Name',
              },
              // {
              //     dataRenderer: (data: any) => <>{data?.examType}</>,
              //     title: 'Interview Type',
              // },
              {
                dataRenderer: (data: any) => <>{data?.description}</>,
                title: 'Description',
              },
            ]}
          />
        </div>

        <div className="question-bank--footer">
          <Button shape="secondary" onClick={onRequestClose}>
            Back
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            shape="primary"
            onClick={() => {
              if (selectedInterview.length > 0) {
                setSelected(selectedInterview);
                onRequestClose();
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
};
