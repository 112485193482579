import React, { useEffect, useState } from 'react';
import { Button, Table } from '@lipihipi/ec-ui';
import { CheckBox, Form, Input, Select } from '@lipihipi/form';
import swal from 'sweetalert';
import { FormSchemaSelectAssign } from './../../../src';

const mapOptions = (values: any[]) => {
  return values.map(value => ({ label: value.name, value: value._id }));
};

export const SelectAssignee = ({
  assignment,
  getCourses,
  getBatches,
  getGroups,
  getStudents,
  getBatchStudents,
  setAssignmentCallback,
  createAssignment,
  updateAssignment,
  nextStep,
}: any) => {
  const [courses, setCourses] = useState<any>({
    totalItems: 0,
    courses: [],
  });

  const [batches, setBatches] = useState<any>({
    totalItems: 0,
    batches: [],
  });

  const [groups, setGroups] = useState<any>({
    totalItems: 0,
    groups: [],
  });

  const [students, setStudents] = useState<any>([]);
  const [selectedStudent, setSelectedStudent] = useState<any>([]);
  const [state, setState] = useState<any>({});
  const [invalidStudent, setInvalidStudent] = useState<any>(false);

  useEffect(() => {
    _getCourses();
    _getBatches();
    _getGroups();
  }, []);

  useEffect(() => {
    const { type, groupId } = state;
    if (type) {
      _getStudents({ type, groupId });
    }
  }, []);

  useEffect(() => {
    if (assignment?._id) {
      const studentsObj = assignment.students.map((student: any) => {
        return {
          name: student.name,
          _id: student._id,
          email: student.email,
        };
      });
      const studentId = assignment.students.map((student: any) => student._id);

      setStudents(studentsObj);
      setSelectedStudent([...studentId]);
    }
  }, [assignment]);

  const _getCourses = () => {
    getCourses({ all: true }).then((response: any) => {
      setCourses(response.data);
    });
  };

  const _getBatches = () => {
    getBatches({ all: true }).then((response: any) => {
      setBatches(response.data);
    });
  };

  const _getGroups = () => {
    getGroups({ all: true }).then((response: any) => {
      setGroups(response.data);
    });
  };

  const _getStudents = (data: any) => {
    getStudents({ all: true, ...data })
      .then((res: any) => {
        const data = res.data.users;

        const students = data.map((student: any) => {
          return {
            _id: student?._id,
            name: student?.name,
            email: student?.email,
          };
        });

        setStudents(students);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const _getBatchStudents = (data: any) => {
    getBatchStudents(data?.batchId, {
      all: true,
      populate: true,
      isRecent: true,
    })
      .then((res: any) => {
        const data = res.data.students;
        const students: any = [];
        const tempStudents: any = {};

        data.forEach((batch: any) => {
          const student = batch?.user;
          const _id = student?._id;
          const name = student?.name;
          const email = student?.email;

          if (!tempStudents[_id]) {
            students.push({
              _id: _id,
              name: name,
              email: email,
            });
            tempStudents[_id] = true;
          }
        });

        setStudents(students);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const onSelectBatchOrGroupHandler = (data: any) => {
    setState({
      ...state,
      ...data,
    });

    if (data.type === 'BATCH') {
      _getBatchStudents(data);
    } else {
      _getStudents(data);
    }
  };

  const onSelectAllStudent = (isChecked: boolean) => {
    setSelectedStudent([]);
    setInvalidStudent(false);

    if (isChecked) {
      students.forEach((data: any) => {
        setSelectedStudent((prevState: any) => [...prevState, data?._id]);
      });
    }
  };

  const onChangeCheckBoxHanlder = (event: any, data: any) => {
    if (event.target.checked) {
      setSelectedStudent((prevState: any) => [...prevState, data?._id]);
    } else {
      const filterValue = selectedStudent.filter((item: any) => {
        return item !== data?._id;
      });
      setSelectedStudent([...filterValue]);
    }

    setInvalidStudent(false);
  };

  const getCheckStatus = (_id: any) => {
    const data = selectedStudent.filter((item: any) => item === _id);
    return !!data.length;
  };

  const handleSubmit = (values: any) => {
    if (!selectedStudent.length) {
      setInvalidStudent(true);
      return;
    }

    let payload: any = {
      name: values.name,
      course: values.course,
      assign_type: values.assign_type,
      active: values.active || false,
      students: selectedStudent,
      _id: assignment._id || undefined,
    };

    if (values?.batch) {
      payload.batch = values.batch;
    }

    if (values?.group) {
      payload.group = values?.group;
    } else {
      payload.group = null;
    }

    const fn = assignment._id ? updateAssignment : createAssignment;

    fn(payload)
      .then((res: any) => {
        if (res.status === 200) {
          swal({
            title: 'Success',
            text: 'Assignment Created',
            icon: 'success',
            dangerMode: false,
          }).then(() => {
            setAssignmentCallback(res.data);
            nextStep();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
      .catch(() => {
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
          dangerMode: true,
        });
      });
  };

  return (
    <>
      <Form
        initialValues={{
          name: assignment?.name || '',
          course: assignment?.course?._id || '',
          assign_type: assignment?.assign_type || '',
          batch: assignment?.batch?._id,
          group: assignment?.group?._id || null,
          active: assignment?.active || false,
        }}
        validationSchema={FormSchemaSelectAssign}
        onSubmit={handleSubmit}
        render={({ values, setFieldValue }: any) => {
          return (
            <>
              <div className="row">
                <div className="col-6 col-md-3">
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    label="Assignment Name"
                    placeholder="Enter assignment name"
                    required
                  />
                </div>

                <div className="col-6 col-md-3">
                  <Select
                    id="course"
                    name="course"
                    label="Course"
                    placeholder="Course"
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapOptions(courses.courses),
                    ]}
                    required
                  />
                </div>

                <div className="col-6 col-md-3">
                  <Select
                    id="assign_type"
                    name="assign_type"
                    label="Candidate Group Type"
                    placeholder="Select a Candidate group type"
                    options={[
                      { label: 'Batch', value: 'BATCH' },
                      { label: 'Group', value: 'GROUP' },
                    ]}
                    onChange={() => {
                      setFieldValue('group', null);
                      setFieldValue('batch', null);
                      setStudents([]);
                    }}
                  />
                </div>

                <div className="col-6 col-md-3">
                  {values.assign_type === 'BATCH' ? (
                    <Select
                      id={'batch'}
                      name={'batch'}
                      label="Select a Candidate Batch"
                      placeholder="Select a Candidate batch"
                      options={[...mapOptions(batches.batches)]}
                      onChange={(e: any) => {
                        onSelectBatchOrGroupHandler({
                          type: values.assign_type,
                          batchId: e.value,
                          groupId: '',
                        });
                      }}
                    />
                  ) : (
                    ''
                  )}

                  {values.assign_type === 'GROUP' ? (
                    <Select
                      id={'group'}
                      name={'group'}
                      label="Select a Candidate Group or no group"
                      placeholder="Select a Candidate group type"
                      options={[
                        { label: 'No Group', value: '' || null },
                        ...mapOptions(groups.groups),
                      ]}
                      onChange={(e: any) => {
                        onSelectBatchOrGroupHandler({
                          type: e.value === '' ? 'NOGROUP' : values.assign_type,
                          groupId: e.value,
                          batchId: '',
                        });
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="form-group mt-3 d-flex justify-content-between">
                      <h5 style={{ textTransform: 'none', marginBottom: 0 }}>
                        Candidates
                      </h5>
                      <div
                        className="d-flex align-items-center justify-content-end"
                        style={{ marginBottom: '-76px' }}
                      >
                        {students.length ? (
                          <CheckBox
                            id="selectAll"
                            name="selectAll"
                            label="Select All"
                            onChange={(event: any) => {
                              onSelectAllStudent(event.target.checked);
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    {invalidStudent ? (
                      <p className="invalid-feedback d-block">
                        Candidate is required
                      </p>
                    ) : (
                      ''
                    )}

                    <Table
                      data={students}
                      columns={[
                        {
                          dataRenderer: (data: any) => (
                            <div className="subject">{data?.name}</div>
                          ),
                          title: 'Name',
                          width: '300px',
                        },
                        {
                          dataRenderer: (data: any) => (
                            <div className="subject">{data?.email}</div>
                          ),
                          title: 'Email',
                          width: 'calc(100% - (100px + 100px + 200px)',
                        },
                        {
                          dataRenderer: (data: any) => (
                            <>
                              <Form
                                initialValues={{
                                  [data._id]: getCheckStatus(data._id),
                                }}
                              >
                                <CheckBox
                                  id={data?._id}
                                  name={data?._id}
                                  onChange={(event: any) => {
                                    onChangeCheckBoxHanlder(event, data);
                                  }}
                                />
                              </Form>
                            </>
                          ),
                          title: '',
                          width: '100px',
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group d-flex justify-content-end">
                    <div className="d-flex align-items-center justify-content-end">
                      <CheckBox id="active" name="active" label="Enabled" />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button className="ml-3" shape="primary" type="submit">
                      Save and Continue
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default SelectAssignee;
