import React, { memo, ReactChild, FC } from 'react';
import styled from 'styled-components';

const TabLiStyled = styled.li<{ active: boolean }>`
  // display: inline-block;
  // margin-right: 30px;
  // cursor: pointer;
  // font-weight: ${({ active }) => (active ? 700 : 500)};
  // border-bottom: 3px solid
  //   ${({ active }) => (active ? '#ffe200' : 'transparent')};
  // padding-top: 5px;
  // padding-bottom: 5px;
  // font-size: 14px;
  // color: #262424;
`;

export const Tab = styled.div`
  // padding-top: 20px;
  // padding-bottom: 20px;
`;

interface TabLiProps {
  title: string;
  active?: string;
  id: string;
  onClick: Function;
}

const TabLi: FC<TabLiProps> = ({ title, active, id, onClick }) => (
  <TabLiStyled
    active={active === id}
    className={`${active === id ? 'active' : ''}`}
    onClick={() => onClick(id)}
    data-tab-name={id}
  >
    {title}
  </TabLiStyled>
);

const renderTabList = (
  children: ReactChild[],
  active: string,
  onClick: Function
) => {
  return React.Children.map(children, ({ props }: any) => (
    <TabLi {...props} active={active} onClick={onClick} />
  ));
};

const renderTabContent = (children: ReactChild[], active: string) => {
  return React.Children.map(
    children,
    ({ props }: any) => active === props.id && <Tab {...props} />
  );
};

interface TabsProps {
  children: ReactChild[];
  active: string;
  onClick: Function;
  tabName?: string;
}

export const Tabs: FC<TabsProps> = memo(
  ({ tabName = '', children, active, onClick }) => {
    return (
      <div className="primary-tabs" id={tabName}>
        <ul className="primary-batch-tabs">
          {renderTabList(children, active, onClick)}
        </ul>

        {/* <TabUl className="tabs-list">{renderTabList(children, active, onClick)}</TabUl> */}
        <div className="primary-batch-tabs--content">
          {renderTabContent(children, active)}
        </div>
      </div>
    );
  }
);
