import { Loader } from '@lipihipi/ec-ui';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Test from './test';
import { SubmitAnswerInterface, TestInterface } from './test.types';

import { loadWIRISplugins, setOnlineStatus, toggleFullScreen } from './utility';

export const TestLayoutSelection = ({
  supportTicketCreate,
  getAssetUrl,
  createTestAttempt,
  getTestAttempt,
  submitAnswer,
  submitTestAttempt,
  onTestSubmit,
  testId,
  imageBaseUrl,
  user,
  tenantId,
  token,
  userLogin,
  setToken,
  createAsset,
}: // onCompletedOrTimeOut,
TestInterface) => {
  const [testAttempt, setTestAttempt] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });
    window.addEventListener('online', () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });
      window.removeEventListener('online', () => {
        setOnlineStatus(true);
      });
      unloadShinkanScript();
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        if (token) {
          const { data } = await userLogin({ token });
          if (data?.token) {
            setToken(data.token);
          }
        }
        const testAttemptData = await createTestAttempt({
          test: testId,
        });

        if (testAttemptData.data._id) {
          const { data } = await getTestAttempt(testAttemptData.data._id);
          setTestAttempt({ ...data });
          if (data?.test?.shinkanProctoredTest && tenantId) {
            loadShinkanScript(
              testAttemptData.data._id,
              testAttemptData?.data?.test?.name,
              testAttemptData?.data?.test?.exam?.name
            );
          }
        } else {
          setLoading(false);
          const modalData = await Swal.fire({
            icon: 'success',
            title: '',
            text: testAttemptData?.data?.message,
            allowOutsideClick: false,
            showConfirmButton: true,
          });
          if (modalData?.isConfirmed) window.close();
          return;
        }
        setLoading(false);
      } catch (err) {
        if (err?.status === 404) {
          setLoading(false);
          apiError('Sorry', 'Attempt not found.', 'warning');
        } else {
          setLoading(false);
          apiError(
            err?.data?.message || 'Server Error',
            err?.data?.message ? '' : 'Something went wrong',
            'error'
          );
        }
      }
    };
    init();
    loadWIRISplugins();
    toggleFullScreen();
  }, []);

  const apiError = (title: any, text: any, icon: any) => {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText: 'Ok',
    }).then(result => {
      if (result) {
        window.close();
      }
    });
  };

  const loadShinkanScript = (
    attemptId: any,
    testName: string,
    examName: string
  ) => {
    window.parent.postMessage(
      {
        TeamUniqueId: tenantId, //Mandatory
        msg: 'start', //Mandatory
        ExamTitle: examName, //Optional
        // 'ExamDetails': '', //Optional
        EnrolmentNumber: attemptId, //Optional
        Name: testName, //Optional
        StudentId: user?.eduId, //Optional
        // 'AttemptNumber':' ', //Optional
      },
      '*'
    );
  };

  const unloadShinkanScript = () => {
    window.parent.postMessage(
      {
        msg: 'stop',
      },
      '*'
    );
  };

  useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());
    return () => {
      document.removeEventListener('contextmenu', () => {});
    };
  }, []);

  const submitAnswers = async (
    attemptId: string,
    activeQuestion: any,
    timeTaken: number,
    sectionId: string,
    groupName: string
  ) => {
    const typedAnswers = activeQuestion?.typedAnswers || [];
    const ans =
      activeQuestion?.question?.type === 'RE-ARR'
        ? typedAnswers[0]
        : [...typedAnswers] || [];
    const payload: SubmitAnswerInterface = {
      isMarkForReview: activeQuestion?.isMarkForReview,
      questionId: activeQuestion?._id,
      // @ts-ignore
      timeTaken: timeTaken,
    };

    if (activeQuestion?.selectedAnswers?.length) {
      payload.selectedAnswers = activeQuestion?.selectedAnswers?.length
        ? [...activeQuestion?.selectedAnswers]
        : [];
    }
    if (activeQuestion?.typedAnswers?.length) {
      payload.typedAnswers = ans;
    }
    if (groupName) payload.group = groupName;
    if (
      sectionId &&
      (testAttempt?.test?.exam?.name === 'CAT' ||
        testAttempt?.test?.exam?.name === 'NMAT')
    )
      payload.section = sectionId;
    if (activeQuestion?.question?.type === 'DSC') {
      payload.answerImages = activeQuestion.answerImages;
    }
    const { data } = await submitAnswer(attemptId, payload);
    return data;
  };

  const renderTest = () => {
    if (testAttempt?.test?.exam) {
      return (
        <Test
          user={user}
          testAttempt={testAttempt}
          submitAnswer={submitAnswers}
          imageBaseUrl={imageBaseUrl}
          submitTestAttempt={submitTestAttempt}
          onTestSubmit={onTestSubmit}
          supportTicketCreate={supportTicketCreate}
          getAssetUrl={getAssetUrl}
          createAsset={createAsset}
        />
      );
    }
    return <div />;
  };

  return <>{loading ? <Loader /> : renderTest()}</>;
};
