import React from 'react';
import Styled, { css } from 'styled-components';
import { BadgeProps } from './badge.types';

const CustomBadge = Styled.span<BadgeProps>`
    font-size: 13px;
  ${props =>
    props.variant === 'contained' &&
    css`
      background: white;
      color: black;
    `}

  ${props =>
    props.variant === 'outlined' &&
    css`
      background: white;
      color: black;
      border: ${(props: BadgeProps) =>
        props.borderColor === 'primary'
          ? '1px solid #47b3bb'
          : '1px solid #e6e6e6'};
    `}

    ${props =>
      props.disabled
        ? css`
            opacity: ${(props: BadgeProps) => (props.disabled ? '0.3' : '1')};
          `
        : css`
            &:hover {
              background: #47b3bb;
              color: white;
              cursor: pointer;
            }
          `}
`;

export const Badge = ({
  id,
  variant,
  className,
  children,
  borderColor,
  disabled,
  ...rest
}: BadgeProps) => {
  return (
    <React.Fragment>
      <CustomBadge
        id={id}
        variant={variant}
        borderColor={borderColor}
        disabled={disabled}
        className={`${className} badge badge-pill py-2 px-3`}
        {...rest}
      >
        {children}
      </CustomBadge>
    </React.Fragment>
  );
};
