import { client } from './client';
import {
  ObjectId,
  TCreateResource,
  TGetResource,
  TUpdateResource,
} from './types';

export interface ITenantToken {
  email: string;
}

export interface ITenantResponse {
  message: string;
}

export interface ITenantReq {
  token: string;
  password: string;
}

export interface ITenantUpdateReq {
  payment?: {
    key: string;
    secret: string;
    webhook: string;
  };
  smtp?: {
    host: string;
    port: string;
    user: string;
    password: string;
  };
  sms?: {
    secret: string;
  };
  styles?: {
    favicon?: ObjectId;
    onboarding?: ObjectId;
    onboardingText?: string;
    siteLoader?: ObjectId;
  };
  socials?: {
    meta?: string;
    insta?: string;
    twitter?: string;
    youtube?: string;
    linkedin?: string;
  };
}
const verifyToken: TGetResource<ITenantToken> = (token: string) => {
  return client.get<ITenantToken>(`/tenant/${token}`);
};

const createPassword: TCreateResource<ITenantReq, ITenantResponse> = (
  payload: ITenantReq
) => {
  return client.post<ITenantResponse>(`/tenant/create-password`, payload);
};
const get: TGetResource<any> = () => {
  return client.get(`/tenant`);
};
const updateSettings: TUpdateResource<ITenantUpdateReq> = (
  payload: ITenantUpdateReq
) => {
  return client.put(`/tenant`, payload);
};
const testEmail = () => {
  return client.post(`/tenant/test/email`);
};

export const tenant = {
  get,
  verifyToken,
  createPassword,
  updateSettings,
  testEmail,
};
