import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface ICoursewareListParams extends IListParams {
  course?: ObjectId;
  fileType?: ObjectId;
  subject?: ObjectId;
  topic?: ObjectId;
  addedBy?: ObjectId;
  allItem?: Boolean;
}

export interface ICourseware {
  chapterName: string;
  subChapterName: string;
  keywords: string[];
  asset: string;
  course: ObjectId | PopulatedDocument;
  subject: ObjectId | PopulatedDocument;
  status: 'PENDING' | 'APPROVED' | 'UNAPPROVED';
}

export interface ICoursewareDocument extends ICourseware, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface ICoursewareListResponse {
  courseware: ICoursewareDocument[];
  totalItems: number;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<ICoursewareDocument>(`/courseware/${id}`, { params });
};

const create = (courseware: ICourseware) => {
  return client.post<ICoursewareDocument>(`/courseware`, courseware);
};

const update = (courseware: ICoursewareDocument) => {
  return client.put<ICoursewareDocument>(
    `/courseware/${courseware._id}`,
    courseware
  );
};

const approve = (id: string) => {
  return client.put<ICoursewareDocument>(`/courseware/${id}/status`, {
    status: 'APPROVED',
  });
};

const unapprove = (id: string) => {
  return client.put<ICoursewareDocument>(`/courseware/${id}/status`, {
    status: 'UNAPPROVED',
  });
};

const remove = (id: string) => {
  return client.delete(`/courseware/${id}`);
};

const list = (params: ICoursewareListParams) => {
  return client.get<ICoursewareListResponse>(`/courseware/`, { params });
};

export const courseware = {
  get,
  create,
  update,
  remove,
  list,
  approve,
  unapprove,
};
