import { UserRole } from './user';
import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  TListResource,
} from './types';

export interface IStudentProfile extends IStudent {
  followers: number;
  following: number;
  posts: number;
}

export interface IStudentCourse {
  course: ObjectId | PopulatedDocument;
  isActive?: boolean;
}
export interface IKyc {
  documentType: IKycFileType;
  asset: ObjectId;
  value: string;
}

enum IKycFileType {
  PAN = 'PAN',
  AADHAR_FRONT = 'AADHAR_FRONT',
  AADHAR_BACK = 'AADHAR_BACK',
}
enum IStudentStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
  PENDING = 'PENDING',
}
export interface IStudent {
  name: string;
  gender: string;
  mobile: string;
  email: string;
  group?: ObjectId | PopulatedDocument;
  displayPicture: string;
  selectedCourses?: IStudentCourse[];
  status?: IStudentStatus;
  isDeleted?: boolean;
  kycFile?: IKyc[];
}

export interface IStudentDocument extends IStudent, IDocument {
  studentProfileId: ObjectId;
}
enum IStudentQueryType {
  GROUP = 'GROUP',
  NOGROUP = 'NOGROUP',
  BATCH = 'BATCH',
}
export interface IStudentParam extends IListParams {
  type?: IStudentQueryType;
  groupId?: string;
  batchId?: string;
  course?: string;
}

export interface IStudentListResponse {
  totalItems: number;
  students: IStudentDocument[];
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IStudentProfile>(`/users/${id}`, {
    params: { ...params, role: UserRole.USER },
  });
};

const update = (id: string, student: IStudentDocument) => {
  return client.put<IStudentDocument>(
    `/users/${student.studentProfileId || id}`,
    student
  );
};

const list: TListResource<IStudentParam, IStudentListResponse> = params => {
  return client.get(`/users`, { params: { ...params, role: UserRole.USER } });
};

const getLoginToken = (id: string) => {
  return client.get(`/users/${id}/token`, {});
};

const login = (body: { token: string }) => {
  return client.post(`/users/login`, body);
};

export const studentProfile = {
  get,
  update,
  list,
  getLoginToken,
  login,
};
