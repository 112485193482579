import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
} from './types';

export interface IDiscussListParams extends IListParams {
  user?: ObjectId;
  populate?: boolean;
}

export interface IDiscuss {
  text: string;
  coursewareId?: ObjectId;
}

export interface IDiscussDocument extends IDiscuss, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IDiscussListResponse {
  totalItems: number;
  discussChat: IDiscussDocument[];
}

const list = (itemId: string, params?: IDiscussListParams) => {
  return client.get<IDiscussDocument>(`/discussions/${itemId}`, { params });
};

const create = (discuss: IDiscuss) => {
  return client.post<IDiscussDocument>(`/discussions`, discuss);
};

export const discussions = { create, list };
