import React, { useState, useEffect } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiX } from 'react-icons/bi';
import { FiThumbsUp, FiMessageSquare } from 'react-icons/fi';
import adminUser from './images/default-user.png';
import user from './images/user.png';
import moment from 'moment';
// import { Button } from '@lipihipi/ec-ui';
import { ActionButton, ListItemAction, Menu, Button } from '@lipihipi/ec-ui';

const PostDoubt = ({
  item,
  createComment,
  removePost,
  removeComment,
  User,
  createDoubtResponse,
  updateDoubtResponse,
  updateDoubt,
  doubtArr,
  setDoubtArr,
  getAssetUrl,
}: any) => {
  const [txt, setTxt] = useState<string>('');
  const [like, setLike] = useState<any>({ value: false, _id: '' });
  const [likeCount, setLikeCount] = useState<number>(0);
  const [commentsArr, setcommentsArr] = useState<any>([]);

  useEffect(() => {
    setLikeCount(item.likes);
    if (item?.response?.length && item?.response[0]?.isLiked) {
      setLike({ value: !!item.response[0].isLiked, _id: item.response[0]._id });
    }
    setcommentsArr(item.comments);
  }, []);

  const savehandler = () => {
    createComment({
      text: txt,
      doubt: item._id,
    }).then((res: any) => {
      setcommentsArr([
        ...commentsArr,
        {
          text: txt,
          _id: res.data?._id,
          addedBy: { name: User.name, _id: User._id },
        },
      ]);
      setTxt('');
    });
  };

  const commentSection = () => (
    <>
      <div className="post-card--footer">
        {commentsArr.length > 0 ? (
          <div className="added-comment">
            {commentsArr.map((i: any, index: number) => {
              return (
                <div key={index} className="wrap">
                  <img
                    style={{
                      height: '30px',
                      width: '30px',
                      borderRadius: '50%',
                    }}
                    src={
                      i?.addedBy && i?.addedBy?.displayPicture
                        ? getAssetUrl(i?.addedBy?.displayPicture)
                        : user
                    }
                    alt="user"
                  />
                  <div className="comment">
                    <span>{i?.addedBy?.name}</span>
                    <p>{i.text}</p>
                  </div>
                  {User.role === 'admin' ||
                    (User.role === 'superadmin' &&
                      User?._id === i.addedBy?._id && (
                        <div
                          className="comment-remove"
                          onClick={() => removeCommentHandler(i._id)}
                        >
                          <FaTrashAlt />
                        </div>
                      ))}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}

        {!item.answered.isAnswered ? (
          <div className="add-comment">
            <img src={user} alt="user" />
            <input
              placeholder={
                User.role === 'user' ? 'Ask your question' : 'Answer now'
              }
              value={txt}
              onChange={(e: any) => setTxt(e.target.value)}
            />
            {txt.length !== 0 && (
              <div className="comment-action">
                <span onClick={() => setTxt('')}>
                  <BiX />
                </span>
                <span onClick={savehandler}>
                  <FaTelegramPlane />
                </span>
              </div>
            )}
          </div>
        ) : null}
      </div>

      <div className="wallpost-card_comment">
        <div className="comment-text-wrapper"></div>
        <div className="comment-input-box">
          <div className="comment-user-box"></div>
        </div>
      </div>
    </>
  );

  const handleDoubtResolve = (isResolved: boolean) => {
    updateDoubt({
      _id: item._id,
      answered: {
        isAnswered: true,
      },
      withdraw: !isResolved,
      thanks: isResolved,
    }).then((res: any) => {
      const updateDoubtArr = doubtArr?.map((updatedDoubt: any) => {
        if (updatedDoubt?._id === res?.data?._id) {
          return res?.data;
        } else {
          return updatedDoubt;
        }
      });
      setDoubtArr(updateDoubtArr);
    });
  };

  const blockDoubt = (isBlocked: boolean) => {
    updateDoubt({
      _id: item._id,
      isBlocked: isBlocked,
    }).then((res: any) => {
      const blockedDoubtArr = doubtArr?.map((blockedDoubt: any) => {
        if (blockedDoubt?._id === res?.data?._id) {
          return res?.data;
        } else {
          return blockedDoubt;
        }
      });
      setDoubtArr(blockedDoubtArr);
    });
  };

  const likesHandler = () => {
    let fn = like._id
      ? updateDoubtResponse({
          isLiked: !like.value,
          _id: like._id,
        })
      : createDoubtResponse({
          isLiked: !like.value,
          doubt: item._id,
        });
    fn.then((res: any) => {
      setLike({
        value: res.data.isLiked,
        _id: res.data._id,
      });
      setLikeCount(res.data.isLiked ? likeCount + 1 : likeCount - 1);
    });
  };

  const remove = () => {
    removePost(item._id).then(() => {
      setDoubtArr(doubtArr.filter((i: any) => i._id !== item._id));
    });
  };

  const removeCommentHandler = (id: string) => {
    removeComment(id).then(() => {
      setcommentsArr(commentsArr.filter((i: any) => i._id !== id));
    });
  };
  return (
    <div className="post-card" id={item?._id}>
      <div className="post-card--header">
        <div className="wrap">
          <img
            src={
              item?.addedBy?.displayPicture
                ? getAssetUrl(item?.addedBy?.displayPicture)
                : User.role === 'admin' || User.role === 'superadmin'
                ? adminUser
                : user
            }
            alt="user"
          />{' '}
          <div className="user-info">
            <p
              className="text-secondary"
              style={{ fontSize: '16px', fontWeight: 'bold' }}
            >
              {item?.addedBy?.name}
            </p>
            <span>{item?.course?.name}</span>
            <span>{moment(item?.createdAt).calendar()}</span>
          </div>
        </div>

        <div className="d-flex align-items-center">
          {item.answered.isAnswered && (
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
              className="text-success mr-2"
            >
              Resolved
            </span>
          )}
          {User.role === 'admin' ||
            (User.role === 'superadmin' && (
              <div className="d-flex align-items-center justify-content-end">
                {item?.isBlocked && (
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      color: '#ff0000',
                    }}
                  >
                    Blocked
                  </span>
                )}
                <div className="wall-dropdown">
                  <ListItemAction className="action-button">
                    <ActionButton>
                      <BiDotsVerticalRounded />
                    </ActionButton>
                    <Menu
                      style={{
                        padding: '0',
                        borderRadius: '8px',
                        minWidth: '100px',
                        margin: '25px -25px',
                      }}
                    >
                      <li
                        onClick={remove}
                        style={{ borderRadius: '8px', fontSize: '12px' }}
                      >
                        Remove Post
                      </li>
                      {item?.isBlocked ? (
                        <li
                          style={{
                            borderRadius: '8px',
                            fontSize: '12px',
                          }}
                          onClick={() => blockDoubt(false)}
                        >
                          Unblock this doubt
                        </li>
                      ) : (
                        <li
                          style={{
                            borderRadius: '8px',
                            fontSize: '12px',
                          }}
                          onClick={() => blockDoubt(true)}
                        >
                          Block this doubt
                        </li>
                      )}
                    </Menu>
                  </ListItemAction>
                </div>
              </div>
            ))}
        </div>
      </div>

      {item?.displayPicture ? (
        <div className="post-card--body">
          {item?.displayPicture.toString().endsWith('mp4') ? (
            <div>
              <video
                width="100%"
                controls
                controlsList="nodownload"
                disablePictureInPicture
              >
                <source
                  src={getAssetUrl(item?.displayPicture)}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : item?.displayPicture.toString().endsWith('mp3') ? (
            <div>
              <audio controls style={{ width: '100%' }}>
                <source
                  src={getAssetUrl(item?.displayPicture)}
                  type="audio/mpeg"
                />
                Your browser does not support the audio tag.
              </audio>
            </div>
          ) : (
            <img
              className="wallpost-img"
              width="100%"
              src={getAssetUrl(item?.displayPicture)}
            />
          )}
        </div>
      ) : null}

      <div className="post-card--content">
        <p style={{ fontSize: '15px' }}>{item.text}</p>
      </div>

      <div className="post-card--action">
        <div className="wrap">
          <div
            onClick={likesHandler}
            className={`action ${like.value ? 'active' : ''} `}
          >
            <FiThumbsUp />
            <strong>Like</strong>
            <span>{likeCount}</span>
          </div>
          <div className="action">
            <FiMessageSquare />
            <strong>Comment</strong>
            <span>{commentsArr.length}</span>
          </div>
        </div>
      </div>

      {commentSection()}

      {User.role === 'user' && !item.answered.isAnswered ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px',
          }}
        >
          <Button
            shape="popup"
            className="px-5"
            onClick={() => {
              const isExist = commentsArr.filter((comment: any) => {
                if (comment.addedBy._id !== User._id) {
                  return true;
                }
                return;
              });
              handleDoubtResolve(!!isExist.length);
            }}
          >
            Resolved
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default PostDoubt;
