import React, { FunctionComponent, useEffect } from 'react';
// import styled from 'styled-composnents';
import Button from './StyledButton';

interface PadProps {
  onDigitButtonClick: (digit: any) => void;
  onClearEntryButtonClick: () => void;
  onArrowClick: (arrow: string) => void;
}

export const Pad: FunctionComponent<PadProps> = ({
  onDigitButtonClick,
  onClearEntryButtonClick,
  onArrowClick,
}) => {
  const handleKeyDown = ({ keyCode, shiftKey }: KeyboardEvent) => {
    if (keyCode >= 48 && keyCode <= 57 && !shiftKey) {
      onDigitButtonClick(keyCode - 48);
    } else if (keyCode >= 96 && keyCode <= 105) {
      onDigitButtonClick(keyCode - 96);
    } else if (keyCode === 46 || keyCode === 8) {
      onClearEntryButtonClick();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => document.body.removeEventListener('keydown', handleKeyDown);
  });

  return (
    <>
      <ul>
        <li>
          <Button onClick={() => onDigitButtonClick(1)}>1</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(2)}>2</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(3)}>3</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(4)}>4</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(5)}>5</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(6)}>6</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(7)}>7</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(8)}>8</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(9)}>9</Button>
        </li>
        <li>
          <Button onClick={() => onArrowClick('<')}>{'<'}</Button>
        </li>
        <li>
          <Button onClick={() => onDigitButtonClick(0)}>0</Button>
        </li>
        <li>
          <Button onClick={() => onArrowClick('>')}>{'>'}</Button>
        </li>
      </ul>

      <ul>
        <li>
          <Button onClick={onClearEntryButtonClick}>C</Button>
        </li>
        <li style={{ marginTop: 2 }}>
          <Button onClick={() => onDigitButtonClick('.')}>{'.'}</Button>
        </li>
      </ul>
    </>
  );
};

export default Pad;
