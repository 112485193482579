import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IWallCommentListParams extends IListParams {
  user?: ObjectId;
  populate?: boolean;
}

export interface IWallComments {
  text: string;
  wall: ObjectId | PopulatedDocument;
}

export interface IWallCommentsDocument extends IWallComments, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IWallCommentsListResponse {
  totalItems: number;
  wallsComments: IWallCommentsDocument[];
}

const list = (wallId: string, params?: IWallCommentListParams) => {
  return client.get<IWallCommentsDocument>(`/walls/wall-comments/${wallId}`, {
    params,
  });
};

const create = (wallComment: IWallComments) => {
  return client.post<IWallCommentsDocument>(
    `/walls/wall-comments`,
    wallComment
  );
};

const remove = (commentId: string) => {
  return client.delete(`/walls/wall-comments/${commentId}`);
};

export const wallComment = { create, remove, list };
