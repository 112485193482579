import React from 'react';
import Styled from 'styled-components';
import { IconButtonProps } from './button.types';

const CustomIconButton = Styled.button<IconButtonProps>`
  // background: #f5f7fa;
  // border: 1px solid #acb0b5;
  // border-radius: 20px;
  // font-size: 13px;
  // font-weight: 500;
  // color: #303956;
  
  // &:hover{
  //   background: #47b3bb;
  //   color: #fff;
  //   border: none;

  // }
  // &:focus{
  //   outline: none;
  // }
  // svg{
  //       margin-right: 5px;
  // }
`;

export const IconButton = ({
  id,
  className,
  children,
  onClick,
  ...rest
}: IconButtonProps) => {
  return (
    <React.Fragment>
      <CustomIconButton
        id={id}
        className={`${className} text-uppercase button-with-icon px-3 py-1 d-flex align-items-center justify-content-between`}
        onClick={onClick}
        {...rest}
      >
        {children}
      </CustomIconButton>
    </React.Fragment>
  );
};
