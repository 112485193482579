import { PageHeader, Button } from '@lipihipi/ec-ui';
import { FileUpload, Form } from '@lipihipi/form';
import { commonApiError } from '../index';
import React from 'react';
import swal from 'sweetalert';

interface ICreateCoupon {
  // createCoupon: any,
  createAsset: any;
  uploadFile: any;
  afterSuccessBulkUpload: any;
  backClick: any;
  assetsBaseUrl: any;
}

const CreateCoupon = ({
  // createCoupon,
  createAsset,
  uploadFile,
  afterSuccessBulkUpload,
  backClick,
  assetsBaseUrl,
}: ICreateCoupon) => {
  // const [state, setState] = React.useState('one');
  const [state, setState] = React.useState<any>(true);
  const onSaveClick = (values: any) => {
    uploadFile(values)
      .then(({ status }: any) => {
        if (status === 200) {
          swal({
            title: 'Success',
            text: 'Coupon Upload Successfully',
            icon: 'success',
            dangerMode: false,
          }).then(() => {
            setState(false);
            setState(true);
            afterSuccessBulkUpload();
          })
            .catch((err: any) => {
              commonApiError(err);
            });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
      .catch((err: any) => {
        commonApiError(err);
      });
  };
  return (
    <>
      {state && (
        <section className="main-structure">
          <PageHeader
            title="Create Coupon"
            breadCrumbs={[
              { title: 'Home', link: '/dashboard' },
              { title: 'Manage Rewards and Coupons', link: '/dashboard' },
              { title: 'Create' },
            ]}
          />
          <Form
            initialValues={{ file: '' }}
            render={({ values }: any) => {
              return (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <FileUpload
                        label={''}
                        name={'file'}
                        type="single"
                        maxSize={'3mb'}
                        accept={[
                          'application/vnd.ms-excel',
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        ]}
                        upload={createAsset}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <a
                      style={{ color: 'blue' }}
                      className="font-weight-bolder text-capitalize"
                      href={`${assetsBaseUrl}file/coupon_bulk_upload_sample_file.xlsx`}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      Download Sample file
                    </a>
                  </div>
                  <div className="mt-3">
                    <Button shape="secondary" onClick={backClick}>
                      Back
                    </Button>
                    <Button
                      shape="primary"
                      className="ml-3"
                      onClick={() => onSaveClick(values)}
                    >
                      Save{' '}
                    </Button>
                  </div>
                </>
              );
            }}
          />
        </section>
      )}
    </>
  );
};

export default CreateCoupon;
