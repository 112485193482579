import React, { useState, useEffect } from 'react';
import { Button, Modal, PageHeader } from '@lipihipi/ec-ui';
import { ISessionCredential } from '@lipihipi/client-sdk/dist/live-session';
import { commonApiError } from '../admin/create/index';

interface IJoinModalProps {
  isOpen: boolean;
  onRequestClose: any;
  session: any;
  joinSession: any;
}

interface IZoomCredential {
  name?: string;
  mn?: string;
  email?: string;
  pwd?: string;
  role?: Number;
  lang?: string;
  signature?: string;
  china?: Number;
  apiKey?: string;
}

const serialize = (obj: any) => {
  // eslint-disable-next-line no-shadow
  var keyOrderArr = ["name", "mn", "email", "pwd", "role", "lang", "signature", "china"];

  const intersect = function (...args: any[]) {
    var result = new Array();
    var obj: any = {};
    for (var i = 0; i < args.length; i++) {
      for (var j = 0; j < args[i].length; j++) {
        var str = args[i][j];
        if (!obj[str]) {
          obj[str] = 1;
        } else {
          obj[str]++;
          if (obj[str] == args.length) {
            result.push(str);
          }
        }
      }
    }
    return result;
  };

  /* tslint:disable-next-line */
  var tmpInterArr = intersect(keyOrderArr, Object.keys(obj));
  var sortedObj: any[] = [];
  keyOrderArr.forEach(function (key) {
    if (tmpInterArr.includes(key)) {
      sortedObj.push([key, obj[key]]);
    }
  });
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      if (!tmpInterArr.includes(key)) {
        sortedObj.push([key, obj[key]]);
      }
    });
  var tmpSortResult = (function (sortedObj) {
    var str = [];
    for (var p in sortedObj) {
      if (typeof sortedObj[p][1] !== "undefined") {
        str.push(
          encodeURIComponent(sortedObj[p][0]) +
          "=" +
          encodeURIComponent(sortedObj[p][1])
        );
      }
    }
    return str.join("&");
  })(sortedObj);
  return tmpSortResult;
};

const generateUrl = (cred: ISessionCredential) => {
  const credential: IZoomCredential = {
    name: cred?.name,
    mn: cred?.mn,
    email: cred?.email,
    pwd: cred?.pass,
    role: cred?.role,
    lang: cred?.lang,
    signature: cred?.signature,
    china: cred?.china,
    apiKey: cred?.apiKey,
  }
  const url = `/zoom/?${serialize(credential)}`;
  return url;
}

export const JoinModal = ({
  isOpen,
  onRequestClose,
  session,
  joinSession
}: IJoinModalProps) => {
  const [credential, setCredential] = useState<any>(null)

  useEffect(() => {
    const cred = joinSession({ _id: session._id })
      .then((response: any) => {
        setCredential(response?.data);
      })
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  }, [])

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <h1>{session?.sessionName} </h1>
      {credential && (
        <>
          <h3>
            Dear Students, click on JOIN button to join the Live class.
          </h3>
          <Button shape="primary"><a href={generateUrl(credential)} target="_blank">Join</a> </Button>
          <div className="mt-5">
            <h4 className="mt-5">You can also join the Live class from Zoom app by using following details</h4>
            <div className="row">
              <div className="col-md-4">
                <h5 style={{ color: "teal" }}>Meeting Number </h5>
              </div>
              <div className="col-md-8">
                <h5> {credential?.mn}</h5>

              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <h5 style={{ color: "teal" }}>Password </h5>
              </div>
              <div className="col-md-8">
                <h6>{session?.passCode || credential?.pass}</h6>
              </div>
            </div>
          </div>
          {/* <p>Meeting Number: {credential?.mn}</p>
          <p>Password: {credential?.pass}</p> */}

        </>

      )}
    </Modal>
  );
}

export default JoinModal;
