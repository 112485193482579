import React from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { Label } from '../label/label';
import { SelectProps } from './select.types';
import { useField } from 'formik';
import { ErrorMessage } from '../error/error';

const colourStyles = {
  multiValue: (styles: any, { }: any) => {
    return {
      ...styles,
      backgroundColor: '#239f87',
    };
  },
  multiValueLabel: (styles: any, { }: any) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles: any, { }: any) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#239f87',
      color: 'white',
    },
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 99,
  }),
  control: (base: any, state: any) => ({
    ...base,
    border: '1px solid #e6e6e6',
    boxShadow: 'none',
    // #239f87
    borderColor: state.isFocused ? '#239f87' : '#239f87',
    '&:focus': {
      borderColor: state.isFocused ? '#239f87' : '#e6e6e6',
    },
    '&:hover': {
      borderColor: state.isFocused ? '#239f87' : '#e6e6e6',
      boxShadow: 'none',
    },
  }),
  option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? null
          : isFocused
            ? '#239f87'
            : null,
      color: isDisabled ? null : isSelected ? null : isFocused ? '#fff' : null,
      ':active': {
        ...styles[':active'],
        backgroundColor: '#239f87',
      },
    };
  },
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      color: '#919191',
    };
  },
};

export const Select = ({
  id,
  name,
  label,
  className,
  style,
  takeSpace,
  required,
  placeholder,
  options,
  isMulti,
  onChange,
  disable
}: SelectProps) => {
  const [field, meta, helpers] = useField({ name });
  const _onChange = (v: any) => {
    if (isMulti) {
      helpers.setValue(v.map((item: any) => item.value));
    } else {
      helpers.setValue(v.value);
    }
    onChange && onChange(v);
  };

  const getValue = () => {
    return Array.isArray(options)
      ? options.filter(({ value }) => {
        if (isMulti) {
          return (field.value || []).includes(value);
        } else {
          return field.value === value;
        }
      })
      : null;
  };

  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      {Array.isArray(options) ? (
        <div className="custom-ReactSelect">
          <ReactSelect
            value={getValue()}
            onChange={_onChange}
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            styles={colourStyles}
            isDisabled={disable}
          />
        </div>
      ) : (
        <div className="custom-AsyncSelect">
          <AsyncSelect
            cacheOptions
            defaultOptions
            onChange={_onChange}
            loadOptions={options}
            placeholder={placeholder}
            isMulti={isMulti}
            styles={colourStyles}
            isDisabled={disable}
          />
        </div>
      )}
      <ErrorMessage meta={meta} />
    </div>
  );
};
