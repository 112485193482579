import axios from 'axios';
const instance = axios.create();
// axios.defaults.withCredentials = true;

instance.interceptors.request.use(
  successfulReq => successfulReq,
  error => Promise.reject(error)
);
instance.defaults.responseType = 'json';

instance.interceptors.response.use(
  successRes => successRes,
  ({ response }) => {
    console.log('error : response -> ', response);
    if (response?.status === 401) {
      localStorage.removeItem('token');

      if (!response?.url || !response?.url.includes('auth/local')) {
        window.location.reload();
      }
    }
    return Promise.reject(response);
  }
);
export const client = instance;
