import React, { FC } from 'react';
import { LeftPanel } from '../../common';
import ForgotPasswordForm from './ForgotPasswordForm';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { ILoginParams } from '@lipihipi/client-sdk/dist/auth';
import EducrackAPI from '@lipihipi/client-sdk';

interface IForgotPassword {
  forgotPasswordOtp: any;
  imageBaseUrl: any;
}

const ForgotPassword: FC<IForgotPassword> = ({
  forgotPasswordOtp,
  imageBaseUrl,
}: IForgotPassword) => {
  const history = useHistory();

  const handleSubmit = async (values: ILoginParams) => {
    let response;

    try {
      response = await forgotPasswordOtp(values);
      const user = {
        id: response.data.user._id,
        mobile: response.data.user.mobile,
      };
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('forgotPassword', 'true');
      history.push('/otp-verify');
    } catch (err) {
      swal({
        title: 'Error',
        text: err?.data?.message || 'Server Error!',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const { styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};

  return (
    <main className="user-structure">
      <LeftPanel
        imageBaseUrl={imageBaseUrl}
        title={styles?.onboardingText || ''}
      />
      <ForgotPasswordForm
        handleSubmit={handleSubmit}
        initialErrors={{ mobile: 'Invalid' }}
      />
    </main>
  );
};

export default ForgotPassword;
