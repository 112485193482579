import { CheckBox, Form } from '@lipihipi/form';
import { TestTimer } from '../../components/testTimer/test-timer';
import React, { useEffect, useState } from 'react';
import Mcq from '../../components/mcq';
import {
  checkApiError,
  getDefaultActiveSection,
  getFirstQuestionOfActiveSection,
  getTestOverTime,
} from './utility';
import { Tab, Tabs } from '@lipihipi/ec-ui';
import { ScientificCalculator } from '../../test-bundle/calculator/scientific-calculator/scientific-calculator';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import QuestionPalette from '../../components/questionPalette';
import { FaCalculator } from 'react-icons/fa';
import { ModalInterface } from './test.types';
import Swal from 'sweetalert2';
import moment from 'moment';
import ExplanationModal from './explanation';
import InstructionModal from './instruction';
import Styled from 'styled-components';

const CloseWindowBanner = Styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
`;

const Test = ({
  user,
  testAttempt,
  submitAnswer,
  // imageBaseUrl,
  submitTestAttempt,
  // onTestSubmit,
  supportTicketCreate,
  getAssetUrl,
  createAsset,
}: any) => {
  const _window = window as any;

  const [attempt, setAttempt] = useState<any>({});

  const [activeGroup, setActiveGroup] = useState<any>({});
  const [activeSection, setActiveSection] = useState<any>({});
  const [activeQuestion, setActiveQuestion] = useState<any>({});

  const [showCalculator, setShowCalculator] = useState<boolean>(false);

  const [testTimeOver, setTestTimeOver] = useState(new Date());
  const [questionTime, setQuestionTime] = useState(0);

  const [virtualNumericKeyboard, enableVirtualNumericKeyboard] = useState(
    false
  );

  const [show, setShow] = useState(false);

  const [showInstruction, setShowInstruction] = useState(false);

  const [modal, showModal] = useState<ModalInterface>({
    visible: false,
  });

  const [sectionsTab, setSectionsTab] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [testEnd, showTestEnd] = useState<boolean>(false);

  useEffect(() => {
    changeSectionTabList(activeGroup);
  }, [attempt]);

  useEffect(() => {
    if (testAttempt.status === 'FINISHED') {
      Swal.fire({
        icon: 'error',
        title: 'Completed',
        text: 'This test is already complete',
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    }
    setAttempt({ ...testAttempt });
    const defaultActiveGroup =
      testAttempt?.test?.questionSets?.find(
        (v: any) => v?.group === testAttempt?.submittedGroup?.name
      ) || testAttempt?.test?.questionSets[0];
    setActiveGroup(defaultActiveGroup);
    const defaultActiveSection = getDefaultActiveSection(
      defaultActiveGroup?.sections,
      testAttempt?.submittedSections
    );
    const newSection = testAttempt?.test?.sections?.find(
      (v: any) => v?._id === defaultActiveSection?.section
    );
    defaultActiveSection.section = newSection;
    defaultActiveSection.questions = defaultActiveSection?.questions?.map(
      (v: any, index: number) => ({
        ...v,
        displayOrder: index + 1,
      })
    );
    const firstQuestionOfActiveSection = getFirstQuestionOfActiveSection(
      defaultActiveSection?.questions,
      defaultActiveSection?.section,
      testAttempt?.submittedSections,
      testAttempt?.submittedQuestion?.questionId
    );
    setActiveQuestion(firstQuestionOfActiveSection);
    setActiveSection(defaultActiveSection);
    checkTiming(testAttempt, defaultActiveGroup, defaultActiveSection);
  }, [testAttempt]);

  useEffect(() => {
    const currentQuestion = activeSection?.questions?.find(
      (v: any) => v?.questionId === activeQuestion?._id
    );
    const timeTaken = currentQuestion?.timeTaken
      ? currentQuestion?.timeTaken
      : 0 + 1;
    setQuestionTime(timeTaken);
  }, [activeQuestion]);

  useEffect(() => {
    let interval: any = null;
    if (activeQuestion) {
      interval = setInterval(() => {
        setQuestionTime(oldTime => oldTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [activeQuestion]);

  const drop = (event: any) => {
    const dm: any = document.getElementById('keyboard');
    dm.style.left = event.clientX + 'px';
    dm.style.top = event.clientY + 'px';
    event.preventDefault();
    return false;
  };

  const drag_over = (event: any) => {
    event.preventDefault();
    return false;
  };

  const checkTiming = (attemptData: any, group: any, currentData: any) => {
    let startDate =
      attemptData?.submittedSections[attemptData?.submittedSections?.length - 1]
        ?.datetime || attemptData?.startedAt;
    let time: any;
    if (attemptData?.test?.exam?.configuration?.isGroupingAllowed) {
      startDate =
        attemptData?.submittedGroup[attemptData?.submittedGroup?.length - 1]
          ?.datetime || startDate;
      time = getTestOverTime(startDate, group?.timeLimit);
    } else if (currentData?.timeLimit > 0) {
      time = getTestOverTime(startDate, currentData?.timeLimit);
    } else {
      time = getTestOverTime(
        attemptData?.startedAt,
        attemptData?.test?.totalDurationInMinute
      );
      //@ts-ignore
      if (Date.parse(time) - Date.parse(new Date()) < 0) {
        handleTimeOver(testAttempt._id);
        return;
      }
    }
    setTestTimeOver(time);
  };

  const updateActiveSectionData = (
    question: any,
    section: any,
    group: any,
    submitSection: boolean,
    submitGroup: boolean,
    markForReviewCheck: boolean = false,
    isClear: boolean = false
  ) => {
    let newActiveQuestion = question ? question : activeQuestion;
    const newActiveSection = section ? section : activeSection;
    const newActiveGroup = group ? group : activeGroup;
    const testData = { ...attempt };

    testData.test.questionSet = testData.test.questionSets?.map((set: any) => {
      let currentSection =
        set?.sections?.find(
          (sec: any) =>
            (sec?.section?._id ? sec?.section?._id : sec) ===
            newActiveSection?.section?._id
        ) || newActiveSection;
      const sectionIndex = set?.sections.indexOf(currentSection) || 0;
      if (currentSection) {
        const que = isClear ? question : activeQuestion;
        let currentQuestion = currentSection?.questions?.find(
          (question: any) => question?._id === que._id
        );
        if (currentQuestion) {
          let status = 'VISITED';
          if (
            activeQuestion?.selectedAnswers?.length > 0 ||
            activeQuestion?.typedAnswers?.length > 0
          ) {
            status = 'ANSWERED';
          } else status = 'VISITED';
          currentQuestion = {
            ...currentQuestion,
            status,
            isMarkForReview: markForReviewCheck
              ? newActiveQuestion?.isMarkForReview
              : activeQuestion?.isMarkForReview,
            selectedAnswers: activeQuestion?.selectedAnswers,
            typedAnswers: activeQuestion?.typedAnswers,
            answerImages: activeQuestion?.answerImages || []

          };
          currentSection?.questions.splice(
            currentQuestion?.displayOrder - 1,
            1,
            currentQuestion
          );
        }
        set?.sections.splice(sectionIndex, 1, currentSection);
      }
      return set;
    });

    if (
      submitSection &&
      (testData?.test?.exam?.name === 'CAT' ||
        testData?.test?.exam?.name === 'NMAT')
    ) {
      testData.submittedSections.push({
        datetime: new Date(),
        section: activeSection?.section?._id,
      });
    }
    if (submitGroup) {
      testData.submittedGroup.push({
        datetime: new Date(),
        name: activeGroup?.name,
      });
    }
    const defaultActiveGroup = testData?.test?.questionSets?.find(
      (v: any) => v?.name === newActiveGroup.name
    );

    let defaultActiveSection =
      defaultActiveGroup?.sections?.find(
        (v: any) =>
          (v?.section?._id ? v?.section?._id : v?.section) ===
          newActiveSection.section?._id
      ) || newActiveSection;

    const newSection = testData?.test?.sections?.find(
      (v: any) =>
        v?._id ===
        (defaultActiveSection?.section?._id
          ? defaultActiveSection?.section?._id
          : defaultActiveSection?.section)
    );
    defaultActiveSection.section = newSection;
    defaultActiveSection.questions = defaultActiveSection?.questions?.map(
      (v: any, index: number) => ({
        ...v,
        displayOrder: index + 1,
      })
    );
    if (section) {
      newActiveQuestion = getFirstQuestionOfActiveSection(
        defaultActiveSection?.questions,
        defaultActiveSection?.section,
        testData?.submittedSections,
        testData?.submittedQuestion?.questionId
      );
    }
    setAttempt(testData);
    setActiveQuestion(newActiveQuestion);
    setActiveSection(defaultActiveSection);
    checkTiming(testData, newActiveGroup, defaultActiveSection);
    setQuestionTime(0);
  };

  const handleQuestionNoClick = async (question: any) => {
    try {
      const response = await submitAnswer(
        attempt?._id,
        activeQuestion,
        questionTime
      );
      if (response) {
        updateActiveSectionData(question, null, null, false, false);
      }
    } catch (error) {
      checkApiError(error);
      return;
    }
  };

  const setMarkForReview = async () => {
    try {
      const currentActiveQuestion = { ...activeQuestion };
      currentActiveQuestion.isMarkForReview = !currentActiveQuestion?.isMarkForReview;
      const response = await submitAnswer(
        attempt?._id,
        currentActiveQuestion,
        questionTime
      );
      if (response) {
        setActiveQuestion(currentActiveQuestion);
        updateActiveSectionData(
          currentActiveQuestion,
          null,
          null,
          false,
          false,
          true
        );
      }
    } catch (error) {
      checkApiError(error);
      return;
    }
  };

  const getPreviousQuestionNo = () => {
    if (!activeSection?.questions) {
      return null;
    }
    if (activeQuestion && Object.keys(activeQuestion).length) {
      const { displayOrder: activeQuestionNo } = activeQuestion;
      const activeQuestionIndex = activeSection?.questions.find(
        (question: any, index: number) =>
          (question.displayOrder || index) === activeQuestionNo - 1
      );
      return activeQuestionIndex ? activeQuestionIndex : null;
    } else {
      return null;
    }
  };

  const setPreviousQuestion = async () => {
    const previousQuestionNo: any = getPreviousQuestionNo();
    if (previousQuestionNo) {
      const previousQuestionObj = activeSection?.questions.find(
        (question: any) =>
          question?.displayOrder === previousQuestionNo?.displayOrder
      );
      // mark current question as visited (if not already answered),
      // by sending an empty []
      try {
        const response = await submitAnswer(
          attempt?._id,
          activeQuestion,
          questionTime
        );
        if (response) {
          updateActiveSectionData(
            previousQuestionObj,
            null,
            null,
            false,
            false
          );
        }
      } catch (error) {
        checkApiError(error);
        return;
      }
    }
  };

  const getNextQuestion = () => {
    const sections = attempt?.test?.sections || [];
    const { submittedSections = [] } = attempt;

    if (!activeSection?.questions) {
      return -1;
    }
    const questionIndex = activeSection?.questions?.findIndex(
      (v: any) => v?._id === activeQuestion?._id
    );
    const currentSectionIndex = sections.findIndex(
      (section: any) => section?._id === activeSection?.section?._id
    );
    const submittedSection = submittedSections //?.includes(activeSection?.section?._id);
      ?.find(
        (section: any) => section?.section === activeSection?.section?._id
      );
    if (
      questionIndex === activeSection?.questions?.length - 1 &&
      submittedSection
    ) {
      return sections[currentSectionIndex + 1];
    }
    if (activeQuestion && Object.keys(activeQuestion).length) {
      const { displayOrder: activeQuestionNo } = activeQuestion;
      const activeQuestionIndex = activeSection?.questions.find(
        (question: any, index: number) =>
          (question.displayOrder || index) === activeQuestionNo + 1
      );
      return activeQuestionIndex
        ? activeQuestionIndex
        : sections[currentSectionIndex + 1];
    } else {
      return null;
    }
  };

  const setNextQuestion = async () => {
    const nextQuestion = getNextQuestion();
    if (nextQuestion?.name && nextQuestion?._id) {
      handleSectionChange(nextQuestion?.name);
      return;
    }
    if (nextQuestion) {
      const nextQuestionObj = activeSection?.questions.find(
        (question: any) => question?.displayOrder === nextQuestion?.displayOrder
      );
      try {
        const response = await submitAnswer(
          attempt?._id,
          activeQuestion,
          questionTime
        );
        if (response) {
          updateActiveSectionData(nextQuestionObj, null, null, false, false);
        }
      } catch (error) {
        checkApiError(error);
        return;
      }
    }
  };

  const handleClearAnswer = async () => {
    const currentQuestion = { ...activeQuestion };
    currentQuestion.selectedAnswers = [];
    currentQuestion.typedAnswers = [];
    setActiveQuestion(currentQuestion);

    try {
      const response = await submitAnswer(
        attempt?._id,
        currentQuestion,
        questionTime
      );
      if (response) {
        updateActiveSectionData(
          currentQuestion,
          null,
          null,
          false,
          false,
          true
        );
      }
    } catch (error) {
      checkApiError(error);
    }
  };

  const handleSubmitAnswer = async () => {
    try {
      const nextQuestion = getNextQuestion();
      // check 1
      // if question belongs to a disabled section, show error toast
      const submittedSections = attempt?.submittedSections || [];
      if (
        activeSection?.section &&
        submittedSections.find(
          (section: any) => section?.section === activeQuestion?.section?._id
        )
      ) {
        Swal.fire({
          position: 'top-end',
          text: 'This question belongs to a already submitted section!',
          icon: 'error',
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        });
        return;
      }
      if (
        (activeQuestion?.question?.type === 'FIB' ||
          activeQuestion?.question?.type === 'DSC' ||
          activeQuestion?.question?.type === 'RE-ARR') &&
        activeQuestion?.typedAnswers?.length === 0
      ) {
        Swal.fire({
          position: 'top-end',
          text: 'Please fill in the answer',
          icon: 'error',
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        });
        return;
      }

      // show error if no option selected
      if (
        !(
          (
            activeQuestion?.question?.type === 'FIB' ||
            activeQuestion?.question?.type === 'DSC'
          ) //|| activeQuestion?.question?.type === 'SA'
        ) &&
        activeQuestion?.question?.type !== 'RE-ARR' &&
        activeQuestion?.selectedAnswers.length === 0
      ) {
        Swal.fire({
          position: 'top-end',
          text: 'Please select an answer',
          icon: 'error',
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        });
        return;
      }
      try {
        const response = await submitAnswer(
          attempt?._id,
          activeQuestion,
          questionTime
        );
        if (response) {
          if (nextQuestion?.name && nextQuestion?._id) {
            handleSectionChange(nextQuestion?.name);
            return;
          }
        }
        const nextQuestionObj = activeSection?.questions.find(
          (question: any) =>
            question?.displayOrder === nextQuestion?.displayOrder
        );
        updateActiveSectionData(nextQuestionObj, null, null, false, false);
      } catch (error) {
        checkApiError(error);
        return;
      }
      // set next question as active question
      // const nextQuestion = getNextQuestion();
      if (nextQuestion) {
        setQuestionTime(0);
      } else {
        Swal.fire({
          title: 'Your Test is Saved.',
          text: 'Now you can submit your test.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      checkApiError(error);
      return;
    }
  };

  const handleGroupChange = (tabName: string = '', flag: boolean = false) => {
    const index = attempt?.test?.questionSets?.findIndex(
      (v: any) => v?.name === tabName
    );
    if (attempt?.test?.exam?.configuration?.isGroupingAllowed) {
      if (
        attempt?.test?.exam?.name === 'XAT' &&
        activeGroup?.timeLimit > 0 &&
        !flag
      ) {
        Swal.fire({
          text:
            'Please let the group time complete, then you can proceed further.',
          icon: 'warning',
        });
        return;
      } else {
        if (tabName) {
          changeGroup(attempt?.test?.questionSets[index]);
        } else {
          submitAttempt();
        }
        return;
      }
    }
  };

  const handleSectionChange = async (
    tabName: string = '',
    flag: boolean = false
  ) => {
    const newSection = attempt?.test?.sections?.find(
      (v: any) => v?.name === tabName
    );
    if (attempt?.test?.exam?.name === 'CAT' && !flag) {
      if (
        attempt?.submittedSections.find(
          (section: any) => section?.section === newSection?._id
        )
      ) {
        Swal.fire({
          text: 'This section is already submitted.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return;
      }
      if (moment() !== moment(testTimeOver) && activeSection?.timeLimit > 0) {
        Swal.fire({
          text:
            'Please let the section time complete, then you can proceed further.',
          icon: 'warning',
        });
        return;
      }
      changeSection(tabName);
    } else if (
      attempt?.test?.exam?.name === 'NMAT' &&
      attempt?.test?.examType === 'FULL LENGTH'
    ) {
      if (
        attempt?.submittedSections.find(
          (section: any) => section?.section === newSection?._id
        )
      ) {
        Swal.fire({
          text: 'This section is already submitted.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return;
      }
      try {
        Swal.fire({
          title: 'Are you sure?',
          text:
            "This question belongs to a different section. If you continue, you won't be able to answer any questions belonging to the current section",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#239f87',
          confirmButtonText: 'Yes, Proceed!',
        }).then(async result => {
          if (result.isConfirmed) {
            changeSection(tabName);
            return;
          }
        });
      } catch (error) {
        checkApiError(error);
        return;
      }
      return;
    } else {
      changeSection(tabName);
      return;
    }
  };

  const changeGroup = async (group: any) => {
    try {
      const response = await submitAnswer(
        attempt?._id,
        activeQuestion,
        questionTime,
        activeSection?.section?._id,
        activeGroup?.name
      );
      if (response) {
        setActiveGroup(group);
        changeSectionTabList(group);
        updateActiveSectionData(null, null, group, false, true);
      }
    } catch (error) {
      checkApiError(error);
      return;
    }
  };

  const changeSection = async (tabName: string = '') => {
    let newActiveSection = getDefaultActiveSection(
      activeGroup?.sections,
      testAttempt?.submittedSections
    );
    if (tabName) {
      const newSection = attempt?.test?.sections?.find(
        (v: any) => v?.name === tabName
      );

      newActiveSection = activeGroup?.sections?.find(
        (v: any) =>
          (v?.section?._id ? v?.section?._id : v?.section) === newSection?._id
      );
      if (newSection) {
        newActiveSection.section = newSection;
      }
    }
    try {
      const response = await submitAnswer(
        attempt?._id,
        activeQuestion,
        questionTime,
        activeSection?.section?._id
      );
      if (response) {
        newActiveSection.questions = newActiveSection?.questions?.map(
          (v: any, index: number) => ({
            ...v,
            displayOrder: index + 1,
          })
        );
        updateActiveSectionData(null, newActiveSection, null, true, false);
      }
    } catch (error) {
      checkApiError(error);
      return;
    }
  };

  const submitAttempt = async (
    attemptId = attempt?._id,
    isTimeOver = false
  ) => {
    if (typeof attemptId !== 'string') {
      attemptId = attempt?._id;
    }
    try {
      const { data } = await submitTestAttempt(attemptId);
      console.log(data);
      if (isTimeOver) {
        Swal.fire({
          title: 'Timeout!',
          text: 'Test time is completed',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: false,
        }).then(result => {
          if (result?.isConfirmed) {
            unloadShinkanScript();
            _window.close();
            showTestEnd(true);
            return;
          }
        });
      }
      if (data) {
        setLoading(false);
        Swal.fire({
          title: 'Your test is submitted successfully.',
          text: 'Result will be shared with you soon.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          if (localStorage.getItem('isScholarshipTest')) {
            localStorage.clear();
            _window.location.href = `/scholarship`;
          } else {
            unloadShinkanScript();
            _window.close();
            showTestEnd(true);
          }
        });
      }
    } catch (error) {
      checkApiError(error);
      return;
    }
  };

  const unloadShinkanScript = () => {
    window.parent.postMessage(
      {
        msg: 'stop',
      },
      '*'
    );
  };

  const handleSubmitAttemptClick = () => {
    setLoading(true);

    let arr: any = [];

    attempt?.test?.questionSets?.map((set: any) => {
      set?.sections?.map((a: any) => {
        arr = [...arr, ...a.questions];
      });
    });
    const flag = arr.find((q: any) => {
      if (q?.typedAnswers?.length > 0 || q?.selectedAnswers.length > 0) {
        return true;
      }
      return false;
    });

    if (!flag) {
      Swal.fire({
        text: 'Please attempt at least one question',
        icon: 'error',
      });
      return;
    }
    const examArray: any = ['NMAT', 'SNAP', 'IIFT', 'MAHCET'];
    const currentSectionIndex = attempt?.test?.sections.findIndex(
      (section: any) => section?._id === activeSection?.section?._id
    );
    const isLastSection = !attempt?.test?.sections[currentSectionIndex + 1]
      ?.name;
    if (
      (attempt?.test?.examType === 'FULL LENGTH' &&
        (examArray?.includes(attempt?.test?.exam?.name) || isLastSection)) ||
      attempt?.test?.examType !== 'FULL LENGTH'
    ) {
      Swal.fire({
        title: 'Are you sure want to submit the test?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#239f87',
        confirmButtonText: 'Yes, Proceed!',
      }).then(result => {
        if (result.isConfirmed) {
          submitAttempt();
        } else setLoading(false);
      });
    }
  };

  const handleTimeOver = (attemptId: any) => {
    if (attempt?.test?.examType === 'FULL LENGTH') {
      if (attempt?.test?.exam?.name === 'CAT') {
        const currentSectionIndex = attempt?.test?.sections.findIndex(
          (section: any) => section?._id === activeSection?.section?._id
        );
        if (attempt?.test?.sections[currentSectionIndex + 1]?.name) {
          handleSectionChange(
            attempt?.test?.sections[currentSectionIndex + 1]?.name,
            true
          );
        } else {
          submitAttempt(attemptId, true);
        }
      } else if (attempt?.test?.exam?.name === 'XAT') {
        const currentGroupIndex = attempt?.test?.questionSets.findIndex(
          (group: any) => group?.name === activeGroup?.name
        );
        if (attempt?.test?.questionSets[currentGroupIndex + 1]?.name) {
          handleGroupChange(
            attempt?.test?.questionSets[currentGroupIndex + 1]?.name,
            true
          );
        } else {
          submitAttempt(attemptId, true);
        }
      } else {
        submitAttempt(attemptId, true);
      }
    } else {
      submitAttempt(attemptId, true);
    }
  };

  const onSubmitQuery = async (value: any) => {
    try {
      const { data } = await supportTicketCreate({
        text:
          '<div style="font-weight: bold">' +
          activeQuestion?.question?.text +
          '</div>' +
          '<END_OF_QUESTION>' +
          value.explanation,
        category: 'REPORTED_QUESTION',
        status: 'OPEN',
        question: activeQuestion?.parentQuestion?._id,
      });
      console.log('data', data);
      showModal((oldData: any) => ({ ...oldData, visible: false }));
    } catch (err) {
      console.error(err);
    }
  };

  const getSectionHeader = () => {
    if (
      attempt?.test?.examType === 'FULL LENGTH' ||
      attempt?.test?.examType === 'SECTION BASED'
    ) {
      return <p>Sections</p>;
    } else if (attempt?.test?.examType === 'SECTION BASE') {
      return <p>{`Section: ${attempt?.test?.sections?.[0]?.name || ''}`}</p>;
    } else if (attempt?.test?.examType === 'SUBJECT BASE')
      return <p>{`Subject: ${attempt?.test?.subjects?.[0]?.name || ''}`}</p>;
    else return <p>{`Topic: ${attempt?.test?.topics?.[0]?.name || ''}`}</p>;
  };

  const changeSectionTabList = (currentGroup: any) => {
    if (attempt?.test?.sections && attempt?.test?.examType === 'FULL LENGTH') {
      let sectionsList = attempt?.test?.sections;
      const tab2 = ['Essay', 'GK', 'General Knowledge', 'Knowledge'];
      if (attempt?.test?.exam?.configuration?.isGroupingAllowed) {
        if (currentGroup?.name === 'group a') {
          sectionsList = attempt?.test?.sections?.filter(
            (v: any) => !tab2.includes(v?.name)
          );
        } else if (currentGroup?.name === 'group b') {
          sectionsList = attempt?.test?.sections?.filter((v: any) =>
            tab2.includes(v?.name)
          );
        }
      }
      setSectionsTab(sectionsList);
    }
  };

  const profilePic = user?.displayPicture
    ? getAssetUrl(user?.displayPicture)
    : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

  return (
    <>
      {testEnd ? (
        <CloseWindowBanner>
          <h4>Your test has been submitted. You can close this window.</h4>
        </CloseWindowBanner>
      ) : (
        <>
          <div
            className="test-page secondary user-select-none"
            style={{ userSelect: 'none' }}
            onDragOver={event => drag_over(event)}
            onDragEnd={event => drop(event)}
          >
            <div className="test-page--header">
              <div className="left-col">
                <div className="section-main-header">
                  <p>
                    <strong style={{ fontWeight: 'bold' }}>
                      {attempt?.name}
                    </strong>
                  </p>

                  <div className="d-flex align-items-center">
                    {attempt?.test?.enableVirtualKeyboard && (
                      <Form
                        initialValues={{
                          virtualKeyboard: virtualNumericKeyboard,
                        }}
                      >
                        <CheckBox
                          id="virtualKeyboard"
                          name="virtualKeyboard"
                          label={`Numeric Keyboard`}
                          onChange={() =>
                            enableVirtualNumericKeyboard(
                              !virtualNumericKeyboard
                            )
                          }
                        />
                      </Form>
                    )}

                    {attempt?.test?.isDisplayCalculator ? (
                      <button
                        type="button"
                        id="displayCalculator"
                        className="btn ml-3"
                        onClick={() => setShowCalculator(!showCalculator)}
                      >
                        <FaCalculator />
                      </button>
                    ) : null}
                  </div>
                </div>

                <div className="section-header">
                  {getSectionHeader()}
                  <div className="timer">
                    <TestTimer
                      testOverTime={testTimeOver}
                      handleTimeOver={() => {
                        handleTimeOver(attempt?._id);
                      }}
                    />
                  </div>
                </div>

                {attempt?.test?.exam?.configuration?.isGroupingAllowed &&
                  attempt?.test?.questionSets?.length > 1 &&
                  attempt?.test?.examType === 'FULL LENGTH' && (
                    <div className="scrollable-section">
                      <button>
                        <GrFormPrevious />
                      </button>
                      <Tabs
                        active={activeGroup?.name}
                        onClick={handleGroupChange}
                        tabName="primary-tab"
                      >
                        {[
                          {
                            label: 'Part 1',
                            name: 'group a',
                          },
                          {
                            label: 'Part 2',
                            name: 'group b',
                          },
                        ]?.map((part: any) => (
                          <Tab
                            id={part?.name}
                            title={part?.label}
                            key={part?.label}
                          ></Tab>
                        ))}
                      </Tabs>
                      <button>
                        <GrFormNext />
                      </button>
                    </div>
                  )}

                <div className="scrollable-section">
                  <button>
                    <GrFormPrevious />
                  </button>
                  {sectionsTab?.length > 0 && (
                    <Tabs
                      active={activeSection?.section?.name}
                      onClick={handleSectionChange}
                      tabName="secondary-tab"
                    >
                      {sectionsTab?.map((section: any) => (
                        <Tab
                          id={section?.name}
                          title={section?.name}
                          key={section?._id}
                        ></Tab>
                      ))}
                    </Tabs>
                  )}
                  <button>
                    <GrFormNext />
                  </button>
                </div>
              </div>

              <div className="right-col">
                <div className="user-info">
                  <img src={profilePic} alt="" />
                  <div className="pl-3">
                    <p>{user?.name || ''}</p>
                    <a
                      href="javascript:void(0)"
                      id="instruction"
                      onClick={() => setShowInstruction(true)}
                    >
                      Instructions
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="test-page--body">
              <div className="left-col pr-5">
                <Mcq
                  createAsset={createAsset}
                  getAssetUrl={getAssetUrl}
                  activeQuestion={activeQuestion}
                  negativeMarking={attempt?.negativeMarking}
                  onChangeInputValue={(value: any) => {
                    const currentQuestion = { ...activeQuestion };
                    currentQuestion.typedAnswers = value;
                    setActiveQuestion(currentQuestion);
                  }}
                  setSelectedOption={(value: any) => {
                    const currentQuestion = { ...activeQuestion };
                    if (
                      activeQuestion &&
                      (activeQuestion?.question?.type === 'T/F' ||
                        activeQuestion?.question?.type === 'SA')
                    ) {
                      currentQuestion.selectedAnswers = [value];
                    } else {
                      currentQuestion.selectedAnswers = value;
                    }
                    setActiveQuestion(currentQuestion);
                  }}
                  onAnswerChange={(value: any) => {
                    const currentQuestion = { ...activeQuestion };
                    currentQuestion.answerImages = value;
                    setActiveQuestion(currentQuestion);
                  }}
                  virtualNumericKeyboard={virtualNumericKeyboard}
                  enablePhysicalKeyboard={attempt?.test?.enablePhysicalKeyboard}
                />
              </div>

              {attempt?.test?.isDisplayCalculator && showCalculator && (
                <div
                  id="keyboard"
                  className="scientific-calculator draggable"
                  draggable={true}
                >
                  <ScientificCalculator />
                </div>
              )}
              <div className={`right-col ${show ? 'in' : ''}`}>
                <button onClick={() => setShow(prev => !prev)}>
                  {!show ? <GrFormNext /> : <GrFormPrevious />}
                </button>
                <QuestionPalette
                  active={activeQuestion ? activeQuestion?.displayOrder : 0}
                  list={activeSection?.questions}
                  handleClick={handleQuestionNoClick}
                  name={activeSection?.section?.name}
                />
              </div>
            </div>
            <div className="test-page--footer">
              <div className="left-col">
                {/* <ExplanationImage
              src={`${imageBaseUrl}explanation.png`}
              className="img-fluid"
              onClick={() =>
                showModal((oldData: ModalInterface) => ({
                  ...oldData,
                  visible: true,
                }))
              }
              alt="explanation"
            /> */}
                <div className="d-flex align-items-center">
                  <button
                    id="MarkForReview"
                    type="button"
                    className="btn"
                    style={
                      activeQuestion?.isMarkForReview
                        ? {
                            color: '#239F87',
                            borderColor: '#239F87',
                            backgroundColor: '#f9d54d',
                            fontWeight: 400,
                          }
                        : {
                            color: '#239F87',
                            borderColor: '#239F87',
                            fontWeight: 400,
                          }
                    }
                    onClick={setMarkForReview}
                  >
                    {activeQuestion?.isMarkForReview
                      ? 'Unmark Review'
                      : 'Mark for Review'}
                  </button>

                  <button
                    id="ClearResponse"
                    type="button"
                    className="btn ml-2"
                    style={{
                      color: '#239F87',
                      borderColor: '#239F87',
                      fontWeight: 400,
                    }}
                    onClick={handleClearAnswer}
                  >
                    Clear <span>Response</span>
                  </button>
                </div>
                <div className="button-group d-flex align-items-center justify-conter-between">
                  {getPreviousQuestionNo() && (
                    <button
                      id="PreviousQue"
                      type="button"
                      className="btn"
                      style={{
                        color: '#239F87',
                        borderColor: '#239F87',
                        fontWeight: 400,
                      }}
                      onClick={setPreviousQuestion}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    type="button"
                    id="SaveQue"
                    className="btn btn-primary"
                    style={{ fontWeight: 400, minWidth: 142 }}
                    onClick={handleSubmitAnswer}
                  >
                    {!getNextQuestion() ? 'Save' : 'Save & Next'}
                  </button>
                  {getNextQuestion() && (
                    <button
                      type="button"
                      id="NextQue"
                      className="btn"
                      style={{
                        color: '#239F87',
                        borderColor: '#239F87',
                        fontWeight: 400,
                      }}
                      onClick={setNextQuestion}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
              <div className="right-col">
                <button
                  type="button"
                  className="btn"
                  style={{
                    color: '#fff',
                    fontWeight: 400,
                    zIndex: 9999999,
                  }}
                  disabled={loading}
                  onClick={handleSubmitAttemptClick}
                >
                  Submit Test
                </button>
              </div>
            </div>
          </div>
          <ExplanationModal
            _id={modal._id}
            isOpen={modal.visible}
            onSubmitQuery={onSubmitQuery}
            onRequestClose={() =>
              showModal((oldData: any) => ({ ...oldData, visible: false }))
            }
          />

          <InstructionModal
            attempt={attempt?.test}
            isOpen={showInstruction}
            onRequestClose={() => setShowInstruction(false)}
          />
        </>
      )}
    </>
  );
};

export default Test;
