import { client } from './client';
import { IDocument, IListParams, ObjectId } from './types';

export interface ISubject {
  name: string;
  subjectId?: string;
}

export interface ISubjectDocument extends ISubject, IDocument {}

export interface ISubjectListResponse {
  totalItems: number;
  subjects: ISubjectDocument[];
}

export interface ISubjectListParams extends IListParams {
  id?: ObjectId[];
  course?: ObjectId;
  exam?: ObjectId;
  section?: ObjectId;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<ISubjectDocument>(`/subjects/${id}`, { params });
};

const create = (subject: ISubject) => {
  return client.post<ISubjectDocument>(`/subjects`, subject);
};

const update = (subject: ISubjectDocument) => {
  return client.put<ISubjectDocument>(`/subjects/${subject._id}`, subject);
};

const remove = (id: string) => {
  return client.delete(`/subjects/${id}`);
};

const list = (params: ISubjectListParams) => {
  return client.get<ISubjectListResponse>(`/subjects/`, { params });
};

export const subject = { get, create, update, remove, list };
