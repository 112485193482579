import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { ImFileText2 } from 'react-icons/im';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { commonApiError } from '../subscriber-report';

const SubscriberBatches = ({ getBatches, _id, onBatchTestClick }: any) => {
  const [batches, setBatches] = useState<any>({
    totalItems: 0,
    batches: [],
  });
  const [visible, setVisible] = useState<any>(10);

  const loadMore = () => {
    setVisible(visible + 10);
  };

  useEffect(() => {
    getBatches({ all: true, populate: true, student: _id })
      .then(({ data }: any) => {
        setBatches(data.batches);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  }, []);

  return (
    <div className="lesson-wrapping">
      <p>List of Enrolled Programs</p>

      <div className="batch-accordion test-accordian">
        {batches?.length > 0 ? (
          batches?.slice(0, visible)?.map((batch: any) => {
            return (
              <Accordion key={batch?._id}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <p className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items--md-center pr-4">
                      <span style={{ fontSize: '15px' }}>{batch?.name}</span>
                      <span>
                        <span
                          style={{ color: '#00a795', fontSize: '12px' }}
                          className="mr-3"
                        >
                          {batch?.batchType === 'recorded'
                            ? 'Recorded'
                            : 'Remote'}
                        </span>
                        <span style={{ fontSize: '12px' }}>
                          {batch?.createdAt
                            ? moment(batch?.createdAt).format(
                                'DD MMMM YYYY, h:mma'
                              )
                            : '--'}
                        </span>
                      </span>
                    </p>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ padding: '0 17px 17px' }}>
                      {batch && (
                        <>
                          <div className="my-progress">
                            <div className="my-progress--name">
                              <span>
                                <ImFileText2 />
                              </span>
                              <p>Tests</p>
                            </div>
                            <div className="my-progress--bar">
                              <div className="primary-progress-bar">
                                <div className="wrap">
                                  <div
                                    className="bar-line"
                                    style={{ width: '30%' }}
                                  >
                                    Line
                                  </div>
                                </div>
                                <span>0/{batch?.tests?.length}</span>
                              </div>
                              <p>
                                10 attended out of total 45 Tests assigned to
                                you
                              </p>
                            </div>
                            <div className="my-progress--action">
                              <a
                                // href="javascript:void(0)"
                                style={{ cursor: 'pointer' }}
                                onClick={() => onBatchTestClick(batch?._id)}
                              >
                                View Details
                              </a>
                              {/* <span>Score</span> */}
                            </div>
                          </div>
                          <div className="my-progress">
                            <div className="my-progress--name">
                              <span>
                                <HiOutlineLightBulb />
                              </span>
                              <p>Recorded Lessons</p>
                            </div>
                            <div className="my-progress--bar">
                              <div className="primary-progress-bar">
                                <div className="wrap">
                                  <div
                                    className="bar-line"
                                    style={{ width: '30%' }}
                                  >
                                    Line
                                  </div>
                                </div>
                                <span>0/{batch?.syllabus?.length}</span>
                              </div>
                              <p>
                                0 videos watched out of total{' '}
                                {batch?.syllabus?.length} videos
                              </p>
                            </div>
                            <div className="my-progress--action">
                              {/* <a href="javascript:void(0)">View Details</a> */}
                            </div>
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            );
          })
        ) : (
          <p>No Data Found</p>
        )}
      </div>
      {visible < batches?.length && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={loadMore}>View More</p>
        </div>
      )}
      {visible >= batches?.length && visible > 10 && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={() => setVisible(10)}>View less</p>
        </div>
      )}
    </div>
  );
};

export default SubscriberBatches;
