import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from '../types';
import { IQuestionDocument, IQuestionItem } from '../question';

export interface ITestListParams extends IListParams {
  addedBy?: ObjectId;
}

export interface ITestListResponse {
  tests: ITest[];
  totalItems: number;
}

export interface IQuestion {
  displayOrder: number;
  subject: ObjectId | PopulatedDocument;
  topic: ObjectId | PopulatedDocument;
  question: ObjectId | IQuestionDocument;
  subQuestion: ObjectId | IQuestionItem;
  points: number;
  negativePoints?: number;
}

export interface ISectionSetting {
  isTimeLimitEnabled: boolean;
  timeMapping: [
    {
      section: string | PopulatedDocument;
      timeLimit: number;
      isSubmitted: boolean;
    }
  ];
}

export interface IBooleanValueSetting {
  isEnabled: boolean;
  value: number;
}

export interface IProctoredTest {
  isEnabled: boolean;
  startDate: string | null;
  isEndDateRequired: false;
  endDate: string | null;
}

export interface QuestionSection {
  section: ObjectId;
  questions: IQuestion[];
  timeLimit: number;
}
export interface QuestionSet {
  name: string;
  sections: QuestionSection[];
}
export interface ITest {
  name: string;
  instruction: string;
  displayPicture: string;
  center?: ObjectId[];
  isDisplayResult: boolean;
  noOfAttempts?: number;
  questions?: ObjectId[];
  totalDurationInMinute: number;
  displayOrder?: number;
  price?: number;
  actualPrice?: number;
  slug?: string;
  status?: string;
  active?: boolean;
}

export interface ITestDocument extends ITest, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<ITestDocument>(`/psychometric/tests/${id}`, { params });
};

const create = (questionSet: ITest) => {
  return client.post<ITestDocument>(`/psychometric/tests`, questionSet);
};

const update = (id: string, questionSet: ITestDocument) => {
  return client.patch<ITestDocument>(
    `/psychometric/tests/${id || questionSet._id}`,
    questionSet
  );
};

const list = (params: ITestListParams) => {
  return client.get<ITestListResponse>(`/psychometric/tests`, { params });
};

export const psychometricTest = { get, create, update, list };
