import { client } from './client';
import { IDocument, IListParams, ObjectId, PopulatedDocument } from './types';

export interface ISectionListParams extends IListParams {
  exams?: ObjectId[];
  course?: ObjectId;
  subject?: ObjectId;
}

export interface ISectionSubject {
  exam: ObjectId | PopulatedDocument;
  course: ObjectId | PopulatedDocument;
  section: ObjectId | PopulatedDocument;
  subject?: ObjectId | PopulatedDocument;
  topics: ObjectId[] | PopulatedDocument[];
}

export interface ISectionSubjectsList extends Array<ISectionSubject> {}

export interface ISection {
  name: string;
  sectionId: string;
  displayPicture: string;
  exam: ObjectId | PopulatedDocument;
  course: ObjectId | PopulatedDocument;
  subjects?: ObjectId[] | ISectionSubject[];
}

export interface IUpdateSubjectsInSection {
  subjects: string[];
}

export interface IUpdateTopicesInSubjectInSection {
  subject?: string;
  topics?: string[];
  isEnabled?: boolean;
}
export interface ISectionDocument extends ISection, IDocument {}

export interface ISectionListResponse {
  totalItems: number;
  sections: ISectionDocument[];
}
export interface ISectionUpdate {
  name: string;
}
const get = (id: string, params?: { populate: boolean }) => {
  return client.get<ISectionDocument>(`/sections/${id}`, { params });
};

const getSubject = (params?: {}) => {
  return client.get<any>(`/sections/subjects`, { params });
};

const create = (section: ISection) => {
  return client.post<ISectionDocument>(`/sections`, section);
};

const update = (id: string, section: ISectionUpdate) => {
  return client.patch<ISectionDocument>(`/sections/${id}`, section);
};

// const remove = (id: string) => {
//   return client.delete(`/sections/${id}`);
// };

const list = (params: ISectionListParams) => {
  return client.get<ISectionListResponse>(`/sections/`, { params });
};

const getSectionSubjects = (params: ISectionListParams) => {
  return client.get<ISectionSubjectsList>(`/sections/subjects/`, { params });
};

const getSectionTopics = (params: ISectionListParams) => {
  return client.get<ISectionSubjectsList>(`/sections/topics/`, { params });
};

const updateSubjectsInSection = (
  _id: string,
  payload: IUpdateSubjectsInSection
) => {
  return client.patch<ISectionDocument>(
    `/sections/${_id}/updateSubjects`,
    payload
  );
};

const patchSectionSubjects = (
  _id: string,
  payload: IUpdateTopicesInSubjectInSection
) => {
  return client.patch<ISectionDocument>(
    `/sections/${_id}/sectionSubject`,
    payload
  );
};

export const section = {
  get,
  create,
  update,
  list,
  updateSubjectsInSection,
  patchSectionSubjects,
  getSubject,
  getSectionSubjects,
  getSectionTopics,
};
