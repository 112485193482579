import { Button, Tab, Tabs } from '@lipihipi/ec-ui';
import { Form, Input, TextArea } from '@lipihipi/form';
import React, { useEffect, useState } from 'react';
import FileUpload from '../../components/file-upload/file-upload';
import { FilePreview } from '../../components/file-upload/file-preview';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { commonApiError } from '../../test-bundle/test-results/test-results-new';
import { FilePreviewContainer } from '../../components/file-upload/file-upload.styled';

interface ISubmitEvaluatedAnswer {
  comment: string;
  feedbackImages: [];
  score: string | number;
  questionId: string | number;
}

export const ModelSchema = Yup.object().shape({
  comment: Yup.string(),
  feedbackImages: Yup.array(),
  score: Yup.number().typeError('Score must be a number')
  .test(
    'is-less-than-or-equal',
    'Score should not exceed Points',
    function (value: any) {
      const point = this.parent.point;
      return value <= point;
    }
  ),
});

export const AdminExplanation = ({
  uploadFile,
  onCancelRequest,
  getTestAttempt,
  attemptId,
  submitEvaluatedAnswer,
  finishEvaluation,
  navigateBack
}: any) => {
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState<any>(null);
  const [attemptData, setAttemptData] = useState<any>();

  useEffect(() => {
    async function init() {
      try {
        if (attemptId) {
          const { data } = await getTestAttempt(attemptId, { populate: true });
          setAttemptData(data);
          if (data.test.examType === 'FULL LENGTH') {
            const section =
              (data?.sections.length > 0 && data.sections) ||
              (data?.test?.sections.length > 0 && data.test.sections);
            const sections = section?.map((v: any) =>
              v?.section ? v.section : v
            );
            setTabs(sections);
            const questions = data?.test?.questionSets.flatMap((set: any) =>
              set.sections
                .filter((sec: any) => sec.section === sections[0]._id)
                .flatMap((sec: any) => {
                  return sec.questions.filter(
                    (que: any) => que.question.type === 'DSC'
                  );
                })
            );
            setActiveTab({ ...sections[0], questions });
          } else {
            const questions = data?.test?.questionSets[0].sections[0].questions.filter(
              (que: any) => que.question.type === 'DSC'
            );
            setActiveTab({ questions });
          }
        }
      } catch (err) {
        console.error(err);
        // setLoading(false);
        Swal.fire({
          title: 'No Result',
          text: err?.data?.message || 'This test has no result.',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: false,
        });
      }
    }
    init();
  }, [attemptId]);

  const setSelectedTab = (name: string) => {
    const newTab: any = tabs.find((V: any) => V.name === name);
    const questions = attemptData?.test?.questionSets.flatMap((set: any) =>
      set.sections
        .filter((sec: any) => sec.section === newTab._id)
        .flatMap((sec: any) => {
          return sec.questions.filter(
            (que: any) => que.question.type === 'DSC'
          );
        })
    );
    setActiveTab({ ...newTab, questions });
  };

  const onSubmit = async (values: ISubmitEvaluatedAnswer) => {
    try {
      const { data } = await submitEvaluatedAnswer(attemptId, {
        ...values,
        isCorrect: +values.score > 0,
      });
      if (data) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Answer evaluation is completed.',
          allowOutsideClick: false,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      commonApiError(error);
    }
  };

  const finish = async () => {
    try {
      const { data } = await finishEvaluation(attemptId);
      if (data) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Test evaluation is completed.',
          allowOutsideClick: false,
          showConfirmButton: true,
        }).then(() => {
          navigateBack();
        });
      }
    } catch (error) {
      commonApiError(error);
    }
  };

  return (
    <main className="main-structure p-4">
      <div className="primary-page-header mb-3">
        <div className="wrap">
          <h3>{attemptData?.test?.name}</h3>
        </div>
      </div>
      {activeTab?.name && tabs?.length > 0 && (
        <Tabs active={activeTab.name} onClick={setSelectedTab}>
          {tabs?.map((v: any) => (
            <Tab id={v.name} title={v.name}>
              <h1>{v.name}</h1>
              {activeTab?.questions.map((que: any, index: number) => {
                return (
                  <div>
                    <div className="d-flex align-items-center mb-3">
                      <div className="section-info mb-0 mr-2">
                        <strong style={{ lineHeight: 1 }}>{`Q${index +
                          1}`}</strong>
                      </div>

                      {que.score && (
                        <ul className="test-duration-info mb-0">
                          <li style={{ color: '#F00', fontSize: '16px' }}>
                            {`Marks obtained: ${que.score}/${que.points}`}
                          </li>
                        </ul>
                      )}
                    </div>

                    <div className="common-data-view mb-5">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: que?.question?.text,
                        }}
                      ></p>
                    </div>

                    <div className="attempted-test-view">
                      <div className="left">
                        <>
                          <div
                            className="explanation-box common-data-view"
                            style={{ background: '#EFF' }}
                          >
                            <h3 className="mb-3">
                              Answer submission by Student
                            </h3>

                            <div
                              dangerouslySetInnerHTML={{
                                __html: que?.typedAnswers[0],
                              }}
                            ></div>
                          </div>

                          {que?.answerImages?.map((value: string) => (
                            <FilePreview key={value} file={value} />
                          ))}

                          <div
                            className="explanation-box common-data-view"
                            style={{ marginTop: '30px' }}
                          >
                            <h3 className="mb-3">
                              Supported Documents uploaded by question
                              creator
                            </h3>
                            <FilePreviewContainer className='uploaded-files'>
                              {que?.question?.supportedDocuments?.map(
                                (value: string) => (
                                  <FilePreview key={value} file={value} />
                                )
                              )}
                            </FilePreviewContainer>
                          </div>
                        </>
                      </div>
                      <div className="right">
                        <Form
                          initialValues={{
                            comment: que?.comment || '',
                            feedbackImages: que?.feedbackImages || [],
                            score: que?.score || '',
                            point: que?.points
                          }}
                          validationSchema={ModelSchema}
                          onSubmit={(values: ISubmitEvaluatedAnswer) =>
                            onSubmit({ ...values, questionId: que?._id })
                          }
                        >
                          <div className="form-group">
                            <TextArea
                              rows={10}
                              className="mb-0"
                              placeholder="Enter Here..."
                              id={'comment'}
                              name={'comment'}
                              label="Feedback by Educator"
                            />
                          </div>

                          <div className="form-group">
                            <FileUpload
                              name="feedbackImages"
                              upload={uploadFile}
                              accept={[
                                'application/pdf',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-excel',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.ms-powerpoint',
                                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                'text/plain',
                                'image/jpeg',
                                'image/jpg',
                                'image/png',
                              ]}
                              label={
                                'Attach Files (PDF, DOC, DOCX, TEXT, PNG, JPEG, JPG)'
                              }
                              type={'multiple'}
                              maxSize={'2gb'}
                              onCancel={onCancelRequest}
                              isCancelAllowed={true}
                            />
                          </div>
                          {/* <div className="row">
                            <div className="col-md-9">
                              <CheckboxGroup
                                value={''}
                                onChange={() => {}}
                                name="isCorrect"
                              >
                                {Checkbox => (
                                  <div className="d-flex flex-column">
                                    {[
                                      { label: 'Yes', value: true },
                                      { label: 'No', value: false },
                                    ].map((option: any) => (
                                      <label
                                        key={option.label}
                                        className="d-flex align-items-center"
                                      >
                                        <Checkbox value={option.value} />
                                        <span className="ml-3">
                                          {option.label}
                                        </span>
                                      </label>
                                    ))}
                                  </div>
                                )}
                              </CheckboxGroup>
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-md-9">
                              <Input
                                id="score"
                                name="score"
                                type="text"
                                placeholder="Enter marks"
                              />
                              <p>{`Max points: ${que?.points}`}</p>
                            </div>
                            <div className="col-md-3">
                              <Button
                                className="w-100 text-center"
                                shape="primary"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <hr />
                  </div>
                );
              })}
            </Tab>
          ))}
        </Tabs>
      )}

      {attemptData?.test?.name && (
        <Button
        className="text-center"
        shape="primary"
        type="submit"
        onClick={finish}
      >
        Finish Evaluation
      </Button>
      )}
    </main>
  );
};
