import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface IFaq {
  name: string;
  active?: boolean;
  description: string;
  course?: string;
  displayOrder?: number;
}

export interface IFaqDocument extends IFaq, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IFaqListResponse {
  totalItems: number;
  faqs: IFaqDocument[];
}

const get: TGetResource<IFaqDocument> = (id, params) => {
  return client.get<IFaqDocument>(`/faqs/${id}`, { params });
};

const create: TCreateResource<IFaq, IFaqDocument> = (faq: IFaq) => {
  return client.post<IFaqDocument>(`/faqs`, faq);
};

const update: TUpdateResource<IFaqDocument> = faq => {
  return client.put(`/faqs/${faq._id}`, faq);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/faqs/${id}`);
};

const list: TListResource<IListParams, IFaqListResponse> = params => {
  return client.get(`/faqs/`, { params });
};

export const faq = { get, create, update, remove, list };
