import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import courseImg from '../../images/course/1.svg';
import { FaCheck } from 'react-icons/fa';

import { ICourseDocument } from '@lipihipi/client-sdk/dist/course';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '@lipihipi/ec-ui';
// difference between two arrays
const differenceWith = (
  arr: Array<any>,
  val: Array<any>,
  comp: (a: any, b: any) => {}
) => arr.filter(a => val.findIndex(b => comp(a, b)) === -1);

const MyGoals: FC<any> = ({
  getCourses,
  getMyProfile,
  updateStudentProfile,
}: any) => {
  const [profile, setProfile] = useState<any>();
  const [courses, setCourses] = useState<Array<ICourseDocument>>([]);
  const [selected, setSelected] = useState<Array<ICourseDocument>>([]);

  const history = useHistory();

  useEffect(() => {
    getMyProfile()
      .then((response: any) => setProfile(response.data))
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (profile) {
      getCourses({ all: true })
        .then((response: any) => {
          const allCourses = response.data.courses;
            let mySelected = profile?.selectedCourses;
            const otherCourses = differenceWith(
              allCourses,
              mySelected,
              (a: any, b: any) => a._id === b.course?._id
            );
            mySelected = mySelected
              .map((selected: any) =>
                allCourses.find((item: any) => item._id === selected.course?._id)
              )
              .filter((item: any) => item);

            setSelected(mySelected);
            setCourses(otherCourses);
        })
        .catch(() => {});
    }
  }, [profile]);

  const handleSelectionChange = (
    event: ChangeEvent<HTMLInputElement>,
    course: any
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelected(prev => [...prev, course]);
      setCourses(prev => [...prev].filter(item => item._id !== value));
    } else {
      const isSelected = selected.find(item => item._id === value);
      if (isSelected) {
        setCourses(prev => [...prev, course]);
        setSelected(prev => [...prev].filter(item => item._id !== value));
      }
    }
  };

  const saveSelectedCoursee = useCallback(() => {
    const selectedCourses = selected.map(course => ({
      course: course._id,
      isActive: true,
    }));
    updateStudentProfile(profile?._id, { selectedCourses })
      .then(() => {
        console.log('[Success]: courses saved');
        history.push('/course/wall');
      })
      .catch((error: any) => {
        console.log('[Error]: courses failed', error);
      });
  }, [selected]);

  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="main-mid">
          <PageHeader
            title={'Your Selected Course'}
            breadCrumbs={[{ title: 'All Course', link: '/' }]}
          />

          <div className="courser-selection three-col">
            <ul>
              {selected.map((course: any) => (
                <li key={course._id} className="selected">
                  <input
                    type="checkbox"
                    name={course.name}
                    value={course._id}
                    defaultChecked
                    onChange={event => handleSelectionChange(event, course)}
                  />

                  <span>
                    <FaCheck />
                  </span>
                  {course.name}
                </li>
              ))}
            </ul>
          </div>
         

          <div className="primary-page-header flex-row align-items-center mt-5">

            <div className="wrap">
            {(!!courses.length) && (
              <h3>Select More</h3>
              )}
            </div>
            {(!!courses.length || !!selected.length) && (
              <div className="button-group">
                <button
                  type="button"
                  style={{ width: 160, height: 40 }}
                  className="btn btn-primary m-0"
                  onClick={saveSelectedCoursee}
                  disabled={!selected.length}
                >
                  Save
                </button>
              </div>
            )}
          </div>

          <div className="courser-selection three-col">
            <ul>
              {courses.map((course: any, index) => (
                <li key={index}>
                  <input
                    type="checkbox"
                    name={course.name}
                    value={course._id}
                    checked={false}
                    onChange={event => handleSelectionChange(event, course)}
                  />
                  <span>
                    <img src={courseImg} alt="Course" />
                  </span>
                  {course.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyGoals;
