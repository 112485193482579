import { Tooltip } from '@lipihipi/form';
import React from 'react';
import { TestCardProps } from './test-card.types';

export const TestCard = ({
  role,
  data,
  onClick,
  getAssetUrl,
  imageBaseUrl,
  isAttemptCompleted,
  isDisplayResult,
  ...rest
}: TestCardProps) => {
  // function testStatusToActionMapping(status: string) {
  //   switch (status) {
  //     case 'IN PROGRESS':
  //       return 'Continue';
  //     case 'FINISHED':
  //       return 'Reattempt';
  //     case 'NOT ATTEMPTED':
  //       return 'Attempt Now';
  //     default:
  //       return '';
  //   }
  // }
  // const actionText = testStatusToActionMapping(data.status);
  return (
    <div className={`col-md-4 col-lg-3`} data-test-id={data?._id}>
      <Tooltip>
        <a
          data-tip={data.title}
          style={{
            color: 'grey',
          }}
          href="javascript:void(0)"
        >
          <div className="priamry-test-card pb-0" {...rest} onClick={onClick}>
            <img
              alt="test"
              src={data.thumbnailUrl ? getAssetUrl(data.thumbnailUrl) : ''}
            />
            <p>{data.title}</p>

            <ul>
              <li>{data.questionsCount} Questions</li>
              <li className="active">{data.duration} Mins</li>
            </ul>
            {/* view-report */}
            {/* handle single attempt test handle only */}
            {data.isSubsribed && (
              <>
                {role === 'user' && data?.paymentStatus === 'APPROVED' && (
                  <>
                    {isAttemptCompleted &&
                      isAttemptCompleted === 'IN PROGRESS' && (
                        <div className="test-card-footer">Resume</div>
                      )}
                    {/* {(!isAttemptCompleted ||
                        isAttemptCompleted === 'NOT ATTEMPTED') &&
                        !data?.futureTest && (
                          <div className="test-card-footer">Attempt Now</div>
                        )} */}
                    {(!isAttemptCompleted ||
                      isAttemptCompleted === 'NOT ATTEMPTED') && (
                      <div
                        className={`test-card-footer ${
                          data?.futureTest ? 'view-report disabled' : ''
                        }`}
                        onClick={() => {
                          console.log(data);
                        }}
                      >
                        {data?.futureTest ? 'Test Disabled' : 'Attempt Now'}
                      </div>
                    )}
                    {isAttemptCompleted === 'FINISHED' && isDisplayResult && (
                      <div className="test-card-footer view-report">
                        {' '}
                        View Report
                      </div>
                    )}
                    {isAttemptCompleted === 'SUBMITTED' && (
                      <div className="test-card-footer"> Completed </div>
                    )}
                  </>
                )}
                {role === 'user' && data?.paymentStatus !== 'APPROVED' && (
                  <>
                    {isAttemptCompleted &&
                      isAttemptCompleted === 'IN PROGRESS' && (
                        <div className="test-card-footer">Resume</div>
                      )}
                    {(!isAttemptCompleted ||
                      isAttemptCompleted === 'NOT ATTEMPTED') &&
                      data?.isTestEnabled && (
                        <div className="test-card-footer">Attempt Now</div>
                      )}
                    {isAttemptCompleted === 'FINISHED' && isDisplayResult && (
                      <div className="test-card-footer view-report">
                        {' '}
                        View Report
                      </div>
                    )}
                    {isAttemptCompleted === 'SUBMITTED' && (
                      <div className="test-card-footer"> Completed </div>
                    )}
                  </>
                )}
                {role === 'superadmin' && (
                  <>
                    {isAttemptCompleted === 'FINISHED' && isDisplayResult && (
                      <div className="test-card-footer view-report">
                        View Report
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </a>
      </Tooltip>
    </div>
  );
};
