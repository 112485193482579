import React, { FC } from 'react';
import { Input, Form } from '@lipihipi/form';
import { validationSchema } from '../../../utils/validations';

const CreatePasswordForm: FC<any> = (props: any) => {
	return (
		<div className="user-structure--form">
			<div className="wrap">
			<h2>Create Password</h2>
        <Form
          initialValues={{ password: ''}}
          validationSchema={validationSchema.password}
          onSubmit={props.handleSubmit}
        >
          <Input
            id="password"
            name="password"
            type="password"
            label="Password"
            required
          />
          <Input
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            label="Confirm Password"
            required
          />

          <div className="button-group">
          <button type="submit" className="btn btn-primary" style={{width: '100%'}}>
              Submit
            </button>
          </div>
        </Form>
			</div>
		</div>
	);
};

export default CreatePasswordForm;
