import React, { useEffect, useState } from 'react';
import { PageHeader, Tab, Tabs, Loader } from '@lipihipi/ec-ui';
interface IStudentResource {
  breadCrumbs?: any[];
  imageBaseUrl: string;
  getResourceList: any;
  getAssetUrl: any;
  courseId: any;
  onVideoClick: any;
  onTestClick: any;
}

export const StudentResource: React.FC<IStudentResource> = ({
  breadCrumbs,
  getResourceList,
  getAssetUrl,
  courseId,
  onVideoClick,
  onTestClick,
}) => {
  const [exam, setExam] = useState<any>('');
  const [test, setTest] = useState('Full Length');
  const [resource, setResource] = useState<any>({
    totalItems: 0,
    freeResources: [],
  });
  const [videos, setFreeVideos] = useState<any>([]);
  const [allTest, setAllTest] = useState<any>([]);
  const [fullLenghts, setFullLenghts] = useState<any>([]);
  const [tobicBase, setTobicBase] = useState<any>([]);
  const [sectionBase, setSectionBase] = useState<any>([]);
  const [subjectBase, setSubjectBase] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    _getResource();
  }, [courseId]);
  const _getResource = () => {
    getResourceList({ populate: true, course: courseId, isRecent: true,all:true }).then(
      (res: any) => {
        setResource(res.data);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    const data = resource.freeResources.sort((a: any, b: any) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
    const videos = data.filter((i: any) => i.type === 'FILE');
    const tests = data.filter((i: any) => i.type === 'TEST');
    const testSection: any = {
      'FULL LENGTH': [],
      'SECTION BASE': [],
      'SUBJECT BASE': [],
      'TOPIC BASE': [],
    };
    tests?.forEach((test: any) => {
      if (test.examType) {
        testSection[test.examType].push(test);
      }
    });
    setAllTest(testSection)
    let freeVideos: any = {};
    let topicTests: any = {};
    let subjectTests: any = {};
    let sectionTests: any = {};
    videos.forEach((topic: any) => {
      if (freeVideos.hasOwnProperty(topic?.section._id)) {
        freeVideos[topic?.section._id].files.push({
          file: topic,
        });
      } else {
        freeVideos[topic?.section._id] = {
          files: [
            {
              file: topic,
              resourceId: topic._id,
            },
          ],
          sectionName: topic.section.name,
        };
      }
    });

    testSection['TOPIC BASE'].forEach(function(topic: any) {
      if (topicTests.hasOwnProperty(topic.topic._id)) {
        topicTests[topic.topic._id].test.push({
          test: topic,
          resourceId: topic._id,
        });
      } else {
        topicTests[topic.topic._id] = {
          test: [
            {
              test: topic,
              resourceId: topic._id,
            },
          ],
          topicName: topic.topic.name,
        };
      }
    });
    testSection['SUBJECT BASE'].forEach(function(subject: any) {
      if (subjectTests.hasOwnProperty(subject.subject._id)) {
        subjectTests[subject.subject._id].test.push({
          test: subject,
          resourceId: subject._id,
        });
      } else {
        subjectTests[subject.subject._id] = {
          test: [
            {
              test: subject,
              resourceId: subject._id,
            },
          ],
          subjectName: subject.subject.name,
        };
      }
    });
    testSection['SECTION BASE'].forEach(function(section: any) {
      if (sectionTests.hasOwnProperty(section.section._id)) {
        sectionTests[section.section._id].test.push({
          test: section,
          resourceId: section._id,
        });
      } else {
        sectionTests[section.section._id] = {
          test: [
            {
              test: section,
              resourceId: section._id,
            },
          ],
          sectionName: section.section.name,
        };
      }
    });
    setExam(Object.keys(freeVideos)[0]);

    setFreeVideos(freeVideos);
    setTobicBase(topicTests);
    setSubjectBase(subjectTests);
    setSectionBase(sectionTests);
    setFullLenghts(testSection['FULL LENGTH']);
  }, [resource]);
  return (
    <section className="main-structure">
      {isLoading && <Loader />}
      <PageHeader breadCrumbs={breadCrumbs || [{ title: 'Free Resource' }]} />
      {resource &&
      (Object.keys(videos).length > 0 ||
        Object.keys(sectionBase).length > 0 ||
        Object.keys(subjectBase).length > 0 ||
        Object.keys(tobicBase).length > 0 ||
        fullLenghts.length > 0) ? (
        <>
          {resource && Object.keys(videos).length > 0 && (
            <div className="mb-5">
              <h3>Free Videos/PDFs</h3>
              <div className="student-tabs">
                <Tabs active={exam} onClick={setExam}>
                  {Object.keys(videos).map((id: any) => {
                    return (
                      <Tab id={id} title={videos[id].sectionName}>
                        <div className="row">
                          {videos[id].files.map((video: any) => {
                            return (
                              <div
                                className="col-md-4"
                                id={videos[id]}
                                data-video-id={video.file._id}
                              >
                                <a
                                  href='javascript:void(0)'
                                  onClick={() => {
                                    onVideoClick(video?.file?._id);
                                  }}
                                  className="d-block mb-4"
                                >
                                  <div className="resource-card">
                                    <div className="image">
                                      <img
                                        alt="resource"
                                        src={getAssetUrl(
                                          video?.file?.thumbnail
                                        )}
                                        className="img-responsive"
                                      />
                                    </div>
                                    <h5>{video?.file?.title}</h5>
                                    <p>{video?.file?.exam?.name}</p>
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </Tab>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          )}
          {resource &&
            (Object.keys(sectionBase).length > 0 ||
              Object.keys(subjectBase).length > 0 ||
              Object.keys(tobicBase).length > 0 ||
              fullLenghts.length > 0) && (
              <div>
                <h3>Free Tests</h3>

                <div className="student-tabs">
                  <Tabs active={test} onClick={setTest}>
                    <Tab
                      id={'Full Length'}
                      title={`Full Length (${fullLenghts.length})`}
                    >
                      {fullLenghts.length > 0 ? (
                        <div className="row">
                          {fullLenghts.map((full: any) => {
                            return (
                              <div
                                className="col-md-3"
                                data-test-id={full.test._id}
                              >
                                <div className="educrack-primary-test-card">
                                  <div className="educrack-primary-test-card_header">
                                    {/* <img
                                      alt="free"
                                      src="https://educrack.com/static-assets/asset/svg/new/free.svg"
                                      className="overlap"
                                    /> */}
                                    <a
                                      href='javascript:void(0)'
                                      onClick={() => {
                                        onTestClick(full._id, full.test._id);
                                      }}
                                      target="_blank"
                                    >
                                      <img
                                        src={getAssetUrl(
                                          full?.test.displayPicture
                                        )}
                                        className="img-responsive"
                                        alt="Test"
                                      />
                                    </a>
                                  </div>
                                  <div className="educrack-primary-test-card_body">
                                    <h4>
                                      <a
                                        className="text-truncate"
                                        href='javascript:void(0)'
                                        onClick={() => {
                                          onTestClick(full._id, full.test._id);
                                        }}
                                        target="_blank"
                                      >
                                        {full?.test.name}
                                      </a>
                                    </h4>
                                    <ul>
                                      <li>
                                        <img
                                          alt="Questions"
                                          src="https://educrack.com/static-assets/asset//svg/new/tick.svg"
                                          className="overlap"
                                        />
                                        {full?.test.totalQuestions} questions
                                      </li>
                                    </ul>
                                  </div>
                                  {/* <div className="educrack-primary-test-card_footer view-report"> */}
                                    {full?.userTest &&
                                      full?.userTest?.status ===
                                      'IN PROGRESS' && (
                                      <div className="educrack-primary-test-card_footer">
                                        <a
                                          href='javascript:void(0)'
                                          onClick={() => {
                                            onTestClick(
                                              full._id,
                                              full.test._id
                                            );
                                          }}
                                          target="_blank"
                                          className="full-width educrack-primary-button secondary"
                                        >
                                          <span>Resume</span>
                                        </a>
                                        </div>
                                      )}
                                    {(!full?.userTest ||
                                      full?.userTest?.status ===
                                      'NOT ATTEMPTED') && (
                                      <div className="educrack-primary-test-card_footer">
                                      <a
                                        href='javascript:void(0)'
                                        onClick={() => {
                                          onTestClick(full._id, full.test._id);
                                        }}
                                        target="_blank"
                                        className="full-width educrack-primary-button secondary"
                                      >
                                        <span>Attempt Now</span>
                                      </a>
                                        </div>
                                    )}
                                    {full?.userTest?.status === 'FINISHED' && (
                                      <div className="educrack-primary-test-card_footer view-report">
                                      <a
                                        href='javascript:void(0)'
                                        onClick={() => {
                                          window.open(
                                            `/course/test/result/${full.userTest.attempts[0]}`
                                          );
                                        }}
                                        target="_blank"
                                        className="full-width educrack-primary-button secondary"
                                      >
                                        <span>View Report</span>
                                      </a>
                                        </div>
                                    )}
                                    {full?.userTest?.status === 'SUBMITTED' && (
                                      <div className="educrack-primary-test-card_footer completed">
                                      <p> Completed </p>
                                        </div>
                                    )}
                                    {/* <span>Attempt Now</span> */}
                                  {/* </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="mb-5">
                          <div className="row">
                            <div className="col">
                              <p>No Test Available</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab>

                    <Tab
                      id={'Section Wise Tests'}
                      title={`Section Wise Tests (${
                        allTest['SECTION BASE'].length
                      })`}
                    >
                      {Object.keys(sectionBase).length > 0 ? (
                        <>
                          {Object.keys(sectionBase).map((id: any) => {
                            return (
                              <div className="mb-5">
                                <h5>{sectionBase[id].sectionName}</h5>
                                <div className="row">
                                  {sectionBase[id].test.map((test: any) => {
                                    return (
                                      <div
                                        className="col-md-3"
                                        data-test-id={test.test.test._id}
                                      >
                                        <div className="educrack-primary-test-card mb-3">
                                          <div className="educrack-primary-test-card_header">
                                            {/* <img
                                              alt="free"
                                              src="https://educrack.com/static-assets/asset/svg/new/free.svg"
                                              className="overlap"
                                            /> */}
                                            <a
                                              href='javascript:void(0)'
                                              onClick={() => {
                                                onTestClick(
                                                  test.resourceId,
                                                  test.test.test._id
                                                );
                                              }}
                                              target="_blank"
                                            >
                                              <img
                                                src={getAssetUrl(
                                                  test?.test.test.displayPicture
                                                )}
                                                className="img-responsive"
                                                alt="Test"
                                              />
                                            </a>
                                          </div>
                                          <div className="educrack-primary-test-card_body">
                                            <h4>
                                              <a
                                                className="text-truncate"
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  onTestClick(
                                                    test.resourceId,
                                                    test.test.test._id
                                                  );
                                                }}
                                                target="_blank"
                                              >
                                                {test?.test.test.name}
                                              </a>
                                            </h4>
                                            <ul>
                                              <li>
                                                <img
                                                  alt="Questions"
                                                  src="https://educrack.com/static-assets/asset//svg/new/tick.svg"
                                                  className="overlap"
                                                />
                                                {test?.test.test.totalQuestions}{' '}
                                                questions
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="educrack-primary-test-card_footer">
                                            {test?.test.userTest &&
                                              test?.test.userTest?.status ===
                                                'IN PROGRESS' && (
                                                <a
                                                  href='javascript:void(0)'
                                                  onClick={() => {
                                                    onTestClick(
                                                      test.resourceId,
                                                      test.test.test._id
                                                    );
                                                  }}
                                                  target="_blank"
                                                  className="full-width educrack-primary-button secondary"
                                                >
                                                  <span>Resume</span>
                                                </a>
                                              )}
                                            {(!test?.test.userTest ||
                                              test?.test.userTest?.status ===
                                                'NOT ATTEMPTED') && (
                                              <a
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  onTestClick(
                                                    test.resourceId,
                                                    test.test.test._id
                                                  );
                                                }}
                                                target="_blank"
                                                className="full-width educrack-primary-button secondary"
                                              >
                                                <span>Attempt Now</span>
                                              </a>
                                            )}
                                            {test?.test.userTest?.status ===
                                              'FINISHED' && (
                                              <a
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  window.open(
                                                    `/course/test/result/${test.test.userTest.attempts[0]}`
                                                  );
                                                }}
                                                target="_blank"
                                                className="full-width educrack-primary-button secondary"
                                              >
                                                <span>View Report</span>
                                              </a>
                                            )}
                                            {test?.test.userTest?.status ===
                                              'SUBMITTED' && (
                                              <span> Completed </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="mb-5">
                          <div className="row">
                            <div className="col">
                              <p>No Test Available</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab>
                    <Tab
                      id={'Subject Wise Tests'}
                      title={`Subject Wise Tests (${
                        allTest['SUBJECT BASE'].length
                      })`}
                    >
                      {Object.keys(subjectBase).length > 0 ? (
                        <>
                          {Object.keys(subjectBase).map((id: any) => {
                            return (
                              <div className="mb-5">
                                <h5>{subjectBase[id].subjectName}</h5>
                                <div className="row">
                                  {subjectBase[id].test.map((test: any) => {
                                    return (
                                      <div
                                        className="col-md-3"
                                        data-test-id={test.test.test._id}
                                      >
                                        <div className="educrack-primary-test-card mb-3">
                                          <div className="educrack-primary-test-card_header">
                                            {/* <img
                                              alt="free"
                                              src="https://educrack.com/static-assets/asset/svg/new/free.svg"
                                              className="overlap"
                                            /> */}
                                            <a
                                              href='javascript:void(0)'
                                              onClick={() => {
                                                onTestClick(
                                                  test.resourceId,
                                                  test.test.test._id
                                                );
                                              }}
                                              target="_blank"
                                            >
                                              <img
                                                src={getAssetUrl(
                                                  test?.test.test.displayPicture
                                                )}
                                                className="img-responsive"
                                                alt="Test"
                                              />
                                            </a>
                                          </div>
                                          <div className="educrack-primary-test-card_body">
                                            <h4>
                                              <a
                                                className="text-truncate"
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  onTestClick(
                                                    test.resourceId,
                                                    test.test.test._id
                                                  );
                                                }}
                                                target="_blank"
                                              >
                                                {test?.test.test.name}
                                              </a>
                                            </h4>
                                            <ul>
                                              <li>
                                                <img
                                                  alt="Questions"
                                                  src="https://educrack.com/static-assets/asset//svg/new/tick.svg"
                                                  className="overlap"
                                                />
                                                {test?.test.test.totalQuestions}{' '}
                                                questions
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="educrack-primary-test-card_footer">
                                            {test?.test.userTest &&
                                              test?.test.userTest?.status ===
                                                'IN PROGRESS' && (
                                                <a
                                                  href='javascript:void(0)'
                                                  onClick={() => {
                                                    onTestClick(
                                                      test.resourceId,
                                                      test.test.test._id
                                                    );
                                                  }}
                                                  target="_blank"
                                                  className="full-width educrack-primary-button secondary"
                                                >
                                                  <span>Resume</span>
                                                </a>
                                              )}
                                            {(!test?.test.userTest ||
                                              test?.test.userTest?.status ===
                                                'NOT ATTEMPTED') && (
                                              <a
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  onTestClick(
                                                    test.resourceId,
                                                    test.test.test._id
                                                  );
                                                }}
                                                target="_blank"
                                                className="full-width educrack-primary-button secondary"
                                              >
                                                <span>Attempt Now</span>
                                              </a>
                                            )}
                                            {test?.test.userTest?.status ===
                                              'FINISHED' && (
                                              <a
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  window.open(
                                                    `/course/test/result/${test.test.userTest.attempts[0]}`
                                                  );
                                                }}
                                                target="_blank"
                                                className="full-width educrack-primary-button secondary"
                                              >
                                                <span>View Report</span>
                                              </a>
                                            )}
                                            {test?.test.userTest?.status ===
                                              'SUBMITTED' && (
                                              <span> Completed </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="mb-5">
                          <div className="row">
                            <div className="col">
                              <p>No Test Available</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab>
                    <Tab
                      id={'Topic Wise Tests'}
                      title={`Topic Wise Tests (${
                        allTest['TOPIC BASE'].length
                      })`}
                    >
                      {Object.keys(tobicBase).length > 0 ? (
                        <>
                          {Object.keys(tobicBase).map((id: any) => {
                            return (
                              <div className="mb-5">
                                <h5>{tobicBase[id].topicName}</h5>
                                <div className="row">
                                  {tobicBase[id].test.map((test: any) => {
                                    return (
                                      <div
                                        className="col-md-3"
                                        data-test-id={test.test.test._id}
                                      >
                                        <div className="educrack-primary-test-card mb-3">
                                          <div className="educrack-primary-test-card_header">
                                            {/* <img
                                              alt="free"
                                              src="https://educrack.com/static-assets/asset/svg/new/free.svg"
                                              className="overlap"
                                            /> */}
                                            <a
                                              href='javascript:void(0)'
                                              onClick={() => {
                                                onTestClick(
                                                  test.resourceId,
                                                  test.test.test._id
                                                );
                                              }}
                                              target="_blank"
                                            >
                                              <img
                                                src={getAssetUrl(
                                                  test?.test.test.displayPicture
                                                )}
                                                className="img-responsive"
                                                alt="Test"
                                              />
                                            </a>
                                          </div>
                                          <div className="educrack-primary-test-card_body">
                                            <h4>
                                              <a
                                                className="text-truncate"
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  onTestClick(
                                                    test.resourceId,
                                                    test.test._id
                                                  );
                                                }}
                                                target="_blank"
                                              >
                                                {test?.test.test.name}
                                              </a>
                                            </h4>
                                            <ul>
                                              <li>
                                                <img
                                                  alt="Questions"
                                                  src="https://educrack.com/static-assets/asset//svg/new/tick.svg"
                                                  className="overlap"
                                                />
                                                {test?.test.test.totalQuestions}{' '}
                                                questions
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="educrack-primary-test-card_footer">
                                            {test?.test.userTest &&
                                              test?.test.userTest?.status ===
                                                'IN PROGRESS' && (
                                                <a
                                                  href='javascript:void(0)'
                                                  onClick={() => {
                                                    onTestClick(
                                                      test.resourceId,
                                                      test.test._id
                                                    );
                                                  }}
                                                  target="_blank"
                                                  className="full-width educrack-primary-button secondary"
                                                >
                                                  <span>Resume</span>
                                                </a>
                                              )}
                                            {(!test?.test.userTest ||
                                              test?.test.userTest?.status ===
                                                'NOT ATTEMPTED') && (
                                              <a
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  onTestClick(
                                                    test.resourceId,
                                                    test.test._id
                                                  );
                                                }}
                                                target="_blank"
                                                className="full-width educrack-primary-button secondary"
                                              >
                                                <span>Attempt Now</span>
                                              </a>
                                            )}
                                            {test?.test.userTest?.status ===
                                              'FINISHED' && (
                                              <a
                                                href='javascript:void(0)'
                                                onClick={() => {
                                                  window.open(
                                                    `/course/test/result/${test.test.userTest.attempts[0]}`
                                                  );
                                                }}
                                                target="_blank"
                                                className="full-width educrack-primary-button secondary"
                                              >
                                                <span>View Report</span>
                                              </a>
                                            )}
                                            {test?.test.userTest?.status ===
                                              'SUBMITTED' && (
                                              <span> Completed </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="mb-5">
                          <div className="row">
                            <div className="col">
                              <p>No Test Available</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            )}
        </>
      ) : (
        <p>No resource available</p>
      )}
    </section>
  );
};
