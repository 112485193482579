import React, { useState, useEffect } from 'react';
import { PageHeader } from '@lipihipi/ec-ui';
import Styled from 'styled-components';
import { commonApiError } from '../../admin/create/index';

const ObjectBox = Styled.embed<{ src: string }>`
width: 800px;
height: auto;
min-height: 450px;
margin:10px;
margin-left:0;
src: src(${({ src }: any) => src});`;

const PdfBox = Styled.embed<{ src: string }>`
width: 90vw;
height: auto;
min-height: 100vh;
margin:10px;
margin-left:0;
src: src(${({ src }: any) => src});`;

const FreeResourceDetail = ({
    resourceId,
    getSessionById,
    getAssetUrl,
}: any) => {
    const [resData, setData] = useState<any>(null);

    useEffect(() => {
        get_res();
    }, []);
    const get_res = () => {
        getSessionById(resourceId, { populate: true })
            .then((res: any) => {
                setData(res?.data);
            })
            .catch((error: any) => {
                commonApiError(error);
                // handle the error as needed, e.g. display an error message to the user
            });
    };

    return (
        <div className="d-flex flex-wrap">
            <div className="main-mid">
                <PageHeader
                    title={resData !== null ? resData?.batches[0]?.name : ""}
                    breadCrumbs={[{ title: 'Recorded Session' }]}
                />

                {/* <h3 className="primary-heading">
                    {resData?.subject?.name || 'Nan'}
                </h3>
                <h3 className="primary-heading">
                    {resData?.topics[0]?.name || 'Nan'}
                </h3> */}

                <div className="resource-detail-box">
                    {resData?.recordedFile ? (
                        resData?.recordedFile?.replace(/^.*\./, '') === 'pdf' ? (
                            <PdfBox src={getAssetUrl(resData?.recordedFile)} />
                        ) : resData?.recordedFile?.replace(/^.*\./, '') === 'mp4' ? (
                            <video
                                style={{ borderRadius: '20px' }}
                                width="100%"
                                controls
                                controlsList="nodownload"
                                disablePictureInPicture
                            >
                                <source src={getAssetUrl(resData?.recordedFile)} type="video/mp4" />
                            </video>
                        ) : (
                            <ObjectBox src={getAssetUrl(resData?.recordedFile)} />
                        )
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default FreeResourceDetail;
