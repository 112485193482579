import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TGetResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface IInterview {
  name: string;
  course: string;
  displayPicture: string;
  description: string;
  noOfLinks: number;
  price: number;
  interviewId?: string;
  status?: status;
}

export interface IInterviewDocument extends IInterview, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IInterviewListResponse {
  totalItems: number;
  interviews: IInterviewDocument[];
}

export enum status {
  'INIT',
  'UNPUBLISH',
  'PUBLISH',
}

const get: TGetResource<IInterviewDocument> = (id, params) => {
  return client.get<IInterviewDocument>(`/interviews/${id}`, { params });
};

const create: TCreateResource<IInterview, IInterviewDocument> = (
  course: IInterview
) => {
  return client.post<IInterviewDocument>(`/interviews`, course);
};

const update: TUpdateResource<IInterviewDocument> = course => {
  return client.patch(`/interviews/${course._id}`, course);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/interviews/${id}`);
};

const list: TListResource<IListParams, IInterviewListResponse> = params => {
  return client.get(`/interviews/`, { params });
};
const statusUpdate = (id: string, data: { status: status }) => {
  return client.post<any>(`/interviews/${id}/statusUpdate`, data);
};

export const interview = { get, create, update, remove, list, statusUpdate };
