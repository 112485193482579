import { Modal } from '@lipihipi/ec-ui';
import { Form, Input } from '@lipihipi/form';
import { commonApiError } from '../admin/coupon/index';
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
//import swal from 'sweetalert';

const PaymentDetailsModal = ({
  isOpen,
  onRequestClose,
  resourceName,
  id,
  type,
  applyCoupon,
  modulePurchase,
  initCashFreePayment,
  moduleData,
}: //viewAllOption,
  any) => {
  const [moduleItemPrice, setModuleItemPrice] = useState(moduleData?.price);
  const [finalPrice, setFinalPrice] = useState(moduleData?.price);
  const [invalidCoupon, setInvalidCoupon] = useState('');
  const [validCoupon, setValidCoupon] = useState('');
  const [discountAmounts, setDiscountAmounts] = useState(0);
  const [couponValue, setCouponValue] = useState('');

  const onPlaceOrder = () => {
    onRequestClose();
    if (type === 'INTERVIEW') {
      modulePurchase({ interviewId: id })
        .then(({ data }: any) => {
          initCashFreePayment({
            productId: data?._id,
            productType: type,
            coupon: couponValue,
          });
        })
        .catch((err: any) => {
          commonApiError(err);
        });
    } else if (type === 'TESTBUNDLE') {
      modulePurchase({ testBundle: id })
        .then(({ data }: any) => {
          initCashFreePayment({
            productId: data?._id,
            productType: type,
            coupon: couponValue,
          });
        })
        .catch((err: any) => {
          commonApiError(err);
        });
    } else if (type === 'PROGRAM') {
      modulePurchase(moduleData)
        .then(({ data }: any) => {
          initCashFreePayment({
            productId: data?._id,
            productType: type,
            coupon: couponValue,
          });
        })
        .catch((err: any) => {
          commonApiError(err);
        });
    }
  };

  const getCouponValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target);
    setCouponValue(event.target.value);
  };

  const onApplyCoupon = (values: any) => {
    const payload = {
      id: id,
      type: type,
      coupon: values.couponId,
    };
    setCouponValue(values.couponId);
    applyCoupon(payload)
      .then(({ data }: any) => {
        if (data['price'] >= 0) {
          setModuleItemPrice(data.originalPrice);
          setFinalPrice(data.price);
          setDiscountAmounts(data.discountedPrice);
          setInvalidCoupon('');
          setValidCoupon('Coupon Applied Successfully');
        }
      })
      .catch((error: any) => {
        setInvalidCoupon(error.data.message);
        setCouponValue('');
        //setModuleItemPrice(0);
        setDiscountAmounts(0);
        setFinalPrice(moduleItemPrice);
        setValidCoupon('');
        commonApiError(error);
      });
  };
  return (
    <Modal heightDefault={true} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="redeem-modal">
        <h3>Payment Details</h3>
        <p>
          It's important to have an interaction with your Mentors from time to
          time
        </p>
        <>
          <Form
            initialValues={{}}
            onSubmit={onApplyCoupon}
            render={() => (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <span>{resourceName}</span>
                  </div>
                  <div className="col-md-6">
                    ₹ <span>{moduleItemPrice}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <Input
                      id="couponId"
                      name="couponId"
                      type="text"
                      placeholder="Enter Coupon Code"
                      onChange={getCouponValue}
                    />
                    <p>{validCoupon}</p>
                    <p>{invalidCoupon}</p>
                  </div>
                  <div className="col-md-6">
                    <Button type="submit">Apply Coupon</Button>
                  </div>
                </div>
              </>
            )}
          />
          <div className="row">
            <div className="col-md-6">
              <span>Subtotal</span>
            </div>
            <div className="col-md-6">
              ₹ <span>{moduleItemPrice}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span>Coupon Discount</span>
            </div>
            <div className="col-md-6">
              ₹ <span>{discountAmounts}</span>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-md-6">
              <span>Total Amount</span>
            </div>
            <div className="col-md-6">
              ₹ <span>{finalPrice}</span>
            </div>
          </div>
          <br></br>
          <Button onClick={onPlaceOrder}>Place Order</Button>
        </>
      </div>
    </Modal>
  );
};

export default PaymentDetailsModal;
