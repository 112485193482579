import { client } from './client';
import { IDocument, IListParams, ObjectId, PopulatedUserDocument } from 'types';

export interface IFollowListParams extends IListParams {
  addedBy?: ObjectId;
  requestType?: string;
  accepted?: string;
  reject?: string;
  isBlocked?: string;
}

export interface IFollow {
  _id: string;
  follower: string;
  following: string;
  accepted: boolean;
  reject: boolean;
  isBlocked: boolean;
}

export interface IFollowDocument extends IFollow, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IFollowListResponse {
  follows: IFollowDocument[];
  totalItems: number;
}

const list = (params: IFollowListParams) => {
  return client.get<IFollowListResponse>('/following', { params });
};

const get = (params: IFollowListParams) => {
  return client.get<IFollowDocument>(`/following`, { params });
};

const create = (follow: IFollow) => {
  return client.post<IFollowDocument>('/following', follow);
};

const update = (id: string, data: IFollowListParams) => {
  return client.patch<IFollowDocument>(`/following/${id}`, data);
};

const remove = (id: string) => {
  return client.delete(`/following/${id}`);
};

export const follow = {
  list,
  get,
  create,
  update,
  remove,
};
