import React, { useEffect, useRef } from 'react';
import { LogProps } from './types';

const Log = ({ log, handleLogChange }: LogProps) => {
  let ref: any = useRef(null);

  // useEffect(() => {
  //   if(ref?.current) window.addEventListener('click', () => ref?.current?.focus());
  // }, []);

  useEffect(() => {
    if (ref.current) {
      ref?.current.focus();
    }
  }, [ref]);

  const handleLogChanges = () => {
    handleLogChange(ref.value);
  };

  return (
    <input
      type="text"
      className="log"
      ref={a => {
        ref = a;
      }}
      value={log}
      onChange={handleLogChanges}
      autoFocus
    />
  );
};

export default Log;
