import React from 'react';
import { Tooltip } from '@lipihipi/form';
import { TestTopicCardProps } from './test-topic-card.types';
import Styled from 'styled-components';
// const rightArrowIcon = require('./../../assets/images/right-arrow-green.png');

// const TestTopicCardContainer = Styled.div`
//   border-radius: 8px;
//   background-color: #fff;
//   width: 200px;
//   height: 200px;
//   padding: 10px 10px 4px;
//   display: flex;
//   flex-direction: column;
//   margin-right: 20px;
//   margin-bottom: 20px;
//   box-shadow: 0px 1px 4px 1px #ddd;

//   ${att =>
//     att.className?.includes('full-width') &&
//     `
//       width: auto;
//       height: 102px;
//       flex-direction: row;
//       justify-content: space-between;
//       padding: 10px 20px 10px;
//     `}
// `;

// const Thumbnail = Styled.img`
//   border-radius: 4px;
//   border: 1px solid silver;
//   width: 50px;
//   height: 50px;

//   ${att =>
//     att.className?.includes('full-width') &&
//     `
//       width: 70px;
//       height: 70px;
//       margin-right: 12px;
//     `}
// `;

// const Title = Styled.span`
//   font-size: 16px;
//   font-weight: 600;
//   display: block;
//   margin: 8px 0 2px 0;

//   ${att =>
//     att.className?.includes('full-width') &&
//     `
//       font-size: 24px;
//       color: #636363;
//       margin-top: 16px;
//       line-height: 23px;
//     `}
// `;

// const InfoText = Styled.span`
//   font-size: 14px;
//   color: #636363;
// `;

// const DurationText = Styled.p`
//   font-size: 14px;
//   font-weight: 600;
//   color: #636363;
// `;

const CostText = Styled.span`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #239F87;

  ${att =>
    att.className?.includes('full-width') &&
    `
      font-size: 20px;
      font-weight: 400;
    `}
`;

// const MidDot = Styled.span`
//   width: 4px;
//   height: 4px;
//   border-radius: 4px;
//   background-color: #C4C4C4;
//   margin: 0 8px;
// `;

// const ViewText = Styled.span`
//   font-size: 14px;
//   color: #239F87;
//   margin-right: 4px;
//   position: relative;
//   top: -1px;
// `;

export const TestTopicCard = ({
  fullWidth,
  data,
  onTestBundleSubscribe,
  onClick,
  getAssetUrl,
  imageBaseUrl,
  ...rest
}: TestTopicCardProps) => {
  return (
    <div
      className={fullWidth ? 'col-sm-12' : 'col-md-4 col-lg-3'}
      data-bundle-id={data?._id}
    >
      <div
        onClick={onClick}
        className={`priamry-test-card  ${
          data.isSubsribed ? 'subscribed' : ''
        }  ${
          fullWidth
            ? 'full-width   d-flex align-items-center justify-content-between'
            : ''
        }`}
        {...rest}
      >
        <Tooltip>
          <a
            data-tip={data.title}
            style={{
              color: 'grey',
            }}
            href="javascript:void(0)"
          >
            <div className={'wrap'}>
              <img
                src={data.thumbnailUrl ? getAssetUrl(data.thumbnailUrl) : ''}
              />

              <p>{data.title}</p>
              <ul>
                <li>{data.testType}</li>
                <li>{data.testCount}</li>
              </ul>
            </div>
            <CostText className={fullWidth ? 'full-width' : ''}>
              {/* {data.cost && <span> &#8377; {data.cost}</span>} */}
              {data.cost && <span>Try Now</span>}
              {onTestBundleSubscribe && !data.isSubsribed && (
                <p>
                  <span
                    onClick={() => {
                      onTestBundleSubscribe();
                    }}
                  >
                    Subsribe Now
                  </span>
                </p>
              )}
            </CostText>
          </a>
        </Tooltip>
      </div>
    </div>
  );
};
