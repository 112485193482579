import { IInterview } from './interview';
import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TGetResource,
  TListResource,
} from './types';

export interface IAttendedInterviewDetails {
  interview_url: string;
  analytics_url: string;
  analytics_pdf: string;
  interview_status: string;
  interview_name: string;
  interview_id: string;
  activate_date: string;
  deactivate_date: string;
  analytics_data: {
    audio_analytics: {
      communication: number;
      rate_of_speech: number;
      articulation_rate: number;
      number_of_pauses: number;
      speaking_duration: number;
      original_duration: number;
      response_latency: number;
    };
    video_analytics: {
      attention: number;
      engagement: number;
      expressiveness: number;
      confidence: number;
      positive_emotions: number;
      negative_emotions: number;
      positive_facial_expressions: number;
      negative_facial_expressions: number;
    };
  };
}

export interface IUserInterview extends Document {
  user: string;
  candidateId: string;
  interview: IInterview;
  attended: IAttendedInterviewDetails[];
}
export interface IAssignListParams extends IListParams {
  user?: ObjectId;
  group?: ObjectId;
  interview?: ObjectId;
}
export interface IUserInterviewSubscribe {
  interviewId: string;
  user?: ObjectId;
}
export interface IAttendInterview {
  interview_url: string;
}

export interface IUserInterviewDocument extends IUserInterview, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IUserInterviewListResponse {
  totalItems: number;
  userInterview: IUserInterviewDocument[];
}

export interface IUserInterviewListParams extends IListParams {
  user?: ObjectId;
}

export interface IAssignPayload {
  interview: ObjectId;
  group?: ObjectId;
  user?: ObjectId;
  name?: string;
  email?: string;
  mobile?: string;
}

const get: TGetResource<IUserInterviewDocument> = (id, params) => {
  return client.get<IUserInterviewDocument>(`/user-interview/${id}`, {
    params,
  });
};

const subscribeInterview: TCreateResource<
  IUserInterviewSubscribe,
  IUserInterviewDocument
> = (userInterview: IUserInterviewSubscribe) => {
  return client.post<IUserInterviewDocument>(
    `/user-interview/subscribe`,
    userInterview
  );
};

const attend = (userInterviewId: string) => {
  return client.get<IAttendInterview>(
    `/user-interview/attend/${userInterviewId}`
  );
};

const list: TListResource<
  IUserInterviewListParams,
  IUserInterviewListResponse
> = params => {
  return client.get(`/user-interview/`, { params });
};

const getReport: TListResource<any, any> = id => {
  client.defaults.responseType = 'arraybuffer';
  return client.get(`/user-interview/attempt-result/${id}`);
};

const getVideo: TListResource<any, any> = id => {
  return client.get(`/user-interview/attempt-result-video/${id}`);
};

const assignList = (params: IAssignListParams) => {
  return client.get(`/user-interview/assign`, { params });
};

const assign = (data: IAssignPayload) => {
  return client.post(`/user-interview/assign`, data);
};

const assingVerify = (data: { token: string }) => {
  return client.post('/user-interview/assign/verify', data).then(response => {
    client.defaults.headers.authorization = `Bearer ${response.data.token}`;
    return response;
  });
};

export const userInterview = {
  get,
  subscribeInterview,
  list,
  attend,
  getReport,
  getVideo,
  assign,
  assingVerify,
  assignList,
};
