import React, { useState } from 'react';
import {
  ActionButton,
  Button,
  IconButton,
  ListItemAction,
  Menu,
  Modal,
  PageHeader,
  PaginatedTable,
} from '@lipihipi/ec-ui';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdSearch } from 'react-icons/md';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { Form, Input, Select } from '@lipihipi/form';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import EducrackAPI from '@lipihipi/client-sdk';
import swal from 'sweetalert';
import { commonApiError } from '../admin/coupon/index';

const type = [
  { label: 'Influencer', value: 'INFLUENCER' },
  { label: 'Student', value: 'STUDENTS' },
  { label: 'All', value: '' },
];
interface IListProps {
  getCouponList: any;
  title?: string;
  breadCrumbs?: any[];
  onAddCoupon?: () => void;
  onBulkUpload?: () => void;
  onCouponClick?: (_id: string) => void;
  onEditClick?: (_id: string) => void;
  currentModulePermission?: any[];
}

export const getComponent = (onClick: any, name: string, flag: boolean) => {
  if (flag) {
    return (
      <IconButton onClick={onClick} className="primary-outine-button ml-2">
        <AiOutlinePlusCircle />
        {name}
      </IconButton>
    );
  }
  return null;
};


const RewardAdmin = ({
  getCouponList,
  title,
  breadCrumbs,
  onAddCoupon,
  onBulkUpload,
  onCouponClick,
  onEditClick,
  currentModulePermission
}: IListProps) => {
  const [params, setParams] = useState<any>({
    page: 1,
    perPage: 10,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reactivateEndDate, setReactivateEndDate] = useState(null);
  const [isOpen, setModal] = useState(false);
  const [reactivateCoupon, setReactivateCoupon] = useState("");
  const [coupons, setCoupons] = React.useState<any>({
    totalItems: 0,
    courses: [],
  });

  const onDeactivateClick = (id: any) => {
    EducrackAPI.coupon.deactivate(id).then(({ status }: any) => {
      if (status === 200) {
        swal({
          title: 'Success',
          text: 'Coupon Deactive Successfully',
          icon: 'success',
          dangerMode: false,
        }).then(() => {
          window.location.reload();
        })
          .catch((err: any) => {
            commonApiError(err);
          });
      } else {
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
          dangerMode: true,
        });
      }
    })
      .catch((err: any) => {
        commonApiError(err);
      });
  };

  const onReactivateClick = (id: any) => {
    setReactivateCoupon(id);
    setModal(true);
  };

  const handleSave = () => {

    //@ts-ignore
    EducrackAPI.coupon.reactivate(reactivateCoupon, reactivateEndDate)
      .then(({ status }: any) => {
        if (status === 200) {
          swal({
            title: 'Success',
            text: 'Coupon Reactivate Successfully',
            icon: 'success',
            dangerMode: false,
          }).then(() => {
            setModal(false);
            window.location.reload();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
      .catch((err: any) => {
        commonApiError(err);
      });
  };

  React.useEffect(() => {
    getCouponList(params)
      .then(({ data }: any) => {
        setCoupons(data);
      })
      .catch((err: any) => {
        commonApiError(err);
      });
  }, [params]);

  const onCouponCodeClick = (dataValue: any) => {
    if (onCouponClick) {
      onCouponClick(dataValue._id);
    }
  };

  const handleSearch = (values: any) => {
    setParams({
      page: 1,
      ...values,
    });
  };
  return (
    <>
      <section className="main-structure">
        {currentModulePermission?.includes('read') ? (
          <>
            <PageHeader
              title={title || 'Manage Rewards and Coupons'}
              breadCrumbs={breadCrumbs || [{ title: 'Manage Rewards and Coupons' }]}
              component={
                <div className='d-flex align-items-center'>
                  {getComponent(
                    onAddCoupon,
                    'Create Coupons',
                    currentModulePermission?.includes('create')
                  )}
                  {getComponent(
                    onBulkUpload,
                    'Bulk Upload',
                    currentModulePermission?.includes('create')
                  )}
                </div>
              }
            />

            <Form
              initialValues={params}
              onSubmit={handleSearch}
              render={() => (
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <Input
                      prefix={<MdSearch />}
                      id="searchCoupon"
                      name="q"
                      placeholder="Enter here to search for Coupons or user's name"
                    />
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <Select
                      id="couponType"
                      name="couponType"
                      placeholder="Select Type"
                      options={type}
                    />
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="datepicker-wrap form-group">
                      <DatePicker
                        selected={startDate}
                        className="primary-form-control"
                        onChange={(date: any) => {
                          console.log(date);
                          handleSearch({ ...params, start_date: date });
                          setStartDate(date);
                        }}
                        selectsStart
                        placeholderText="Start Date"
                        startDate={startDate}
                        endDate={endDate}
                      />
                      <FaCalendarAlt />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="datepicker-wrap form-group">
                      <DatePicker
                        selected={endDate}
                        className="primary-form-control"
                        onChange={(date: any) => {
                          handleSearch({ ...params, end_date: date });
                          setEndDate(date);
                        }}
                        selectsEnd
                        placeholderText="End Date"
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                      <FaCalendarAlt />
                    </div>
                  </div>
                  <Button shape="primary" className="ml-3" type="submit">
                    Apply{' '}
                  </Button>
                </div>
              )}
            />

            <PaginatedTable
              data={coupons.coupons}
              columns={[
                {
                  dataRenderer: (_data: any, index: number) => (
                    <>
                      <div className="primary-text">{index + 1}</div>
                    </>
                  ),
                  title: 'S.No',
                  width: '15%',
                },
                {
                  dataRenderer: (data: any) => (
                    <>
                      <div className="primary-text">{data?.name}</div>
                      <div
                        className={
                          data.active == true ? 'status approved' : 'status danger'
                        }
                      >
                        <span onClick={() => onCouponCodeClick(data)}>
                          {data?.coupon}
                        </span>
                      </div>
                    </>
                  ),
                  title: 'Name',
                  width: '15%',
                },
                {
                  dataRenderer: (data: any) => (
                    <div className="primary-text">{data?.emailAddress}</div>
                  ),
                  title: 'Contact',
                  width: 'calc(100% - (15% + 15% + 15% + 15% + 15% + 10%))',
                },
                // {
                //   dataRenderer: (data: any) => (
                //     <div className="primary-text">
                //       {data.parentOrg}
                //     </div>
                //   ),
                //   title: 'Parent org',
                //   width: '150px'
                // },
                {
                  dataRenderer: (data: any) => (
                    <div className="primary-text">{data?.couponType}</div>
                  ),
                  title: 'User type',
                  width: '15%',
                },
                {
                  dataRenderer: (data: any) => (
                    <div className="primary-text">
                      {moment(data?.validityStartTime).format('MMM DD YYYY')}
                    </div>
                  ),
                  title: 'Start Date',
                  width: '15%',
                },
                {
                  dataRenderer: (data: any) => (
                    <div className="primary-text">
                      {data?.validityEndTime === null ? "________" : moment(data?.validityEndTime).format('MMM DD YYYY')}
                    </div>
                  ),
                  title: 'Expiry Date',
                  width: '15%',
                },
                {
                  dataRenderer: (data: any) => (
                    <ListItemAction className="action-button">
                      <ActionButton>
                        <BsThreeDotsVertical />
                      </ActionButton>
                      <Menu>
                        {currentModulePermission?.includes('delete') && (
                          data.active === true ?
                            (<li onClick={() => onDeactivateClick(data._id)}>
                              Deactivate
                            </li>) :
                            (<li onClick={() => onReactivateClick(data._id)}>
                              Reactivate
                            </li>)

                        )}
                        {currentModulePermission?.includes('update') && (
                          <li onClick={() => onEditClick(data._id)}>Edit</li>
                        )}
                        <li onClick={() => { }}>Report</li>
                      </Menu>
                    </ListItemAction>
                  ),
                  title: '',
                  width: '10%',
                },
              ]}
              totalItems={coupons.totalItems}
              onPageChange={(page: any) => {
                setParams({ ...params, page: page });
              }}
              itemsPerPage={10}
              currentPage={params.page || 1}
            />
          </>
        ) :
          (
            <div>
              <h4>Sorry!, you don't have permission to see this.</h4>
            </div>
          )}
      </section>
      <Modal
        heightDefault
        isOpen={isOpen}
        onRequestClose={() => {
          setModal(false);
        }}
      >
        <Form
          initialValues={""}
          onSubmit={handleSave}
          render={() => (
            <>
              <DatePicker
                id="validityStartTime"
                selected={reactivateEndDate}
                className="primary-form-control full-width"
                onChange={(date: any) => {
                  //handleSave({ endDate: date });
                  setReactivateEndDate(date);
                }}
                selectsStart
                placeholderText="End Date"
                endDate={reactivateEndDate}
                minDate={startDate}
                required
              />
              <div className="mt-3">
                <Button shape="primary" type="submit">
                  Finish
                </Button>
              </div>
            </>
          )}

        />
      </Modal>
    </>
  );
};

export default RewardAdmin;
