import React from 'react';
import Styled from 'styled-components';
import { getQuestionData } from '../../test-bundle/test/utility';
import { QuestionPaletteProps } from './questionPalette.types';

const Circle = Styled.li`
  background: ${({ status, active }: any) =>
    status ? mapStatusToBackgroundColor(status, active) : '#fff'};
  font-weight: ${({ active }: any) => (active ? 700 : 400)};
  width: 38px;
`;

const StatusText = Styled.span`
  font-size: 14px;
  // font-weight: 600;
  color: #000;
`;

const mapStatusToBackgroundColor = (status: string, active: string) => {
  if (active) {
    return '#fff';
  }
  switch (status) {
    case 'ANSWERED':
      return '#50B167';
    case 'VISITED':
      return '#EF7382';
    case 'MARKED FOR REVIEW':
      return '#f9d54d';
    default:
      return '#fff';
  }
};

const statusTypes = [
  { name: 'NOT ANSWERED', showAs: 'Unanswered', color: ' #EE1002' },
  { name: 'ANSWERED', showAs: 'Answered', color: '#50B167' },
  { name: 'NOT VISITED', showAs: 'Not Visited', color: '#E5E5E5' },
  { name: 'MARKED FOR REVIEW', showAs: 'Marked For Review', color: '#f9d54d' },
];

const psychometricTestStatus = [
  { name: 'NOT ANSWERED', showAs: 'Unanswered', color: ' #EE1002' },
  { name: 'ANSWERED', showAs: 'Answered', color: '#50B167' },
  { name: 'NOT VISITED', showAs: 'Not Visited', color: '#E5E5E5' },
];

const QuestionPalette = ({
  list,
  active,
  handleClick,
  name,
  isPsychometric = false,
}: QuestionPaletteProps) => {
  const onClick = (data: any = {}) => {
    // @ts-ignore
    handleClick(data);
  };

  const testStatus = isPsychometric ? psychometricTestStatus : statusTypes;
  const questionData: any = getQuestionData(list);
  return (
    <>
      <div className="q-pallete">
        <div className="q-pallete--header">
          <div
            className="status-wrap"
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {testStatus.map((item, index) => (
              <div className="d-flex align-items-center" key={index}>
                <p
                  className={item.showAs
                    .split(' ')
                    .join('-')
                    .toLowerCase()}
                  style={{ background: item.color }}
                >
                  {questionData[testStatus[index]?.name]}
                </p>
                <StatusText>{item.showAs}</StatusText>
              </div>
            ))}
          </div>
        </div>
        <div className="q-pallete--body">
          <p>{name}</p>
          <div className="flex-grow-1">
            <ul>
              {list &&
                list.map((item: any, index) => (
                  <Circle
                    // @ts-ignore
                    status={
                      item?.isMarkForReview
                        ? 'marked-for-review'
                        : item.status
                            .split(' ')
                            .join('-')
                            .toLowerCase()
                    }
                    active={active === item.displayOrder}
                    key={index}
                    onClick={() => onClick(item)}
                    className={
                      item?.isMarkForReview
                        ? 'marked-for-review'
                        : item.status === 'VISITED'
                        ? 'unanswered'
                        : item.status
                            .split(' ')
                            .join('-')
                            .toLowerCase()
                    }
                  >
                    {index + 1}
                  </Circle>
                ))}
            </ul>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionPalette;
