import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
  TDeleteResource,
  TListResource,
  TUpdateResource,
} from './types';

export interface ICenter {
  name: string;
  code: string;
  gstName: string;
  gstNumber: string;
  mobile: string;
  email: string;
  address?: string;
  bankAccountId?: string;
  contactPerson?: string;
}

export interface ICenterList extends IListParams {
  mobile?: string;
  code?: string;
  gstNumber?: string;
  email?: string;
  contactPerson?: string;
}

export interface ICenterDocument extends ICenter, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface ICenterListResponse {
  totalItems: number;
  centers: ICenterDocument[];
}

const create: TCreateResource<ICenter, ICenterDocument> = (center: ICenter) => {
  return client.post<ICenterDocument>(`/center`, center);
};

const update: TUpdateResource<ICenterDocument> = center => {
  return client.put(`/center/${center._id}`, center);
};

const remove: TDeleteResource = (id: string) => {
  return client.delete(`/center/${id}`);
};

const get: TDeleteResource = (id: string) => {
  return client.get(`/center/${id}`);
};

const getByCode: TDeleteResource = (code: string) => {
  return client.get(`/center/code/${code}`);
};

const list: TListResource<ICenterList, ICenterListResponse> = params => {
  return client.get(`/center/`, { params });
};

export const center = { create, update, remove, list, get, getByCode };
