import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Divider,
  Button,
  List,
  ListItem,
  ListItemImage,
  ListItemText,
  PageHeader,
  Pagination,
  Tab,
  Tabs,
  TextPrimary,
  TextSecondary,
  ListItemAction,
  ActionButton,
  Menu,
} from '@lipihipi/ec-ui';
import { Form, Input, Select } from '@lipihipi/form';
import EducrackAPI from '@lipihipi/client-sdk';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { JoinModal } from '../../components/join-modal';
import { AiOutlineVideoCamera } from 'react-icons/ai';
import FreeResourceDetail from './resouces';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdSearch } from 'react-icons/md';
import { ISubjectListResponse } from '@lipihipi/client-sdk/dist/subject';
import { ICoursewareListParams } from '@lipihipi/client-sdk/dist/courseware';
import { commonApiError } from '../../admin/create/index';

interface IRemoteClasses {
  getSessions: any;
  joinSession: any;
  onView: any;
  courseId: string;
  getFile: any;
}

const formatSessionDate = (sDate: any, sessionTime: any) => {
  console.log(sDate, 'sDate');
  const datestr = moment(
    `${moment(sDate).format('YYYY-MM-DD')} ${sessionTime.hh}:${sessionTime.mm
    }:00`
  );
  return datestr;
};

const prettyTimefromNow = (dateTime: Date) => {
  const diff = moment(dateTime).diff(moment(new Date()));
  const duration = moment.duration(diff);
  const dur = {
    days: Math.floor(duration.asDays()),
    hours: Math.floor(duration.asHours()),
    minutes: Math.floor(duration.asMinutes()),
    seconds: Math.floor(duration.asSeconds()),
  };
  if (dur.days > 0) {
    return `${dur.days} days`;
  } else if (dur.hours > 0) {
    return `${dur.hours} hours`;
  } else if (dur.minutes > 0) {
    return `${dur.minutes} mins`;
  } else {
    return `${dur.seconds} secs`;
  }
};

const JoinColumn = ({ session, onClickJoin }: any) => {
  const startDate: any = formatSessionDate(
    session?.sessionDate,
    session?.sessionStartTime
  );
  const remainingTime: any = moment
    .duration(startDate.diff(moment()))
    .asMinutes();
  return (
    <>
      {remainingTime <= 15 ? (
        <Button shape="primary" onClick={onClickJoin}>
          Join
        </Button>
      ) : (
        ''
      )}
      {remainingTime > 0 ? (
        <div className="primary-text mt-1">
          Time Rem : {prettyTimefromNow(startDate)}
        </div>
      ) : (
        <div className="primary-text mt-1">Streaming Now</div>
      )}
    </>
  );
};

const RemoteClasses = ({
  getSessions,
  joinSession,
  onView,
  courseId,
  getFile,
}: IRemoteClasses) => {
  const [subjects, setSubjects] = React.useState<ISubjectListResponse>({
    totalItems: 0,
    subjects: [],
  });
  const [params, setParams] = useState<any>({
    populate: true,
    page: 1,
    type: 'pending',
    courseId: courseId || '',
  });

  const [sessions, setSessions] = useState<any>({
    sessions: [],
    totalItems: 0,
  });

  const [selectedSession, setSelectedSession] = useState(null);

  const [state, setState] = useState('Upcoming Sessions');

  const [modalState, setModalState] = useState<any>({
    isOpen: false,
    modalUrl: '',
  });

  const [selectedVideoSession, setSelectedVideoSession] = useState(null);

  const [batchList, setBatchList] = useState<any>({
    label: '',
    value: '',
  });
  const [purchasedBatches, setPurchasedBatches] = useState<any>([]);

  const mapOptions = (values: any[]) => {
    return values.map((value) => ({
      label: value.batchId.name,
      value: value._id,
    }));
  };

  const handleLoadForBatch = () => {
    const batchParams = {
      all: true,
      batchType: 'live',
      isRecent: true,
      populate: true,
      courseId: courseId || '',
    };
    EducrackAPI.batchV2.list({ ...batchParams })
      .then(({ data }: any) => {
        const all = { label: 'All', value: '' };
        const options = mapOptions(data.batches);
        const list = [all, ...options];
        //setBatchList(list);
      })
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  };

  const handlePurchasedBatch = () => {
    EducrackAPI.batchV2
      .listPurchasedBatches({ courseId: courseId, all: true })
      .then(({ data }: any) => {
        const all = { label: 'All', value: '' };
        const options = mapOptions(data.batches);
        const list = [all, ...options];
        console.log(list, 'list');
        setBatchList(list);
        setPurchasedBatches(data);
      })
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  };

  const tabChange = (input: string) => {
    setState(input);
    var type;
    if (input == 'Past Sessions') {
      type = 'done';
    } else {
      type = 'pending';
    }
    setParams({ ...params, type: type });
  };

  const handleVideoView = (data: any) => {
    onView(data._id);
  };

  const getBatchName = (item: any) => {
    let finalValue = purchasedBatches?.batches?.find(
      (e: any) => e.batchId._id === item._id
    );
    if (finalValue != undefined) {
      return finalValue?.batchId?.name;
    }
  };

  const getBatchesHandler = (data: any) => {
    setParams({ ...params, batches: data });
  };

  const getData = () => {
    getSessions({ ...params, populate: true })
      .then((response: any) => {
        setSessions(response.data);
      })
      .catch((error: any) => {
        commonApiError(error);
        // handle the error as needed, e.g. display an error message to the user
      });
  };

  useEffect(() => {
    if (courseId != undefined) {
      setParams({ ...params, courseId: courseId });
    }
  }, [courseId]);

  useEffect(() => {
    if (params.courseId != undefined) {
      getData();
      handlePurchasedBatch();
      //handleLoadForBatch();
    }
  }, [params]);

  const handleSearch = (values: any) => {
    setParams({
      page: 1,
      ...values,
    });
  };
  const AdminFilter = ({ params, handleSearch }: any) => {
    return (
      <div className="col-md-6 col-lg-4">
        <Form
          initialValues={params}
          onSubmit={handleSearch}
          render={({ submitForm }: any) => (
            <>
              <Input
                prefix={<MdSearch />}
                id="searchExam"
                name="q"
                placeholder="Search the content here"
                onChange={submitForm}
              />
              <button type="submit" className="d-none">
                Search
              </button>
            </>
          )}
        />
      </div>
    );
  };

  const Filter = ({ ...rest }: any) => {
    return <AdminFilter {...rest} />;
  };

  return (
    <section className="main-structure">
      <PageHeader
        title={'Your Remote Classes'}
        description={
          'It’s important to have an interaction with your Mentors from time to time to build a better understanding to apply the concept which you study. Learn from them Live in the Remote Classes with an interactive experience. In case if you miss learning Live from your Mentor or would like to revise then you click on past sessions and watch the recording.'
        }
        breadCrumbs={[{ title: 'Remote Classes' }]}
      />
      <div className="row">
        <Filter params={params} handleSearch={handleSearch} />
        {console.log(batchList, 'batchList')}
        {batchList.length > 1 && (
          <div className="col-md-3">
            <Form
              initialValues={params}
              onSubmit={handleSearch}
              render={({ submitForm }: any) => (
                <Select
                  id="batch"
                  name="batch"
                  placeholder="Select Session"
                  onChange={submitForm}
                  options={batchList}
                />
              )}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-12">
          <Tabs active={state} onClick={tabChange}>
            <Tab id={'Upcoming Sessions'} title={'Upcoming Sessions'}>
              {sessions.sessions.map((sessData: any) => {
                return (
                  <div
                    className="reward-banner"
                    data-session-id={sessData?._id}
                  >
                    <div className="row flex-grow-1 align-items-center">
                      {/* <div className="col-md-2 mt-3">
                                <ListItemImage
                                  style={{ height: '120px', width: '120px' }}
                                  url={`https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80`}
                                />
                              </div> */}
                      <div className="col-md-4">
                        <ListItemText>
                          <TextPrimary style={{ fontWeight: 'bold' }}>
                            {sessData.batches.length > 1
                              ? sessData.batches.map((item: any) => {
                                return getBatchName(item);
                              })
                              : sessData?.batches[0]?.name}
                          </TextPrimary>
                          {/* <TextSecondary className="mt-2">
                            {' '}
                            {sessData.subject?.name}
                          </TextSecondary> */}
                          <TextSecondary className="mt-2">
                            {sessData?.topics
                              .map((e: any) => e.name)
                              .join(', ')}
                          </TextSecondary>
                          <TextSecondary className="mt-2">
                            <span style={{ color: 'teal', fontWeight: 'bold' }}>
                              {sessData?.educator != undefined
                                ? `by ${sessData?.educator?.name}`
                                : ''}
                            </span>
                          </TextSecondary>
                        </ListItemText>
                      </div>
                      <div className="col-md-4 mt-3 mt-md-0">
                        <ListItemText>
                          <TextPrimary style={{ fontWeight: 'bold' }}>
                            {sessData?.sessionName}
                          </TextPrimary>
                          <TextSecondary className="mt-2">
                            <span>
                              {moment(sessData?.sessionDate).format(
                                'DD/MM/YYYY'
                              )}
                            </span>
                            <span className="ml-3">
                              {moment(sessData?.sessionDate).format('dddd')}
                            </span>{' '}
                            <span
                              className="ml-3"
                              style={{
                                color:
                                  sessData.isFree || sessData.openToAll
                                    ? 'red'
                                    : 'teal',
                                fontWeight: 'bold',
                              }}
                            >
                              {sessData.isFree || sessData.openToAll
                                ? 'Free'
                                : 'Paid'}
                            </span>
                          </TextSecondary>
                          <TextSecondary className="mt-2">
                            <span>
                              {moment(
                                sessData.sessionStartTime.hh +
                                ':' +
                                sessData.sessionStartTime.mm,
                                'HH:mm'
                              ).format('LT')}
                            </span>{' '}
                            <span className="ml-3">
                              {Math.floor(sessData?.sessionInterval / 60)} hr{' '}
                              {sessData?.sessionInterval % 60} mins
                            </span>
                          </TextSecondary>
                        </ListItemText>
                      </div>
                      <div className="col-md-4 mt-3 mt-md-0">
                        <JoinColumn
                          session={sessData}
                          onClickJoin={() => setSelectedSession(sessData)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <Pagination
                totalItems={sessions.totalItems}
                currentPage={params.page || 1}
                itemsPerPage={10}
                onPageChange={(page: number) => {
                  setParams({ ...params, page: page });
                  handleSearch({ ...params, page });
                }}
              />
            </Tab>
            <Tab id={'Past Sessions'} title={'Past Sessions'}>
              {sessions.sessions.map((sessData: any) => {
                return (
                  <div
                    className="reward-banner"
                    data-session-id={sessData?._id}
                  >
                    <div className="row flex-grow-1 align-items-center">
                      {/* <div className="col-md-2 mt-3">
                                <ListItemImage
                                  style={{ height: '120px', width: '120px' }}
                                  url={`https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80`}
                                />
                              </div> */}
                      <div className="col-md-4">
                        <ListItemText>
                          {/* <TextSecondary className="mt-2 mt-md-0">
                            {' '}
                            {sessData.subject?.name}
                          </TextSecondary> */}
                          <TextSecondary className="mt-2">
                            {sessData.topics.map((e: any) => e.name).join(', ')}
                          </TextSecondary>
                          <TextSecondary className="mt-2">
                            <span style={{ color: 'teal', fontWeight: 'bold' }}>
                              {sessData?.educator != undefined
                                ? `by ${sessData?.educator?.name}`
                                : ''}
                            </span>
                          </TextSecondary>
                        </ListItemText>
                      </div>
                      <div className="col-md-4 mt-3 mt-md-0">
                        <ListItemText>
                          <TextPrimary style={{ fontWeight: 'bold' }}>
                            {sessData?.isFree || sessData?.openToAll
                              ? sessData.sessionName
                              : sessData.batches.length
                                ? sessData.batches[0]?.name
                                : ''}
                          </TextPrimary>

                          <TextSecondary className="mt-2">
                            <span>
                              {moment(sessData?.sessionDate).format(
                                'DD/MM/YYYY'
                              )}
                            </span>
                            <span className="ml-3">
                              {moment(sessData?.sessionDate).format('dddd')}
                            </span>{' '}
                            <span
                              className="ml-3"
                              style={{
                                color:
                                  sessData.isFree || sessData.openToAll
                                    ? 'red'
                                    : 'teal',
                                fontWeight: 'bold',
                              }}
                            >
                              {sessData.isFree === false ? 'Paid' : 'Free'}
                            </span>
                          </TextSecondary>
                          <TextSecondary className="mt-2">
                            <span>
                              {moment(
                                sessData.sessionStartTime.hh +
                                ':' +
                                sessData.sessionStartTime.mm,
                                'HH:mm'
                              ).format('LT')}
                            </span>{' '}
                            <span className="ml-4">
                              {Math.floor(sessData.sessionInterval / 60)} hr{' '}
                              {sessData.sessionInterval % 60} mins
                            </span>
                          </TextSecondary>
                        </ListItemText>
                      </div>
                      <div className="col-md-3 mt-3 mt-md-0">
                        <ListItemAction>
                          <ActionButton>
                            <BsThreeDotsVertical />
                          </ActionButton>
                          <Menu>
                            {!sessData.recordedFile ? (
                              <li>No File Exist</li>
                            ) : (
                              <li onClick={() => handleVideoView(sessData)}>
                                View Recorded Sessions
                              </li>
                            )}
                            {!sessData.pdfRecordedFile ? (
                              <li>No PDF Exist</li>
                            ) : (
                              <li
                                onClick={() =>
                                  window.open(getFile(sessData.pdfRecordedFile))
                                }
                              >
                                View Recorded PDF File
                              </li>
                            )}
                          </Menu>
                        </ListItemAction>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Pagination
                totalItems={sessions.totalItems}
                currentPage={params.page || 1}
                itemsPerPage={10}
                onPageChange={(page: number) => {
                  setParams({ ...params, page: page });
                }}
              />
            </Tab>
          </Tabs>
        </div>
        {/* <div className="col-md-2">
            <Form>
              <Select
                id="batches"
                name="batches"
                options={handleLoadForBatch}
                placeholder="Select Batch"
                onChange={(data: any) => {
                  getBatchesHandler(data);
                }}
              />
            </Form>
          </div> */}
      </div>

      {selectedSession && (
        <JoinModal
          isOpen={!!selectedSession}
          onRequestClose={() => setSelectedSession(null)}
          session={selectedSession}
          joinSession={joinSession}
        />
      )}
    </section>
  );
};

export default RemoteClasses;
