import QuestionPalette from '../components/questionPalette';
import { TestTimer } from '../components/testTimer/test-timer';
import React, { useEffect, useState } from 'react';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import Swal from 'sweetalert2';
import InstructionModal from '../test-bundle/test/instruction';
// import { checkApiError } from '../test-bundle/test/utility';
import MCQ from './mcq';
import { Loader } from '@lipihipi/ec-ui';
import { checkApiError, getTestOverTime } from '../test-bundle/test/utility';

export interface PsychometricTestProps {
  user: any;
  testId: any;
  createTestAttempt: any;
  getTestAttempt: any;
  getAssetUrl: any;
  submitAnswer: any;
  submitTestAttempt: any;
}

export const PsychometricTest = ({
  user,
  testId,
  createTestAttempt,
  getTestAttempt,
  getAssetUrl,
  submitAnswer,
  submitTestAttempt,
}: PsychometricTestProps) => {
  const _window = window as any;

  const [testAttempt, setTestAttempt] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState<any>({});

  const [testTimeOver, setTestTimeOver] = useState(new Date());
  const [questionTime, setQuestionTime] = useState(0);

  const [showInstruction, setShowInstruction] = useState(false);

  useEffect(() => {
    const getUserTestList = async () => {
      try {
        const testAttemptData = await createTestAttempt({
          test: testId,
        });

        if (testAttemptData.data._id) {
          let { data }: any = await getTestAttempt(testAttemptData.data._id);
          let arr: any = [];
          data?.test?.questions.forEach((v: any, index: number) => {
            const obj = { ...v, displayOrder: index + 1 };
            arr.push(obj);
          });
          data.totalQuestions = arr?.length;
          data.test.questions = arr;

          if (data?.status === 'FINISHED') {
            Swal.fire({
              icon: 'error',
              title: 'Completed',
              text: 'This test is already complete',
              allowOutsideClick: false,
              showConfirmButton: false,
            });
            return;
          }
          setTestAttempt({ ...data });

          setActiveQuestion(data?.test?.questions?.[0]);
          checkTiming(data);
        } else {
          setLoading(false);
          const modalData = await Swal.fire({
            icon: 'success',
            title: '',
            text: testAttemptData?.data?.message,
            allowOutsideClick: false,
            showConfirmButton: true,
          });
          if (modalData?.isConfirmed) window.close();
          return;
        }
        setLoading(false);
      } catch (err) {
        if (err?.status === 404) {
          setLoading(false);
          Swal.fire({
            title: 'Sorry',
            text: 'Attempt not found.',
            icon: 'warning',
            confirmButtonText: 'Ok',
          }).then(result => {
            if (result) {
              window.close();
            }
          });
        }
      }
    };
    getUserTestList();
  }, []);

  useEffect(() => {
    const timeTaken = activeQuestion?.timeTaken
      ? activeQuestion?.timeTaken
      : 0 + 1;
    setQuestionTime(timeTaken);

    let interval: any = null;
    if (activeQuestion) {
      interval = setInterval(() => {
        setQuestionTime(oldTime => oldTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [activeQuestion]);

  const checkTiming = (attemptData: any) => {
    const time = getTestOverTime(
      attemptData?.updatedAt,
      attemptData?.test?.totalDurationInMinute
    );
    // @ts-ignore
    if (Date.parse(time) - Date.parse(new Date()) < 0) {
      submitAttempt(attemptData._id, true);
      return;
    }

    setTestTimeOver(time);
  };

  const submitAttempt = async (
    attemptId = testAttempt?._id,
    isTimeOver = false
  ) => {
    if (typeof attemptId !== 'string') {
      attemptId = testAttempt?._id;
    }
    try {
      const { data } = await submitTestAttempt(attemptId);
      if (isTimeOver) {
        Swal.fire({
          title: 'Timeout!',
          text: 'Test time is completed',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: false,
        }).then(result => {
          if (result?.isConfirmed) {
            _window.close();
            return;
          }
        });
      }
      if (data) {
        setLoading(false);
        Swal.fire({
          title: 'Your test is submitted',
          text: 'Result will be shared with you soon.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          if (localStorage.getItem('isScholarshipTest')) {
            localStorage.clear();
            _window.location.href = `/scholarship`;
          } else _window.close();
        });
      }
    } catch (error) {
      checkApiError(error);
      return;
    }
  };

  const setPreviousQuestion = () => {
    checkQuestionStatus();
    submitAns();

    const nextQuestion = testAttempt?.test?.questions.find(
      (question: any) =>
        question?.displayOrder === activeQuestion?.displayOrder - 1
    );
    setActiveQuestion(nextQuestion);
  };

  const checkQuestionStatus = () => {
    const currentAttempt = { ...testAttempt };
    currentAttempt?.test?.questions?.map((question: any) => {
      if (question?._id === activeQuestion._id) {
        if (activeQuestion.status === 'ANSWERED') {
          return;
        } else {
          activeQuestion.status = 'VISITED';
        }
      }
    });
    setTestAttempt(currentAttempt);
  };

  const setNextQuestion = async () => {
    checkQuestionStatus();
    submitAns();
    const nextQuestion = testAttempt?.test?.questions.find(
      (question: any) =>
        question?.displayOrder === activeQuestion?.displayOrder + 1
    );
    setActiveQuestion(nextQuestion);
  };

  const handleSubmitAnswer = async () => {
    if (!activeQuestion?.selectedAnswer) {
      Swal.fire({
        position: 'top-end',
        text: 'Please select an answer',
        icon: 'error',
        timer: 3000,
        toast: true,
        showConfirmButton: false,
      });
      return;
    }
    submitAns(true);
  };

  const submitAns = async (flag: boolean = false) => {
    const payload = {
      questionId: activeQuestion?._id,
      selectedAnswer: activeQuestion?.selectedAnswer,
      timeTaken: questionTime,
    };
    try {
      const { data } = await submitAnswer(testAttempt?._id, payload);
      if (
        data?.success &&
        activeQuestion?.displayOrder === testAttempt?.totalQuestions
      ) {
        Swal.fire({
          title: 'Your Test is Saved.',
          text: 'Now you can submit your test.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        return;
      }
      if (data?.success && flag) setNextQuestion();
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Server error!',
        icon: 'error',
      });
    }
  };

  const handleSubmitAttemptClick = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#239f87',
      confirmButtonText: 'Yes, Proceed!',
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true);
        submitAttempt();
      }
    });
  };

  const profilePic =
    user && user?.displayPicture
      ? getAssetUrl(user?.displayPicture)
      : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div
          className="test-page secondary user-select-none"
          style={{ userSelect: 'none' }}
        >
          <div className="test-page--header">
            <div className="left-col">
              <div className="section-main-header">
                <p>
                  <strong style={{ fontWeight: 'bold' }}>
                    {testAttempt?.test?.name}
                  </strong>
                </p>
              </div>

              <div className="section-header">
                <div className="timer">
                  <TestTimer
                    testOverTime={testTimeOver}
                    handleTimeOver={() => {
                      submitAttempt(testAttempt?._id, true);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="right-col">
              <div className="user-info">
                <img src={profilePic} alt="" />
                <div className="pl-3">
                  <p>{user?.name || ''}</p>
                  <a
                    href="javascript:void(0)"
                    id="instruction"
                    onClick={() => setShowInstruction(true)}
                  >
                    Instructions
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="test-page--body">
            <div className="left-col pr-5">
              <MCQ
                activeQuestion={activeQuestion}
                setSelectedOption={(value: any) => {
                  const obj = activeQuestion;
                  activeQuestion.selectedAnswer = value;
                  if (value) {
                    activeQuestion.status = 'ANSWERED';
                  } else activeQuestion.status = 'VISITED';
                  setActiveQuestion(obj);
                }}
              />
            </div>
            <div className={`right-col ${show ? 'in' : ''}`}>
              <button onClick={() => setShow(prev => !prev)}>
                {!show ? <GrFormNext /> : <GrFormPrevious />}
              </button>
              <QuestionPalette
                active={activeQuestion ? activeQuestion?.displayOrder : 0}
                list={testAttempt?.test?.questions}
                isPsychometric
              />
            </div>
          </div>
          <div className="test-page--footer">
            <div className="left-col">
              <div className="button-group d-flex align-items-center justify-conter-between">
                {activeQuestion?.displayOrder !== 1 && (
                  <button
                    id="PreviousQue"
                    type="button"
                    className="btn"
                    style={{
                      color: '#239F87',
                      borderColor: '#239F87',
                      fontWeight: 400,
                    }}
                    onClick={setPreviousQuestion}
                  >
                    Previous
                  </button>
                )}
                <button
                  type="button"
                  id="SaveQue"
                  className="btn btn-primary"
                  style={{ fontWeight: 400, minWidth: 142 }}
                  onClick={handleSubmitAnswer}
                >
                  {activeQuestion?.displayOrder != testAttempt?.totalQuestions
                    ? 'Save & Next'
                    : 'Save'}
                </button>
                {activeQuestion?.displayOrder !=
                  testAttempt?.totalQuestions && (
                  <button
                    type="button"
                    id="NextQue"
                    className="btn"
                    style={{
                      color: '#239F87',
                      borderColor: '#239F87',
                      fontWeight: 400,
                    }}
                    onClick={setNextQuestion}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            <div className="right-col">
              <button
                type="button"
                className="btn"
                style={{
                  color: '#fff',
                  fontWeight: 400,
                  zIndex: 9999999,
                }}
                disabled={loading}
                onClick={handleSubmitAttemptClick}
              >
                Submit Test
              </button>
            </div>
          </div>
        </div>
      )}

      {showInstruction && (
        <InstructionModal
          attempt={testAttempt?.test}
          isOpen={showInstruction}
          onRequestClose={() => setShowInstruction(false)}
          isFromTest
        />
      )}
    </>
  );
};
