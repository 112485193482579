import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
} from '../types';

export interface IQuestionDocument extends IQuestion, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
  updatedBy: ObjectId | PopulatedUserDocument;
}

export interface IQuestionListParams extends IListParams {
  addedBy?: ObjectId;
  active?: boolean;
  start_date?: string;
  end_date?: string;
}

export interface IQuestionListResponse {
  questions: IQuestionDocument[];
  totalItems: number;
}

export interface IQuestion {
  fileUrl: string;
  text: string;
  tags: string[];
  questionId?: string;
  active?: boolean;
  center?: string;
  score: {
    d: Number;
    i: Number;
    s: Number;
    c: Number;
  };
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IQuestionDocument>(`/psychometric/questions/${id}`, {
    params,
  });
};

const create = (question: IQuestion) => {
  return client.post<IQuestionDocument>(`/psychometric/questions`, question);
};

const update = (question: IQuestionDocument) => {
  return client.put<IQuestionDocument>(
    `/psychometric/questions/${question._id}`,
    question
  );
};

const remove = (id: string) => {
  return client.delete(`/psychometric/questions/${id}`);
};

const list = (params: IQuestionListParams) => {
  return client.get<IQuestionListResponse>(`/psychometric/questions/`, {
    params,
  });
};

export const psychometricQuestion = {
  get,
  create,
  update,
  remove,
  list,
};
