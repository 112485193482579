import { client } from './client';
import { ObjectId, TListResource, TUpdateResource, IListParams } from './types';

export enum ModuleTypes {
  WALL = 'WALL',
  DOUBTS = 'DOUBTS',
  BATCH = 'BATCH',
  TESTS = 'TEST',
  LIBRARY = 'LIBRARY',
  SUPPORT = 'SUPPORT',
  USERS = 'USERS',
  REFERRA = 'REFERRAL',
  REPORT = 'REPORT',
  PAYMENT = 'PAYMENT',
  ASSIGNMENTS = 'ASSIGNMENTS',
}

export enum EventTypes {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  UPDATED = 'UPDATED',
}

export enum NotificationTypes {
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export interface INotification extends Document {
  userId: ObjectId;
  id: ObjectId;
  notificationType: NotificationTypes;
  event: EventTypes;
  module: ModuleTypes;
  description: string;
}

export interface INotificationList extends IListParams {
  isRead: boolean;
}

export interface INotificationListResponse {
  totalItems: number;
  notifications: INotification[];
}

const list: TListResource<
  INotificationList,
  INotificationListResponse
> = params => {
  return client.get(`/notifications`, {
    params,
  });
};

const markRead: TUpdateResource<INotification> = (id: any) => {
  return client.patch(`/notifications/read/${id}`, {});
};

export const notifications = {
  list,
  markRead,
};
