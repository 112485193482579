import EducrackAPI from '@lipihipi/client-sdk';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { LeftPanel } from '../../common';
import PersonalDetailForm from './RegisterForm';

const PersonalDetail: FC<any> = ({ imageBaseUrl, submit, centerList }: any) => {
  const history = useHistory();
  const handleSubmit = async (values: any, actions: any) => {
    try {
      const response = await submit(values);
      if (response.status === 200) {
        history.push('/course/wall');
      }
    } catch (err) {
      if (err.data && err.status === 422) {
        const errors = err.data.errors;
        errors.forEach(({ field, message }: any) => {
          actions.setFieldError(field, message);
        });
      }
    }
  };

  const { styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};
  return (
    <main className="user-structure">
      <LeftPanel
        imageBaseUrl={imageBaseUrl}
        title={styles?.onboardingText || ''}
      />
      <PersonalDetailForm
        handleSubmit={handleSubmit}
        initialErrors={{ email: 'Invalid' }}
        centerList={centerList}
      />
    </main>
  );
};

export default PersonalDetail;
