import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';
import { IQuestionDocument, IQuestionItem } from './question';

export interface IQuestionSetDocument extends IQuestionSet, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IQuestionSetListParams extends IListParams {
  exam?: ObjectId;
  course?: ObjectId;
  subject?: ObjectId;
  section?: ObjectId;
  topic?: ObjectId;
  addedBy?: ObjectId;
}

export interface IQuestionSetListResponse {
  questionSets: IQuestionSet[];
  totalItems: number;
}

export interface IQuestionSetSection {
  section: ObjectId | PopulatedDocument;
  questions: [
    {
      question: ObjectId | IQuestionDocument;
      points: number;
    }
  ];
  timeLimit: number;
}

export interface IQuestionList {
  displayOrder: number;
  section: ObjectId | PopulatedDocument;
  subject: ObjectId | PopulatedDocument;
  topic: ObjectId | PopulatedDocument;
  parentQuestion: ObjectId | IQuestionDocument;
  subQuestion: ObjectId | IQuestionItem;
  points: number;
  selectedAnswers: string[];
  negativePoints?: number;
  status?: string;
  active?: boolean;
}

export interface ISectionSetting {
  isTimeLimitEnabled: boolean;
  timeMapping: [
    {
      section: string | PopulatedDocument;
      timeLimit: number;
      isSubmitted: boolean;
    }
  ];
}

export interface IBooleanValueSetting {
  isEnabled: boolean;
  value: number;
}

export interface IProctoredTest {
  isEnabled: boolean;
  startDate: string | null;
  isEndDateRequired: false;
  endDate: string | null;
}
export interface IQuestionSet {
  name: string;
  instruction: string;
  displayPicture: string;
  exam: ObjectId | PopulatedDocument;
  examType: string;
  isDescriptive: Boolean;
  // sections: IQuestionSetSection[];
  questions: IQuestionList[];
  sectionalSetting: ISectionSetting;
  totalTiming: IBooleanValueSetting;
  negativeMarking: IBooleanValueSetting;
  notAttempted: IBooleanValueSetting;
  totalQuestions: number;
  totalDurationInMinute: number;
  totalMarks: number;
  futureTest: boolean;
  isDisplayResult: boolean;
  isDisplayCalculator?: boolean;
  noOfAttempts?: number;
  sections: ObjectId[] | PopulatedDocument[];
  subjects: ObjectId[] | PopulatedDocument[];
  topics: ObjectId[] | PopulatedDocument[];
  proctoredTest?: IProctoredTest;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IQuestionSetDocument>(`/question-sets/${id}`, { params });
};

const create = (questionSet: IQuestionSet) => {
  return client.post<IQuestionSetDocument>(`/question-sets`, questionSet);
};

const update = (id: string, questionSet: IQuestionSetDocument) => {
  return client.put<IQuestionSetDocument>(
    `/question-sets/${id || questionSet._id}`,
    questionSet
  );
};

const remove = (id: string) => {
  return client.delete(`/question-sets/${id}`);
};

const list = (params: IQuestionSetListParams) => {
  return client.get<IQuestionSetListResponse>(`/question-sets/`, { params });
};

const testReport = (params: IQuestionSetListParams) => {
  return client.get<IQuestionSetListResponse>(`/question-sets/report/`, {
    params,
  });
};

export const questionSet = { get, create, update, remove, list, testReport };
