import { client } from '../client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
  TCreateResource,
} from '../types';
import { IQuestionDocument, IQuestionItem } from '../question';

import { IUserTestAttemptDocument } from './test-attempt';

export interface IQuestionSetListParams extends IListParams {
  exam?: ObjectId;
  subject?: ObjectId;
  section?: ObjectId;
  topic?: ObjectId;
  user?: ObjectId;
  test?: ObjectId;
}
export interface IAssignListParams extends IListParams {
  user?: ObjectId;
  group?: ObjectId;
  test?: ObjectId;
}

export interface IQuestionList {
  displayOrder: number;
  section: ObjectId | PopulatedDocument;
  subject: ObjectId | PopulatedDocument;
  topic: ObjectId | PopulatedDocument;
  parentQuestion: ObjectId | IQuestionDocument;
  question: ObjectId | IQuestionItem;
  points: number;
  selectedAnswers: string[];
  status: string;
}

export interface ISectionSetting {
  isTimeLimitEnabled: boolean;
  timeMapping: [
    {
      section: string | PopulatedDocument;
      timeLimit: number;
    }
  ];
}

export interface IBooleanValueSetting {
  isEnabled: boolean;
  value: number;
}

export interface IUserTest {
  test: string;
  name: string;
  instruction: string;
  displayPicture: string;
  center?: ObjectId[];
  isDisplayResult: boolean;
  noOfAttempts?: number;
  questions?: ObjectId[];
  totalDurationInMinute: number;
  displayOrder?: number;
  price?: number;
  actualPrice?: number;
  slug?: string;
  status?: string;
  active?: boolean;
  latestAttempt?: string | IUserTestAttemptDocument;
}

export interface IUserTestListResponse {
  userTestEnrollments: IUserTest[];
  totalItems: number;
}

export interface IEnroll {
  testId: string;
}
export interface IEnrollResponse {
  enrollmentId: string;
}

export interface IUserTestDocument extends IUserTest, IDocument {
  user: ObjectId | PopulatedUserDocument;
}

export interface IAssignPayload {
  test: ObjectId;
  group?: ObjectId;
  user?: ObjectId;
}

const get = (id: string, params?: { populate: boolean; user?: string }) => {
  return client.get<IUserTestDocument>(`/psychometric/user-test/${id}`, {
    params,
  });
};

const create: TCreateResource<IEnroll, IEnrollResponse> = (data: IEnroll) => {
  return client.post<IEnrollResponse>(`/psychometric/user-test`, data);
};

const update = (id: string, questionSet: IUserTestDocument) => {
  return client.put<IUserTestDocument>(
    `/psychometric/user-test/${id || questionSet._id}`,
    questionSet
  );
};

const list = (params: IQuestionSetListParams) => {
  return client.get<IUserTestListResponse>(`/psychometric/user-test/`, {
    params,
  });
};

const assignList = (params: IAssignListParams) => {
  return client.get(`/psychometric/user-test/assign`, { params });
};

const assign = (data: IAssignPayload) => {
  return client.post(`/psychometric/user-test/assign`, data);
};

const assingVerify = (data: { token: string }) => {
  return client
    .post('/psychometric/user-test/assign/verify', data)
    .then(response => {
      client.defaults.headers.authorization = `Bearer ${response.data.token}`;
      return response;
    });
};

export const psychometricUserTest = {
  get,
  update,
  list,
  create,
  assign,
  assingVerify,
  assignList,
};
