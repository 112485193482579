import React, { FC, useState, useCallback } from 'react';
import { useDropzone, DropzoneProps } from 'react-dropzone';
import { useField } from 'formik';
import styled, { css } from 'styled-components';
import { AxiosResponse } from 'axios';
import Label from '../Dropzone/Label';
import Error from '../Dropzone/Error';
import Loader from '../Dropzone/Loader';
import { IAssetDocument, IAssetUpload } from '@lipihipi/client-sdk/dist/asset';
import { AiFillCloseCircle } from 'react-icons/ai';

const ImageList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 5px;
  display: flex;
  li {
    display: inline-block;
    border: 1px solid #919191;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    position: relative;
    img {
      width: 100%;
    }
    button {
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      bottom: 0;
      right: 0;
      border: none;
      color: #fff;
      font-size: 10px;
    }
  }
`;

const DropzoneLabel = styled(Label)`
  margin-bottom: 0;
  font-weight: normal;
  font-size: 12px;
`;

const StyledDiv = styled.div<{ inline?: boolean }>`
  border-radius: 6px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  & + label {
    text-align: center;
    display: block;
  }
  & > img {
    max-width: 100%;
    max-height: 135px;
  }

  ${({ inline }) =>
    inline &&
    css`
      height: auto;
      border: solid 1px #e6e6e6;
      color: #919191;
      padding-left: 15px;
      overflow: hidden;
      button {
        background-color: #919191;
        color: #fff;
        padding: 10px 20px;
        margin-left: auto;
        border: none;
        outline: 0;
      }
    `}
`;
// export const fileTypes = {
//   IMAGE: 'image',
//   VIDEO: 'video',
// };
interface Props {
  name: string;
  label?: string;
  required?: boolean;
  inline?: boolean;
  value?: string;
  onChange?: Function;
  upload?: (params: IAssetUpload) => Promise<AxiosResponse<IAssetDocument>>;
  getAssetUrl: Function;
  // fileType?: string;
}

const Dropzone: FC<DropzoneProps & Props> = ({
  label,
  required,
  name,
  inline,
  upload,
  getAssetUrl,
  multiple,
  value,
  onChange,
  // fileType = fileTypes.IMAGE,
  ...props
}) => {
  const [isUploading, setUploading] = useState(false);
  const [field, meta, helpers] = useField({ name });
  const [image, setImage]= useState();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...props,
    multiple,
    onDrop(files: IAssetUpload[]) {
      if (upload) {
        setUploading(true);
        const allPromises = files.map(file => upload({ file } as any));
        Promise.all(allPromises)
          .then(res => {
            if (multiple) {
              helpers.setValue([
                ...field.value,
                ...res.map(file => file.data._id),
              ]);
            } else {
              const key = res[0].data._id;
              if (onChange) {
                onChange(key, name);
              } else {
                helpers.setValue(key);
              }
            }
          })
          .finally(() => {
            setUploading(false);
          });
      }
    },
    disabled: isUploading,
  } as any);
  
  const removeImage = useCallback(
    (key: string) => {
      helpers.setValue(field.value.filter((item: string) => item !== key));
    },
    [field.value]
  );

  React.useEffect(()=>{
    if(field.value){
      getImage()
    }
  },[field.value])
    const getImage = () =>{
      getAssetUrl(field.value).then(({data}:any)=>{
        setImage(data.url)
      })
    }
  const removeVideo = (e: any) => {
    e.preventDefault();
    helpers.setValue('');
  };
  return (
    <div className="form-group position-relative">
      {inline && <Label id={name} required={required} label={label} />}
      {multiple && field.value.length > 0 && (
        <ImageList>
          {field.value.map((key: string) => (
            <li key={key}>
              {/* {fileType === fileTypes.IMAGE ? ( */}
                <img src={image} alt={key} />
              {/* ) : (
                <video>
                  <source src={getAssetUrl(key)} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )} */}
              <button onClick={() => removeImage(key)}>x</button>
            </li>
          ))}
        </ImageList>
      )}
      <StyledDiv
        className="image-box"
        {...getRootProps({ refKey: 'innerRef' })}
        inline={inline}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : isUploading ? (
          <Loader />
        ) : (
          (field.value || value) &&
          !multiple &&
            <img src={image} alt="preview" />
          
        )}
        {inline && (
          <>
            <DropzoneLabel id={name} label="Click on browse to select a file" />
            <button>Browse</button>
          </>
        )}
      </StyledDiv>
      {!inline && <Label id={name} required={required} label={label} />}
      {!inline && field.value && (
        <div className="video-close" onClick={e => removeVideo(e)}>
          <AiFillCloseCircle />
        </div>
      )}
      <Error meta={meta} />
    </div>
  );
};

export default Dropzone;
