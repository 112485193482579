import React, { useEffect, useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import { MdDelete } from 'react-icons/md';
import { Button, Table } from '@lipihipi/ec-ui';
import { Form, Select, DateTime } from '@lipihipi/form';
import {
  SelectTestModal,
  SelectLibraryModal,
  SelectInterviewModal,
  FormSchemaAssignments,
} from './../../../src';

export const SelectAssignment = ({
  assignment,
  getAssignment,
  getSectionSubjects,
  getTests,
  getLibrary,
  updateAssignment,
  previousStep,
  onAddEditSuccess,
}: any) => {
  const [state, setState] = React.useState<any>({
    startTime: null,
    endTime: null,
    _id: '',
  });
  const [isOpen, setOpen] = React.useState(false);
  const [selectedModel, setSelectedModel] = React.useState('');
  const [sectionSubjects, setSectionSubjects] = useState<any>([]);
  const [assignTest, setAssignTest] = useState<any>([]);
  const [assignLibrary, setAssignLibrary] = useState<any>([]);
  const [assignInterview, setAssignInterview] = useState<any>([]);

  useEffect(() => {
    _getSectionSubjects();
  }, []);

  useEffect(() => {
    getAssignment(assignment?._id);
  }, [assignment?._id]);

  useEffect(() => {
    if (assignment?._id) {
      setAssignTest(assignment.tests);
      setAssignLibrary(assignment.library);
      setState({
        ...state,
        _id: assignment?._id,
        startTime: assignment?.startTime
          ? new Date(assignment.startTime)
          : null,
        endTime: assignment?.endTime ? new Date(assignment.endTime) : null,
      });
    }
  }, [assignment]);

  const _getSectionSubjects = () => {
    getSectionSubjects({
      course: assignment.course,
      all: true,
      populate: true,
    }).then((response: any) => {
      setSectionSubjects(response.data);
    });
  };

  const handleSubmit = (values: any) => {
    const payload = {
      _id: state._id,
      startTime: values.startTime,
      endTime: values.endTime || new Date(`${moment().endOf('year')}`),
      tests: assignTest.map((test: any) => test._id),
      library: assignLibrary.map((library: any) => library._id),
    };

    updateAssignment(payload)
      .then(({ status }: any) => {
        if (status === 200) {
          swal({
            title: 'Success',
            text: 'Assignment Created',
            icon: 'success',
            dangerMode: false,
          }).then(() => {
            onAddEditSuccess();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
      .catch(() => {
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
          dangerMode: true,
        });
      });
  };

  const setSelectedTest = (selectedTest: any, isDeleted: any = false) => {
    if (isDeleted) {
      const filterValue = assignTest.filter(
        (item: any) => item._id !== selectedTest._id
      );
      setAssignTest([...filterValue]);
    } else {
      setAssignTest((prevState: any) => [...prevState, ...selectedTest]);
    }

    setSelectedModel('');
  };

  const setSelectedLibrary = (selectedLibrary: any, isDeleted: any = false) => {
    if (isDeleted) {
      const filterValue = assignLibrary.filter(
        (item: any) => item._id !== selectedLibrary._id
      );
      setAssignLibrary([...filterValue]);
    } else {
      setAssignLibrary((prevState: any) => [...prevState, ...selectedLibrary]);
    }

    setSelectedModel('');
  };

  const setSelectedInterview = (
    selectedInterview: any,
    isDeleted: any = false
  ) => {
    if (isDeleted) {
      const filterValue = assignInterview.filter(
        (item: any) => item._id !== selectedInterview._id
      );
      setAssignInterview([...filterValue]);
    } else {
      setAssignInterview((prevState: any) => [
        ...prevState,
        ...selectedInterview,
      ]);
    }

    setSelectedModel('');
  };

  return (
    <>
      <Form
        initialValues={{
          startTime: state.startTime,
          endTime: state.endTime,
        }}
        validationSchema={FormSchemaAssignments}
        onSubmit={handleSubmit}
        render={({ values, setFieldValue }: any) => {
          const nextDay = new Date(values.startTime);
          nextDay.setDate(nextDay.getDate() + 1);

          return (
            <>
              <div
                style={{ marginTop: '-110px', paddingBottom: '75px' }}
                className="d-flex justify-content-end"
              >
                <div className="add-question">
                  <Select
                    id={'addItem'}
                    name={'addItem'}
                    placeholder={'Add Items'}
                    options={[
                      { label: 'Test', value: 'TEST' },
                      { label: 'Library Items', value: 'LIBRARY' },
                      { label: 'AI Mock Interview', value: 'INTERVIEW' },
                    ]}
                    onChange={(data: any) => {
                      setOpen(!isOpen);
                      setSelectedModel(data.value);
                    }}
                  />
                </div>
              </div>

              <div className="block mb-3">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <p className="mt-4">
                      You are assigning to:{' '}
                      <strong style={{ fontWeight: 600 }}>
                        {`${assignment?.name || '--'}`}
                        {` (${assignment.students.length})`}
                      </strong>
                    </p>
                  </div>

                  <div className="col-6 col-md-4">
                    <DateTime
                      dateFormat="MMMM d, yyyy h:mm aa"
                      id="startTime"
                      name="startTime"
                      label="Start Date and Time"
                      minDate={new Date()}
                      showTimeInput
                      timeInputLabel="Time:"
                      autoComplete="false"
                      required
                      onChange={() => {
                        setFieldValue('endTime', '');
                        return true;
                      }}
                    />
                  </div>
                  <div className="col-6 col-md-4">
                    <DateTime
                      dateFormat="MMMM d, yyyy h:mm aa"
                      disabled={!values?.startTime}
                      id="endTime"
                      name="endTime"
                      label="Completion Date and Time"
                      minDate={nextDay}
                      showTimeInput
                      timeInputLabel="Time:"
                      autoComplete="false"
                    />
                  </div>
                </div>
              </div>

              <div className="block mb-5">
                <h5 style={{ textTransform: 'none' }}>Assigned Tests</h5>
                {assignTest.length ? (
                  <Table
                    data={assignTest}
                    columns={[
                      {
                        dataRenderer: (data: any) => (
                          <>
                            <div className="primary-text">{data?.name}</div>
                          </>
                        ),
                        title: '',
                        width: 'calc(100% - (300px + 300px + 65px))',
                      },
                      {
                        dataRenderer: (data: any) => (
                          <>
                            <div className="primary-text">{data?.examType}</div>
                          </>
                        ),
                        title: '',
                        width: '300px',
                      },
                      {
                        dataRenderer: (data: any) => (
                          <>
                            <div className="primary-text">
                              {`Q-${data?.totalQuestions ||
                                0}, M-${data?.totalMarks ||
                                0}, T-${data?.totalDurationInMinute || 0}`}
                            </div>
                          </>
                        ),
                        title: '',
                        width: '300px',
                      },
                      {
                        dataRenderer: (data: any) => (
                          <MdDelete
                            size={25}
                            className={'cursor-pointer'}
                            id={'delete'}
                            onClick={() => {
                              setSelectedTest(data, true);
                            }}
                          />
                        ),
                        title: '',
                        width: '65px',
                      },
                    ]}
                  />
                ) : (
                  'No Data'
                )}
              </div>

              <div className="block mb-5">
                <h5 style={{ textTransform: 'none' }}>Assigned Notes</h5>
                {assignLibrary.length ? (
                  <Table
                    data={assignLibrary}
                    columns={[
                      {
                        dataRenderer: (data: any) => (
                          <>
                            <div className="primary-text">
                              {data?.subChapterName}
                            </div>
                          </>
                        ),
                        title: '',
                        width: 'calc(100% - (300px + 300px + 65px))',
                      },
                      {
                        dataRenderer: (data: any) => (
                          <div className="primary-text">
                            {data?.asset?.split('.').pop()}
                          </div>
                        ),
                        title: 'Type',
                        width: '600px',
                      },
                      {
                        dataRenderer: (data: any) => (
                          <MdDelete
                            size={25}
                            className={'cursor-pointer'}
                            id={'delete'}
                            onClick={() => {
                              setSelectedLibrary(data, true);
                            }}
                          />
                        ),
                        title: '',
                        width: '65px',
                      },
                    ]}
                  />
                ) : (
                  'No Data'
                )}
              </div>

              <div className="block mb-5">
                <h5 style={{ textTransform: 'none' }}>Assigned Interview</h5>
                {assignInterview.length ? (
                  <Table
                    data={assignInterview}
                    columns={[
                      {
                        dataRenderer: (data: any) => (
                          <>
                            <div className="primary-text">{data?.name}</div>
                          </>
                        ),
                        title: '',
                        width: 'calc(100% - (300px + 300px + 65px))',
                      },
                      {
                        dataRenderer: (data: any) => (
                          <div className="primary-text">
                            {data?.description}
                          </div>
                        ),
                        title: 'Type',
                        width: '600px',
                      },
                      {
                        dataRenderer: (data: any) => (
                          <MdDelete
                            size={25}
                            className={'cursor-pointer'}
                            id={'delete'}
                            onClick={() => {
                              setSelectedInterview(data, true);
                            }}
                          />
                        ),
                        title: '',
                        width: '65px',
                      },
                    ]}
                  />
                ) : (
                  'No Data'
                )}
              </div>

              <div className="form-group d-flex align-items-center justify-content-end">
                <Button
                  className="px-5"
                  shape="secondary"
                  onClick={previousStep}
                >
                  Back
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button className="px-5" shape="primary" type="submit">
                  Finish
                </Button>
              </div>

              {isOpen && selectedModel === 'TEST' ? (
                <SelectTestModal
                  isOpen={isOpen}
                  onRequestClose={() => {
                    setOpen(false);
                    setSelectedModel('');
                  }}
                  assignment={assignment}
                  sectionSubjects={sectionSubjects}
                  getTests={getTests}
                  setSelected={setSelectedTest}
                  assignedTest={assignTest}
                />
              ) : (
                ''
              )}

              {isOpen && selectedModel === 'LIBRARY' ? (
                <SelectLibraryModal
                  isOpen={isOpen}
                  onRequestClose={() => {
                    setOpen(false);
                    setSelectedModel('');
                  }}
                  assignment={assignment}
                  sectionSubjects={sectionSubjects}
                  getLibrary={getLibrary}
                  setSelected={setSelectedLibrary}
                  assignedLibrary={assignLibrary}
                />
              ) : (
                ''
              )}

              {isOpen && selectedModel === 'INTERVIEW' ? (
                <SelectInterviewModal
                  isOpen={isOpen}
                  onRequestClose={() => {
                    setOpen(false);
                    setSelectedModel('');
                  }}
                  assignment={assignment}
                  sectionSubjects={sectionSubjects}
                  setSelected={setSelectedInterview}
                  assignedInterview={assignInterview}
                />
              ) : (
                ''
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default SelectAssignment;
