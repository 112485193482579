import { client } from './client';
import { IDocument, IListParams, ObjectId, PopulatedDocument } from './types';

export interface ITopicListParams extends IListParams {
  subject?: ObjectId;
  id?: ObjectId[];
}

export interface ITopic {
  name: string;
  subject: ObjectId | PopulatedDocument;
  topicId?: string;
  displayPicture?: string;
}

export interface ITopicDocument extends ITopic, IDocument {}

export interface ITopicListResponse {
  totalItems: number;
  topics: ITopicDocument[];
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<ITopicDocument>(`/topics/${id}`, { params });
};

const create = (topic: ITopic) => {
  return client.post<ITopicDocument>(`/topics`, topic);
};

const update = (topic: ITopicDocument) => {
  return client.put<ITopicDocument>(`/topics/${topic._id}`, topic);
};

const remove = (id: string) => {
  return client.delete(`/topics/${id}`);
};

const list = (params: ITopicListParams) => {
  return client.get<ITopicListResponse>(`/topics/`, { params });
};

export const topic = { get, create, update, remove, list };
