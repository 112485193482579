import moment from 'moment';
import lodash from 'lodash';

export const barChartOptions = {
  plugins: {
    title: {
      display: true,
      text: '',
    },
    legend: {
      display: true,
      position: 'bottom',
      textAlign: 'left',
    },
  },
  // responsive: true,
  scales: {
    x: {
      stacked: true,
      // bar
    },
    y: {
      stacked: true,
      beginAtZero: true,
      min: 0,
    },
  },
};

const SECTION_CHART_COLOR: any = {
  QUANT: 'rgba(75, 192, 192, 0.5)',
  'LR-DI': 'rgba(255, 159, 64, 0.5)',
  'LR DI': 'rgba(255, 159, 64, 0.5)',
  'V ABILIT': 'rgb(53, 162, 235)',
  'VA & Reading Comprehension': 'rgba(255, 99, 132, 0.5)',
  'VA RC': 'rgba(255, 99, 132, 0.5)',
  'VA-RC': 'rgba(255, 99, 132, 0.5)',
  'Quantitative Aptitude': 'rgba(54, 162, 235, 0.5)',
  'Quantitative  Ability': 'rgba(95, 41, 196, 0.5)',
  'Decision making': 'rgba(153, 102, 255, 0.2)',
};

export const mapStatusToBackgroundColor = (
  status: string,
  active: string,
  isCorrect: boolean
) => {
  if (active) {
    return '#fff';
  }
  switch (status) {
    case 'ANSWERED':
      if (isCorrect) return '#50B167';
      return '#EE6A7A';
    case 'NOT VISITED':
      return '#7B7B7B';
    case 'VISITED':
      return 'rgb(217, 165, 53)';
    case 'MARKED FOR REVIEW':
      return '#f9d54d';
    default:
      return '#fff';
  }
};

export const mapStatusToColor = (status: string, active: string) => {
  if (active) {
    return '#239F87';
  }
  switch (status) {
    case 'NOT VISITED':
      return '#B6B6B6';
    default:
      return '#fff';
  }
};

export const mapStatusToBorder = (status: string, active: string) => {
  if (active) {
    return '1px solid #239F87';
  }
  switch (status) {
    case 'NOT VISITED':
      return '1px solid #B6B6B6';
    default:
      return '#fff';
  }
};

export const getScores = (v: any, topper: any) => {
  const arr = topper?.percentile
    ? [v?.percentile?.toFixed(2), topper?.percentile?.toFixed(2)]
    : [v?.percentile?.toFixed(2)];
  return {
    label: v?.section?.name,
    data: arr,
    backgroundColor: SECTION_CHART_COLOR[v?.section?.name],
    barThickness: 60,
  };
};

export const buildSectionWiseQuestionData = (
  data: any,
  tableObj: any,
  questionAnalysis: any
) => {
  if (data?.test?.examType !== 'FULL LENGTH') {
    data?.questions.forEach((question: any) => {
      const currentQuestion = questionAnalysis?.find(
        (q: any) => question?.question?._id === q?.questionId
      );
      let que = tableObj?.question || [];

      que?.length > 0 ? que.push(question) : (que = [question]);
      tableObj.question = que;
      tableObj.averageTime = currentQuestion?.averageTime;
      tableObj.correctAttempts = currentQuestion?.correctAttempts;
      tableObj.totalAttempts = currentQuestion?.totalAttempts;
      tableObj.totalTestAttempts = currentQuestion?.totalTestAttempt;
    });
    return tableObj;
  }

  const questionData: any = buildQuestionData(data, tableObj, questionAnalysis);
  return { ...tableObj, ...questionData };
  // return tableObj;
};

export const buildQuestionData = (
  data: any,
  tableObj: any,
  questionAnalysis: any
) => {
  data?.sections.forEach((section: any) => {
    data?.questions.forEach((question: any) => {
      if (question?.section?._id === section?.section?._id) {
        const item = tableObj[question?.section?.name];
        const currentQuestion = questionAnalysis?.find(
          (q: any) => question?.question?._id === q?.questionId
        );
        if (item) {
          const que = item?.question || [];
          if (currentQuestion) {
            (item.averageTime = currentQuestion?.averageTime),
              (item.correctAttempts = currentQuestion?.correctAttempts),
              (item.totalAttempts = currentQuestion?.totalAttempts),
              (item.totalTestAttempts = currentQuestion?.totalTestAttempt);
          }
          que?.length > 0 ? que.push(question) : [question];

          item.totalQuestions = item.totalQuestions + 1;
          item.attempted =
            question?.status === 'ANSWERED'
              ? item.attempted + 1
              : item.attempted;
          item.right = question?.isCorrect ? item.right + 1 : item.right;
          item.wrong =
            !question?.isCorrect && question?.status === 'ANSWERED'
              ? item.wrong + 1
              : item.wrong;
          item.skipped =
            !question?.isCorrect && question?.status === 'NOT VISITED'
              ? item.skipped + 1
              : item.skipped;
          item.notAnswered =
            !question?.isCorrect && question?.status === 'VISITED'
              ? item.notAnswered + 1
              : item.notAnswered;
          item.question = que;
        } else {
          tableObj[section?.section?.name] = {
            score: section?.score,
            section: section?.section,
            totalQuestions: 1,
            attempted: question?.status === 'ANSWERED' ? 1 : 0,
            right: question?.isCorrect ? 1 : 0,
            wrong:
              !question?.isCorrect && question?.status === 'ANSWERED' ? 1 : 0,
            skipped:
              !question?.isCorrect && question?.status === 'NOT VISITED'
                ? 1
                : 0,
            notAnswered:
              !question?.isCorrect && question?.status === 'VISITED' ? 1 : 0,
            question: [question] || [],
            displayOrder: section?.displayOrder,
            percentile: section?.percentile?.toFixed(2),
            averageTime: currentQuestion?.averageTime,
            correctAttempts: currentQuestion?.correctAttempts,
            totalAttempts: currentQuestion?.totalAttempts,
            totalTestAttempts: currentQuestion?.totalTestAttempt,
          };
        }
      }
    });
  });
  return tableObj;
};

// build section array for dropdown in Question component
export const getSections = (attempt: any) => {
  const arr: any = [];
  attempt?.sections?.forEach((section: any) => {
    arr.push({ label: section?.section?.name, value: section?.section?._id });
  });
  return arr;
};

// Show total time spent on top and for each questions
export const getTotalTimeSpent = (attempt: any) => {
  let timeSpent = '';

  if (attempt.startedAt && attempt.finishedAt) {
    // start time and end time
    var startTime = moment(attempt.startedAt);
    var endTime = moment(attempt.finishedAt);

    // calculate total duration
    let duration = moment.duration(endTime.diff(startTime));

    if (attempt?.test?.examType === 'FULL LENGTH') {
      const { totalTime } = attempt?.test?.exam?.configuration;

      if (
        (attempt?.test?.exam?.name === 'CAT' ||
          attempt?.test?.exam?.name === 'XAT') &&
        duration.asMinutes().toString() > totalTime
      ) {
        const hr = 120 / 60;
        const rhr = Math.floor(hr);
        const min = Math.round((hr - rhr) * 60);
        return `${rhr}h ${min}min`;
      }
    }

    // duration in hours
    var hours = parseInt(duration.asHours().toString());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes().toString()) % 60 || 0;

    const seconds = parseInt(duration.asSeconds().toString()) % 60 || 0;

    timeSpent = hours
      ? `${hours}h ${minutes}min ${seconds && seconds}sec`
      : `${minutes}min ${seconds && seconds}sec`;
  }
  return timeSpent;
};

// Question list for Test-Analysis
export const getQuestionList = (questions: any) => {
  let questionList: any = {};
  questions.forEach((question: any) => {
    const difficulty = question?.difficulty;
    const item = questionList[difficulty];
    if (item) {
      const que = item?.question || [];
      que?.length > 0 ? que.push(question) : que;
      item.right = question?.isCorrect ? item.right + 1 : item.right;
      item.wrong =
        !question?.isCorrect && question?.status === 'ANSWERED'
          ? item.wrong + 1
          : item.wrong;
      item.skipped =
        !question?.isCorrect && question?.status === 'NOT VISITED'
          ? item.skipped + 1
          : item.skipped;
      item.notAnswered =
        !question?.isCorrect && question?.status === 'VISITED'
          ? item.notAnswered + 1
          : item.notAnswered;
    } else {
      questionList[difficulty] = {
        question: [question] || [],
        right: question?.isCorrect ? 1 : 0,
        wrong: !question?.isCorrect && question?.status === 'ANSWERED' ? 1 : 0,
        skipped:
          !question?.isCorrect && question?.status === 'NOT VISITED' ? 1 : 0,
        notAnswered:
          !question?.isCorrect && question?.status === 'VISITED' ? 1 : 0,
      };
    }
  });
  return questionList;
};

// Chart data topper vs you
export const prepareChartData = (data: any) => {
  let chartData: any = {
    labels:
      data?.topperScore[0]?.sections?.length > 0 ? ['You', 'Topper'] : ['You'],
  };
  let chartDataSet: any = [];
  if (data?.topperScore[0]?.sections?.length > 0) {
    data?.topperScore[0]?.sections.map((topper: any) => {
      data?.sections?.map((v: any) => {
        if (topper?.section?._id === v?.section?._id) {
          const scores = getScores(v, topper);
          chartDataSet.push(scores);
        }
      });
    });
  } else {
    data?.sections?.map((v: any) => {
      const scores = getScores(v, null);
      chartDataSet.push(scores);
    });
  }

  chartData['datasets'] = chartDataSet;
  return chartData;
};

// Question
export const difficultyLevels = [
  { label: 'Difficulty Level/All', value: '' },
  { label: 'Easy', value: 'EASY' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Hard', value: 'HARD' },
];

export const convertTime = (time: number) => {
  if (time) {
    let hours: any = Math.floor(time / 3600);
    let minutes: any = Math.floor((time - hours * 3600) / 60);
    let seconds: any = time - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    if (hours > 0) return `${hours}hr ${minutes}min ${seconds}sec`;
    else if (minutes > 0) return `${minutes}min ${seconds}sec`;
    else return `${seconds}sec`;
  } else return '';
};

// Performance Index
export const buildSectionList = (testData: []) => {
  const dropdownValues = lodash.flatten(lodash.map(testData, 'sections'));
  return getDropDownArray(
    lodash.uniqBy(lodash.flatten(lodash.map(dropdownValues, 'section')), '_id')
  );
};

export const buildDropdownData = (testData: []) => {
  return testData?.map((v: any) => ({
    label: v?.test?.name,
    name: v?.test?.name,
    value: v?.test?._id,
    _id: v?.test?._id,
  }));
};

const getTestList = (testData: any, testList: any) => {
  let arr: any = [];
  testData?.map((v: any) => {
    const currentTest = testList?.find((u: any) => v?.test?._id === u?.value);
    if (currentTest) arr.push(v);
  });
  return arr;
};

export const buildChartData = (testData: [], testList: any, flag: boolean) => {
  let chartData: any = {
    labels: [],
    datasets: buildSectionList(testData)?.map((v: any) => {
      return { id: v?._id, label: v.label, data: [] };
    }),
  };
  const data = flag ? getTestList(testData, testList) : testData;
  data?.forEach((v: any) => {
    if (v?.rank?.sections?.length > 0) {
      v?.rank?.sections.forEach((u: any) => {
        const currentSection = chartData?.datasets.find(
          (sec: any) => sec?.label === u?.section?.name
        );
        if (chartData?.labels.includes(v?.test?.name)) {
          chartData['labels'] = [...chartData?.labels];
        } else chartData['labels'].push(v?.test?.name);
        if (currentSection) {
          currentSection.data = [
            ...currentSection.data,
            u?.percentile?.toFixed(2),
          ];
          currentSection.backgroundColor =
            SECTION_CHART_COLOR[u?.section?.name];
          currentSection.barThickness = 60;
        }
      });
    } else {
      chartData['labels'].push(v?.test?.name);
      (chartData.datasets = chartData.datasets?.map((v: any) => {
        return { id: v?._id, label: v.label, data: [0] };
      })),
        (chartData.backgroundColor = 'blue');
      chartData.barThickness = 60;
    }
  });
  return chartData;
};

export const getDropDownArray = (arr: any) => {
  let data: any = [];
  arr?.map((v: any) => {
    if (v?.name && v?._id) {
      const obj = {
        ...v,
        label: v?.name,
        value: v?._id,
      };
      data.push(obj);
    }
  });
  return data;
};

export const buildTopPerformerData = (topPerformerData: []) =>
  topPerformerData?.map((v: any) => {
    return {
      bestScore: v?.bestScore,
      rank: v?.rank,
      totalTime: getTotalTimeSpent(v?.testAttempt),
      id: v?.user?._id,
      name: v?.user?.name,
      percentile: v?.percentile?.toFixed(2),
    };
  });
