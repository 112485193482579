// import { PageHeader } from '@lipihipi/ec-ui';
import React, { useEffect, useState } from 'react';
// import Styled from 'styled-components';
import Swal from 'sweetalert2';

// Components
import { DashboardLayout } from '../../components/dashboard-layout/dashboard-layout';
import { ContinueTestCard } from '../../components/continue-test-card/continue-test-card';
import EducrackAPI from '@lipihipi/client-sdk';

// const InstructionsFrame = Styled.div`
//   min-height: 300px;
//   background: white;
//   overflow: auto;
// `;

export const TestInstructions = ({ isFromTest = false, ...props }: any) => {
  const _window = window as any;

  const [testDetails, setTestDetails] = useState<any>({});

  const { testId, onTestContinueClick } = props;
  useEffect(() => {
    if (props.attempt) {
      setTestDetails(props.attempt);
      return;
    }
    async function init() {
      try {
        let data: any = {};
        if (testId && props?.isPsychometric) {
          data = await EducrackAPI.psychometricUserTest.get(testId, {
            populate: true,
          });
        } else {
          data = await props.getTest(testId, {
            populate: true,
          });
          console.log({ data });
        }
        setTestDetails(data?.data);
      } catch (err) {
        console.error(err);
        if (err?.status === 404) {
          Swal.fire({
            title: 'Sorry',
            text: 'No attempt found for this test.',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
          }).then(() => {
            apiError();
          });
        } else {
          Swal.fire({
            title: 'Error',
            text:
              err?.message ||
              'Something went wrong, please try after some time.',
            icon: 'error',
          }).then(() => {
            apiError();
          });
        }
      }
    }
    init();
  }, []);

  const apiError = () => {
    if (props?.isPsychometric) {
      window.location.href = '/login';
      return;
    }
    _window.close();
  };

  const { logo } = EducrackAPI.getConfig();

  return (
    <React.Fragment>
      <DashboardLayout>
        {!isFromTest && (
          <header className="secondary-header">
            <img src={EducrackAPI.asset.getAssetUrl(logo?.key)} alt="logo" />

            <p className="m-0">Instructions</p>

            {props?.user?.name || ''}
            <img
              src={
                props?.user && props?.user?.displayPicture
                  ? props.getAssetUrl(props?.user?.displayPicture)
                  : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMPSURBVEhLrZZZTyJBEIBl8AAVDzzwIBokECWEw3f/vA++GmJi1Bhj4k1UTkHw/MauHQemp8Xd/RKxq2iqqquqaybw8fExZIQNT09P9Xr9+fm52+2+v7+PjIyEw+GJiYmZmZlAICD7fDA5aLVa19fXd3d3OBBVL5ZlLS4urq+vT09Pi8qD3sHLy8vp6ent7S0LURnhKJlMZnJyUmQXGgePj4+Hh4eEL/LApFKpRCIhwh/6HVxeXp6cnLy+vor8S2KxWC6XcxemxwHWj4+P397eRP4rqEo+n3d8WOofVKvVf7cO5XL5/PxcBMcBOfkv1hU0SK1WU2txcHFx4aj8CAaDU1NTdOTo6KiofCDttIla2zWgF/f29jqdjlJ5GR4eXltbW1hYYKE0RHN2dmbutEKhQD3sE5A1g3XizWazy8vLjnXgHNvb27S/yDqurq74tB1wV780GmiGZDLJVBDZBUff2toypOv+/p6iWvwZsk+ks7OzInhgVKyurorggQgeHh6sZrNpmAdU1TzOzFnCuMWAFEkHg1NWPpg3MCUtxq9IOn4cduYNXK/vm6ylUqnIygfuv6x0kF7qZPLRaDR41IjggQZhpIugg862tEPcDSOEZ5kILrDOSGi32yLr4MFnhUIh9w3yQhfs7+8TqVMt+o/OPjo6otOVxo9IJGKPilKpZLhrQAR2uSzL/kEgwLUncPrHXGFm187Oju3g5ubm4OBA1L2Mj49zlebm5sgSBaev2T82Nkb7A13IlPRrhKWlJfvhww84++7ubt+FINJ4PI51AhGVDzjQjnqG1fz8vN1CnJ2Bo7QKpWGC/mgdmCVE2jeUGL1YZyE9SrDOqwdnQuTpqsRBIJObm5sifNVsY2NDrcUBCeFBqtopGo2urKwo/eBQf5UGTKXTaSfc71tGvxaLRZIzSN61cGiM8B7mHrE9bxVAnxCCCL+HbuYo7unQ7wBoKjrdPAS9EBZ19t5ZjQMFlwj8vu0Du9w7d+AOvg6ArzgyDe7tcQUWqRbWtaYVJgcO7CFjfDqbsUhODHaFoaFPopaZfyViswsAAAAASUVORK5CYII='
              }
              alt=""
            />
          </header>
        )}
        <div className="instruction-box">
          <div className="instruction-box--body">
            <ol type="1">
              <li>Test Name : {testDetails.name}</li>
              {testDetails?.examType && (
                <li>Test Type : {testDetails.examType}</li>
              )}
              <li>Test Duration : {testDetails.totalDurationInMinute} Min</li>
              <li>
                Total Questions :{' '}
                {testDetails.totalQuestions
                  ? testDetails.totalQuestions
                  : testDetails.questions?.length}
              </li>
              {testDetails?.totalMarks && (
                <li>Total Marks : {testDetails.totalMarks}</li>
              )}
              {testDetails.negativeMarking?.isEnabled && (
                <li>
                  {' '}
                  Negative Marking : {testDetails.negativeMarking?.value} marks
                </li>
              )}
              {testDetails.notAttempted?.isEnabled && (
                <li>
                  {' '}
                  Not Attempted : {testDetails.notAttempted?.value} marks
                </li>
              )}
            </ol>
            <div
              dangerouslySetInnerHTML={{
                __html: testDetails.instruction,
              }}
            ></div>
          </div>

          {!props.attempt && !props?.isFromTest && (
            <div className="instruction-box--footer">
              {!props?.isFromTest && (
                <ContinueTestCard
                  testDetails={testDetails}
                  onTestContinueClick={onTestContinueClick}
                />
              )}
            </div>
          )}

          {/* {props.attempt ? (
            <PrimaryButton isWhite onClick={() => props.onRequestClose()}>
              Close
            </PrimaryButton>
          ) : (
            <div className="instruction-box--footer">
              {!props?.isFromTest && (
                <ContinueTestCard
                  testDetails={testDetails}
                  onTestContinueClick={onTestContinueClick}
                />
              )}
            </div>
          )} */}
        </div>
      </DashboardLayout>
    </React.Fragment>
  );
};
