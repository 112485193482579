import React from 'react';

const Label = ({ label, id, required, ...props }: any) => {
  return label ? (
    <label htmlFor={id} {...props}>
      {label} {required && <span className="text-danger">*</span>}
    </label>
  ) : null;
};

export default Label;
