import React, { FC, useEffect, useState } from 'react';
import { Input, Select } from '@lipihipi/form';
import { Formik, Form } from 'formik';
import { validationSchema } from '../../../utils/validations';

const PersonalDetailForm: FC<any> = (props: any) => {
  const [centersData, setCentersData] = useState<any>([]);
  const [url, setUrl] = useState<any>([]);
  const absoluteUrl = window.location.href;

  useEffect(() => {
    const getCenterList = async () => {
      try {
        const { data } = await props.centerList({ all: true });
        data.centers = data.centers.map((center: any) => ({
          label: center.name,
          value: center._id,
        }));
        setCentersData(data.centers);
      } catch {
        console.log('error');
      }
    };
    getCenterList();
    setUrl(absoluteUrl);
  }, []);
  return (
    <div className="user-structure--form">
      <div className="wrap">
        <h2 className="m-0">Personal Details</h2>
        <div className="or-seprator"></div>
        <Formik
          initialValues={{
            name: '',
            email: '',
            centre: '',
          }}
          validationSchema={validationSchema.register}
          onSubmit={props.handleSubmit}
        >
          <Form>
            <Input
              type="text"
              name="name"
              label="Name"
              id="name"
              required={true}
            />

            <Input
              type="text"
              name="email"
              label="Email"
              id="email"
              required={true}
            />
            {/* <Select
              id="role"
              label="I'm interested in joining Educrack as"
              name="role"
              className="form-group d-none"
              options={[
                { label: 'Educator', value: 'educator' },
                { label: 'Student', value: 'user' },
              ]}
            /> */}
            {url.includes('https://student.cat4me.com/') ? (
              <Select
                id="reference"
                label="From where you heard about Us?"
                name="reference"
                className="form-group"
                options={[
                  { label: 'Our Coaching', value: 'Our Coaching' },
                  { label: 'Our Mentor', value: 'Our Mentor' },
                  { label: 'Social Media', value: 'Social Media' },
                  { label: 'Google', value: 'Google' },
                ]}
              />
            ) : (
              <Select
                id="center"
                name="center"
                label={'Center Code'}
                placeholder="Select a Center Code"
                options={centersData}
              />
            )}

            <div className="button-group">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: '100%' }}
              >
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PersonalDetailForm;
