import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Modal } from '@lipihipi/ec-ui';
import { Form, Select } from '@lipihipi/form';
import DatePicker from 'react-datepicker';
import { BiCalendarAlt } from 'react-icons/bi';

import { CreatePost, WallPost } from '../index';

const Wall = ({
  createAsset,
  getAssetUrl,
  getWallList,
  createComment,
  createPost,
  removePost,
  removeComment,
  user,
  currentModulePermission,
  createWallResponse,
  updateWallResponse,
  // getSubjects,
  type,
  // getPost,
  getCourse,
  selectedCourse,
  // getTeachers,
  getEducators,
  updateWall,
}: any) => {
  const initialValue = {
    page: 1,
    populate: true,
    isRecent: true,
    course: null,
    user: null,
    postType: null,
    startDate: '',
    endDate: '',
  };
  const [isOpen, setOpen] = useState<boolean>(false);
  const [wallArr, setWallArr] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [courseOptions, setcourseOptions] = useState<any>([]);
  const [educatorOptions, setEducatorOptions] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [params, setParams] = useState<any>({ ...initialValue });
  const [modal, showModal] = useState({
    show: false,
    data: {},
  });

  useEffect(() => {
    getFeeds();
  }, [params]);

  useEffect(() => {
    if (selectedCourse) {
      setParams({ ...params, course: selectedCourse._id });
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (user.role === 'admin' || user.role === 'superadmin') {
      getCourse({ all: true }).then((res: any) => {
        setcourseOptions(
          res.data.courses.map((i: any) => ({ label: i.name, value: i._id }))
        );
      });

      getEducators({ all: true, populate: true }).then((res: any) => {
        setEducatorOptions(
          res.data.educators.map((i: any) => ({
            label: i?.name,
            value: i?._id,
          }))
        );
      });
    }

    if (user.role === 'user') {
      let data = user?.selectedCourses || [];
      setcourseOptions(
        data.map((i: any) => ({
          label: i.course.name,
          value: i.course._id,
        }))
      );
    } else {
      let data = user?.subjectDetail;

      setcourseOptions(
        data?.course?.map((course: any) => ({
          label: course.name,
          value: course._id,
        }))
      );
    }
  }, []);

  const getFeeds = () => {
    getWallList(params).then((res: any) => {
      let arr = res.data.walls;
      if (params.page === 1) {
        setWallArr(arr);
      } else {
        setWallArr([...wallArr, ...arr]);
      }
      if (arr.length) {
        setHasMore(true);
      }
    });
  };

  const renderWallFeed = () => {
    return wallArr.map((item: any) => {
      return (
        <div key={item?._id}>
          <WallPost
            item={item}
            type={type}
            createWallResponse={createWallResponse}
            User={user}
            currentModulePermission={currentModulePermission}
            wallArr={wallArr}
            setWallArr={setWallArr}
            updateWallResponse={updateWallResponse}
            createComment={createComment}
            getFeeds={getFeeds}
            removeComment={removeComment}
            removePost={removePost}
            getAssetUrl={getAssetUrl}
            updateWall={updateWall}
            showModal={showModal}
          />
        </div>
      );
    });
  };

  const isVisible = () => {
    if (user.role !== 'user' && currentModulePermission.includes('create'))
      return true;
    return false;
  };

  const onRequestClose = () => {
    if (modal?.show) {
      showModal({
        show: false,
        data: {},
      });
    } else setOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={modal?.show ? modal?.show : isOpen}
        onRequestClose={() => onRequestClose()}
      >
        <CreatePost
          createAsset={createAsset}
          // getFeeds={getFeeds}
          // type={type}
          User={user}
          // selectedCourse={selectedCourse}
          // getTeachers={getTeachers}
          setWallArr={setWallArr}
          wallArr={wallArr}
          // getPost={getPost}
          // getSubjects={getSubjects}
          createPost={createPost}
          courseOptions={courseOptions}
          setOpen={setOpen}
          isEdit={modal?.show}
          editData={modal?.data}
          updateWall={updateWall}
          showModal={showModal}
          getAssetUrl={getAssetUrl}
        />
      </Modal>

      <section className="main-structure educrack-wall">
        <div className="wall-grid">
          {(currentModulePermission &&
            currentModulePermission?.includes('read')) ||
          user.role === 'user' ? (
            <div className="content">
              {isVisible() && (
                <div className="create-button">
                  <Button shape={'primary'} onClick={() => setOpen(true)}>
                    create {type}
                  </Button>
                </div>
              )}

              <InfiniteScroll
                dataLength={10}
                next={() => setParams({ ...params, page: params.page + 1 })}
                hasMore={hasMore}
                loader=""
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {renderWallFeed()}
              </InfiniteScroll>
            </div>
          ) : (
            <div className="content">
              <h4>Sorry!, you don't have permission to see this.</h4>
            </div>
          )}
          {user.role !== 'user' ? (
            <div className="filter">
              <h3>Filters and Sorting</h3>
              <Form
                initialValues={{ ...initialValue }}
                onSubmit={() => {}}
                render={({
                  values,
                  errors,
                  setFieldValue,
                  resetForm,
                  //@ts-ignore
                  ...rest
                }: any) => {
                  return (
                    <div className="row">
                      <div className="col-md-12">
                        <Select
                          id={'course'}
                          name={'course'}
                          placeholder={'Course'}
                          options={
                            courseOptions
                              ? [{ label: 'All', value: '' }, ...courseOptions]
                              : []
                          }
                          onChange={(e: any) => {
                            setParams({ ...params, course: e.value, page: 1 });
                          }}
                        />
                      </div>
                      {user?.role === 'admin' || user?.role === 'superadmin' ? (
                        <div className="col-md-12">
                          <Select
                            id={'user'}
                            name={'user'}
                            placeholder={'Educator'}
                            options={[
                              { label: 'All', value: '' },
                              ...educatorOptions,
                            ]}
                            onChange={(e: any) => {
                              setParams({ ...params, user: e.value, page: 1 });
                            }}
                          />
                        </div>
                      ) : null}
                      <div className="col-md-12">
                        <Select
                          id={'postType'}
                          name={'postType'}
                          placeholder={'Post Type'}
                          options={[
                            { label: 'All', value: '' },
                            { label: 'Normal', value: 'normal' },
                            { label: 'Quiz', value: 'quiz' },
                          ]}
                          onChange={(e: any) => {
                            setParams({
                              ...params,
                              postType: e.value,
                              page: 1,
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="datepicker-wrap form-group">
                          <DatePicker
                            id={'startDate'}
                            name={'startDate'}
                            className="primary-form-control"
                            selected={startDate}
                            selectsStart
                            placeholderText="Start Date"
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat={'MMMM-dd-yyyy'}
                            onChange={(date: any) => {
                              setParams({ ...params, startDate: date });
                              setStartDate(date);
                            }}
                          />
                          <BiCalendarAlt />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="datepicker-wrap form-group">
                          <DatePicker
                            id={'endDate'}
                            name={'endDate'}
                            className="primary-form-control"
                            selected={endDate}
                            selectsEnd
                            dateFormat={'MMMM-dd-yyyy'}
                            placeholderText="End Date"
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(date: any) => {
                              setParams({ ...params, endDate: date });
                              setEndDate(date);
                            }}
                            minDate={startDate}
                            maxDate={new Date()}
                          />
                          <BiCalendarAlt />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type={'button'}
                          shape={'secondary'}
                          onClick={() => {
                            resetForm();
                            setParams(initialValue);
                            setStartDate(null);
                            setEndDate(null);
                          }}
                        >
                          All Clear
                        </Button>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          ) : (
            <div className="filter"></div>
          )}
        </div>
      </section>
    </>
  );
};

export default Wall;
