import { client } from './client';
import {
  TCreateResource,
  TGetResource,
  TListResource,
  IListParams,
  IDocument,
  ObjectId,
  PopulatedUserDocument,
} from './types';

export interface IPaymentResponse {
  status: string;
  paymentLink: string;
  reason: string;
}
export interface IOrder {
  id: string;
  entity: string;
  amount: number;
  amount_paid: number;
  amount_due: number;
  currency: string;
  receipt: string;
  offer_id: string;
  status: string;
  attempts: number;
  notes: string[];
  created_at: string;
}
export interface IPayment {
  order_type: string;
  testBundleId: string;
  interviewId: string;
  batchId: string;
  user: string;
  receiptId: string;
  order: IOrder;
  payment_status: string;
}
export interface IPaymentListResponse {
  totalItems: number;
  payments: IPaymentDocument[];
}
export interface IPaymentDocument extends IPayment, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IPaymentStatusResponse {
  orderStatus: string;
  txStatus: string;
  orderAmount: string;
  txMsg: string;
}
export interface IPayemtRequest {
  type: string;
  id: string;
  coupon?: string;
  consent?: boolean;
  user?: string;
  payment_type?: Payment_Type;
  transaction_id?: string;
}
export enum Payment_Type {
  EMI = 'EMI',
  FULL_PAYMENT = 'FULL_PAYMENT',
}
export enum Module {
  BATCH = 'BATCH',
  INTERVIEW = 'INTERVIEW',
  TESTBUNDLE = 'TESTBUNDLE',
}
export interface ICouponResponse {
  price?: string;
  message?: string;
}
const list: TListResource<IListParams, IPaymentListResponse> = params => {
  return client.get(`/payment/`, { params });
};

const createOrder: TCreateResource<IPayemtRequest, IPaymentResponse> = (
  body: IPayemtRequest
) => {
  return client.post<IPaymentResponse>(`/payment/order`, body);
};
const getPaymentStatus: TGetResource<IPaymentStatusResponse> = (
  orderId: string
) => {
  return client.get<IPaymentStatusResponse>(`/payment/get-status/${orderId}`);
};
const applyCoupon: TCreateResource<IPayemtRequest, ICouponResponse> = (
  body: IPayemtRequest
) => {
  return client.post<ICouponResponse>(`/payment/apply-coupon/`, body);
};

const applyStudentCoupon: TCreateResource<IPayemtRequest, ICouponResponse> = (
  body: IPayemtRequest
) => {
  return client.post<ICouponResponse>(`/payment/apply-coupon/`, body);
};

const getInvoice = (id: string) => {
  return client.get(`/payment/invoice/${id}`, { responseType: 'blob' });
};

const adminPayment = (body: IPayemtRequest) => {
  return client.post(`/payment/user`, body);
};

const updatePayment = (id: string, body: IPayemtRequest) => {
  return client.patch(`/payment/${id}`, body);
};

export const payment = {
  list,
  createOrder,
  getPaymentStatus,
  applyCoupon,
  applyStudentCoupon,
  getInvoice,
  adminPayment,
  updatePayment,
};
